/* eslint-disable */

const $ = require("jquery");
const APIConfig = require("apiconfig");
const _ = require("underscore");
const Marionette = require("backbone.marionette");
const DomEvents = require("../helpers/dom_events");
require("jquery.cookie");
const { getDatadogRumOptions } = require("../datadog");

// models
const TokensEmailModel = require("../models/tokens_email_model");
const AccountProfileModel = require("../models/account_profile_model");
const UserTypeModel = require("../models/user_type_model");
const Cookies = require("js-cookie");
const constants = require("../constants");
const { shouldShowLogout } = require("../helpers/shouldShowLogout");
const {
  showGlobalNavBarUpdates,
} = require("src/helpers/featureFlags/showGlobalNavBarUpdates");

module.exports = {
  loadInitialData(authController, tiara, configureClient) {
    let ready,
      tiaraParams,
      deferredUserData,
      shouldRenderSidebar,
      originalMonitorDOMRefresh;

    originalMonitorDOMRefresh = Marionette.MonitorDOMRefresh;

    Marionette.MonitorDOMRefresh = function (view) {
      view.on("dom:refresh", () => {
        if (deferredUserData) {
          deferredUserData.scopes.done(() => {
            authController.deny(view);
          });

          view.$el.find("[data-confirm]").confirm();
        }

        DomEvents.checkInputFields();
        DomEvents.select2Everywhere();
        DomEvents.tooltipify();
      });

      originalMonitorDOMRefresh(view);
    };

    ready = new $.Deferred();
    const unifiedUserInfoCookie = Cookies.get(
      constants.UNIFIED_USER_INFO_COOKIE_NAME
    );
    tiaraParams = {
      jQuery: $,
      underscore: _,
      api_host: APIConfig.host,
      target: "[role=container]",
      sg_host: APIConfig.sendgrid_com,
      nlvxHost: APIConfig.nlvx_host,
      makoHost: APIConfig.mako_host,
      mcAutoHost: APIConfig.mc_host,
      // This toggles Tiara injecting Segment and other third party scripts.
      includeThirdPartyScripts:
        APIConfig.thirdPartyScripts && !window.denylistInit,
      datadogRum: getDatadogRumOptions(),
      unifiedUserInfo: unifiedUserInfoCookie
        ? JSON.parse(unifiedUserInfoCookie)
        : undefined,
      shouldShowGlobalNavBarUpdates: showGlobalNavBarUpdates(),
    };

    const isOnSubuserAccessPath = this.routeStartsWith("subuser/access");

    if (!this.shouldRenderSidebar(authController)) {
      tiaraParams.shouldHideSidebar = true;
      // not going to reuse anything from tiara, we're on basic pages like signup, provision, error, etc that don't reuse fetched tiara data
      tiaraParams.logoutCallback = function () {
        /* no-op */
      };
      tiaraParams.shouldHideSegmentIntegrations = true;
      tiaraParams.shouldMakeRequests = false;
      tiara.init(tiaraParams); // Init strictly for analytics

      ready.resolve({
        account: [{}],
        profile: [{}],
        nonImpersonatedProfile: [{}],
        feature_toggles: [[]],
        scopes: [{}],
        signupStatus: [{}],
        email: [{}],
        username: [{}],
        nonImpersonatedUsername: [{}],
        accountProfile: [{}],
        userPackage: [{}],
        camAccount: [{}],
        userType: [{}],
        subuserAccess: [{}],
      });
      authController.setPrefilter();
      return ready;
    }

    if (isOnSubuserAccessPath) {
      tiaraParams.shouldHideSidebar = true;
    }

    const shouldShowLogoutPage = shouldShowLogout();
    if (!shouldShowLogoutPage) {
      tiaraParams.logoutCallback = function (clickedLogOut) {
        // Being called by the Sign Out of Account menu item
        if (clickedLogOut) {
          window.EventBus.trigger("redirectToLoginAppLogoutPage");
          return;
        }
        /* no-op */
        // Tiara I want to log out not during the sign out of account menu item
        window.EventBus.trigger("redirectToLoginAppPageRedirect");
      };
    }
    deferredUserData = tiara.init(tiaraParams);

    tiara.deferreds = deferredUserData;

    deferredUserData.scopes.done((data) => {
      authController.scopes = authController.scopes || {};
      if (data) {
        const scopes = data.scopes;
        for (let i = 0; i < scopes.length; i++) {
          authController.scopes[scopes[i]] = true;
        }
      }
    });

    // after sidebar loads, clean the url and set prefilters
    $.whenAll([
      deferredUserData.account,
      deferredUserData.profile,
      deferredUserData.nonImpersonatedProfile,
      deferredUserData.feature_toggles,
      deferredUserData.scopes,
      deferredUserData.signup_status,
      deferredUserData.pkg,
      deferredUserData.camAccount,
      deferredUserData.username,
      deferredUserData.nonImpersonatedUsername,
      deferredUserData.subuserAccess,
      deferredUserData.userProducts,
      configureClient,
    ]).done(
      (
        account,
        profile,
        nonImpersonatedProfile = [{}],
        feature_toggles,
        scopes,
        signupStatus,
        userPackage,
        camAccount,
        username,
        nonImpersonatedUsername = [{}],
        subuserAccess,
        oesAccount
      ) => {
        window.track("Finished first batch of deferreds");
        const configureClient = configureClient;
        authController.setPrefilter();

        const tokensEmailModel = new TokensEmailModel();
        const emailXHR = tokensEmailModel.fetch();

        const accountProfileModel = new AccountProfileModel();
        const accountProfileXHR = accountProfileModel.fetch();

        const userTypeModel = new UserTypeModel();
        const userTypeXHR = userTypeModel.fetch();

        $.whenAll([emailXHR, accountProfileXHR, userTypeXHR]).done(
          (email, accountProfile, userType, configureClient) => {
            window.track("Finished second batch of deferreds");

            if (
              _.some(
                [
                  account,
                  profile,
                  nonImpersonatedProfile,
                  feature_toggles,
                  scopes,
                  signupStatus,
                  email,
                  username,
                  nonImpersonatedUsername,
                  accountProfile,
                  userPackage,
                  camAccount,
                  userType,
                ],
                (def) => def[2] === "UNAUTHORIZED"
              )
            ) {
              ready.fail();
            } else {
              ready.resolve({
                account,
                profile,
                nonImpersonatedProfile,
                feature_toggles,
                scopes,
                signupStatus,
                email,
                username,
                nonImpersonatedUsername,
                accountProfile,
                userPackage,
                camAccount,
                userType,
                subuserAccess,
                oesAccount,
              });
            }
          }
        );
      }
    );

    return ready;
  },

  // private

  getImpersonatingUsername() {
    const regExForUsername = /[\?&]impersonatingUsername=([^&]+)/;
    const result = location.search.match(regExForUsername);
    if (result) {
      return decodeURIComponent(result[1]);
    }
    return null;
  },

  shouldRenderSidebar(authController) {
    // never show sidebar if the auth token doesn't exist
    if (!authController.checkAuthToken()) {
      return false;
    }

    // signup or error pages, public pages, or 2fa pages
    return !this.routeStartsWith(
      "signup|errors|login|forgot_password|reset_password|validate_2fa|account/billing/thanks|public|reset_credentials|teammates/confirm|teammates/setup_2fa|security_checkup|password_reset_required|oauth|2fa_email_checkpoint|token_exchange|payment|reseller/sso/error|login/identifier|login/password|auth0/callback|unified_login/start|onboarding"
    );
  },

  routeStartsWith(routePrefix) {
    let route = this.getCurrentRoute();
    if (route.indexOf(APIConfig.root) === 0) {
      route = route.substring(APIConfig.root.length);
    }

    const renderSidebar = new RegExp(`^\/?(${routePrefix})`);
    return renderSidebar.test(route);
  },

  getCurrentRoute() {
    // Backbone.history hasn't started yet, so manually get route
    return window.location.pathname;
  },
};
