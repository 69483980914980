import { AuthedHttp } from '../../../../helpers/AuthedHttp';
import { SubuserRoutes } from '../apiRoutes';

export interface SubuserDto {
  username: string;
  email: string;
  id: number;
  disabled: boolean;
}

export const getSubusers = async (
  inputAccountPrefix: string
): Promise<SubuserDto[]> => {
  try {
    const getSubusersPromise = await AuthedHttp.get<SubuserDto[]>(
      SubuserRoutes.getSubusers({ username: inputAccountPrefix })
    );

    if (!getSubusersPromise.ok) {
      throw new Error(`Error: ${getSubusersPromise.status}`);
    }

    const subusers = await getSubusersPromise.json();

    return subusers;
  } catch (e) {
    throw new Error(e);
  }
};

export default getSubusers;
