const _ = require("underscore");
const Backbone = require("backbone");

const show = function (container, message, severity, rendersAsHtml) {
  const $container = $(container);
  const $flash = $container.find("[role=flash]");
  const $flashMessage = $container.find("[role=flash] span");
  const $flashIcon = $container.find("[role=flash-icon]");

  const stripModifiers = (function () {
    $flash.removeClass("alert-success");
    $flash.removeClass("alert-warning");
    $flash.removeClass("alert-danger");
    $flashIcon.removeClass("sg-icon-check-circle");
    $flashIcon.removeClass("sg-icon-warning");
    $flashIcon.removeClass("sg-icon-info-circle");
  })();

  if (severity === "danger") {
    $flash.addClass("alert-danger");
    $flashIcon.addClass("sg-icon-warning");
    $flash.data("role", "alert-failure"); // Automated tests need this data-role to be set. https://issues.corp.twilio.com/browse/MAKO-5867
  } else if (severity === "warning") {
    $flash.addClass("alert-warning");
    $flashIcon.addClass("sg-icon-warning");
    $flash.data("role", "alert-warning"); // Automated tests need this data-role to be set. https://issues.corp.twilio.com/browse/MAKO-5867
  } else if (severity === "success") {
    $flash.addClass("alert-success");
    $flashIcon.addClass("sg-icon-check-circle");
    $flash.data("role", "alert-success"); // Automated tests need this data-role to be set. https://issues.corp.twilio.com/browse/MAKO-5867
  } else {
    $flashIcon.addClass("sg-icon-info-circle");
    $flash.data("role", "alert-info");
  }

  message =
    message || "Sorry, an error occurred. Engineers have been notified.";
  if (rendersAsHtml) {
    $flashMessage.html(message);
  } else {
    $flashMessage.text(message);
  }
  $flash.fadeIn();
  return $flash;
};

const Flash = function () {};

Flash.prototype = _.extend(
  {
    success(container, message, renderAsHtml) {
      if (_.isUndefined(renderAsHtml)) {
        renderAsHtml = false;
      }
      return show.call(this, container, message, "success", renderAsHtml);
    },
    warning(container, message, renderAsHtml) {
      if (_.isUndefined(renderAsHtml)) {
        renderAsHtml = false;
      }
      return show.call(this, container, message, "warning", renderAsHtml);
    },
    danger(container, message, renderAsHtml) {
      if (_.isUndefined(renderAsHtml)) {
        renderAsHtml = false;
      }
      return show.call(this, container, message, "danger", renderAsHtml);
    },
    info(container, message, renderAsHtml) {
      if (_.isUndefined(renderAsHtml)) {
        renderAsHtml = false;
      }
      return show.call(this, container, message, null, renderAsHtml);
    },
  },
  Backbone.Events
);

module.exports = new Flash();
