import { TypedMap } from '../../../../@types/TypedMap';
import { PlanTierFeatures } from '../../../types/planTierFeatures';
import { OfferingDto, EmailApiTierDto } from '../../services/oes/dtos';
import { EmailApiPlanTier } from '../../../types/emailApiPlan';
import { getTierSummaryMap } from '../../common/adapters';
import { PlanType } from '../../../types/planType';
import { getEmailValidationCredits } from '../../user_products/adapters/emailValidation';
import {
  apisRelayAndWebhooks,
  deliveryOptimizationTools,
  dynamicTemplateEditor,
  emailApiAnalytics,
  ticketSupport,
  ticketChatAndPhoneSupport,
  guaranteedTicketAndChatSupport,
  noEmailTesting,
  noDedicatedIps,
  noSubusers,
  dedicatedIp,
  subusers,
  emailActivity,
  noEmailValidation,
  emailTesting,
  emailValidation,
  webhooks,
} from './features';

interface DynamicFeatureListData {
  easeDays?: number;
  testingCredits?: number;
  teammates?: number;
  emailValidations?: number;
  webhooks?: number;
}

export function mapEmailApiFeatures(
  offerings: OfferingDto[]
): PlanTierFeatures {
  const eiSummaries = getTierSummaryMap(offerings, PlanType.EmailApi);

  const dataByTier = Object.keys(eiSummaries).reduce((tierData, tier) => {
    const summary = eiSummaries[tier];
    const { entitlements, pricing } = summary;
    const easeDays = entitlements.ease_days;
    const testingCredits = entitlements.email_testing_cap_monthly;
    const teammates = entitlements.teammates_cap_total;
    const webhooks = entitlements.webhooks_workspace_cap_total;
    const emailValidations = getEmailValidationCredits(pricing);

    tierData[tier] = {
      easeDays,
      testingCredits,
      teammates,
      emailValidations,
      webhooks
    };

    return tierData;
  }, {} as TypedMap<DynamicFeatureListData>);

  const freeTierData = dataByTier[EmailApiTierDto.Free];
  const essentialsTierData = dataByTier[EmailApiTierDto.Essentials];
  const proTierData = dataByTier[EmailApiTierDto.Pro];
  const premierTierData = dataByTier[EmailApiTierDto.Premier];

  const featureLists = {
    [EmailApiPlanTier.Free]: [
      apisRelayAndWebhooks,
      deliveryOptimizationTools,
      dynamicTemplateEditor,
      emailApiAnalytics,
      ticketSupport,
      emailActivity(freeTierData.easeDays),
      webhooks(freeTierData.webhooks),
      noEmailTesting,
      noEmailValidation,
      noDedicatedIps,
      noSubusers,
    ],
    [EmailApiPlanTier.Essentials]: [
      apisRelayAndWebhooks,
      deliveryOptimizationTools,
      dynamicTemplateEditor,
      emailApiAnalytics,
      guaranteedTicketAndChatSupport,
      emailActivity(essentialsTierData.easeDays),
      webhooks(essentialsTierData.webhooks),
      noEmailTesting,
      noEmailValidation,
      noDedicatedIps,
      noSubusers,
    ],
    [EmailApiPlanTier.Pro]: [
      apisRelayAndWebhooks,
      deliveryOptimizationTools,
      dynamicTemplateEditor,
      emailApiAnalytics,
      ticketChatAndPhoneSupport,
      emailActivity(proTierData.easeDays),
      webhooks(proTierData.webhooks),
      emailTesting(proTierData.testingCredits),
      emailValidation(proTierData.emailValidations),
      dedicatedIp,
      subusers,
    ],
    [EmailApiPlanTier.Premier]: [
      apisRelayAndWebhooks,
      deliveryOptimizationTools,
      dynamicTemplateEditor,
      emailApiAnalytics,
      ticketChatAndPhoneSupport,
      emailActivity(premierTierData.easeDays),
      webhooks(premierTierData.webhooks),
      emailTesting(premierTierData.testingCredits),
      emailValidation(premierTierData.emailValidations),
      dedicatedIp,
      subusers,
    ],
  } as PlanTierFeatures;

  return featureLists;
}
