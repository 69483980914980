// jshint ignore:start
/* eslint-disable */
/* istanbul ignore next */

!(function a(b, c, d) {
  function e(g, h) {
    if (!c[g]) {
      if (!b[g]) {
        const i = typeof require === "function" && require;
        if (!h && i) return i(g, !0);
        if (f) return f(g, !0);
        const j = new Error(`Cannot find module '${g}'`);
        throw ((j.code = "MODULE_NOT_FOUND"), j);
      }
      const k = (c[g] = { exports: {} });
      b[g][0].call(
        k.exports,
        (a) => {
          const c = b[g][1][a];
          return e(c || a);
        },
        k,
        k.exports,
        a,
        b,
        c,
        d
      );
    }
    return c[g].exports;
  }
  for (
    var f = typeof require === "function" && require, g = 0;
    g < d.length;
    g++
  )
    e(d[g]);
  return e;
})(
  {
    1: [
      function (a, b) {
        (function (c) {
          let d, e;
          (e = a("./util/merge.coffee")),
            (d = (function () {
              function a(a, b) {
                (this._projectId = 0),
                  (this._projectKey = ""),
                  (this._host = "https://api.airbrake.io"),
                  (this._context = {}),
                  (this._params = {}),
                  (this._env = {}),
                  (this._session = {}),
                  (this._processor = a),
                  (this._reporters = []),
                  (this._filters = []),
                  b && this.addReporter(b);
              }
              return (
                (a.prototype.setProject = function (a, b) {
                  return (this._projectId = a), (this._projectKey = b);
                }),
                (a.prototype.setHost = function (a) {
                  return (this._host = a);
                }),
                (a.prototype.addContext = function (a) {
                  return e(this._context, a);
                }),
                (a.prototype.setEnvironmentName = function (a) {
                  return (this._context.environment = a);
                }),
                (a.prototype.addParams = function (a) {
                  return e(this._params, a);
                }),
                (a.prototype.addEnvironment = function (a) {
                  return e(this._env, a);
                }),
                (a.prototype.addSession = function (a) {
                  return e(this._session, a);
                }),
                (a.prototype.addReporter = function (a) {
                  return this._reporters.push(a);
                }),
                (a.prototype.addFilter = function (a) {
                  return this._filters.push(a);
                }),
                (a.prototype.push = function (a) {
                  let b, d;
                  return (
                    (b = {
                      language: "JavaScript",
                      sourceMapEnabled: !0,
                    }),
                    ((d = c.navigator) != null ? d.userAgent : void 0) &&
                      (b.userAgent = c.navigator.userAgent),
                    c.location && (b.url = String(c.location)),
                    this._processor(
                      a.error || a,
                      (function (c) {
                        return function (d, f) {
                          let g, h, i, j, k, l, m, n, o;
                          for (
                            l = {
                              notifier: {
                                name: `airbrake-js-${d}`,
                                version: "0.3.12",
                                url: "https://github.com/airbrake/airbrake-js",
                              },
                              errors: [f],
                              context: e(b, c._context, a.context),
                              params: e({}, c._params, a.params),
                              environment: e({}, c._env, a.environment),
                              session: e({}, c._session, a.session),
                            },
                              m = c._filters,
                              h = 0,
                              j = m.length;
                            j > h;
                            h++
                          )
                            if (((g = m[h]), !g(l))) return;
                          for (
                            n = c._reporters, i = 0, k = n.length;
                            k > i;
                            i++
                          )
                            (o = n[i])(l, {
                              projectId: c._projectId,
                              projectKey: c._projectKey,
                              host: c._host,
                            });
                        };
                      })(this)
                    )
                  );
                }),
                (a.prototype._wrapArguments = function (a) {
                  let b, c, d, e;
                  for (c = d = 0, e = a.length; e > d; c = ++d)
                    (b = a[c]),
                      typeof b === "function" && (a[c] = this.wrap(b));
                  return a;
                }),
                (a.prototype.wrap = function (a) {
                  let b, c, d;
                  if (a.__airbrake__) return a;
                  (d = this),
                    (b = function () {
                      let b, c;
                      b = d._wrapArguments(arguments);
                      try {
                        return a.apply(this, b);
                      } catch (e) {
                        return (
                          (c = e),
                          (b = Array.prototype.slice.call(arguments)),
                          d.push({ error: c, params: { arguments: b } }),
                          null
                        );
                      }
                    });
                  for (c in a) a.hasOwnProperty(c) && (b[c] = a[c]);
                  return (b.__airbrake__ = !0), (b.__inner__ = a), b;
                }),
                a
              );
            })()),
            (b.exports = d);
        }.call(
          this,
          typeof global !== "undefined"
            ? global
            : typeof self !== "undefined"
            ? self
            : typeof window !== "undefined"
            ? window
            : {}
        ));
      },
      { "./util/merge.coffee": 10 },
    ],
    2: [
      function (a) {
        (function (b) {
          let c, d, e, f, g, h, i, j;
          if (
            (a("./util/compat.coffee"),
            (c = a("./client.coffee")),
            (h = a("./processors/stack.coffee")),
            (i = a("./reporters/hybrid.coffee")),
            (d = new c(h, i)),
            (d.consoleReporter = a("./reporters/console.coffee")),
            (j = b.Airbrake),
            (b.Airbrake = d),
            a("./util/slurp_config_from_dom.coffee")(d),
            j != null)
          )
            for (
              j.wrap != null && (d.wrap = j.wrap),
                j.onload != null && j.onload(d),
                f = 0,
                g = j.length;
              g > f;
              f++
            )
              (e = j[f]), d.push(e);
        }.call(
          this,
          typeof global !== "undefined"
            ? global
            : typeof self !== "undefined"
            ? self
            : typeof window !== "undefined"
            ? window
            : {}
        ));
      },
      {
        "./client.coffee": 1,
        "./processors/stack.coffee": 3,
        "./reporters/console.coffee": 4,
        "./reporters/hybrid.coffee": 5,
        "./util/compat.coffee": 8,
        "./util/slurp_config_from_dom.coffee": 11,
      },
    ],
    3: [
      function (a, b) {
        let c, d, e;
        (d = [
          {
            name: "v8",
            re: /^\s*at\s(.+?)\s\((?:(?:(.+):(\d+):(\d+))|(.+))\)$/,
            fn(a) {
              return {
                function: a[1],
                file: a[2] || a[5],
                line: (a[3] && parseInt(a[3], 10)) || 0,
                column: (a[4] && parseInt(a[4], 10)) || 0,
              };
            },
          },
          {
            name: "firefox30",
            re: /^(.*)@(.+):(\d+):(\d+)$/,
            fn(a) {
              let b, c, d, e;
              return (
                (d = a[1]),
                (c = a[2]),
                (b = /^(\S+)\s(line\s\d+\s>\seval.*)$/),
                (e = c.match(b)) &&
                  ((d = d.length > 0 ? `${d} ${e[2]}` : e[2]), (c = e[1])),
                {
                  function: d,
                  file: c,
                  line: parseInt(a[3], 10),
                  column: parseInt(a[4], 10),
                }
              );
            },
          },
          {
            name: "firefox14",
            re: /^(.*)@(.+):(\d+)$/,
            fn(a, b, c) {
              let d;
              return (
                (d = b === 0 ? c.columnNumber || 0 : 0),
                {
                  function: a[1],
                  file: a[2],
                  line: parseInt(a[3], 10),
                  column: d,
                }
              );
            },
          },
          {
            name: "v8-short",
            re: /^\s*at\s(.+):(\d+):(\d+)$/,
            fn(a) {
              return {
                function: "",
                file: a[1],
                line: parseInt(a[2], 10),
                column: parseInt(a[3], 10),
              };
            },
          },
          {
            name: "default",
            re: /.+/,
            fn(a) {
              return { function: a[0], file: "", line: 0, column: 0 };
            },
          },
        ]),
          (e = /^\S+:\s.+$/),
          (c = function (a, b) {
            let c, f, g, h, i, j, k, l, m, n, o, p, q, r, s;
            for (
              o = "nostack",
                q = a.stack || "",
                l = q.split("\n"),
                c = [],
                f = g = 0,
                i = l.length;
              i > g;
              f = ++g
            )
              if (((k = l[f]), k !== ""))
                for (h = 0, j = d.length; j > h; h++)
                  if (((p = d[h]), (m = k.match(p.re)))) {
                    (o = p.name), c.push(p.fn(m, f, a));
                    break;
                  }
            return (
              (o === "v8" || o === "v8-short") &&
                c.length > 0 &&
                c[0].function.match(e) &&
                (c = c.slice(1)),
              c.length !== 0 ||
                (a.fileName == null &&
                  a.lineNumber == null &&
                  a.columnNumber == null) ||
                c.push({
                  function: "",
                  file: a.fileName || "",
                  line: parseInt(a.lineNumber, 10) || 0,
                  column: parseInt(a.columnNumber, 10) || 0,
                }),
              c.length !== 0 ||
                (a.filename == null &&
                  a.lineno == null &&
                  a.column == null &&
                  a.colno == null) ||
                c.push({
                  function: "",
                  file: a.filename || "",
                  line: parseInt(a.lineno, 10) || 0,
                  column: parseInt(a.column || a.colno, 10) || 0,
                }),
              (n = a.message != null ? a.message : String(a)),
              a.name != null
                ? ((r = a.name), (n = `${r}: ${n}`))
                : ((s = /^Uncaught\s(.+?):\s.+$/),
                  (m = n.match(s)),
                  (r = m ? m[1] : "")),
              b(o, { type: r, message: n, backtrace: c })
            );
          }),
          (b.exports = c);
      },
      {},
    ],
    4: [
      function (a, b) {
        let c, d;
        (c = function (a) {
          let b, c, d, e, f;
          for (
            f = "", f += `${a.message}\n`, e = a.backtrace, b = 0, c = e.length;
            c > b;
            b++
          )
            (d = e[b]),
              d.function !== "" && (f += ` at ${d.function}`),
              d.file !== "" &&
                ((f += ` in ${d.file}:${d.line}`),
                d.column !== 0 && (f += `:${d.column}`)),
              (f += "\n");
          return f;
        }),
          (d = function (a) {
            let b, d, e, f, g;
            for (f = a.errors, g = [], d = 0, e = f.length; e > d; d++)
              (b = f[d]),
                g.push(
                  typeof console !== "undefined" &&
                    console !== null &&
                    typeof console.log === "function"
                    ? console.log(c(b))
                    : void 0
                );
            return g;
          }),
          (b.exports = d);
      },
      {},
    ],
    5: [
      function (a, b) {
        b.exports = a(
          "withCredentials" in new XMLHttpRequest()
            ? "./xhr.coffee"
            : "./jsonp.coffee"
        );
      },
      { "./jsonp.coffee": 6, "./xhr.coffee": 7 },
    ],
    6: [
      function (a, b) {
        (function (c) {
          let d, e, f;
          (e = a("../util/jsonify_notice.coffee")),
            (d = 0),
            (f = function (a, b) {
              let f, g, h, i, j, k, l;
              return (
                d++,
                (f = `airbrakeCb${String(d)}`),
                (c[f] = function (a) {
                  let b;
                  typeof console !== "undefined" &&
                    console !== null &&
                    typeof console.debug === "function" &&
                    console.debug(
                      "airbrake: error #%s was reported: %s",
                      a.id,
                      a.url
                    );
                  try {
                    return delete c[f];
                  } catch (d) {
                    return (b = d), (c[f] = void 0);
                  }
                }),
                (i = encodeURIComponent(e(a))),
                (l = `${b.host}/api/v3/projects/${b.projectId}/create-notice?key=${b.projectKey}&callback=${f}&body=${i}`),
                (g = c.document),
                (h = g.getElementsByTagName("head")[0]),
                (k = g.createElement("script")),
                (k.src = l),
                (j = function () {
                  return h.removeChild(k);
                }),
                (k.onload = j),
                (k.onerror = j),
                h.appendChild(k)
              );
            }),
            (b.exports = f);
        }.call(
          this,
          typeof global !== "undefined"
            ? global
            : typeof self !== "undefined"
            ? self
            : typeof window !== "undefined"
            ? window
            : {}
        ));
      },
      { "../util/jsonify_notice.coffee": 9 },
    ],
    7: [
      function (a, b) {
        (function (c) {
          let d, e;
          (d = a("../util/jsonify_notice.coffee")),
            (e = function (a, b) {
              let e, f, g;
              return (
                (g = `${b.host}/api/v3/projects/${b.projectId}/notices?key=${b.projectKey}`),
                (e = d(a)),
                (f = new c.XMLHttpRequest()),
                f.open("POST", g, !0),
                f.setRequestHeader("Content-Type", "application/json"),
                f.send(e),
                (f.onreadystatechange = function () {
                  let a;
                  return f.readyState === 4 &&
                    f.status === 201 &&
                    (typeof console !== "undefined" && console !== null
                      ? console.debug
                      : void 0) != null
                    ? ((a = JSON.parse(f.responseText)),
                      console.debug(
                        "airbrake: error #%s was reported: %s",
                        a.id,
                        a.url
                      ))
                    : void 0;
                })
              );
            }),
            (b.exports = e);
        }.call(
          this,
          typeof global !== "undefined"
            ? global
            : typeof self !== "undefined"
            ? self
            : typeof window !== "undefined"
            ? window
            : {}
        ));
      },
      { "../util/jsonify_notice.coffee": 9 },
    ],
    8: [
      function () {
        Array.prototype.indexOf ||
          (Array.prototype.indexOf = function (a, b) {
            let c, d, e, f;
            for (
              b = b || 0, c = d = e = b, f = this.length;
              f >= e ? f > d : d > f;
              c = f >= e ? ++d : --d
            )
              if (this[c] === a) return c;
            return -1;
          });
      },
      {},
    ],
    9: [
      function (a, b) {
        let c, d, e;
        (d = a("./truncate.coffee")),
          (e = function (a, b) {
            let c, e;
            b == null && (b = 1e3), (c = {});
            for (e in a) c[e] = d(a[e], (b = b));
            return c;
          }),
          (c = function (a, b, c) {
            let d, f;
            for (b == null && (b = 1e3), c == null && (c = 64e3); ; ) {
              if (
                ((a.params = e(a.params, (b = b))),
                (a.environment = e(a.environment, (b = b))),
                (a.session = e(a.session, (b = b))),
                (f = JSON.stringify(a)),
                f.length < c)
              )
                return f;
              if (b === 0) break;
              b = Math.floor(b / 2);
            }
            throw (
              ((d = new Error(
                `airbrake-js: cannot jsonify notice (length=${f.length} maxLength=${c})`
              )),
              (d.params = {
                json: `${f.slice(0, +Math.floor(b / 2) + 1 || 9e9)}...`,
              }),
              d)
            );
          }),
          (b.exports = c);
      },
      { "./truncate.coffee": 12 },
    ],
    10: [
      function (a, b) {
        let c;
        (c = function () {
          let a, b, c, d, e, f;
          for (
            f = Array.prototype.slice.call(arguments),
              a = f.shift() || {},
              b = 0,
              d = f.length;
            d > b;
            b++
          ) {
            e = f[b];
            for (c in e)
              Object.prototype.hasOwnProperty.call(e, c) && (a[c] = e[c]);
          }
          return a;
        }),
          (b.exports = c);
      },
      {},
    ],
    11: [
      function (a, b) {
        (function (a) {
          let c;
          (c = function (a, b) {
            return a.getAttribute(`data-airbrake-${b}`);
          }),
            (b.exports = function (b) {
              let d, e, f, g, h, i, j, k, l, m;
              for (
                m = a.document.getElementsByTagName("script"),
                  k = [],
                  f = 0,
                  g = m.length;
                g > f;
                f++
              )
                (l = m[f]),
                  (i = c(l, "project-id")),
                  (j = c(l, "project-key")),
                  i && j && b.setProject(i, j),
                  (d = c(l, "environment-name")),
                  d && b.setEnvironmentName(d),
                  (e = c(l, "host")),
                  e && b.setHost(e),
                  (h = c(l, "onload")),
                  k.push(h ? a[h](b) : void 0);
              return k;
            });
        }.call(
          this,
          typeof global !== "undefined"
            ? global
            : typeof self !== "undefined"
            ? self
            : typeof window !== "undefined"
            ? window
            : {}
        ));
      },
      {},
    ],
    12: [
      function (a, b) {
        let c, d;
        (c = function (a, b) {
          let c;
          try {
            return a[b];
          } catch (d) {
            return void (c = d);
          }
        }),
          (d = function (a, b, d) {
            let e, f, g, h, i;
            return (
              b == null && (b = 1e3),
              d == null && (d = 5),
              (h = 0),
              (g = []),
              (i = []),
              (f = function (a) {
                let b, d, e, f, h;
                for (
                  d = i.indexOf(a), f = [g[d]], b = e = h = d;
                  h <= 0 ? e <= 0 : e >= 0;
                  b = h <= 0 ? ++e : --e
                )
                  i[b] && c(i[b], f[0]) === a && ((a = i[b]), f.unshift(g[b]));
                return `~${f.join(".")}`;
              }),
              (e = function (a, j, k) {
                let l, m, n, o, p, q;
                if ((j == null && (j = ""), k == null && (k = 0), h++, h > b))
                  return "[Truncated]";
                if (a === null || void 0 === a) return a;
                switch (typeof a) {
                  case "boolean":
                  case "number":
                  case "string":
                  case "function":
                    return a;
                  case "object":
                    break;
                  default:
                    return String(a);
                }
                if (
                  a instanceof Boolean ||
                  a instanceof Number ||
                  a instanceof String ||
                  a instanceof Date ||
                  a instanceof RegExp
                )
                  return a;
                if (i.indexOf(a) >= 0) return `[Circular ${f(a)}]`;
                if ((k++, k > d)) return "[Truncated]";
                if (
                  (g.push(j),
                  i.push(a),
                  h--,
                  Object.prototype.toString.apply(a) === "[object Array]")
                ) {
                  for (
                    l = [], n = o = 0, p = a.length;
                    p > o && ((m = a[n]), h++, !(h >= b));
                    n = ++o
                  )
                    l.push(e(m, (j = n), k));
                  return l;
                }
                l = {};
                for (j in a)
                  if (Object.prototype.hasOwnProperty.call(a, j)) {
                    if ((h++, h >= b)) break;
                    (q = c(a, j)), void 0 !== q && (l[j] = e(q, (j = j), k));
                  }
                return l;
              })(a)
            );
          }),
          (b.exports = d);
      },
      {},
    ],
  },
  {},
  [2]
);
// jshint ignore:end
