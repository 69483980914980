interface SSOIntegrationInfo {
  id: string;
}

interface IDPCertificateInfo {
  id: number;
}

export const SettingsSSORoutes = {
  getServiceProviderCert: (): string => `sso/certificates/serviceprovider`,

  getSSOIntegration: (id: string): string => `sso/integrations/${id}?si=true`,

  getSSOIntegrations: (): string => `sso/integrations?si=true`,

  createSSOIntegration: (): string => `sso/integrations?si=true`,

  updateSSOIntegration: ({ id }: SSOIntegrationInfo): string =>
    `sso/integrations/${id}?si=true`,

  deleteSSOIntegration: ({ id }: SSOIntegrationInfo): string =>
    `sso/integrations/${id}`,

  getCertificate: (certId: number): string => `sso/certificates/${certId}`,

  createCertificate: (): string => `sso/certificates`,

  getCertificates: (integrationId: string): string =>
    `sso/integrations/${integrationId}/certificates`,

  deleteCertificate: ({ id }: IDPCertificateInfo): string =>
    `sso/certificates/${id}`,
};
