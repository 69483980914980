import { MakoStateType } from '../types/makoStateType';
import { TestingCreditsPlan } from '../testing_credits_plans/types';
import { TestingCreditsSubscription } from './types';
import { ProductId } from '../types/productId';

export function hasPurchasedTestingCredits(state: MakoStateType) {
  return state.testingCreditsSubscription.purchasedCount > 0;
}

export function shouldShowTestingCreditsUpsell(state: MakoStateType) {
  const { testingCreditsSubscription } = state;
  return !testingCreditsSubscription.canBePurchased;
}

export function isDowngradingTestingCredits(
  currentSubscription: TestingCreditsSubscription,
  futurePlan: TestingCreditsPlan
) {
  return (
    futurePlan.name === ProductId.SG_X_EMAIL_TESTING_0_V1 ||
    currentSubscription.purchasedCount > futurePlan.count
  );
}
