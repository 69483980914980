import moment from 'moment';
import { StatsAggregationOptions } from '../components/StatsAggregationSelect';
import { formatDisplayDate as formatDisplayFullStatDate } from './formatDisplayDateRange';

export const displayStatDateFormat = 'MMM D';
export const displayStatMonthFormat = 'MMM';

export const formatDisplayStatDate = (statDate: string | moment.Moment) =>
  typeof statDate === 'string'
    ? moment(statDate).format(displayStatDateFormat)
    : statDate.format(displayStatDateFormat);

export const formatDisplayStatMonth = (statDate: string) =>
  moment(statDate).format(displayStatMonthFormat);

export const formatChartXAxisDate = ({
  statDate,
  aggregatedBy,
  startDate,
}: {
  statDate: string;
  aggregatedBy?: StatsAggregationOptions;
  startDate?: string;
}) => {
  // If the stat date is earlier than the start date, we will use the start date
  let displayedDate = statDate;
  if (startDate && moment(statDate) < moment(startDate)) {
    displayedDate = startDate;
  }

  // If aggregated monthly, we will just show the month
  if (aggregatedBy === StatsAggregationOptions.Monthly) {
    return formatDisplayStatMonth(displayedDate);
  } else {
    return formatDisplayStatDate(displayedDate);
  }
};

export const formatChartTooltipDate = ({
  statDate,
  aggregatedBy,
  startDate,
  endDate,
}: {
  statDate: string;
  aggregatedBy?: StatsAggregationOptions;
  startDate?: string;
  endDate?: string;
}) => {
  // If the stat date is earlier than the start date, we will use the start date
  let displayedStartDate = statDate;
  if (startDate && moment(statDate) < moment(startDate)) {
    displayedStartDate = startDate;
  }

  // If aggregated monthly, we will return the date range for the month
  if (aggregatedBy === StatsAggregationOptions.Monthly) {
    // If the calculated end date is later than the specified end date, we will use the specified end date
    let displayedEndDate: string | moment.Moment = moment(statDate).endOf(
      'month'
    );
    if (endDate && moment(displayedEndDate) > moment(endDate)) {
      displayedEndDate = endDate;
    }

    return `${formatDisplayStatDate(
      displayedStartDate
    )} - ${formatDisplayStatDate(displayedEndDate)}`;
  }

  // If aggregated weekly, we will return the date range for the week
  else if (aggregatedBy === StatsAggregationOptions.Weekly) {
    // If the calculated end date is later than the specified end date, we will use the specified end date
    let displayedEndDate: string | moment.Moment = moment(statDate).add(
      6,
      'days'
    );
    if (endDate && moment(displayedEndDate) > moment(endDate)) {
      displayedEndDate = endDate;
    }

    return `${formatDisplayStatDate(
      displayedStartDate
    )} - ${formatDisplayStatDate(displayedEndDate)}`;

    // If aggregated daily or not specified, we will return the given date
  } else {
    return formatDisplayStatDate(displayedStartDate);
  }
};

export const formatTableDate = ({
  statDate,
  aggregatedBy,
  startDate,
  endDate,
}: {
  statDate: string;
  aggregatedBy?: StatsAggregationOptions;
  startDate?: string;
  endDate?: string;
}) => {
  // If the stat date is earlier than the start date, we will use the start date
  let displayedStartDate = statDate;
  if (startDate && moment(statDate) < moment(startDate)) {
    displayedStartDate = startDate;
  }

  // If aggregated monthly, we will return the date range for the month
  if (aggregatedBy === StatsAggregationOptions.Monthly) {
    // If the calculated end date is later than the specified end date, we will use the specified end date
    let displayedEndDate: string | moment.Moment = moment(statDate).endOf(
      'month'
    );
    if (endDate && moment(displayedEndDate) > moment(endDate)) {
      displayedEndDate = endDate;
    }

    return `${formatDisplayFullStatDate(
      displayedStartDate
    )} - ${formatDisplayFullStatDate(displayedEndDate)}`;
  }

  // If aggregated weekly, we will return the date range for the week
  else if (aggregatedBy === StatsAggregationOptions.Weekly) {
    // If the calculated end date is later than the specified end date, we will use the specified end date
    let displayedEndDate: string | moment.Moment = moment(statDate).add(
      6,
      'days'
    );
    if (endDate && moment(displayedEndDate) > moment(endDate)) {
      displayedEndDate = endDate;
    }

    return `${formatDisplayFullStatDate(
      displayedStartDate
    )} - ${formatDisplayFullStatDate(displayedEndDate)}`;

    // If aggregated daily or not specified, we will return the given date
  } else {
    return formatDisplayFullStatDate(displayedStartDate);
  }
};
