import { Action } from '../types/action';
import { ActionTypes } from './actionTypes';
import { TestingCreditsPlan } from './types';

interface TestingCreditsPlansAction extends Action {
  payload: TestingCreditsPlan[];
}

export const testingCreditsPlans = (
  state = [],
  action: TestingCreditsPlansAction
) => {
  switch (action.type) {
    case ActionTypes.GetTestingCreditsPlansSuccess:
      return action.payload;
    case ActionTypes.GetTestingCreditsPlansFailure:
    default:
      return state;
  }
};
