export function validateOAuthRedirect(redirectUrl: string) {
  const allowedProtocols = ['https:'];
  const allowedHosts = ['api.staging.sendgrid.com', 'api.sendgrid.com'];
  const allowedPaths = ['/v3/oauth/authorize'];

  const decodedRedirectUrl = decodeURIComponent(redirectUrl);
  const parsedRedirectUrl = new URL(decodedRedirectUrl);

  if (!allowedProtocols.includes(parsedRedirectUrl.protocol)) {
    return false;
  }

  if (!allowedHosts.includes(parsedRedirectUrl.host)) {
    return false;
  }

  if (!allowedPaths.includes(parsedRedirectUrl.pathname)) {
    return false;
  }

  return true;
}

export function redirectToOAuthAuthorize(redirectUrl?: string) {
  try {
    const oAuthRedirect =
      redirectUrl || sessionStorage.getItem('oauth_redirect_to');
    if (!oAuthRedirect) {
      throw new Error('No OAuth redirect URL provided');
    }
    const decodedRedirect = decodeURIComponent(oAuthRedirect);
    const oAuthRedirectURL = new URL(decodedRedirect);
    if (validateOAuthRedirect(oAuthRedirectURL.href)) {
      window.location.href = oAuthRedirectURL.href;
    }
  } catch (e) {
    window.location.href = '/oauth/error';
  }
}
