import { RumEvent, RumInitConfiguration } from '@datadog/browser-rum';

export const SUBUSER_USERNAME_REPLACEMENT = 'redacted:0';
const subuserSettingsRegex = new RegExp('(/settings/subusers/)([^/]+)(/.+)');

/**
 * Replaces the subuser username in the subuser settings URLs with a constant
 * value that contains a number, so it is treated as an identifier.
 * This causes Datadog to automatically group these URLs and also serves
 * as the redaction of the username.
 * For example, http://app.sendgrid.com/settings/subusers/foo/settings is
 * replaced by http://app.sendgrid.com/settings/subusers/redacted:0/settings
 * @param event The Datadog RUM event
 * @returns void
 */
export const groupSubuserSettings = (event: RumEvent) => {
  // For more info on string replacement patterns:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/String/replace#specifying_a_string_as_the_replacement
  event.view.url = event.view.url.replace(
    subuserSettingsRegex,
    `$1${SUBUSER_USERNAME_REPLACEMENT}$3`
  );
};

/**
 * A function that runs before events are sent to Datadog so we can
 * perform custom processing on them. Bloodhound does processing that should
 * apply to all events, the ones specified here should be mako specific.
 * @param event The datadog RUM event
 * @param context The Datadog RUM context
 * @returns void
 */
export const beforeSend: NonNullable<RumInitConfiguration['beforeSend']> = (
  event: RumEvent
) => {
  try {
    groupSubuserSettings(event);
  } catch {
    console.warn('Failed to process RUM event');
  }
};
