import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';
import { ActionTypes } from './actionTypes';
import { UserPackage } from '../types/user';
import { userPackageAdapter } from './adapters';
import { UserPackageDto } from '../dtos';
import { updatePackageOverages } from '../utils';

// This is a placeholder for all the account_profile data we get from Tiara
// until we know how we want to organize it.
const initialState: MakoStateType['userPackage'] = {} as UserPackage;

interface UserAction extends Action {
  payload: MakoStateType['userPackage'] | UserPackageDto;
}

export const userPackage = (state = initialState, action: UserAction) => {
  switch (action.type) {
    case ActionTypes.CreateUserPackage:
    case ActionTypes.GetUserPackageSuccess:
      const withUpdatedOveragePrices = updatePackageOverages(
        action.payload as UserPackageDto
      );
      const statePackage = userPackageAdapter(
        withUpdatedOveragePrices as UserPackageDto
      );
      return statePackage;
    case ActionTypes.GetUserPackageFailure:
      // TODO: handle user package request failures
      // For now, we won't do anything with the errors coming back from user/package upon billing page refresh
      return state;
    default:
      return state;
  }
};
