import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';

import { AddExpertInsightsRequest } from '../types/addExpertInsights';
import { AddExpertInsightsDto } from '../dtos';

import { ActionTypes } from './actionTypes';

const initialState: MakoStateType['addExpertInsights'] = {
  isPurchasingService: false,
  errorMessage: '',
};

interface AddExpertInsightsAction extends Action {
  payload: AddExpertInsightsRequest | AddExpertInsightsDto;
}

export const addExpertInsights = (
  state = initialState,
  action: AddExpertInsightsAction
) => {
  switch (action.type) {
    case ActionTypes.AddExpertInsightsRequest:
      return {
        isPurchasingService: true,
        errorMessage: '',
      };
    case ActionTypes.AddExpertInsightsSuccess:
      return {
        isPurchasingService: false,
        errorMessage: '',
      };
    case ActionTypes.AddExpertInsightsFailure:
      const payload = action.payload as AddExpertInsightsDto;
      const errorMessage = (payload.errors && payload.errors[0].message) || '';
      return {
        isPurchasingService: false,
        errorMessage,
      };
    default:
      return state;
  }
};
