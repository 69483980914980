import { EmailApiUsage } from '../types/emailApiUsage';
import { ActionsObservable, ofType } from 'redux-observable';
import { switchMap, map, catchError } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
// actions
import { Actions as EmailApiUsageActions } from './actions';
// types
import { ActionTypes } from './actionTypes';
import { getEmailApiUsage } from './service';
import { ResponseError } from '../types/responseError';

const emailApiUsageEpics = (
  action$: ActionsObservable<
    ReturnType<typeof EmailApiUsageActions.getEmailApiUsage>
  >
): Observable<
  | ReturnType<typeof EmailApiUsageActions.getEmailApiUsageSuccess>
  | ReturnType<typeof EmailApiUsageActions.getEmailApiUsageFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.GetEmailApiUsageRequest),
    switchMap(() => {
      return from(getEmailApiUsage()).pipe(
        map((data: EmailApiUsage) => {
          return EmailApiUsageActions.getEmailApiUsageSuccess(data);
        }),
        catchError((error: ResponseError) => {
          return [EmailApiUsageActions.getEmailApiUsageFailure(error)];
        })
      );
    })
  );
};

export default [emailApiUsageEpics];
