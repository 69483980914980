import moment from 'moment';

export const abbreviatedDisplayDateFormat = 'MMM D';
export const displayDateFormat = 'MMM D, YYYY';

export const formatDisplayDate = (date: moment.Moment | string) =>
  typeof date === 'string'
    ? moment(date).format(displayDateFormat)
    : date.format(displayDateFormat);

// Given a start date and optional end date, we will return back the proper display date range
// i.e. startDate = Jan 7, 2021 and endDate = Jan 12, 2021 => Jan 7 to Jan 12, 2021
// i.e. startDate = Dec 12, 2020 and endDate = Jan 12, 2021 => Dec 12, 2020 to Jan 12, 2021
// i.e. startDate = Jan 7, 2021 and endDate = null or undefined => Jan 7, 2021 to
export const formatDisplayDateRange = (
  startDate: moment.Moment,
  endDate?: moment.Moment | null
) => {
  // Given start date only, it should look like "MMM D, YYYY to"
  if (!endDate) {
    return `${formatDisplayDate(startDate)} to`;
  }

  // Given start and end date within the same year, it should look like "MMM D to MMM D, YYYY"
  const isDateRangeWithinSameYear =
    startDate.get('year') === endDate.get('year');
  if (isDateRangeWithinSameYear) {
    return `${startDate.format(
      abbreviatedDisplayDateFormat
    )} to ${formatDisplayDate(endDate)}`;
  }

  // Given start and end date in separate years, it should look like "MMM D, YYYY to MMM D, YYYY"
  return `${formatDisplayDate(startDate)} to ${formatDisplayDate(endDate)}`;
};
