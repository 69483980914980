const { analytics } = require("@sendgrid/bloodhound");

module.exports = {
  /**
   * a click handler for when users click breadcrumb links
   * - primary function is to dispatch an analytics event
   * @param {object} evt DOM event
   * @returns void
   */
  breadcrumbClickHandler: ({ target }) => {
    const breadcrumbName = window.$(target).data("breadcrumb-name");
    const page = window.$(target).data("analytics-page");

    if (!breadcrumbName || !page) {
      return;
    }

    analytics.track({
      event: `${breadcrumbName} breadcrumb clicked`,
      properties: {
        page,
        section: "breadcrumbs",
        elementType: "link",
        eventType: "click",
      },
    });
  },
};
