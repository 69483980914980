import * as Sentry from '@sentry/browser';
import ApiConfig from './apiconfig';

interface SentryErrorTags {
  [index: string]: string;
}

// Currently, the only type of logs we save are errors. If we feel the need
// to send informational logs to another system, we'll need to rework this.
// Sentry would be incorporated into the general logging infrastructure.
const logger = {
  load() {
    if (process.env.NODE_ENV === 'production') {
      Sentry.init({
        dsn:
          'https://9a3af649b4c54d1c8d8af760dcf6e374@o12388.ingest.sentry.io/5336893s',
        environment: ApiConfig.environment,
        debug: false,
        ignoreErrors: [
          // This ignores Sentry event errors due to MSFT IPs triggering hundreds of thousands of
          // "UnhandledRejection: Non-Error promise rejection captured with value: Object Not Found Matching Id:8"
          'Non-Error promise rejection captured with value: Object Not Found Matching Id',
        ],
      });
    }
  },
  captureError(
    error: Error,
    severity: Sentry.Severity,
    tags?: SentryErrorTags
  ) {
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope((scope) => {
        scope.setLevel(severity);
        if (tags) {
          Object.keys(tags).forEach((tag) => {
            scope.setTag(tag, tags[tag]);
          });
        }
        Sentry.captureException(error);
      });
    }
  },
};

export default logger;
