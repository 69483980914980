import { BaseQueryFn } from '@reduxjs/toolkit/query/react';
import {
  AuthedHttp,
  RequestTypes,
  RequestOptions,
  ResponseBody,
} from '../../../helpers/AuthedHttp';
import { dataInjectionKey, constructUrl, UnknownError } from './utils';

// Tests for authedBaseQuery are located in ./utils.test.ts

/**
 * Our custom baseQuery function for rtk query.
 * It uses AuthedHttp so previous logic is preserved.
 *
 * Currently unable to specify error type since we can only
 * dictate it for all requests, and not all requests follow the
 * same error response structure
 */
export const authedBaseQuery: BaseQueryFn<
  {
    url: string;
    method: RequestTypes;
    body?: any; // aka request body
    queryParams?: Record<string, any>; // aka url params
    baseUrl?: string; // Change the baseUrl to hit, by default it's MAKO Api
    requestOptions?: RequestOptions;
    [dataInjectionKey]?: any;
  },
  unknown,
  unknown
> = async ({
  url,
  method,
  body,
  dataInjection,
  queryParams,
  baseUrl,
  requestOptions,
}) => {
  // Handle the special case of tiara hydrating rtk in main.js by passing in dataFromTiara
  if (dataInjection != null) {
    const { data, error, status } = dataInjection;
    if (error) {
      return {
        error: {
          data,
          status,
        },
      };
    }
    return { data };
  }

  // Add queryParams to url
  const constructedUrl = constructUrl(url, queryParams);

  const mutationAuthedHttpArgs: [
    string,
    any,
    RequestOptions | undefined,
    string | undefined
  ] = [constructedUrl, body, requestOptions, baseUrl];

  try {
    let request: Promise<ResponseBody<any>>;
    switch (method) {
      case RequestTypes.Get:
        request = AuthedHttp.get(constructedUrl, requestOptions, baseUrl);
        break;
      case RequestTypes.Post:
        request = AuthedHttp.post(...mutationAuthedHttpArgs);
        break;
      case RequestTypes.Put:
        request = AuthedHttp.put(...mutationAuthedHttpArgs);
        break;
      case RequestTypes.Patch:
        request = AuthedHttp.patch(...mutationAuthedHttpArgs);
        break;
      case RequestTypes.Delete:
        request = AuthedHttp.del(...mutationAuthedHttpArgs);
    }

    // await request will resolve if the request receives any response
    // (even if it has an error code e.g. 404 or 500)
    const response = await request;
    const isJson = response.headers
      .get('content-type')
      ?.includes('application/json');

    let data;
    try {
      if (response.status === 204) {
        // By default, return an empty object if no content is received
        data = {};
      } else if (isJson) {
        data = await response.json();
      } else {
        data = await response.text();
      }
    } catch (err) {
      data = {};
    }

    // response.ok will be true if the status code is between 200-299
    // false otherwise
    if (response.ok) {
      return { data };
    }
    return {
      error: {
        status: response.status,
        data,
      },
    };
  } catch (err) {
    return {
      error: {
        // When the request promise is rejected, there was some kind of network
        // error outside of usual (404, 500, etc.) server error responses
        status: 0,
        data: UnknownError,
      },
    };
  }
};
