import { ActionsUnion, createAction } from '../../actionBuilder';
import { ActionTypes } from './actionTypes';

export const Actions = {
  getProducts: () => createAction(ActionTypes.GetProductsRequest),
  getProductsSuccess: () => createAction(ActionTypes.GetProductsSuccess),
  getProductsFailure: () => createAction(ActionTypes.GetProductsFailure),
};

export type Actions = ActionsUnion<typeof Actions>;
export { Actions as ProductsActions };
export default Actions;
