// This is for babel, if we target newer browsers we don't need to bring this. Babel is so we can use react-fast
// Maybe we can have typescript for prod and babel for dev? Then we'd have an issue with dev and prod being different which may suck
// Might be able to proccess/env this out as well. Lots of options
import "regenerator-runtime/runtime";

import { analytics } from "@sendgrid/bloodhound";
import "./datadog";
import Cookies from "js-cookie";
import { getUserWithTimezone } from "./state/time_zone/actions";
import logger from "./logger";
import { setBrowserFingerprintHeader } from "./helpers/browserFingerprint";
import { RenderMockUI } from "./MockFromURL.tsx";
import { sendgridAppStore } from "./state/store/sendgridAppStore";
import { configureApplication } from "./state/store/appConfigSlice";
import { dispatchInitialData } from "./lib/dispatchInitialData";
import { GlobalNavbar } from "src/components/Navbar/GlobalNavbar";
import { showGlobalNavBarUpdates } from "src/helpers/featureFlags/showGlobalNavBarUpdates";

const Backbone = require("backbone");
const $ = require("jquery");
Backbone.$ = require("jquery");
require("dotdotdot"); // For sender auth jquery-dotdotdot faq
require("bootstrap-daterangepicker");
require("./lib/modal.js");
require("select2");
require("../styles/mako.scss");

const moment = require("moment");
const Error500View = require("./views/errors/500_view");
const Error404View = require("./views/errors/404_view");

logger.load();

// Tiara needs jQuery to be global so we just make it global because we're lazy
// https://github.com/webpack/webpack/issues/2180#issuecomment-196048732
window.$ = require("jquery");
window.jQuery = require("jquery");
Backbone.routefilter = require("./helpers/backbone_routefilter");

if (process.env.publicPath !== "/") {
  // eslint-disable-next-line no-console
  console.log(
    `%c You're on the ${process.env.publicPath} feature branch`,
    "background: green; color: white; display: block;"
  );
}
const Marionette = require("backbone.marionette");
require("bootstrap-daterangepicker");

const APIConfig = require("apiconfig");
sendgridAppStore.dispatch(configureApplication(APIConfig));

// explicitly state when mako ajax is complete, to ease automation, per Alex
let ajaxcalls = 0;
const $body = $("body");
$body.attr("data-mako-ready", "starting");
$(document).ajaxSend(() => {
  ajaxcalls += 1;
  if ($body.attr("data-mako-ready") === "starting") {
    $body.attr("data-mako-ready", "pending");
  }
  $body.attr("data-mako-network-on", "true");
});
$(document).ajaxComplete(() => {
  ajaxcalls -= 1;
  if (ajaxcalls === 0) {
    $body
      .attr("data-mako-ready", "complete")
      .attr("data-mako-network-on", "false");
  }
});
$(document).ajaxError(() => {
  ajaxcalls -= 1;
  if (ajaxcalls === 0) {
    $body
      .attr("data-mako-ready", "complete")
      .attr("data-mako-network-on", "false");
  }
});

$("head").prepend(
  `<link rel="stylesheet" href="${APIConfig.tiara.cdn_css}" type="text/css" />`
);

$.ajax({
  url: APIConfig.tiara.cdn_js,
  dataType: "script",
  cache: true,
}).done(() => {
  window.track("Finished loading tiara.js");

  // Patch any missing parts of the tiara interface to deal with mismatches
  // between what mako expects and what tiara provides. This can happen due
  // to caching or imperfect release coordination
  if (!window.SendGridTiara.bannerReady) {
    window.SendGridTiara.bannerReady = () => Promise.resolve();
  }

  const AppInitializer = require("./lib/app_initializer");

  // routers
  const Router = require("./router");
  const SuppressionsRouter = require("./suppressions_router");
  const StatsRouter = require("./stats_router");
  const ErrorRouter = require("./error_router");

  // controllers
  const AuthController = require("./controllers/auth_controller");
  const LayoutController = require("./controllers/layout_controller");

  // collections
  const StatsCollection = require("./collections/stats_collection");
  const FeatureToggleCollection = require("./collections/feature_toggle_collection");

  // models
  const UserProfileModel = require("./models/user_profile_model");
  const ReputationModel = require("./models/reputation_model");
  const EmailModel = require("./models/email_model");
  const UsernameModel = require("./models/username_model");
  const TimezoneModel = require("./models/timezone_model");
  const ProvisionStatusModel = require("./models/provision_status_model");
  const AccountProfileModel = require("./models/account_profile_model");
  const UserPackageModel = require("./models/user_package_model");

  // helpers
  const Notifier = require("./helpers/notifier.js");
  require("./helpers/jquery/errors");
  require("./helpers/jquery/confirm");
  require("./helpers/jquery/multirange_slider");
  require("./helpers/jquery/whenall");
  require("./helpers/jquery/styleguide_modal");
  require("./helpers/underscore/xor");
  const DomEvents = require("./helpers/dom_events");
  const {
    configureClient,
    login,
    redirectCallback,
    callApi,
    checkIfUserHasAccountWithAuth0Email,
  } = require("src/auth0");
  const shouldShowLogoutPage = require("./helpers/shouldShowLogout");

  window.track("Finished requiring modules");

  const Mako = new Marionette.Application();

  // eslint-disable-next-line no-undef
  const authController = new AuthController({
    configureClient: configureClient,
    login: login,
    redirectCallback: redirectCallback,
    callApi: callApi,
    checkIfUserHasAccountWithAuth0Email: checkIfUserHasAccountWithAuth0Email,
    tiara: SendGridTiara,
  }); // SendGridTiara exposed through index.html

  $.cookie.defaults.path = "/";
  if (APIConfig.cookie_default_domain) {
    const defaultCookieDomain = APIConfig.cookie_default_domain;

    $.cookie.defaults.domain = defaultCookieDomain;
    Cookies.defaults.domain = defaultCookieDomain;
  } else if (window.location.hostname !== "localhost") {
    const defaultCookieDomain = ".sendgrid.com";

    $.cookie.defaults.domain = defaultCookieDomain;
    Cookies.defaults.domain = defaultCookieDomain;
  }

  window.EventBus = Mako.vent;
  window.Reqres = Mako.reqres;
  Mako.addRegions({ bodyRegion: "[role=container]" });

  // Redux
  const { billingStore } = require("./state/store");
  const { setScopes } = require("./state/scopes/actions");
  const { createUserProfile } = require("./state/account_profile/actions");
  const { createUserAccount } = require("./state/account/actions");
  const { createUserPackage } = require("./state/user_package/actions");

  /**
   * sets up the base path for react router
   */
  let basePath = process.env.publicPath;
  if (basePath.substring(0, 1) === "/") {
    basePath = basePath.substring(1);
  }

  Mako.on("start", function onStartMako(userData) {
    RenderMockUI();

    // Capture browser fingerprint (EHawk talon) for anti-abuse purposes
    setBrowserFingerprintHeader().catch(() =>
      console.warn("failed to set fingerprint header")
    );

    // eslint-disable-next-line no-undef
    analytics.configure({ apiBaseUrl: api_config.host });

    // update Redux store with userData
    billingStore.dispatch(createUserProfile(userData));

    billingStore.dispatch(
      createUserAccount({
        account: userData.account,
        signupStatus: userData.signup_status,
        camAccount: userData.camAccount,
      })
    );
    billingStore.dispatch(createUserPackage(userData.user_package));

    // scopes should be an array of strings in the form `{prefix}.{suffix}`
    const userScopes =
      userData.scopes && userData.scopes.scopes ? userData.scopes.scopes : [];
    billingStore.dispatch(setScopes(userScopes));

    const userProfileModel = new UserProfileModel(userData.profile);
    const reputationModel = new ReputationModel(userData.account);
    const emailModel = new EmailModel();
    const usernameModel = new UsernameModel(userData.username);
    const accountProfileModel = new AccountProfileModel(
      userData.accountProfile
    );

    const timezoneModel = new TimezoneModel();
    const userPackageModel = new UserPackageModel(userData.user_package);

    window.Reqres.setHandler("signupStatus", () => userData.signup_status);
    window.Reqres.setHandler("timezone", () => timezoneModel);
    window.Reqres.setHandler("account", () => userData.account);
    window.Reqres.setHandler("accountProfile", () => userData.accountProfile);
    window.Reqres.setHandler("userPackage", () => userPackageModel);
    window.Reqres.setHandler("isEaseGA", () => true);
    window.Reqres.setHandler("camAccount", () => userData.camAccount);
    window.Reqres.setHandler("oesAccount", () => userData.oesAccount);

    const statsCollection = new StatsCollection();
    statsCollection.params({
      start_date: moment()
        .utcOffset(-window.Reqres.request("timezone").getOffsetMinutes())
        .startOf("month")
        .format("YYYY-MM-DD"),
    });
    const featureToggleCollection = new FeatureToggleCollection(
      userData.feature_toggles
    );
    window.Reqres.setHandler("hasFeature", (appName, featureName) =>
      featureToggleCollection.hasFeature(appName, featureName)
    );
    // Can pass down scopes string like "teammates.read,subusers.read" to check
    window.Reqres.setHandler("hasScopes", (scopesToCheckStr) => {
      const scopesToCheck = scopesToCheckStr.split(",");
      return scopesToCheck.every(
        (scopeToCheck) => authController.scopes[scopeToCheck]
      );
    });
    window.Reqres.setHandler("hasCatalogOrg", (catalogOrg) => {
      return userData.camAccount.catalog_org === catalogOrg;
    });
    window.Reqres.setHandler("getScopes", () => {
      const scopesObj = authController.scopes;
      const scopes = Object.keys(scopesObj);

      return scopes.filter((scope) => scopesObj[scope]).map((scope) => scope);
    });
    window.Reqres.setHandler("getEntitlement", (entitlementName) => {
      const { entitlements = {} } = userData.oesAccount;
      return entitlements[entitlementName];
    });

    window.Reqres.setHandler("currentUser", () => {
      const username = usernameModel.get("username");
      const userId = usernameModel.get("user_id");
      const userType = reputationModel.get("type");
      return {
        user_id: userId,
        username: username || "Yourself",
        userType,
        email: userData.email.email,
      };
    });
    window.Reqres.setHandler("2faUser", () => {
      const multifactorPhone = userProfileModel.get("multifactor_phone");
      const authyId = userProfileModel.get("authy_id");
      return {
        multifactorPhone,
        authyId,
      };
    });

    // this needs called outside of the layout controller to properly push data into the new react
    // components and at the same time keep the current model working properly.
    window.Reqres.request("timezone")
      .fetch()
      .then((timezone) => billingStore.dispatch(getUserWithTimezone(timezone)));

    const provisionStatusModel = new ProvisionStatusModel();

    const notifier = new Notifier(APIConfig.airbrake);

    // Controllers
    const layoutController = new LayoutController({
      region: Mako.bodyRegion,
      provisionStatusModel,
      emailModel,
    });

    // Routers
    this.errorRouter = new ErrorRouter({
      usernameModel,
      accountProfileModel,
      layoutController,
      // authController is used by router helper
      authController,
    });

    window.EventBus.on("navigate", (href, callback) => {
      // passing this refferer pathname along to Tiara for page tracking
      const referrer = window.location.href;
      Backbone.history.navigate(href, { trigger: true });
      window.EventBus.trigger("closeFlash");
      window.SendGridTiara.updateNav({ referrer });
      window.scrollTo(0, 0);
      if (callback && typeof callback === "function") {
        callback();
      }
    });

    window.EventBus.on("render500Error", () => {
      const error500View = new Error500View();
      layoutController.display(error500View);
    });

    window.EventBus.on("render404Error", () => {
      const error404View = new Error404View();
      layoutController.display(error404View);
    });

    window.EventBus.on("redirectToSignupApp", () => {
      // Redirect to new signup subdomain if users navigate to our subdomain for /signup
      const queryParams = window.location.search;
      window.location.href = `${APIConfig.signup_host}${queryParams}`;
    });

    window.EventBus.on("redirectToLoginApp", () => {
      // Redirect to the new login subdomain if users navigate to our subdomain for /login
      const queryParams = window.location.search;
      const loginRemainingPath = "/login/identifier";
      window.location.href = `${APIConfig.login_host}${loginRemainingPath}${queryParams}`;
    });

    window.EventBus.on("redirectToLoginAppPageRedirect", () => {
      let redirect_to = encodeURIComponent(
        window.location.pathname + window.location.search
      );

      //Remove /'s in the path so /login/ and /login are the same
      let pathname = window.location.pathname.replace(/\//g, "");
      if (pathname === "login" || pathname === "login/identifier") {
        redirect_to = "";
      } else {
        redirect_to = "?redirect_to=" + redirect_to;
      }
      window.location.href = `${APIConfig.login_host}/login/identifier${redirect_to}`;
    });

    window.EventBus.on("redirectToLoginAppLogoutPage", () => {
      // Redirect to the new logout subdomain if users navigate to our subdomain for /logout
      const queryParams = window.location.search;
      const logoutRemainingPath = "/logout";

      window.location.href = `${APIConfig.login_host}${logoutRemainingPath}${queryParams}`;
    });

    window.EventBus.on("redirectToLabsApp", () => {
      // Redirect to labs subdomain if users navigate to our subdomain for /labs
      // If given /labs/<some_labs_path>, append the path after /labs to the labs url
      // i.e. /labs -> https://labs.sendgrid.com
      // i.e. /labs/sso/grow -> https://labs.sendgrid.com/sso/grow
      // i.e. /labs/mda -> https://labs.sendgrid.com/mda
      const queryParams = window.location.search;
      const labsPathPrefix = "/labs";
      const labsRemainingPath = window.location.pathname.slice(
        labsPathPrefix.length
      );

      window.location.href = `${APIConfig.labs_host}${labsRemainingPath}${queryParams}`;
    });

    window.EventBus.on("redirectToMCApp", (pathInMC) => {
      // Redirect to new MC app and append whatever MC path you want i.e. /senders
      // will go to https://mc.staging.sendgrid.com/senders in staging
      // and https://mc.sendgrid.com/senders in production
      window.location.href = `${APIConfig.mc_host}${pathInMC}`;
    });

    // redirects to new account and billing pages
    window.EventBus.on("redirectToAccountPage", () => {
      window.location.href = `${APIConfig.mako_host}/account/details`;
    });

    window.EventBus.on("redirectToBillingPage", () => {
      window.location.href = `${APIConfig.mako_host}/account/billing`;
    });

    this.router = new Router({
      // jshint ignore:line
      region: Mako.bodyRegion,
      usernameModel,
      emailStatsCollection: statsCollection,
      userProfileModel,
      emailModel,
      authController,
      layoutController,
      notifier,
      errorRouter: this.errorRouter,
      // Redux store
      store: billingStore,
      sendgridAppStore,
      basePath,
    });

    // eslint-disable-next-line no-new
    new SuppressionsRouter({
      region: Mako.bodyRegion,
      layoutController,
      authController,
      errorRouter: this.errorRouter,
    });

    // eslint-disable-next-line no-new
    new StatsRouter({
      region: Mako.bodyRegion,
      layoutController,
      authController,
      errorRouter: this.errorRouter,
    });

    DomEvents.init();
    Backbone.history.start({
      pushState: true,
      root: APIConfig.root,
      hashChange: true,
    });
  });

  // eslint-disable-next-line no-undef
  AppInitializer.loadInitialData(authController, SendGridTiara, configureClient)
    .done((data) => {
      const {
        account,
        profile,
        feature_toggles,
        scopes,
        signupStatus,
        email,
        username,
        accountProfile,
        userPackage,
        camAccount,
        userType = [{}], // Could be undefined coming back if in login page,
        oesAccount = [{}], // Could be undefined if unauthenticated or missing user.account.read scope
      } = data;

      const cookies = {};
      const cookieString = document.cookie.split("; ");

      for (let i = 0; i < cookieString.length; i++) {
        const [key, value] = cookieString[i].split("=");
        cookies[key] = value;
      }

      const { lastUserId } = cookies;

      // This tracks the last userid that we tracked with datadog. This covers all edge cases ie. wrong logouts or identity changes (impersonations)
      if (
        username[0] &&
        username[0].user_id &&
        lastUserId !== username[0].user_id.toString()
      ) {
        document.cookie = `lastUserId=${username[0].user_id}; path=/;`;
      }
      const ogLogout = window.SendGridTiara.defaultLogout.bind(
        window.SendGridTiara
      );

      // when a user pressed logout (weird) so this is how we're resetting the identity prior to that
      window.SendGridTiara.defaultLogout = () => {
        ogLogout();
      };

      dispatchInitialData(data, sendgridAppStore);

      Mako.start({
        account: account[0],
        profile: profile[0],
        feature_toggles: feature_toggles[0],
        email: email[0],
        scopes: scopes[0],
        username: username[0],
        accountProfile: accountProfile[0],
        signup_status: signupStatus[0],
        user_package: userPackage[0],
        camAccount: camAccount[0],
        userType: userType[0],
        oesAccount: oesAccount[0],
      });
    })
    .fail(() => {
      if (shouldShowLogoutPage) {
        // This deferred only fails if there was a 401
        authController.logout();
      } else {
        window.EventBus.trigger("redirectToLoginAppPageRedirect");
      }
    });
});

if (showGlobalNavBarUpdates()) {
  GlobalNavbar("#globalNavBar");
}
