import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { concatMap } from 'rxjs/operators';
import { AccountAndPlanUtils } from '../../pages/AccountAndBilling/utils';
import { CancelAccountInfoDto } from '../dtos';
import { Alert } from '../types/ui';
import { Actions as UIActions } from '../ui/actions';
import { Actions as UserPackageActions } from '../user_package/actions';
import * as CancelAccountActions from './actions';
import { ActionTypes } from './actionTypes';
import { cancelAccount } from './service';

const cancelAccountEpic = (
  action$: ActionsObservable<
    ReturnType<typeof CancelAccountActions.Actions.cancelAccountRequest>
  >
): Observable<
  | ReturnType<typeof CancelAccountActions.Actions.cancelAccountFailure>
  | ReturnType<typeof CancelAccountActions.Actions.cancelAccountSuccess>
  | ReturnType<typeof UserPackageActions.getUserPackage>
  | ReturnType<typeof UIActions.createAlert>
> => {
  return action$.pipe(
    ofType(ActionTypes.CancelAccountRequest),
    concatMap((action) => {
      return from(cancelAccount(action.payload)).pipe(
        concatMap((data: CancelAccountInfoDto) => {
          const alert = {
            type: 'success',
            text: `You've initiated a cancellation. Your subscriptions will be cancelled on ${AccountAndPlanUtils.dates.getStartOfNextMonth()}.`,
          } as Alert;

          let actionsToDispatch: any[] = [
            UIActions.createAlert(alert),
            CancelAccountActions.Actions.cancelAccountSuccess(),
            UserPackageActions.getUserPackage(),
          ];

          if (data && data.errors) {
            actionsToDispatch = [
              CancelAccountActions.Actions.cancelAccountFailure(data),
            ];
          }
          return actionsToDispatch;
        })
      );
    })
  );
};

export default [cancelAccountEpic];
