import { AuthedHttp, ResponseBodySuccess } from '../../helpers/AuthedHttp';
import { Routes } from './routes';
import { ExpertServicesImplementationDto } from '../dtos';
import { ResponseError } from '../types/responseError';

export function getExpertServicesImplementationData(): Promise<
  ExpertServicesImplementationDto
> {
  return AuthedHttp.get<ExpertServicesImplementationDto>(
    `${Routes.ExpertServicesImplementation}`
  )
    .then((res: ResponseBodySuccess<ExpertServicesImplementationDto>) =>
      res.json()
    )
    .catch(() => {
      return {
        errors: [
          {
            message:
              'Failed to retrieve implementation expert services due to some reason!',
          },
        ] as ResponseError[],
      };
    });
}
