import moment from 'moment';
import { AccountOfferingDto, EntitlementsDto } from '../../services/oes/dtos';
import {
  LegacyMarketingCampaignsContactsCountDto,
  UsageStatsDto,
} from '../../services/mako_api/dtos';
import { EmailApiSubscription } from '../../../types/emailApiSubscription';
import { getEmailApiPlan, getOfferingByName } from '../../common/adapters';
import { ProductId } from '../../../types/productId';

// MC backend can't provide perfect contact count accuracy, so we give the
// customer a hidden buffer of "extra" contacts before considering them
// over the limit for downgrade logic. We are mirroring this behavior
// for legacy contacts.
const CONTACTS_OVERAGE_BUFFER = 500;

interface MapEmailApiSubscriptionData {
  currentOfferings: AccountOfferingDto[];
  futureOfferings: AccountOfferingDto[];
  entitlements: EntitlementsDto;
  usageStats: UsageStatsDto;
  legacyMarketingCampaignsContactsCount: LegacyMarketingCampaignsContactsCountDto;
}

export function getTrialDaysRemaining(offerings: AccountOfferingDto[]): number {
  const trial40k = getOfferingByName(offerings, ProductId.SG_EI_TRIAL_40K_V1);
  if (!trial40k || !trial40k.end_date) {
    return 29;
  }
  const now = moment().utc();
  const trialEndDate = moment(trial40k.end_date).utc();
  const diff = trialEndDate.diff(now, 'days');
  return diff <= 0 ? 1 : diff;
}

export function mapEmailApiSubscription({
  currentOfferings,
  futureOfferings,
  entitlements,
  usageStats,
  legacyMarketingCampaignsContactsCount,
}: MapEmailApiSubscriptionData): EmailApiSubscription {
  const currentPlan = getEmailApiPlan(currentOfferings);
  const futurePlan = getEmailApiPlan(futureOfferings);

  // OES Should always return an EAPI plan
  // but if one doesn't exist, show free plan
  if (!currentPlan) {
    return {
      planId: ProductId.SG_EI_FREE_100_V1,
      emailSendingCreditsUsed: 0,
      isTrialAvailable: false,
      legacyMarketingContactsUsed: 0,
      trialDaysRemaining: 0,
    } as EmailApiSubscription;
  }

  const downgradePlan = futurePlan
    ? getDowngradePlan(currentPlan, futurePlan)
    : undefined;

  return {
    planId: currentPlan.name,
    emailSendingCreditsUsed: usageStats.email_api_sends,
    downgradePlanId: downgradePlan && downgradePlan.name,
    isTrialAvailable: entitlements.ei_trial_eligible || false,
    legacyContactsOverageBuffer: CONTACTS_OVERAGE_BUFFER,
    legacyMarketingContactsUsed:
      legacyMarketingCampaignsContactsCount.recipient_count,
    trialDaysRemaining: getTrialDaysRemaining(currentOfferings),
    isUpgradeLocked: entitlements.is_upgrade_locked || false,
  } as EmailApiSubscription;
}

// We will have a future dated plan when the current plan is
// 1. pending downgrade
// 2. trial 40k and will be automatically downgraded to Free 100 when the trial ends
// 3. scheduled to be moved to a successor plan (these are filtered out before mapping)
//
// The automatic trial downgrade should not trigger the pending downgrade UI state.
// A successor plan should not trigger the pending downgrade UI state.
export function getDowngradePlan(
  currentPlan: AccountOfferingDto,
  futurePlan: AccountOfferingDto
): AccountOfferingDto | undefined {
  const isCurrentPlanTrial40k =
    currentPlan.name === ProductId.SG_EI_TRIAL_40K_V1;
  const downgradePlan = isCurrentPlanTrial40k ? undefined : futurePlan;
  return downgradePlan;
}
