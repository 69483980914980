const _ = require("underscore");

module.exports = function (key, context) {
  let value = context[`${key}_rate`];
  if (_.isFunction(value)) {
    value = value();
  }

  return `${Math.min(value * 100, 100)}%`;
};
