import { Scopes, ScopesReduxState } from './../types/scopes';

const tneScopes = Scopes.MarketingRead;
const mcLegacyScopes = [
  Scopes.MarketingCampaignsCreate,
  Scopes.MarketingCampaignsDelete,
  Scopes.MarketingCampaignsRead,
  Scopes.MarketingCampaignsUpdate,
];

export interface ScopeMap {
  [index: string]: string;
}

export function scopesAdapter(scopes: string[]): ScopesReduxState {
  /**
   * doing this so we don't have to do redundant loops over an array (props to Alan Smith for the idea)
   * converting [...ScopeString, ...] to:
   *  {
   *    ...,
   *    "billing.read": "billing.read"
   *    ...,
   *  }
   */
  const scopesMap = scopes.reduce((accumulator, scope: string) => {
    accumulator[scope] = scope;
    return accumulator;
  }, {} as ScopeMap);

  return {
    data: scopesMap,
    hasBillingReadAccess: !!scopesMap[Scopes.BillingRead],
    hasBillingUpdateAccess: !!scopesMap[Scopes.BillingUpdate],
    hasTneAccess: !!scopesMap[tneScopes],
    hasMCLegacyAccess: mcLegacyScopes.every((s: string) => !!scopesMap[s]),
  };
}
