import { MailboxProviderStatsDto } from '../types';
import {
  MSPMetricNames,
  MSPAggregation,
  calculateMetricRate,
  DateToMSPMetrics,
} from './helpers';

export interface MSPMetricCountsByDate {
  MetricCountsByDate: DateToMSPMetrics;
  Overall: {
    MetricCount: number;
    MetricCountsByMSP: MSPAggregation;
    MetricPercentagesByMSP: MSPAggregation;
  };
  TopMSPsLookup: string[];
  Dates: string[];
}

/*
  Calculate the metric counts by date for each MSP with overall metrics across all MSPs and top MSP names based on count in descending order
  This would help for calculating something like the Emails Sent to MSP chart variations

  Usage: calculateMSPMetricCounts("processed", mspStatsDto, ["Gmail", "Yahoo", "AOL", "Microsoft Outlook Live", "Remaining"]); for overall processed count per MSP
  Output: 
  {
    MetricCountsByDate: {
      "2021-02-01": {
        "Gmail": 100,
        "Yahoo": 50,
        ...
      },
      ...
    }
    // Overall processed counts and percentages by MSP
    Overall: {
      MetricCount: 1000,
      MetricCountsByMSP: {
        "Gmail": 100,
        ...
      },
      MetricPercentagesByMSP: {
        "Gmail": 60,
        ...
      }
    },
    TopMSPsLookup: ["Gmail", "Yahoo", "AOL", "Microsoft Outlook Live", "Remaining"],
    Dates: ["2021-02-01", ...]
  }
*/
export function calculateMSPMetricCountsByDate(
  metricName: MSPMetricNames,
  stats: MailboxProviderStatsDto,
  topMSPNames: string[]
): MSPMetricCountsByDate {
  const MetricCountsByDate: DateToMSPMetrics = {};
  const DefaultMSPMetrics: MSPAggregation = {};
  const OverallMetricCountsByMSP: MSPAggregation = {};
  const OverallMetricPercentagesByMSP: MSPAggregation = {};
  let OverallMetricCount = 0;
  const Dates: string[] = [];

  // Seed all the known MSPs to handle the empty data case where a user with no stats will get back an empty array
  topMSPNames.forEach((mspName) => {
    DefaultMSPMetrics[mspName] = 0;
    OverallMetricCountsByMSP[mspName] = 0;
    OverallMetricPercentagesByMSP[mspName] = 0;
  });

  // Sum up the counts for the metric for each MSP
  stats.forEach((byDate) => {
    Dates.push(byDate.date);
    MetricCountsByDate[byDate.date] = { ...DefaultMSPMetrics };

    byDate.stats.forEach((metricByMsp) => {
      const mspName: string = metricByMsp.name;
      if (topMSPNames.includes(mspName)) {
        const metricCount = metricByMsp.metrics[metricName] ?? 0;
        MetricCountsByDate[byDate.date][mspName] =
          MetricCountsByDate[byDate.date][mspName] + metricCount;
        OverallMetricCountsByMSP[mspName] =
          OverallMetricCountsByMSP[mspName] + metricCount;
        OverallMetricCount = OverallMetricCount + metricCount;
      }
    });
  });

  // Calculate metric percentages by MSP given MSP's count and overall metric count
  Object.keys(OverallMetricCountsByMSP).forEach((mspName) => {
    const metricCount = OverallMetricCountsByMSP[mspName];
    OverallMetricPercentagesByMSP[mspName] = calculateMetricRate(
      metricCount,
      OverallMetricCount
    );
  });

  return {
    MetricCountsByDate,
    Overall: {
      MetricCount: OverallMetricCount,
      MetricCountsByMSP: OverallMetricCountsByMSP,
      MetricPercentagesByMSP: OverallMetricPercentagesByMSP,
    },
    TopMSPsLookup: topMSPNames,
    Dates,
  };
}

// TODO: build adapters for series/table data
