// from http://stackoverflow.com/a/30381148
(function ($) {
  $.whenAll = function (deferreds) {
    let lastResolved = 0;
    const wrappedDeferreds = [];

    for (let i = 0; i < deferreds.length; i++) {
      wrappedDeferreds.push($.Deferred());
      if (deferreds[i] && deferreds[i].always) {
        deferreds[i].always(wrappedDeferreds[lastResolved++].resolve);
      } else {
        wrappedDeferreds[lastResolved++].resolve(deferreds[i]);
      }
    }

    return $.when(...wrappedDeferreds).promise();
  };
})(jQuery);
