import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import {
  DowngradePlanRequest,
  UpgradePlanRequest,
} from '../types/updatePlanRequest';
import { MarketingCampaignsSubscription } from '../types/marketingCampaignsSubscription';

export interface SubscriptionChanges {
  isChangeSuccessful: boolean;
  hasErrors: boolean;
}

export const Actions = {
  changeSubscription: (
    updatePlanRequest: UpgradePlanRequest | DowngradePlanRequest
  ) => {
    return createAction(
      ActionTypes.SubscriptionChangesRequest,
      updatePlanRequest
    );
  },
  changeSubscriptionSuccess: () =>
    createAction(ActionTypes.SubscriptionChangesSuccess),
  changeSubscriptionFailure: () =>
    createAction(ActionTypes.SubscriptionChangesFailure),

  startTrial40k: (
    marketingCampaignsSubscription: MarketingCampaignsSubscription
  ) =>
    createAction(
      ActionTypes.StartTrial40KRequest,
      marketingCampaignsSubscription
    ),
  startTrial40kSuccess: () => createAction(ActionTypes.StartTrial40KSuccess),
  startTrial40kFailure: () =>
    createAction(ActionTypes.SubscriptionChangesFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
