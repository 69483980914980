var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\"pull-left\">Figures for "
    + container.escapeExpression(__default(require("/opt/sendgrid/mako/src/templates/helpers/separateWordAndCapitalize.js")).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"metric") : depth0),{"name":"separateWordAndCapitalize","hash":{},"data":data,"loc":{"start":{"line":2,"column":34},"end":{"line":2,"column":70}}}))
    + "</h2>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<h2 class=\"pull-left\">"
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"heading") : depth0), depth0))
    + "</h2>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "  <div class=\"align-left pull-left\" role=\"nameFilterDropdown\">\n    <div class=\"btn btn-secondary dropdown btn-dropdown btn-right-margin\" data-dropdown-toggle=\"dropdown\"\n      role=\"nameFilterDropdownMenu\">\n      Countries / States\n\n      <ul class=\"dropdown-menu\" data-dropdown-menu=\"true\" role=\"nameFilterMenu\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"filters") : depth0),{"name":"each","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":14,"column":8},"end":{"line":20,"column":17}}})) != null ? stack1 : "")
    + "      </ul>\n    </div>\n  </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "        <li class=\"event "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"checked") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":15,"column":25},"end":{"line":15,"column":63}}})) != null ? stack1 : "")
    + "\" role=\"nameFilter\" data-filter=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"statName") : depth0), depth0))
    + "\">\n          <a class=\"dropdown-link\">\n"
    + ((stack1 = container.invokePartial(require("/opt/sendgrid/mako/src/templates/partials/checkbox.hbs"),depth0,{"name":"checkbox","hash":{"style":"margin: 0 auto","label":__default(require("/opt/sendgrid/mako/src/templates/helpers/separateWordAndCapitalize.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"statName") : depth0),{"name":"separateWordAndCapitalize","hash":{},"data":data,"loc":{"start":{"line":17,"column":59},"end":{"line":17,"column":95}}}),"checked":(depth0 != null ? lookupProperty(depth0,"checked") : depth0),"id":(depth0 != null ? lookupProperty(depth0,"statName") : depth0)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "          </a>\n        </li>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "inactive";
},"9":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"figuresByMetric") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(3, data, 0),"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":7}}})) != null ? stack1 : "")
    + "<div class=\"pull-right\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showNameFilterDropdown") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":2},"end":{"line":24,"column":9}}})) != null ? stack1 : "")
    + "  <div class=\"statsToggle pull-right\">\n    <div class=\"btn-group "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"displayToggleIsHidden") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":26},"end":{"line":26,"column":68}}})) != null ? stack1 : "")
    + "\">\n      <div class=\"btn btn-group-item "
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/eq.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"displayMode") : depth0),"numbers","is-active",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":27,"column":37},"end":{"line":27,"column":77}}}))
    + "\" role=\"numbers\"\n        data-analytics-type=\"number\">#</div>\n      <div class=\"btn btn-group-item "
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/eq.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"displayMode") : depth0),"percentages","is-active",{"name":"eq","hash":{},"data":data,"loc":{"start":{"line":29,"column":37},"end":{"line":29,"column":81}}}))
    + "\" role=\"percentages\"\n        data-analytics-type=\"percent\">%</div>\n    </div>\n  </div>\n  <div class=\"clearfix\"></div>\n</div>\n<div class=\"clearfix\"></div>";
},"usePartial":true,"useData":true});