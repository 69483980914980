export enum Pages {
  Statistics = 'statistics',
  StatisticsBrowser = 'statistics browser',
  StatisticsBrowserCompare = 'statistics browser compare',
  StatisticsCategory = 'statistics category',
  StatisticsCategoryCompare = 'statistics category compare',
  StatisticsDevice = 'statistics device',
  StatisticsGeo = 'statistics geo',
  StatisticsGlobal = 'statistics global',
  StatisticsMailboxProvider = 'statistics mailbox provider',
  StatisticsMailboxProviderCompare = 'statistics mailbox provider compare',
  StatisticsParseWebhook = 'statistics parse webhook',
  StatisticsSubuser = 'statistics subuser',
  StatisticsSubuserCompare = 'statistics subuser compare',
}

export enum Sections {
  ActivityMetrics = 'activity metrics',
  BrowserComparison = 'browser comparison',
  BrowserStats = 'browser stats',
  CategoryStats = 'category stats',
  CategoryStatsComparison = 'category stats comparison',
  ComparisonMetrics = 'comparison metrics',
  ComparisonOverview = 'comparison overview',
  EmailClientsDevices = 'email clients devices',
  Geographical = 'geographical',
  GlobalStats = 'global stats',
  MailboxProvider = 'mailbox provider',
  MailboxProviderComparison = 'mailbox provider comparison',
  MailboxProviderStats = 'mailbox provider stats',
  ParseWebhookRequests = 'parse webhook requests',
  SelectUpTo10CategoriesToCompare = 'select up to 10 categories to compare',
  SelectUpTo10SubusersToCompare = 'select up to 10 subusers to compare',
  StatisticsOverview = 'statistics overview',
  SubuserStats = 'subuser stats',
  SubuserStatsComparision = 'subuser stats comparison',
}

export enum Subsections {
  GettingStartedWith = 'getting started with',
  Search = 'search',
  Selected = 'selected',
}
