import { calculateMetricRate, MSPAggregation } from './helpers';
import { ClassificationTableStatRow } from '../../components/ClassificationTable';

interface BounceClassificationPercentages {
  [mspName: string]: {
    count: number;
    percent: number;
  };
}

export interface BounceClassificationPercentagesByMSP {
  bounceClassificationPercentages: BounceClassificationPercentages;
  overallCount: number;
  topMSPsLookup: string[];
}

/*
  Calculate the counts and percentages for the given top mailbox providers

  Usage: calculateBounceClassificationPercentagesByMSP(bounceClassificationCounts, ["Gmail", "Yahoo", "AOL", "Microsoft Outlook Live", "Remaining"])
  Output:
  {
    bounceClassificationPercentages: {
      Gmail: {
        count: 16,
        percent: 1.1
      },
      Yahoo: {
        count: 5,
        percent: 3.6,
      } 
      ...
    },
    overallCount: 20
    topMSPsLookup: ["Gmail", "Yahoo", ...]
  }
*/
export function calculateBounceClassificationPercentagesByMSP(
  bounceClassificationCounts: MSPAggregation,
  topMSPNames: string[]
): BounceClassificationPercentagesByMSP {
  const bounceClassificationPercentages: BounceClassificationPercentages = {};
  let overallCount = 0;

  // Seed all the known MSPs to handle the empty data case where a user with no stats will get back an empty array
  Object.values(topMSPNames).forEach((topMSPName) => {
    bounceClassificationPercentages[topMSPName] = {
      count: 0,
      percent: 0,
    };
  });

  // Sum up the counts
  Object.keys(bounceClassificationCounts).forEach((mspName) => {
    if (topMSPNames.includes(mspName)) {
      bounceClassificationPercentages[mspName].count +=
        bounceClassificationCounts[mspName];

      overallCount += bounceClassificationCounts[mspName];
    }
  });

  // Calculate the percentages if the overallCount is not 0; otherwise, percentages will remain 0
  if (overallCount) {
    Object.keys(bounceClassificationPercentages).forEach((mspName) => {
      bounceClassificationPercentages[mspName].percent = calculateMetricRate(
        bounceClassificationPercentages[mspName].count,
        overallCount
      );
    });
  }

  return {
    bounceClassificationPercentages,
    overallCount,
    topMSPsLookup: topMSPNames,
  };
}

export const topClassificationByMSPToTableDataAdapter = (
  topClassificationsByMSP: BounceClassificationPercentagesByMSP
): ClassificationTableStatRow[] => {
  const {
    bounceClassificationPercentages,
    topMSPsLookup,
  } = topClassificationsByMSP;

  const rows: ClassificationTableStatRow[] = [];

  topMSPsLookup.forEach((mspName) => {
    const row: ClassificationTableStatRow = {
      mailboxProvider: mspName,
      count: bounceClassificationPercentages[mspName].count,
      percent: bounceClassificationPercentages[mspName].percent,
    };

    rows.push(row);
  });

  return rows;
};
