import { ActionsObservable, ofType } from 'redux-observable';
import * as PaymentActions from './actions';
import * as UIActions from '../ui/actions';
import { from, Observable, of } from 'rxjs';
import { ActionTypes } from './actionTypes';
import {
  getPaymentMethod,
  reactivateUser,
  createSubscriptionPayment,
} from './service';
import {
  map,
  switchMap,
  mergeMap,
  catchError,
  concatMap,
} from 'rxjs/operators';
import { PaymentMethodDto } from '../dtos';

const getPaymentMethods = (
  action$: ActionsObservable<
    ReturnType<typeof PaymentActions.Actions.getPaymentMethod>
  >
): Observable<
  | ReturnType<typeof PaymentActions.Actions.getPaymentMethodSuccess>
  | ReturnType<typeof PaymentActions.Actions.getPaymentMethodFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.GetPaymentMethodRequest),
    switchMap(() => {
      return from(getPaymentMethod()).pipe(
        map((data) => {
          return PaymentActions.Actions.getPaymentMethodSuccess(
            data as PaymentMethodDto
          );
        }),
        catchError((e) => {
          return of(
            PaymentActions.Actions.getPaymentMethodFailure(
              e as PaymentMethodDto
            )
          );
        })
      );
    })
  );
};

const updateDefaultPaymentMethod = (
  action$: ActionsObservable<
    ReturnType<typeof PaymentActions.Actions.updateDefaultPaymentMethod>
  >
): Observable<
  | ReturnType<typeof PaymentActions.Actions.updateDefaultPaymentMethodSuccess>
  | ReturnType<typeof PaymentActions.Actions.updateDefaultPaymentMethodFailure>
  | ReturnType<typeof UIActions.Actions.createAlert>
> => {
  return action$.pipe(
    ofType(ActionTypes.UpdateDefaultPaymentMethodRequest),
    switchMap((action) => {
      return from(createSubscriptionPayment(action.payload)).pipe(
        mergeMap(() => {
          return from(getPaymentMethod()).pipe(
            concatMap((data) => {
              const text = 'Your payment method has been successfully updated.';
              return [
                UIActions.Actions.createAlert({ type: 'success', text }),
                PaymentActions.Actions.updateDefaultPaymentMethodSuccess(
                  data as PaymentMethodDto
                ),
              ];
            }),
            catchError((e) => {
              const text =
                'We failed to retrieve your updated payment method. Please try again.';
              return [
                UIActions.Actions.createAlert({ type: 'danger', text }),
                PaymentActions.Actions.updateDefaultPaymentMethodFailure([
                  {
                    field: '',
                    message: 'failed to retrieve your updated payment method',
                  },
                ]),
              ];
            })
          );
        }),
        catchError((e) => {
          let text =
            'We failed to update your payment method. Please try again.';
          if (
            e.errors.length != 0 &&
            e.errors[0].message.includes('card was declined')
          ) {
            text = 'Your card was declined, please try another payment method';
          }
          return from([
            UIActions.Actions.createAlert({ type: 'danger', text }),
            PaymentActions.Actions.updateDefaultPaymentMethodFailure([
              {
                field: '',
                message: 'failed to update payment method',
              },
            ]),
          ]);
        })
      );
    })
  );
};

const userReactivate = (
  action$: ActionsObservable<
    ReturnType<typeof PaymentActions.Actions.reactivateUser>
  >
): Observable<
  | ReturnType<typeof PaymentActions.Actions.reactivateUserSuccess>
  | ReturnType<typeof PaymentActions.Actions.reactivateUserFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.ReactivateUser),
    switchMap(() => {
      return from(reactivateUser()).pipe(
        map(() => {
          return PaymentActions.Actions.reactivateUserSuccess();
        }),
        catchError(() => {
          return of(PaymentActions.Actions.reactivateUserFailure());
        })
      );
    })
  );
};

export default [getPaymentMethods, updateDefaultPaymentMethod, userReactivate];
