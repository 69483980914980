export const getTeammateRequestAccessRefinedErrorMessage = (
  serverErrorMessage: string
): string => {
  let refinedErrorMessage = '';

  switch (serverErrorMessage) {
    case 'scope request already exists':
      refinedErrorMessage = `You've already requested access. Please wait for your account administrator's response.`;
      break;
    default:
      refinedErrorMessage = serverErrorMessage
        ? serverErrorMessage
        : 'Unable to request access at this time.';
  }

  return refinedErrorMessage;
};
