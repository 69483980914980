import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthedHttp } from '../../helpers/AuthedHttp';
import { sanitizer } from '../dtoSanitizer';
import {
  SendGridAppDispatch,
  SendGridRootState,
} from '../store/sendgridAppStore';
// types
import {
  GenericNetworkSlice,
  NetworkState,
  Unrequested,
} from '../store/sliceNetworkTypes';
import { ResponseError } from '../../helperTypes/responseError';

export enum Routes {
  SenderStepsCompleted = 'verified_senders/steps_completed',
}

const GenericFetchError =
  'Network Error. Failed to fetch sender steps completed';

export interface SenderStepsCompletedDto {
  results: SenderStepsCompletedResults;
}

export interface SenderStepsCompletedResults {
  sender_verified: boolean;
  domain_verified: boolean;
}

export interface SenderStepsCompleted {
  senderVerified: boolean;
  domainVerified: boolean;
}

export const fetchSenderStepsCompleted = createAsyncThunk<
  SenderStepsCompleted,
  void,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('senderStepsCompleted/fetchSenderStepsCompleted', async (_, thunkApi) => {
  try {
    const response = await AuthedHttp.get<SenderStepsCompletedDto>(
      Routes.SenderStepsCompleted
    );

    if (response.ok) {
      const data = await response.json();
      return senderStepsCompletedAdapter(data.results);
    }

    const errorResponse = await response.json();

    if (response.status === 403) {
      return { senderVerified: false, domainVerified: false };
    }

    return thunkApi.rejectWithValue({
      message: errorResponse.errors[0].message,
      statusCode: response.status,
    });
  } catch {
    return thunkApi.rejectWithValue({
      message: GenericFetchError,
    });
  }
});

export const senderStepsCompletedAdapter = (
  dto: SenderStepsCompletedResults
): SenderStepsCompleted => {
  const sanitizedSenderStepsCompleted = sanitizer(dto);
  const {
    sender_verified,
    domain_verified,
    ...sanitized
  } = sanitizedSenderStepsCompleted;

  return {
    senderVerified: sender_verified,
    domainVerified: domain_verified,
    ...sanitized,
  } as SenderStepsCompleted;
};

export type SenderStepsCompletedState = GenericNetworkSlice<
  SenderStepsCompleted
>;

const initialState: Unrequested = {
  networkState: NetworkState.Unrequested,
  data: null,
};

export const senderStepsCompletedSlice = createSlice<
  SenderStepsCompletedState,
  any
>({
  name: 'senderStepsCompleted',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSenderStepsCompleted.pending, (state) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(fetchSenderStepsCompleted.fulfilled, (state, action) => {
      state.networkState = NetworkState.Success;

      if (state.networkState === NetworkState.Success) {
        state.data = action.payload;
      }
    });
    builder.addCase(fetchSenderStepsCompleted.rejected, (state, action) => {
      state.networkState = NetworkState.Error;

      if (state.networkState === NetworkState.Error) {
        state.errorMessage = action.payload?.message || GenericFetchError;
      }
    });
  },
});
