const Marionette = require("backbone.marionette");
const _ = require("underscore");
const APIConfig = require("apiconfig");
const $ = require("jquery");

module.exports = Marionette.ItemView.extend({
  template: require("templates/errors/access_denied.hbs"),

  ui: {
    requestAccess: "[role=requestAccess]",
    showUpgrade: "[role=showUpgrade]",
  },

  events: {
    "click @ui.showUpgrade": "showUpgrade",
    "click @ui.requestAccess": "requestAccess",
  },

  initialize() {
    this.listenTo(
      this.getOption("usernameModel"),
      "sync change",
      this.gotUsernameResponse
    );
    this.listenTo(
      this.getOption("accountProfileModel"),
      "sync change",
      this.gotAccountProfileResponse
    );
    this.listenTo(
      this.getOption("scopeRequestVerifyModel"),
      "sync",
      this.canRequestAccess
    );
    this.listenTo(
      this.getOption("scopeRequestVerifyModel"),
      "sync error",
      this.gotRequestVerifyResponse
    );
    this.canTeammateRequestAccess = this.getOption("canTeammateRequestAccess");
  },

  gotUsernameResponse() {
    this.gotUsername = true;
    this.render();
  },

  gotAccountProfileResponse() {
    this.gotAccountProfile = true;
    this.render();
  },

  gotRequestVerifyResponse() {
    this.gotRequestVerify = true;
    this.render();
  },

  canRequestAccess() {
    const requiredScope = this.getOption("requiredScope");
    const scopeRequestVerifyModel = this.getOption("scopeRequestVerifyModel");
    if (
      !requiredScope ||
      this.getOption("bypassScopeCheck") ||
      _.contains(scopeRequestVerifyModel.get("scopes"), requiredScope)
    ) {
      this.canTeammateRequestAccess = true;
    }
    this.render();
  },

  showUpgrade() {
    window.EventBus.trigger("navigate", "account/products");
  },

  serializeData() {
    const username = this.getOption("usernameModel").get("username");
    const accountUsername = this.getOption("accountProfileModel").get(
      "username"
    );
    const accountEmail = this.getOption("accountProfileModel").get("email");

    const data = {
      restrictAccessRequest: this.getOption("restrictAccessRequest"),
      accountEmail,
    };

    const isTeammate =
      !!username && !!accountUsername && username !== accountUsername;
    if (isTeammate && this.canTeammateRequestAccess) {
      data.canTeammateRequestAccess = true;
    }

    if (this.gotUsername && this.gotAccountProfile && this.gotRequestVerify) {
      data.loaded = true;
    }

    return data;
  },

  requestAccess() {
    const self = this;

    this.ui.requestAccess.addClass("is-disabled");

    $.ajax({
      type: "POST",
      url: `${APIConfig.host}scopes/requests`,
      contentType: "application/json",
      dataType: "json",
      data: JSON.stringify({
        scope_group_name: this.getOption("scopeGroupName"),
      }),
      success() {
        window.EventBus.trigger("flash", "Access was requested.");
        self.ui.requestAccess.text("Access Requested");
      },
      error(response) {
        const resp = response.responseJSON;
        if (response.status === 500) {
          window.EventBus.trigger(
            "flash",
            "Unable to request access at this time.",
            true
          );
        } else {
          let errorMessage = resp.errors[0].message;
          if (errorMessage === "scope request already exists") {
            errorMessage = `You've already requested access. Please wait for your account administrator's response.`;
          }
          window.EventBus.trigger("flash", errorMessage, true);
        }
        self.ui.requestAccess.removeClass("is-disabled");
      },
    });
  },
});
