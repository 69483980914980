import { UserTypeAndRegionDto } from 'src/routes/sender_authentication/create_domain_helper';
import { UserAccountType } from '../types/user';
import { ResponseErrorsDto } from 'src/helperTypes/responseError';

const isErrorResponse = (
  dto: UserTypeAndRegionDto | ResponseErrorsDto
): dto is ResponseErrorsDto => {
  return 'errors' in dto && dto.errors !== undefined && dto.errors.length > 0;
};

export const mapUserType = (
  dto: UserTypeAndRegionDto | ResponseErrorsDto
): UserAccountType => {
  if (isErrorResponse(dto)) {
    return {
      region: '',
      type: '',
      errors: dto.errors,
    };
  }
  return {
    region: dto.region ?? '',
    type: dto.type ?? '',
  };
};
