// Please keep these shared enums alphabetized
// to reduce merge conflicts.

export enum EventTypes {
  Blur = 'blur',
  Click = 'click',
  Display = 'display',
  Hover = 'hover',
  Response = 'response',
  View = 'view',
}

export enum ElementTypes {
  AccordionToggle = 'accordion toggle',
  Alert = 'alert',
  Area = 'area',
  Button = 'button',
  Checkbox = 'checkbox',
  DatePicker = 'date picker',
  DropdownToggle = 'dropdown toggle',
  Icon = 'icon',
  Input = 'input',
  Link = 'link',
  ListItem = 'list item',
  RadioButton = 'radio button',
  Select = 'select',
  Slider = 'slider',
  Tab = 'tab',
  TableRow = 'table row',
  Text = 'text',
  Tooltip = 'tooltip',
}

export enum Locations {
  Modal = 'modal',
  Nav = 'nav',
  Popup = 'popup',
  SideModal = 'side modal',
  FullScreenModal = 'full screen modal',
}
