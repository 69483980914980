import { MakoStateType } from './../types/makoStateType';
import { TestingCreditsPlan } from './types';
import { defaultTestingCreditsPlan } from '../testing_credits_subscription/defaultTestingCreditsPlan';

export const getUserTestingCreditsPlan = (state: MakoStateType) => {
  return state.testingCreditsPlans.find(
    (plan) => state.testingCreditsSubscription.purchasedCount === plan.count
  );
};

export const getTestingCreditsPlanByName = (
  planName: string,
  testingCreditsPlans: TestingCreditsPlan[]
) => {
  return testingCreditsPlans.find((plan) => plan.name === planName);
};

export const getTestingCreditsPlanNameByCount = (
  count: number,
  testingCreditsPlans: TestingCreditsPlan[]
) => {
  const tcPlan = testingCreditsPlans.find((plan) => plan.count === count);
  if (tcPlan && tcPlan.name) {
    return tcPlan.displayName;
  } else {
    return defaultTestingCreditsPlan.name;
  }
};
