var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"columns") : depth0),{"name":"each","hash":{},"fn":container.program(2, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":4},"end":{"line":7,"column":13}}})) != null ? stack1 : "");
},"2":function(container,depth0,helpers,partials,data) {
    var alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<th title=\""
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/separateWordAndCapitalize.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"separateWordAndCapitalize","hash":{},"data":data,"loc":{"start":{"line":6,"column":16},"end":{"line":6,"column":51}}}))
    + "\">"
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/separateWordAndCapitalize.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"separateWordAndCapitalize","hash":{},"data":data,"loc":{"start":{"line":6,"column":53},"end":{"line":6,"column":88}}}))
    + "</th>\n";
},"4":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"columns") : depth0),{"name":"each","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":4},"end":{"line":16,"column":13}}})) != null ? stack1 : "");
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "					<th class=\"stat rank-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rank") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\" title=\""
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/separateWordAndCapitalize.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"separateWordAndCapitalize","hash":{},"data":data,"loc":{"start":{"line":10,"column":51},"end":{"line":10,"column":86}}}))
    + "\">\n						"
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/separateWordAndCapitalize.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"label") : depth0),{"name":"separateWordAndCapitalize","hash":{},"data":data,"loc":{"start":{"line":11,"column":6},"end":{"line":11,"column":41}}}))
    + "\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"computation") : depth0),{"name":"if","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":6},"end":{"line":14,"column":13}}})) != null ? stack1 : "")
    + "					</th>\n";
},"6":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "							<span class=\"computation "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"displayComputations") : depth0),{"name":"unless","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":13,"column":32},"end":{"line":13,"column":80}}})) != null ? stack1 : "")
    + "\">"
    + container.escapeExpression(__default(require("/opt/sendgrid/mako/src/templates/helpers/separateWordAndCapitalize.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"computation") : depth0),{"name":"separateWordAndCapitalize","hash":{},"data":data,"loc":{"start":{"line":13,"column":82},"end":{"line":13,"column":123}}}))
    + "</span>\n";
},"7":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<table class=\"data-table stats reversed "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"className") : depth0), depth0))
    + "\">\n	<thead>\n		<tr>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"rowHeader") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.program(4, data, 0),"data":data,"loc":{"start":{"line":4,"column":3},"end":{"line":17,"column":10}}})) != null ? stack1 : "")
    + "		</tr>\n	</thead>\n	<tbody>\n\n	</tbody>\n</table>\n";
},"useData":true});