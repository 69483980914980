/* eslint-disable */

const Marionette = require("backbone.marionette");
const { analytics } = require("@sendgrid/bloodhound");

// views
const PageLayoutView = require("../views/page_layout_view");
const ProvisionStatusView = require("../views/provision_status_view");

const occupationCookieKey = "sg_signup_user_occupation";
const marketing = "marketing";
const marketingEvent = "is_marketer";

function tryAndFireOccupationEvent() {
  const occupationCookie = $.cookie(occupationCookieKey);
  if (occupationCookie && occupationCookie.toLowerCase() === marketing) {
    analytics.track({ event: marketingEvent });
  }

  // we expire the cookie
  $.removeCookie(occupationCookieKey);
}

module.exports = Marionette.Controller.extend({
  _applyLayout() {
    if (
      this.getOption("region").hasView() &&
      this.getOption("region").currentView === this._layout
    ) {
      return this._layout;
    }

    const provisionStatusView = new ProvisionStatusView({
      model: this.getOption("provisionStatusModel"),
      emailModel: this.getOption("emailModel"),
    });

    this._layout = new PageLayoutView.default({
      provisionStatusView,
    });

    this.getOption("region").show(this._layout);

    this.getOption("provisionStatusModel").fetch();
    this.getOption("emailModel").fetch();

    // check for marketing cookie, if it exists, fire analytics event
    // then delete cookie
    tryAndFireOccupationEvent();

    return this._layout;
  },

  display(view) {
    this._applyLayout().page.show(view);
  },
});
