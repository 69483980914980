import { AuthedHttp, ResponseBodySuccess } from '../../helpers/AuthedHttp';
import { ResponseError } from '../types/responseError';
import { TeammatesDto } from '../dtos';

export const getTeammatesData = (): Promise<TeammatesDto> => {
  return AuthedHttp.get<TeammatesDto>(`teammates`)
    .then((response: ResponseBodySuccess<TeammatesDto>) => response.json())
    .catch(() => {
      return {
        errors: [
          {
            message: 'Failed to retrieve teammates due to some reason',
          },
        ] as ResponseError[],
      };
    });
};
