import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';
import { ActionTypes } from './actionTypes';
import { UserAccountType } from '../types/user';

const initialState = {
  region: '',
  type: '',
  errors: undefined,
} as UserAccountType;

interface UserAction extends Action {
  payload: MakoStateType['userType'];
}

export const userType = (state = initialState, action: UserAction) => {
  switch (action.type) {
    case ActionTypes.UserTypeRequest:
      return {
        ...state,
        errors: undefined,
      };
    case ActionTypes.UserTypeSuccess:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.UserTypeFailure:
      return {
        ...initialState,
        errors: action.payload?.errors || [
          {
            message: 'unexpected error fetching user account type',
          },
        ],
      };
    default:
      return state;
  }
};
