import ReactDOM from 'react-dom';
import React from 'react';
import { Theme } from '@twilio-paste/theme';
import { NavbarContainer } from 'src/components/Navbar/NavbarContainer';

export function GlobalNavbar(querySelector: string) {
  const reactNode = document.querySelector(querySelector);
  if (reactNode) {
    ReactDOM.render(
      <React.StrictMode>
        <Theme.Provider theme="twilio">
          <NavbarContainer />
        </Theme.Provider>
      </React.StrictMode>,
      reactNode
    );
  }
}
