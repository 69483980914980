import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SendGridAppDispatch, SendGridRootState } from './sendgridAppStore';
import { ResponseError } from '../../helperTypes/responseError';
import { ResponseBody } from '../../helpers/AuthedHttp';
import { NetworkState } from './sliceNetworkTypes';
import {
  ProfileDto,
  ProfileData,
  initialProfileState,
  profileAdapter,
} from './profileSlice';

export const getNonImpersonatedProfileFromTiara = createAsyncThunk<
  ProfileData,
  ResponseBody<ProfileDto>,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>(
  'nonImpersonatedProfile/getNonImpersonatedProfileFromTiara',
  async (args, thunkApi) => {
    try {
      const response = await args;

      if (response.ok) {
        const data = await response.json();
        return profileAdapter(data);
      }

      const errorData = await response.json();

      return thunkApi.rejectWithValue({
        message: errorData.errors[0].message,
        statusCode: response.status,
      });
    } catch {
      return thunkApi.rejectWithValue({
        message: 'Network Level Error',
      } as ResponseError);
    }
  }
);

export const nonImpersonatedProfileSlice = createSlice({
  name: 'nonImpersonatedProfile',
  initialState: initialProfileState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNonImpersonatedProfileFromTiara.pending, (state) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(
      getNonImpersonatedProfileFromTiara.fulfilled,
      (state, action) => {
        state.networkState = NetworkState.Success;

        if (state.networkState === NetworkState.Success) {
          state.data = action.payload;
        }
      }
    );
    builder.addCase(
      getNonImpersonatedProfileFromTiara.rejected,
      (state, action) => {
        state.networkState = NetworkState.Error;

        if (state.networkState === NetworkState.Error) {
          state.errorMessage = action?.payload?.message ?? 'Unknown Error';
        }
      }
    );
  },
});
