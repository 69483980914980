import {
  BounceClassificationByMailboxProviderDto,
  MailboxProviderName,
} from '../types';
import {
  MSPAggregation,
  calculateTopMSPsLookupFromMetricsCounts,
} from './helpers';

export interface BounceClassificationCountsByMSP {
  bounceClassificationCounts: MSPAggregation;
  topMSPsLookup: string[];
}

/*
  Calculate the counts by MSP for the given bounce classification data

  Output:
  {
    bounceClassificationCounts: {
      Gmail: 50,
      Yahoo: 25,
      AOL: 10,
      Microsoft Outlook Live: 10,
      Other: 5,
      ...
    }
    topMSPsLookup: ["Gmail", "Yahoo", "AOL", "Microsoft Outlook Live", "Other",...]
  }
*/
export function calculateBounceClassificationCountsByMSP(
  data: BounceClassificationByMailboxProviderDto
): BounceClassificationCountsByMSP {
  const bounceClassificationCounts: MSPAggregation = {};

  // Seed all the known MSPs to handle the empty data case where a user with no stats will get back an empty array
  Object.values({ ...MailboxProviderName }).forEach((mspName) => {
    bounceClassificationCounts[mspName] = 0;
  });

  // Sum up the counts by MSP
  data.result.forEach(({ stats }) => {
    stats.forEach(({ domain, count }) => {
      bounceClassificationCounts[domain] =
        (bounceClassificationCounts[domain] ?? 0) + count;
    });
  });

  const topMSPsLookup = calculateTopMSPsLookupFromMetricsCounts(
    bounceClassificationCounts
  );

  return { bounceClassificationCounts, topMSPsLookup };
}

export function getTopMSPNamesFromBounceClassificationByMSP(
  numTopMSPs: number,
  bounceClassificationCountsByMSP: BounceClassificationCountsByMSP
): string[] {
  return [
    ...bounceClassificationCountsByMSP.topMSPsLookup.slice(0, numTopMSPs),
  ];
}
