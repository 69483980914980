import { createSelector } from '@reduxjs/toolkit';
import { api } from '../apiSlice';
import { RequestTypes } from '../../../../helpers/AuthedHttp';
import { dataInjectionKey, RtkQueryDataInjection } from '../utils';
import { Endpoints } from '../Endpoints';
import { ScopesDto } from '../../scopeSlice';

export const scopesApi = api.injectEndpoints({
  endpoints: (builder) => ({
    [Endpoints.fetchScopes]: builder.query<
      ScopesDto,
      { [dataInjectionKey]?: RtkQueryDataInjection } | void
    >({
      query: ({ dataInjection } = {}) => ({
        url: 'scopes',
        method: RequestTypes.Get,
        dataInjection,
      }),
    }),
  }),
});

const selectScopesResult = scopesApi.endpoints[Endpoints.fetchScopes].select();

export const selectAllScopes = createSelector(
  selectScopesResult,
  (scopesResult) => scopesResult?.data?.scopes ?? []
);

export const selectHasScope = createSelector(
  selectAllScopes,
  (state: any, scopeName: string) => scopeName,
  (scopes, scopeName) => scopes.some((scope) => scope === scopeName)
);

export const { useFetchScopesQuery } = scopesApi;
