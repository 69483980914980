import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SendGridAppDispatch, SendGridRootState } from './sendgridAppStore';
import { ResponseError } from '../../helperTypes/responseError';
import { AuthedHttp, ResponseBody } from '../../helpers/AuthedHttp';
import {
  DtoWrapper,
  GenericNetworkSlice,
  NetworkState,
} from './sliceNetworkTypes';

const GenericErrorMessage = "Failed to load user's email confirmation.";

enum Routes {
  EmailConfirmation = 'tokens/email',
}

export interface EmailConfirmationDto {
  email: string;
  confirmed_at: null | string;
}

export interface EmailConfirmation {
  email: string;
  confirmedAt: null | string;
}

export type UserEmailStore = GenericNetworkSlice<EmailConfirmation>;

const initialEmailState = {
  networkState: NetworkState.Unrequested,
} as UserEmailStore;

const emailConfirmationAdapter = (
  dto: EmailConfirmationDto
): EmailConfirmation => ({
  email: dto.email,
  confirmedAt: dto.confirmed_at,
});

export const getUserEmailFromTokensEmailModel = createAsyncThunk<
  DtoWrapper<EmailConfirmation>,
  Promise<ResponseBody<EmailConfirmationDto>>,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('userEmail/getUserEmailFromTokensEmailModel', async (args, thunkApi) => {
  try {
    const emailResp = await args;

    if (emailResp.ok) {
      const data = await emailResp.json();
      return {
        statusCode: emailResp.status,
        data: emailConfirmationAdapter(data),
      };
    }

    return thunkApi.rejectWithValue({
      message: GenericErrorMessage,
      statusCode: emailResp.status,
    });
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: GenericErrorMessage,
    });
  }
});

export const fetchUserEmail = createAsyncThunk<
  DtoWrapper<EmailConfirmation>,
  void,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('userEmail/fetchUserEmail', async (_, thunkApi) => {
  try {
    const response = await AuthedHttp.get<EmailConfirmationDto>(
      Routes.EmailConfirmation
    );

    if (response.ok) {
      const data = await response.json();
      return {
        statusCode: response.status,
        data: emailConfirmationAdapter(data),
      };
    }

    return thunkApi.rejectWithValue({
      message: GenericErrorMessage,
      statusCode: response.status,
    });
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: GenericErrorMessage,
    });
  }
});

export const userEmailSlice = createSlice({
  name: 'email',
  initialState: initialEmailState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserEmailFromTokensEmailModel.pending, (state) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(
      getUserEmailFromTokensEmailModel.fulfilled,
      (_, action) => ({
        networkState: NetworkState.Success,
        statusCode: action.payload.statusCode,
        data: action.payload.data,
      })
    );
    builder.addCase(getUserEmailFromTokensEmailModel.rejected, (_, action) => ({
      networkState: NetworkState.Error,
      statusCode: action.payload?.statusCode ?? 0,
      errorMessage: action.payload?.message ?? GenericErrorMessage,
    }));

    builder.addCase(fetchUserEmail.pending, (state) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(fetchUserEmail.fulfilled, (state, action) => ({
      networkState: NetworkState.Success,
      statusCode: action.payload?.statusCode ?? 0,
      data: action.payload?.data ?? GenericErrorMessage,
    }));
    builder.addCase(fetchUserEmail.rejected, (state, action) => ({
      networkState: NetworkState.Error,
      statusCode: action.payload?.statusCode ?? 0,
      errorMessage: action.payload?.message ?? GenericErrorMessage,
    }));
  },
});
