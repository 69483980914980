import { api } from '../apiSlice';
import { RequestTypes } from '../../../../helpers/AuthedHttp';
import { CamelCasedProperties } from 'type-fest';
import {
  convertAllKeysToCamelCase,
  dataInjectionKey,
  RtkQueryDataInjection,
} from '../utils';
import { Endpoints } from '../Endpoints';

interface EmailConfirmationDto {
  email: string;
  confirmed_at: null | string;
}

export type EmailConfirmation = CamelCasedProperties<EmailConfirmationDto>;

export const userEmailApi = api.injectEndpoints({
  endpoints: (builder) => ({
    [Endpoints.fetchUserEmail]: builder.query<
      EmailConfirmation,
      { [dataInjectionKey]?: RtkQueryDataInjection } | void
    >({
      query: ({ dataInjection } = {}) => ({
        url: 'tokens/email',
        method: RequestTypes.Get,
        dataInjection,
      }),
      transformResponse: (data: EmailConfirmationDto) =>
        convertAllKeysToCamelCase<EmailConfirmationDto>(data),
    }),
  }),
});

export const { useFetchUserEmailQuery } = userEmailApi;
