var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "is-required";
},"3":function(container,depth0,helpers,partials,data) {
    return "is-error";
},"5":function(container,depth0,helpers,partials,data) {
    return "is-disabled";
},"7":function(container,depth0,helpers,partials,data) {
    return "is-full-width";
},"9":function(container,depth0,helpers,partials,data) {
    return "is-search";
},"11":function(container,depth0,helpers,partials,data) {
    return "has-value";
},"13":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"input_type") : depth0), depth0));
},"15":function(container,depth0,helpers,partials,data) {
    return "text";
},"17":function(container,depth0,helpers,partials,data) {
    return "autofocus";
},"19":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "    <div class=\"input-info-tooltip\" style=\"line-height: 16px\" role=\"tooltip-container\">\n      <span\n              data-tooltip=\""
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"tooltip_text") : depth0), depth0))
    + "\"\n              data-tooltip-pos=\""
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"tooltip_direction") : depth0),{"name":"if","hash":{},"fn":container.program(20, data, 0),"inverse":container.program(22, data, 0),"data":data,"loc":{"start":{"line":53,"column":32},"end":{"line":53,"column":98}}})) != null ? stack1 : "")
    + "\"\n              data-tooltip-length=\"large\" role=\"tooltip-span\"\n      >\n        <i class=\"sg-icon sg-icon-info-circle input-info-icon\" role=\"tooltip-icon\"></i>\n      </span>\n    </div>\n";
},"20":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"tooltip_direction") : depth0), depth0));
},"22":function(container,depth0,helpers,partials,data) {
    return "right";
},"24":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.lambda, alias3=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"input-text-wrap\n  "
    + ((stack1 = __default(require("/opt/sendgrid/mako/src/templates/helpers/eq.js")).call(alias1,"true",(depth0 != null ? lookupProperty(depth0,"is_required") : depth0),{"name":"eq","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":25,"column":2},"end":{"line":25,"column":46}}})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"error_text") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":26,"column":2},"end":{"line":26,"column":35}}})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_disabled") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":27,"column":2},"end":{"line":27,"column":39}}})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_full_width") : depth0),{"name":"if","hash":{},"fn":container.program(7, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":28,"column":2},"end":{"line":28,"column":43}}})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"is_search") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":29,"column":2},"end":{"line":29,"column":35}}})) != null ? stack1 : "")
    + "\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"value") : depth0),{"name":"if","hash":{},"fn":container.program(11, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":30,"column":2},"end":{"line":30,"column":31}}})) != null ? stack1 : "")
    + "\n\"\n     style=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"container_style") : depth0), depth0))
    + "\"\n     role=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"container_role") : depth0), depth0))
    + "\">\n\n  <label class=\"input-text-label\" for=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"container_role") : depth0), depth0))
    + "-input-id\" role=\"label\">\n    "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"label") : depth0), depth0))
    + "\n  </label>\n\n  <input class=\"input-text\"\n         data-field=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"data-field") : depth0), depth0))
    + "\"\n         id=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"container_role") : depth0), depth0))
    + "-input-id\"\n         type=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"input_type") : depth0),{"name":"if","hash":{},"fn":container.program(13, data, 0),"inverse":container.program(15, data, 0),"data":data,"loc":{"start":{"line":42,"column":15},"end":{"line":42,"column":66}}})) != null ? stack1 : "")
    + "\"\n         role=\"input\"\n         value=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"value") : depth0), depth0))
    + "\"\n         maxlength=\""
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"max_length") : depth0), depth0))
    + "\" "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"autofocus") : depth0),{"name":"if","hash":{},"fn":container.program(17, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":45,"column":36},"end":{"line":45,"column":69}}})) != null ? stack1 : "")
    + "\n\n  >\n\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"tooltip_text") : depth0),{"name":"if","hash":{},"fn":container.program(19, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":2},"end":{"line":59,"column":9}}})) != null ? stack1 : "")
    + "\n  <span class=\"input-info danger "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"error_text") : depth0),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":61,"column":33},"end":{"line":61,"column":72}}})) != null ? stack1 : "")
    + "\" role=\"error\">\n    "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"error_text") : depth0), depth0))
    + "\n  </span>\n\n  <span class=\"input-info "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias1,(depth0 != null ? lookupProperty(depth0,"info_text") : depth0),{"name":"unless","hash":{},"fn":container.program(24, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":65,"column":26},"end":{"line":65,"column":64}}})) != null ? stack1 : "")
    + "\" role=\"info\">\n    "
    + alias3(alias2((depth0 != null ? lookupProperty(depth0,"info_text") : depth0), depth0))
    + "\n  </span>\n</div>\n";
},"useData":true});