import { SSOIntegrationDto, SSOIntegrationData } from './types';

export const ssoIntegrationAdapter = (
  config: SSOIntegrationDto
): SSOIntegrationData => {
  const {
    signin_url: signinUrl,
    signout_url: signoutUrl,
    entity_id: entityId,
    single_signon_url: singleSignonUrl,
    audience_url: audienceUrl,
    last_updated: lastUpdated,
    completed_integration: completedIntegration,
    jit_provisioning_enabled: jitProvisioningEnabled,
    ...rest
  } = config;

  return {
    ...rest,
    signinUrl,
    signoutUrl,
    entityId,
    singleSignonUrl,
    audienceUrl,
    lastUpdated,
    completedIntegration,
    jitProvisioningEnabled,
  };
};
