import { ActionsUnion, createAction } from '../../actionBuilder';
import { ActionTypes } from './actionTypes';

export const Actions = {
  getUserProducts: () => createAction(ActionTypes.GetUserProductsRequest),
  getUserProductsSuccess: () =>
    createAction(ActionTypes.GetUserProductsSuccess),
  getUserProductsFailure: () =>
    createAction(ActionTypes.GetUserProductsFailure),
};

export type Actions = ActionsUnion<typeof Actions>;
export { Actions as UserProductsActions };
export default Actions;
