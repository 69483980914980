import { Action } from '../types/action';
import { ActionTypes } from './actionTypes';

import { EaseSubscription } from './types';

interface EaseAction extends Action {
  payload: EaseSubscription;
}

export const easeSubscription = (state = {}, action: EaseAction) => {
  switch (action.type) {
    // get ease
    case ActionTypes.GetEaseSuccess:
      return action.payload;
    case ActionTypes.GetEaseFailure:
    // delete ease
    case ActionTypes.DeleteEaseSuccess:
      return action.payload;
    case ActionTypes.DeleteEaseFailure:
    case ActionTypes.AddEaseSuccess:
      return action.payload;
    default:
      return state;
  }
};
