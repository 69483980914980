import { Action } from '../types/action';
import { MarketingCampaignsPlan } from '../types/marketingCampaignsPlan';
import { ActionTypes } from './actionTypes';

const initialState: MarketingCampaignsPlan[] = new Array<
  MarketingCampaignsPlan
>();

interface MarketingCampaignsPlanAction extends Action {
  payload: MarketingCampaignsPlan[];
}

export const marketingCampaignsPlans = (
  state = initialState,
  action: MarketingCampaignsPlanAction
) => {
  switch (action.type) {
    case ActionTypes.GetMarketingCampaignsPlansSuccess:
      return action.payload as MarketingCampaignsPlan[];

    default:
      return state;
  }
};
