import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { UserAccountType } from '../types/user';
import { ResponseErrorsDto } from 'src/helperTypes/responseError';

export const Actions = {
  userTypeRequest: () => createAction(ActionTypes.UserTypeRequest),
  userTypeSuccess: (userType: UserAccountType) =>
    createAction(ActionTypes.UserTypeSuccess, userType),
  userTypeFailure: (errors: ResponseErrorsDto) =>
    createAction(ActionTypes.UserTypeFailure, errors),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
