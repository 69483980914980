import Highcharts from 'highcharts';
import {
  MSPMetricNames,
  calculateMetricRate,
  MSPAggregation,
  DateToMSPMetrics,
} from './helpers';
import {
  MailboxProviderStatsDto,
  MailboxProviderStatDto,
  MailboxProviderStat,
} from '../types';
import { MSPColors } from '../../../components/Highcharts/chartStyles';
import { formatChartTooltipDate } from '../../../utils/formatDisplayStatDate';
import numberWithCommas from '../../../../../helpers/numberWithCommas';
import { StatsAggregationOptions } from '../../../components/StatsAggregationSelect';

export interface MSPMetricRatesByDate {
  MetricCountsByDate: DateToMSPMetrics;
  DivisorMetricCountsByDate: DateToMSPMetrics;
  MetricRatesByMSP: MSPToDateMetrics;
  Overall: {
    MetricCounts: MSPAggregation;
    DivisorMetricCounts: MSPAggregation;
    MetricRates: MSPAggregation;
  };
  TopMSPsLookup: string[];
  Dates: string[];
}

export type MSPToDateMetrics = {
  [mspName: string]: { [date: string]: number };
};

/*
  Calculate the metric/divisor metric and metric rates (metricName / divisorName) by date, overall counts and rates, and top MSP names sorted based on divisorMetric in descending order 
  This supports the double bar comparison charts like bounced by MSP and blocked by MSP

  Usage: calculateMSPMetricRatesByDate("bounces", "processed", mspStatsDto, ["Gmail, "Yahoo", "AOL", "Microsoft Outlook Live", "Remaining"]); for bounce rates by date
  Output: 
  {
    // Bounce Counts by Date
    MetricCountsByDate: {
      "2021-02-01": {
        Gmail: 10,
        Yahoo: 9,
        AOL: 6,
        Microsoft Outlook Live: 5,
        Remaining: 1,
        ...
      },
      ...
    },
    // Processed Counts by Date
    DivisorMetricCountsByDate: {
      // Properties like MetricsCountsByDate...
    }
    // Bounce Rates by MSP
    MetricRatesByMSP: {
      Gmail: {
        "2021-02-01": 10,
        ...
      },
      ....
    },
    Overall: {
      MetricCounts: {
        Gmail: 100,
        ...
      },
      DivisorMetricCounts: {
        Gmail: 1000,
        ...
      },
      MetricRates: {
        Gmail: 10,
        ...
      }
    }
    // Top X MSP names sorted in descending order based on the MSP's processed count
    TopMSPsLookup: ["Gmail", "Yahoo", "AOL", "Microsoft Outlook Live", "Remaining"],
    Dates: ["2021-02-01", ...]
  }
*/
export function calculateMSPMetricRatesByDate(
  metricName: MSPMetricNames,
  divisorName: MSPMetricNames,
  stats: MailboxProviderStatsDto,
  topMSPNames: string[]
) {
  const MetricCountsByDate: DateToMSPMetrics = {};
  const OverallMetricCounts: MSPAggregation = {};
  const DivisorMetricCountsByDate: DateToMSPMetrics = {};
  const OverallDivisorMetricCounts: MSPAggregation = {};
  const DefaultMSPMetrics: MSPAggregation = {};
  const MetricRatesByMSP: MSPToDateMetrics = {};
  const OverallMetricRates: MSPAggregation = {};
  const Dates: string[] = [];

  // Seed all the known MSPs to handle the empty data case where a user with no stats will get back an empty array
  Object.values(topMSPNames).forEach((mspName) => {
    DefaultMSPMetrics[mspName] = 0;
    MetricRatesByMSP[mspName] = {};
    OverallMetricCounts[mspName] = 0;
    OverallDivisorMetricCounts[mspName] = 0;
    OverallMetricRates[mspName] = 0;
  });

  // Keep track of metric counts by MSP and date
  stats.forEach((byDate: MailboxProviderStatDto) => {
    Dates.push(byDate.date);
    MetricCountsByDate[byDate.date] = { ...DefaultMSPMetrics };
    DivisorMetricCountsByDate[byDate.date] = { ...DefaultMSPMetrics };

    byDate.stats.forEach((metricByMsp: MailboxProviderStat) => {
      const mspName: string = metricByMsp.name;
      if (topMSPNames.includes(mspName)) {
        const metrics = metricByMsp.metrics;

        // Sum up metric and divisor counts by date for each MSP
        const metricCount = metrics[metricName] ?? 0;
        MetricCountsByDate[byDate.date][mspName] =
          MetricCountsByDate[byDate.date][mspName] + metricCount;
        OverallMetricCounts[mspName] =
          OverallMetricCounts[mspName] + metricCount;

        const divisorMetricCount = metrics[divisorName] ?? 0;
        DivisorMetricCountsByDate[byDate.date][mspName] =
          DivisorMetricCountsByDate[byDate.date][mspName] + divisorMetricCount;
        OverallDivisorMetricCounts[mspName] =
          OverallDivisorMetricCounts[mspName] + divisorMetricCount;
      }
    });

    // Calculate metric rates for each MSP for each date
    Object.entries(MetricCountsByDate).forEach(([day, mspData]) => {
      Object.keys(mspData).forEach((msp) => {
        const metricRate = calculateMetricRate(
          MetricCountsByDate[day][msp],
          DivisorMetricCountsByDate[day][msp]
        );
        MetricRatesByMSP[msp][day] = metricRate;
      });
    });
  });

  // Calculate overall metric rates for each MSP
  Object.keys(OverallMetricCounts).forEach((mspName) => {
    const overallMetricRate = calculateMetricRate(
      OverallMetricCounts[mspName],
      OverallDivisorMetricCounts[mspName]
    );

    OverallMetricRates[mspName] = overallMetricRate;
  });

  return {
    MetricCountsByDate,
    DivisorMetricCountsByDate,
    MetricRatesByMSP,
    Overall: {
      MetricCounts: OverallMetricCounts,
      DivisorMetricCounts: OverallDivisorMetricCounts,
      MetricRates: OverallMetricRates,
    },
    TopMSPsLookup: topMSPNames,
    Dates,
  };
}

export const MSPMetricRatesByDateToLineChartSeriesAdapter = (
  MSPMetricRatesByDate: MSPMetricRatesByDate,
  aggregatedBy?: StatsAggregationOptions,
  startDate?: string,
  endDate?: string
) => {
  const {
    MetricRatesByMSP,
    MetricCountsByDate,
    Overall,
    Dates,
  } = MSPMetricRatesByDate;

  const hasOnePoint = Dates.length === 1;

  const series: Highcharts.SeriesLineOptions[] = [];
  Object.entries(MetricRatesByMSP).forEach(([MSP, dateData], colorKey) => {
    const seriesLineOptions: Highcharts.SeriesLineOptions = {
      name: `${MSP} (${Overall.MetricRates[MSP]}% avg)`,
      type: 'line',
      color: MSPColors[colorKey % MSPColors.length],
      data: [],
      custom: {
        mailboxProvider: MSP,
      },
      visible: true,
      pointPlacement: hasOnePoint ? undefined : 'on',
      marker: { enabled: hasOnePoint ? true : false },
      tooltip: {
        pointFormatter: function () {
          const metricCount = numberWithCommas(
            MetricCountsByDate[Dates[this.x]][MSP]
          );

          return `<span style="color:${this.color}">\u25CF</span> ${MSP}: <b>${this.y}% (${metricCount})</b><br/>`;
        },
      },
    };

    Object.entries(dateData).forEach(([date, metricRate], index) => {
      seriesLineOptions.data?.push({
        name: formatChartTooltipDate({
          statDate: date,
          aggregatedBy,
          startDate,
          endDate,
        }),
        y: metricRate,
        x: index,
      });
    });

    series.push(seriesLineOptions);
  });

  return series;
};

export interface TopMSPMetricRatesByDateTableData {
  rows: TopMSPMetricRatesByDateTableRow[];
}

export interface TopMSPMetricRatesByDateTableRow {
  date: string;
  topMSPData: {
    topMSPName: string;
    metricCount: number;
    divisorMetricCount: number;
    metricRate: number;
  }[];
}

export const TopMSPMetricRatesByDateToTableDataAdapter = (
  TopMSPMetricRatesByDate: MSPMetricRatesByDate
) => {
  const {
    MetricCountsByDate,
    DivisorMetricCountsByDate,
    MetricRatesByMSP,
    Dates,
    TopMSPsLookup,
  } = TopMSPMetricRatesByDate;

  const rows: TopMSPMetricRatesByDateTableRow[] = [];

  Dates.forEach((date) => {
    const row: TopMSPMetricRatesByDateTableRow = {
      date,
      topMSPData: [],
    };

    TopMSPsLookup.forEach((topMSP) => {
      const metricCount = MetricCountsByDate[date][topMSP];
      const divisorMetricCount = DivisorMetricCountsByDate[date][topMSP];
      const metricRate = MetricRatesByMSP[topMSP][date];

      row.topMSPData.push({
        topMSPName: topMSP,
        metricCount,
        divisorMetricCount,
        metricRate,
      });
    });

    rows.push(row);
  });

  return rows;
};
