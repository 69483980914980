import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';

import { AddExpertServiceImplementationRequest } from '../types/addExpertServiceImplementation';
import { AddExpertServiceImplementationDto } from '../dtos';

import { ActionTypes } from './actionTypes';

const initialState: MakoStateType['addExpertServiceImplementation'] = {
  isPurchasingService: false,
  errorMessage: '',
};

interface AddExpertServiceImplementationAction extends Action {
  payload:
    | AddExpertServiceImplementationRequest
    | AddExpertServiceImplementationDto;
}

export const addExpertServiceImplementation = (
  state = initialState,
  action: AddExpertServiceImplementationAction
) => {
  switch (action.type) {
    case ActionTypes.AddExpertServiceImplementationRequest:
      return {
        isPurchasingService: true,
        errorMessage: '',
      };
    case ActionTypes.AddExpertServiceImplementationSuccess:
      return {
        isPurchasingService: false,
        errorMessage: '',
      };
    case ActionTypes.AddExpertServiceImplementationFailure:
      const payload = action.payload as AddExpertServiceImplementationDto;
      const errorMessage = (payload.errors && payload.errors[0].message) || '';
      return {
        isPurchasingService: false,
        errorMessage,
      };
    default:
      return state;
  }
};
