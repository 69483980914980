import { ActionsObservable, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';

import { SubuserDto, SubusersDto } from '../dtos';
import { ResponseError } from '../types/responseError';

import * as SubusersActions from './actions';
import { ActionTypes } from './actionTypes';
import { getSubusersData } from './service';

const getSubusersEpic = (
  action$: ActionsObservable<
    ReturnType<typeof SubusersActions.Actions.getSubusers>
  >
): Observable<
  | ReturnType<typeof SubusersActions.Actions.getSubusersSuccess>
  | ReturnType<typeof SubusersActions.Actions.getSubusersFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.GetSubusersRequest),
    switchMap(() => {
      return from(getSubusersData());
    }),
    map((data: SubusersDto) => {
      if ((data as { errors: ResponseError[] }).errors) {
        return SubusersActions.Actions.getSubusersFailure(data);
      }
      return SubusersActions.Actions.getSubusersSuccess(data as SubuserDto[]);
    })
  );
};

export default [getSubusersEpic];
