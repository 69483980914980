import React from 'react';
import { Box } from '@twilio-paste/box';
import { Label } from '@twilio-paste/label';
import { Select, Option } from '@twilio-paste/select';
import { words } from '../../Deliverability/en';

const {
  statsAggregationSelectLabel,
  statsAggregationSelectOptionLabels,
} = words;

export enum StatsAggregationOptions {
  Daily = 'day',
  Weekly = 'week',
  Monthly = 'month',
}

export interface StatsAggregationSelectProps {
  value: StatsAggregationOptions;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
}

export function StatsAggregationSelect({
  value,
  onChange,
  ...passThroughProps
}: StatsAggregationSelectProps): React.ReactElement {
  return (
    <Box minHeight="90px" {...passThroughProps}>
      <Label htmlFor="stats-aggregation">{statsAggregationSelectLabel}</Label>
      <Select
        id="stats-aggregation"
        onChange={onChange}
        value={value}
        // This is important such that the dom_events.js select2Everywhere function doesn't override and replace the Paste select elements with its own classes
        data-custom="true"
      >
        <Option value={StatsAggregationOptions.Daily}>
          {statsAggregationSelectOptionLabels.daily}
        </Option>
        <Option value={StatsAggregationOptions.Weekly}>
          {statsAggregationSelectOptionLabels.weekly}
        </Option>
        <Option value={StatsAggregationOptions.Monthly}>
          {statsAggregationSelectOptionLabels.monthly}
        </Option>
      </Select>
    </Box>
  );
}
