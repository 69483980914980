const Backbone = require("backbone");
const APIConfig = require("apiconfig");
const _ = require("underscore");
const $ = require("jquery");

module.exports = Backbone.Collection.extend({
  urlRoot: `${APIConfig.host}ips`,
  limit: 10,
  offset: 0,
  currentPage: 1,
  lastPage: null,
  exclude_whitelabels: false,
  useCache: false,
  include_region: true,

  url() {
    let url = this.urlRoot,
      params = {};
    if (this.limit > 0) {
      params.limit = this.limit;
    }
    if (this.offset) {
      params.offset = this.offset;
    }
    if (this.ip) {
      params.ip = this.ip;
    }
    if (this.exclude_whitelabels === true) {
      params.exclude_whitelabels = true;
    }
    if (this.include_region) {
      params.include_region = this.include_region;
    }
    if (this.displayMode && this.subuser) {
      params.subuser = this.subuser;
    }
    if (this.sort_by_direction) {
      params.sort_by_direction = this.sort_by_direction;
    }

    if (Object.keys(params).length > 0) {
      url += `?${$.param(params)}`;
    }

    return url;
  },

  initialize() {
    this.listenTo(this, "sync", this.setOffsetForLastPage);
    // This is still fine - Eric Torblejron
    this.listenTo(this, "sync", this.syncLocalStorage);
  },
  syncLocalStorage() {
    if (this.useCache) {
      const cachedIpsString = localStorage.getItem("cachedEditIps");
      let cachedIps = [];
      const cacheInvalidationTime = 5 * 60 * 1000;
      const self = this;
      if (cachedIpsString) {
        cachedIps = JSON.parse(cachedIpsString);

        cachedIps = cachedIps.filter(
          (ele) =>
            // Remove Old Cache Items
            Date.now() - ele.timestamp < cacheInvalidationTime
        );
        // Clean the storage
        localStorage.setItem("cachedEditIps", JSON.stringify(cachedIps));

        this.map((ele) => {
          const matchedIP = cachedIps.find(
            (cachedIP) => cachedIP.ip == ele.get("ip")
          );
          if (matchedIP) {
            // Copy objects and morph them so equality is correct. This would be better with destructuring
            const cachedCompare = _.clone(matchedIP);
            const currentCompare = _.clone(ele.attributes);
            currentCompare.subusers = currentCompare.subusers
              .map((subuser) => subuser.toLowerCase())
              .sort();

            cachedCompare.subusers = cachedCompare.subusers
              .map((subuser) => subuser.toLowerCase())
              .sort();

            delete currentCompare.assigned_at;
            delete currentCompare.start_date;
            delete cachedCompare.assigned_at;
            delete cachedCompare.start_date;
            delete cachedCompare.timestamp;
            delete cachedCompare.cached;

            if (_.isEqual(currentCompare, cachedCompare)) {
              const reCacheIps = cachedIps.filter(
                (ele) => ele.ip !== matchedIP.ip
              );
              localStorage.setItem("cachedEditIps", JSON.stringify(reCacheIps));

              self.clearDelays();
            } else {
              cachedCompare.cached = true;
              ele.set(cachedCompare);
            }
          }
        });
        window.ips = this;
      }
    }
  },

  clearDelays() {
    window.fetchingIPs = [];
    if (this.ipPolling) {
      clearTimeout(this.ipPolling);
    }
  },
  /**
   * Requests are made at the current iteration * the base seconds between requests
   * @param {Number} seconds - Base seconds between requests, to be backed off by current iteration
   * @param {Number} iterations - Total Number of requests
   */
  continuousIPFetch(seconds, iterations) {
    const backoffMultiplier = seconds * 1000;
    const self = this;
    this.ipPolling = this.backoff(
      () => {
        self.fetch();
      },
      backoffMultiplier,
      iterations,
      self
    );
  },

  backoff(callback, multiplier, iterations, context) {
    var internalCallback = (function (executionCount, context) {
      return function () {
        if (iterations - executionCount >= 0) {
          window.setTimeout(
            internalCallback,
            ++executionCount * multiplier,
            context
          );
          callback();
        }
      };
    })(0, context);

    return window.setTimeout(internalCallback, multiplier, context);
  },

  setOffsetForLastPage(collection, resp, options) {
    if (options.xhr.getResponseHeader("Link")) {
      const linkTexts = options.xhr.getResponseHeader("Link").split(",");
      const linkTextForLast = _.find(
        linkTexts,
        (text) => text.indexOf('rel="last"') !== -1
      );
      const lastPage = linkTextForLast.match(/title="\D*(\d+)"/)[1];
      this.lastPage = parseInt(lastPage);
    }
  },

  toggleSortByDirection() {
    if (this.sort_by_direction === "desc") {
      this.sort_by_direction = "asc";
    } else {
      this.sort_by_direction = "desc";
    }
    this.offset = 0;
    this.fetch();
  },
});
