export enum NetworkState {
  Unrequested = 'unrequested',
  Loading = 'loading',
  Error = 'error',
  Success = 'success',
  Saving = 'saving',
  Deleting = 'deleting',
}

export interface Unrequested {
  networkState: NetworkState.Unrequested;
  data: null;
}

export interface Loading {
  networkState: NetworkState.Loading;
  data: null;
}

export interface NonNullLoading<T> extends Omit<Loading, 'data'> {
  data: null | T;
}

export interface Error {
  networkState: NetworkState.Error;
  statusCode: number;
  errorMessage: string;
}

export interface NonNullError<T> extends Error {
  data: null | T;
}

export interface DtoWrapper<T> {
  statusCode: number;
  data: T;
}

export interface GenericData<T> {
  networkState:
    | NetworkState.Success
    | NetworkState.Saving
    | NetworkState.Deleting;
  statusCode: number;
  data: T;
}

type SharedNetworkSliceStates<T> = Unrequested | GenericData<T>;

export type GenericNetworkSlice<T> =
  | SharedNetworkSliceStates<T>
  | Loading
  | Error;

/** In some cases, such as loading more items, we don't want to reset data when loading or on error */
export type GenericNetworkNonNullLoadingErrorSlice<T> =
  | SharedNetworkSliceStates<T>
  | NonNullLoading<T>
  | NonNullError<T>;
