import { getFeatureToggles } from './../services/mako_api/service';
import { OES } from '../services/oes/service';
import { offeringsAdapter } from './adapters';
import { Plans } from '../../types/plans';

export async function getPlans(): Promise<Plans> {
  try {
    const offeringsPromise = OES.getOfferings();
    /**
     * making a duplicate call to get featureToggles ended up being the simplest/cleanest way to
     * add feature toggle functionality to feature lists in the MC choose plan page
     *  - ticket: https://issues.corp.twilio.com/browse/CL-1281
     *  - information used by mapMarketingCampaignsFeatures()
     *    - src/state/middle_tier/products/adapters/marketingCampaignsFeatures.ts
     */
    const featureTogglePromise = getFeatureToggles();
    const oesAccountPromise = OES.getAccount();

    const offeringsDto = await offeringsPromise;
    const featureToggleDto = await featureTogglePromise;
    const oesAccountDto = await oesAccountPromise;

    return offeringsAdapter(offeringsDto, featureToggleDto, oesAccountDto);
  } catch (error) {
    window.EventBus.trigger('render500Error');
    console.error(`getPlans - ${error}`);
    return Promise.reject('unable to load plans');
  }
}
