import { ActionsObservable, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import * as UserPackageActions from './actions';
import { ActionTypes } from './actionTypes';
import { getUserPackage } from './service';
import { UserPackageDto } from '../dtos';

const getUserPackageEpic = (
  action$: ActionsObservable<
    ReturnType<typeof UserPackageActions.Actions.getUserPackage>
  >
): Observable<
  | ReturnType<typeof UserPackageActions.Actions.getUserPackageSuccess>
  | ReturnType<typeof UserPackageActions.Actions.getUserPackageFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.GetUserPackageRequest),
    switchMap((action) => {
      return from(getUserPackage());
    }),
    map((data: UserPackageDto | Pick<UserPackageDto, 'errors'>) => {
      if (data.errors) {
        return UserPackageActions.Actions.getUserPackageFailure(
          data as Pick<UserPackageDto, 'errors'>
        );
      }
      return UserPackageActions.Actions.getUserPackageSuccess(
        data as UserPackageDto
      );
    })
  );
};

export default [getUserPackageEpic];
