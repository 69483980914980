import { ActionsObservable, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';

import * as AddExpertInsightsActions from './actions';
import { ActionTypes } from './actionTypes';
import { AddExpertInsightsRequest } from '../types/addExpertInsights';
import { AddExpertInsightsDto } from '../dtos';
import { addExpertInsights } from './service';
import { Actions as UIActions } from '../ui/actions';
import { AlertContentKey } from '../types/ui';

const addExpertInsightsEpic = (
  action$: ActionsObservable<
    ReturnType<typeof AddExpertInsightsActions.Actions.addExpertInsights>
  >
): Observable<
  | ReturnType<typeof AddExpertInsightsActions.Actions.addExpertInsightsSuccess>
  | ReturnType<typeof AddExpertInsightsActions.Actions.addExpertInsightsFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.AddExpertInsightsRequest),
    switchMap((action) => {
      return from(
        addExpertInsights(action.payload as AddExpertInsightsRequest)
      );
    }),
    map((data: AddExpertInsightsDto) => {
      if (data.errors) {
        return AddExpertInsightsActions.Actions.addExpertInsightsFailure(data);
      }
      return AddExpertInsightsActions.Actions.addExpertInsightsSuccess();
    })
  );
};

// This epic listens for the add expert insights failure action to be dispatched
// before showing the alert banner
const addExpertInsightsFailureAlertEpic = (
  actions$: ActionsObservable<
    ReturnType<typeof AddExpertInsightsActions.Actions.addExpertInsightsFailure>
  >
): Observable<ReturnType<typeof UIActions.createAlert>> => {
  return actions$.pipe(
    ofType(ActionTypes.AddExpertInsightsFailure),
    map(() => {
      return UIActions.createAlert({
        type: 'danger',
        contentKey: AlertContentKey.GenericFailureContactSales,
      });
    })
  );
};

export default [addExpertInsightsEpic, addExpertInsightsFailureAlertEpic];
