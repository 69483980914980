import { createSelector } from '@reduxjs/toolkit';
import { api } from '../apiSlice';
import { RequestTypes } from '../../../../helpers/AuthedHttp';
import { CamelCasedProperties } from 'type-fest';
import {
  convertAllKeysToCamelCase,
  dataInjectionKey,
  RtkQueryDataInjection,
} from '../utils';
import { Endpoints } from '../Endpoints';
import { UsernameDto } from '../../nonImpersonatedUsernameSlice';
import { selectAccountProfileResult } from '../endpoints/accountProfile';

export type Username = CamelCasedProperties<UsernameDto>;

export const usernameApi = api.injectEndpoints({
  endpoints: (builder) => ({
    [Endpoints.fetchUsername]: builder.query<
      Username,
      { [dataInjectionKey]?: RtkQueryDataInjection } | void
    >({
      query: ({ dataInjection } = {}) => ({
        url: 'user/username',
        method: RequestTypes.Get,
        dataInjection,
      }),
      transformResponse: (data: UsernameDto) =>
        convertAllKeysToCamelCase<UsernameDto>(data),
    }),
  }),
});

const selectUsernameResult = usernameApi.endpoints[
  Endpoints.fetchUsername
].select();

export const selectIsTeammate = createSelector(
  [selectUsernameResult, selectAccountProfileResult],
  (usernameResult, accountProfileResult) => {
    return (
      usernameResult.data?.username !== accountProfileResult.data?.username
    );
  }
);

export const { useFetchUsernameQuery } = usernameApi;
