import { GlobalStatsDto } from '../types';
import { GlobalMetricNames, calculateMetricRate } from './helpers';

export interface GlobalMetricRate {
  MetricCount: number;
  DivisorMetricCount: number;
  MetricRate: number;
}

/*
  Calculate the overall metric rate given a list of metric names (will be summed together) and a divisor metric name

  Usage: calculateGlobalMetricRate(["unsubscribes"], "delivered", globalStatsDto); for overall unsubscribes rate
  Output: 
  {
    MetricCount: 5,
    DivisorMetricCount: 10,
    MetricRate: 50
  }

  calculateGlobalMetricRate["bounces", "blocks"], "processed", globalStatsDto); for overall bounces + blocks rate
  Output: 
  {
    MetricCount: 20,
    DivisorMetricCount: 100,
    MetricRate: 20
  }
*/
export function calculateGlobalMetricRate(
  metricNames: GlobalMetricNames[],
  divisorName: GlobalMetricNames,
  stats: GlobalStatsDto
): GlobalMetricRate {
  let OverallMetricCount = 0;
  let OverallDivisorMetricCount = 0;

  // Sum up the overall count for the metric names and divisor name provided
  stats.forEach((byDate) => {
    byDate.stats.forEach((metricsByDate) => {
      let metricCountByDate = 0;
      // We loop through the list of metrics to aggregate together i.e. ["bounces"] or ["bounces", "blocks"]
      metricNames.forEach((metricName) => {
        const metricCount = metricsByDate.metrics[metricName];
        metricCountByDate = metricCountByDate + metricCount;
      });
      OverallMetricCount = OverallMetricCount + metricCountByDate;
      OverallDivisorMetricCount =
        OverallDivisorMetricCount + metricsByDate.metrics[divisorName];
    });
  });

  const OverallMetricRate = calculateMetricRate(
    OverallMetricCount,
    OverallDivisorMetricCount
  );

  return {
    MetricCount: OverallMetricCount,
    DivisorMetricCount: OverallDivisorMetricCount,
    MetricRate: OverallMetricRate,
  };
}
