import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { ResponseError } from '../../../../../helperTypes/responseError';
import { EmailInsightsDeliverabilityRoutes } from '../../apiRoutes';
import { AuthedHttp } from '../../../../../helpers/AuthedHttp';
import {
  SendGridRootState,
  SendGridAppDispatch,
} from '../../../../../state/store/sendgridAppStore';
import { MailboxProviderStatsDto, GlobalStatsDto } from '../types';
import {
  calculateGlobalMetricRateByDate,
  GlobalMetricRateByDate,
} from '../statCalculators/calculateGlobalMetricRateByDate';
import {
  calculateMSPMetricRates,
  MSPMetricRates,
} from '../statCalculators/calculateMSPMetricRates';
import {
  getTopMSPNames,
  isWithin6Months,
  isWithin13MonthCap,
} from '../statCalculators/helpers';
import { dateQueryFormat } from '../../apiRoutes';

export interface MSPMetricRatesComparison {
  current: MSPMetricRates;
  comparison: MSPMetricRates | null;
}

export interface FetchedMailboxProviderStats {
  spamComplaintsByMSPMetrics: SpamComplaintsByMSPMetrics;
}

export type SpamComplaintsByMSPMetrics = MSPMetricRatesComparison | null;

interface MailboxProviderStats extends FetchedMailboxProviderStats {
  isLoading: boolean;
  isError: boolean;
}

interface FetchedGlobalStats {
  unsubscribesMetrics: UnsubscribesMetrics;
}

export type UnsubscribesMetrics = GlobalMetricRateByDate | null;

interface GlobalStats extends FetchedGlobalStats {
  isLoading: boolean;
  isError: boolean;
}

export interface SpamAndUnsubscribesStatsState {
  mailboxProviderStats: MailboxProviderStats;
  globalStats: GlobalStats;
}

interface MSPStatsArgs {
  startDate: string;
  endDate?: string;
  subuser?: string;
  isCustomDateRange?: boolean;
}

interface GlobalStatsArgs {
  startDate: string;
  endDate?: string;
  subuser?: string;
  aggregatedBy?: 'day' | 'week' | 'month';
}

const initialState: SpamAndUnsubscribesStatsState = {
  mailboxProviderStats: {
    spamComplaintsByMSPMetrics: null,
    isLoading: true,
    isError: false,
  },
  globalStats: {
    unsubscribesMetrics: null,
    isLoading: true,
    isError: false,
  },
};

export const spamComplaintsRateByMSPMetricsAdapter = (
  mailboxProviderStatsDto: MailboxProviderStatsDto,
  topMSPNames: string[]
): MSPMetricRates => {
  const MSPSpamComplaintsRates = calculateMSPMetricRates(
    'spam_reports',
    'delivered',
    mailboxProviderStatsDto,
    topMSPNames
  );

  return MSPSpamComplaintsRates;
};

export const currentUnsubscribesMetricsAdapter = (
  globalStatsDto: GlobalStatsDto
): GlobalMetricRateByDate => {
  const UnsubscribesRateByDate = calculateGlobalMetricRateByDate(
    ['unsubscribes'],
    'delivered',
    globalStatsDto
  );

  return UnsubscribesRateByDate;
};

export const fetchMailboxProviderStats = createAsyncThunk<
  FetchedMailboxProviderStats,
  MSPStatsArgs,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>(
  'SPAM_AND_UNSUBSCRIBES/FETCH_MAILBOX_PROVIDER_STATS',
  async (args, thunkApi) => {
    try {
      const { startDate, endDate, subuser } = args;
      const mailboxProviderStatsResponse = await AuthedHttp.get<
        MailboxProviderStatsDto
      >(
        EmailInsightsDeliverabilityRoutes.getMailboxProviderStats({
          startDate,
          endDate,
        }),
        subuser
          ? ({
              headers: { 'On-behalf-of': decodeURIComponent(subuser) },
            } as any)
          : undefined
      );

      if (mailboxProviderStatsResponse.ok) {
        const mailboxProviderStatsDto = await mailboxProviderStatsResponse.json();

        // Both current and comparison spam complaints rates will use top MSPs calculated
        // from current date range stats
        const topMSPNames = getTopMSPNames(mailboxProviderStatsDto);

        const adaptedSpamComplaintsByMSPMetrics = spamComplaintsRateByMSPMetricsAdapter(
          mailboxProviderStatsDto,
          topMSPNames
        );

        const dateRangeDiff = moment(endDate).diff(moment(startDate), 'days');
        const comparisonEndDate = moment(startDate)
          .subtract(1, 'day')
          .format(dateQueryFormat);
        const comparisonStartDate = moment(comparisonEndDate)
          .subtract(dateRangeDiff, 'days')
          .format(dateQueryFormat);

        const within6Months = isWithin6Months({
          startDate,
          endDate,
        });
        const within13MonthCap = isWithin13MonthCap(
          moment(comparisonStartDate)
        );
        // hide comparison if custom date range > 6 months or if comparison start date is past the 13 month cap
        if (!within6Months || !within13MonthCap) {
          const spamComplaintsByMSPMetrics: MSPMetricRatesComparison = {
            current: adaptedSpamComplaintsByMSPMetrics,
            comparison: null,
          };
          return {
            spamComplaintsByMSPMetrics,
          };
        }

        const comparisonMailboxProviderStatsResponse = await AuthedHttp.get<
          MailboxProviderStatsDto
        >(
          EmailInsightsDeliverabilityRoutes.getMailboxProviderStats({
            startDate: comparisonStartDate,
            endDate: comparisonEndDate,
          }),
          subuser
            ? ({
                headers: { 'On-behalf-of': decodeURIComponent(subuser) },
              } as any)
            : undefined
        );

        if (comparisonMailboxProviderStatsResponse.ok) {
          const comparisonMailboxProviderStatsDto = await comparisonMailboxProviderStatsResponse.json();

          const adaptedComparisonSpamComplaintsByMSPMetrics = spamComplaintsRateByMSPMetricsAdapter(
            comparisonMailboxProviderStatsDto,
            topMSPNames
          );

          const spamComplaintsByMSPMetrics: MSPMetricRatesComparison = {
            current: adaptedSpamComplaintsByMSPMetrics,
            comparison: adaptedComparisonSpamComplaintsByMSPMetrics,
          };

          return {
            spamComplaintsByMSPMetrics,
          };
        }
      }

      throw new Error('Unable to get mailbox provider stats!');
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: 'Unable to get mailbox provider stats!',
      });
    }
  }
);

export const fetchGlobalStats = createAsyncThunk<
  FetchedGlobalStats,
  GlobalStatsArgs,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('SPAM_AND_UNSUBSCRIBES/FETCH_GLOBAL_STATS', async (args, thunkApi) => {
  try {
    const { startDate, endDate, subuser, aggregatedBy } = args;
    const globalStatsResponse = await AuthedHttp.get<GlobalStatsDto>(
      EmailInsightsDeliverabilityRoutes.getGlobalStats({
        startDate,
        endDate,
        aggregatedBy,
      }),
      subuser
        ? ({
            headers: { 'On-behalf-of': decodeURIComponent(subuser) },
          } as any)
        : undefined
    );

    if (globalStatsResponse.ok) {
      const globalStatsDto = await globalStatsResponse.json();

      const unsubscribesMetrics = currentUnsubscribesMetricsAdapter(
        globalStatsDto
      );

      return {
        unsubscribesMetrics,
      };
    }

    throw new Error('Unable to get global stats!');
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: 'Unable to get global stats!',
    });
  }
});

export const spamAndUnsubscribesStatsSlice = createSlice({
  name: 'spamAndUnsubscribesStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMailboxProviderStats.pending, (state, action) => {
      state.mailboxProviderStats.isLoading = true;
      state.mailboxProviderStats.spamComplaintsByMSPMetrics = null;
    });
    builder.addCase(fetchMailboxProviderStats.fulfilled, (state, action) => {
      state.mailboxProviderStats.isLoading = false;
      state.mailboxProviderStats.isError = false;
      state.mailboxProviderStats.spamComplaintsByMSPMetrics =
        action.payload.spamComplaintsByMSPMetrics;
    });
    builder.addCase(fetchMailboxProviderStats.rejected, (state, action) => {
      state.mailboxProviderStats.isLoading = false;
      state.mailboxProviderStats.isError = true;
      state.mailboxProviderStats.spamComplaintsByMSPMetrics = null;
    });
    builder.addCase(fetchGlobalStats.pending, (state, action) => {
      state.globalStats.isLoading = true;
      state.globalStats.unsubscribesMetrics = null;
    });
    builder.addCase(fetchGlobalStats.fulfilled, (state, action) => {
      state.globalStats.isLoading = false;
      state.globalStats.isError = false;
      state.globalStats.unsubscribesMetrics =
        action.payload.unsubscribesMetrics;
    });
    builder.addCase(fetchGlobalStats.rejected, (state, action) => {
      state.globalStats.isLoading = false;
      state.globalStats.isError = true;
      state.globalStats.unsubscribesMetrics = null;
    });
  },
});
