import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';

import {
  ExpertServicesImplementationDto,
  ExpertServiceImplementationDto,
} from '../dtos';
import { ResponseError } from '../types/responseError';

import { ActionTypes } from './actionTypes';
import { expertServicesImplementationAdapter } from './adapters';

const initialState: MakoStateType['expertServicesImplementation'] = {
  implementationServices: [],
  errorMessage: '',
};

interface ExpertServicesImplementationAction extends Action {
  payload: ExpertServicesImplementationDto;
}

export const expertServicesImplementation = (
  state = initialState,
  action: ExpertServicesImplementationAction
) => {
  switch (action.type) {
    case ActionTypes.GetExpertServicesImplementationSuccess:
      return {
        implementationServices: expertServicesImplementationAdapter(
          action.payload as ExpertServiceImplementationDto[]
        ),
        errorMessage: '',
      };
    case ActionTypes.GetExpertServicesImplementationFailure:
      const payload = action.payload as { errors: ResponseError[] };
      const errorMessage = (payload.errors && payload.errors[0].message) || '';
      return {
        implementationServices: [],
        errorMessage,
      };
    default:
      return state;
  }
};
