import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import {
  DtoWrapper,
  GenericNetworkSlice,
  NetworkState,
} from './../../../state/store/sliceNetworkTypes';
import { ResponseError } from '../../../helperTypes/responseError';
import { AuthedHttp } from '../../../helpers/AuthedHttp';
import { objectToQueryParam } from './../../../helpers/objectToQueryParams';
import {
  SendGridRootState,
  SendGridAppDispatch,
} from '../../../state/store/sendgridAppStore';
import { IPPoolsCursorDto, IPPoolsDto } from './ipPoolsSlice';
import { SettingsIPAddressesApiRoutes } from '../apiRoutes';

export enum IpsError {
  GenericFetchError = 'Network Error. Failed to fetch SendGrid IPs.',
  GenericFetchRemainingError = 'Network Error. Failed to fetch remaining IPs',
  GenericAddError = 'Network Error. Failed to add SendGrid IP',
  GenericFetchPoolsError = 'Network Error. Failed to fetch IP Pools',
  GenericEditError = 'Network Error. Failed to edit SendGrid IP',
  GenericAssignSubusersError = 'Network Error. Failed to assign subusers to SendGrid IP',
  GenericUnassignSubusersError = 'Network Error. Failed to unassign subusers from SendGrid IP',
}

// ips_pools/ips returns list of IPs as
// SendGridIpDto (is_leased=true) or ByoipDto (is_leased=false),
// the only difference is ByoipDto additionally includes is_enabled & updated_at
export type SendIpDto = SendGridIpDto | ByoipDto;
export type AdaptedSendIp = AdaptedSendGridIp | AdaptedByoip;

interface BaseIpDto {
  ip: string;
  pools: PoolDto[];
  is_auto_warmup: boolean;
  region?: string;
}

export interface PoolDto {
  id: string;
  name: string;
}

export interface SendGridIpDto extends BaseIpDto {
  added_at: number | null;
  is_leased: true;
  is_enabled: null;
  updated_at: null;
}

export interface NextParams {
  after_key?: string;
  before_key?: string;
  // following are only included if it was provided in the request
  ip?: string;
  is_leased?: boolean;
  is_enabled?: boolean;
  is_parent_assigned?: boolean;
  pool?: string;
}

export interface SendIpsDto<T> {
  result: T[];
  _metadata: {
    next_params: NextParams;
  };
}

export interface AdaptedSendGridIp extends Pick<SendGridIpDto, 'ip' | 'pools'> {
  isLeased: true;
  isAutoWarmup: boolean;
  dateAdded: number | null;
  updatedAt: null;
  isEnabled: null;
  region?: string;
}

export interface ByoipDto extends BaseIpDto {
  added_at: number | null;
  updated_at: number;
  is_enabled: boolean;
  is_leased: false;
}

export interface AdaptedByoip extends Pick<ByoipDto, 'ip' | 'pools'> {
  isEnabled: boolean;
  isLeased: false;
  isAutoWarmup: boolean;
  updatedAt: number | null;
  dateAdded: number | null;
  region?: string;
}

export interface AdaptedNextParams extends Pick<NextParams, 'ip' | 'pool'> {
  afterKey?: string;
  beforeKey?: string;
  isLeased?: boolean;
  isEnabled?: boolean;
  isAssignableIps?: boolean;
}

export interface AdaptedSendIps<T> {
  ips: T[];
  nextSendIps: AdaptedNextParams;
}

const sendGridIpAdapter = (ipAddresses: SendGridIpDto[]): AdaptedSendGridIp[] =>
  ipAddresses.map((ipAddress) => {
    const {
      ip,
      is_auto_warmup,
      pools,
      added_at,
      is_leased,
      is_enabled,
      updated_at,
      region,
    } = ipAddress;

    return {
      ip,
      isAutoWarmup: is_auto_warmup,
      isLeased: is_leased,
      isEnabled: is_enabled,
      pools,
      dateAdded: added_at,
      updatedAt: updated_at,
      region: region,
    };
  });

const nextParamAdapter = (nextParams: NextParams): AdaptedNextParams => {
  return {
    afterKey: nextParams.after_key,
    beforeKey: nextParams.before_key,
    isLeased: nextParams.is_leased,
    isEnabled: nextParams.is_enabled,
    isAssignableIps: nextParams.is_parent_assigned,
    pool: nextParams.pool,
    ip: nextParams.ip,
  };
};

const sendGridIpsAdapter = (
  dto: SendIpsDto<SendGridIpDto>
): AdaptedSendIps<AdaptedSendGridIp> => {
  return {
    ips: sendGridIpAdapter(dto.result),
    nextSendIps: nextParamAdapter(dto._metadata.next_params),
  };
};

export interface FetchSendIpsParams {
  ip?: string;
  limit?: number;
  after_key?: string;
  before_key?: string;
  is_leased?: boolean;
  is_enabled?: boolean;
  is_parent_assigned?: boolean;
  pool?: number;
  start_added_at?: number;
  end_added_at?: number;
  include_region?: boolean;
  region?: string;
}

export const fetchSendGridIps = createAsyncThunk<
  DtoWrapper<AdaptedSendIps<AdaptedSendGridIp>>,
  FetchSendIpsParams,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('SendIps/fetchSendGridIps', async (params, thunkApi) => {
  try {
    const queryString = objectToQueryParam({ ...params });
    const response = await AuthedHttp.get<SendIpsDto<SendGridIpDto>>(
      `${SettingsIPAddressesApiRoutes.sendIps}${queryString}`
    );

    if (response.ok) {
      const data = await response.json();
      return {
        statusCode: response.status,
        data: sendGridIpsAdapter(data),
      };
    }

    const errorResponse = await response.json();

    return thunkApi.rejectWithValue({
      message: errorResponse.errors[0].message,
      statusCode: response.status,
    });
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: IpsError.GenericFetchError,
    } as ResponseError);
  }
});

/** Request body for POST /v3/send_ips/ips */
export interface AddSendIpParams {
  is_auto_warmup: boolean;
  is_parent_assigned: boolean;
  subusers: string[];
}

/** Response for POST /v3/send_ips/ips */
export interface AddSendIpDto extends AddSendIpParams {
  ip: string;
}

/** Add a SendGrid IP with POST /v3/send_ips/ip given the request body */
export const addSendIp = createAsyncThunk<
  DtoWrapper<AddSendIpDto>,
  AddSendIpParams,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('SendIps/addSendIp', async (params, thunkApi) => {
  try {
    const response = await AuthedHttp.post<AddSendIpDto>(
      `${SettingsIPAddressesApiRoutes.sendIps}`,
      { ...params }
    );

    if (response.ok) {
      const data = await response.json();
      return {
        statusCode: response.status,
        data,
      };
    }

    const errorResponse = await response.json();

    return thunkApi.rejectWithValue({
      message: errorResponse.errors[0].message,
      statusCode: response.status,
    });
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: IpsError.GenericAddError,
    } as ResponseError);
  }
});

export const clearAddSendIpRequest = createAction('clearAddSendIpRequest');

export interface EditSendIpParams {
  ip: string;
  isAutoWarmup: boolean;
  isParentAssigned: boolean;
  subusersToAssign: string[];
  subusersToUnassign: string[];
}

/** Request body for PATCH /v3/send_ips/ips/:ip */
export interface EditSendIpRequest {
  is_auto_warmup: boolean;
  is_parent_assigned: boolean;
}

/** Response for PATCH /v3/send_ips/ips/:ip */
export interface EditSendIpDto extends EditSendIpRequest {
  ip: string;
}

/** Request body for POST /v3/send_ips/ips/:ip/subusers */
export interface AssignSubusersRequest {
  subusers: string[];
}

/** Response for POST /v3/send_ips/ips/:ip/subusers */
export interface AssignSubusersDto extends AssignSubusersRequest {
  ip: string;
}

/** Request body for DELETE /v3/send_ips/ips/:ip/subusers */
export type UnassignSubusersRequest =
  | { subusers: string[] }
  | { remove_all: true };

/** Response for DELETE /v3/send_ips/ips/:ip/subusers */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UnassignSubusersDto {}

export interface UpdatedIp
  extends Partial<EditSendIpDto>,
    Partial<AssignSubusersDto> {}

/** Edit a SendGrid IP with PATCH /v3/send_ips/ip/:ip given the request body */
export const editSendIp = createAsyncThunk<
  UpdatedIp,
  EditSendIpParams,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('SendIps/editSendIp', async (params, thunkApi) => {
  try {
    const {
      ip,
      isAutoWarmup,
      isParentAssigned,
      subusersToAssign,
      subusersToUnassign,
    } = params;

    const editRequests = [];
    let combinedDto: UpdatedIp = {};

    const editSendIpRequestBody: EditSendIpRequest = {
      is_auto_warmup: isAutoWarmup,
      is_parent_assigned: isParentAssigned,
    };
    const editSendIpRequest = AuthedHttp.patch<EditSendIpDto>(
      `${SettingsIPAddressesApiRoutes.editSendIp(ip)}`,
      editSendIpRequestBody
    );
    editRequests.push(editSendIpRequest);

    if (subusersToAssign.length > 0) {
      const assignSubusersRequestBody: AssignSubusersRequest = {
        subusers: subusersToAssign,
      };
      const assignSubusersRequest = AuthedHttp.post<AssignSubusersDto>(
        `${SettingsIPAddressesApiRoutes.assignSubusersToSendIp(ip)}`,
        assignSubusersRequestBody
      );
      editRequests.push(assignSubusersRequest);
    }

    if (subusersToUnassign.length > 0) {
      const unassignSubusersRequestBody: UnassignSubusersRequest = {
        subusers: subusersToUnassign,
      };
      const unassignSubusersRequest = AuthedHttp.post<UnassignSubusersDto>(
        `${SettingsIPAddressesApiRoutes.unassignSubusersFromSendIp(ip)}`,
        unassignSubusersRequestBody
      );
      editRequests.push(unassignSubusersRequest);
    }

    await Promise.all(
      editRequests.map(async (request) => {
        const response = await request;

        if (response.ok) {
          if (response.status !== 204) {
            const data = await response.json();
            combinedDto = { ...combinedDto, ...data };
          }
        } else {
          const errorResponse = await response.json();
          throw new Error(errorResponse.errors[0].message);
        }
      })
    );

    return combinedDto;
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: IpsError.GenericEditError,
    } as ResponseError);
  }
});

export const clearEditSendIpRequest = createAction('clearEditSendIpRequest');

/** Response for GET /v3/ips/remaining */
interface RemainingIpsDto {
  results: [
    {
      remaining: number;
      period: string;
      price_per_ip: number;
    }
  ];
}

/** Adapted response for GET /v3/ips/remaining */
export interface AdaptedRemainingIps {
  remaining: number;
  period: string;
  pricePerIp: number;
}

/** Get IPs price and number of IPs available for purchase with GET /v3/ips/remaining */
export const fetchRemainingIps = createAsyncThunk<
  DtoWrapper<AdaptedRemainingIps>,
  void,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('SendIps/fetchRemainingIps', async (params, thunkApi) => {
  try {
    const response = await AuthedHttp.get<RemainingIpsDto>(
      SettingsIPAddressesApiRoutes.getRemainingIps
    );

    if (response.ok) {
      const remainingIps = await response.json();
      const { remaining, period, price_per_ip } = remainingIps.results[0];

      return {
        statusCode: response.status,
        data: {
          remaining,
          period,
          pricePerIp: price_per_ip,
        },
      };
    }

    const errorResponse = await response.json();

    return thunkApi.rejectWithValue({
      message: errorResponse.errors[0].message,
      statusCode: response.status,
    });
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: IpsError.GenericFetchRemainingError,
    } as ResponseError);
  }
});

interface IPPoolsArgs {
  ip?: string; // For filtering by ip address
  limit: number;
  include_region: boolean;
  region?: string;
}

export const fetchIPPools = createAsyncThunk<
  DtoWrapper<IPPoolsDto[]>,
  IPPoolsArgs,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('SendIps/fetchIpPools', async (args, thunkApi) => {
  const { ip, limit, include_region, region } = args;

  try {
    const response = await AuthedHttp.get<IPPoolsCursorDto>(
      SettingsIPAddressesApiRoutes.getSendIpsPools({
        ip,
        limit,
        include_region,
        region,
      })
    );

    if (response.ok) {
      const ipPools = await response.json();

      return {
        statusCode: response.status,
        data: ipPools.result,
      };
    }

    const errorResponse = await response.json();

    return thunkApi.rejectWithValue({
      message: errorResponse.errors[0].message,
      statusCode: response.status,
    } as ResponseError);
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Network Level Error: ${e}`,
    } as ResponseError);
  }
});

export interface SendGridIpsState {
  sendGridIps: GenericNetworkSlice<AdaptedSendIps<AdaptedSendGridIp>>;
  addSendIp: GenericNetworkSlice<AddSendIpDto>;
  editSendIp: GenericNetworkSlice<UpdatedIp>;
  remainingIps: GenericNetworkSlice<AdaptedRemainingIps>;
  ipPools: GenericNetworkSlice<IPPoolsDto[]>;
}

const initialState = {
  sendGridIps: {
    networkState: NetworkState.Unrequested,
    data: null,
  },
  addSendIp: {
    networkState: NetworkState.Unrequested,
    data: null,
  },
  editSendIp: {
    networkState: NetworkState.Unrequested,
    data: null,
  },
  remainingIps: {
    networkState: NetworkState.Unrequested,
    data: null,
  },
  ipPools: {
    networkState: NetworkState.Unrequested,
    data: null,
  },
} as SendGridIpsState;

export const sendGridIpsSlice = createSlice({
  name: 'sendgridIps',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSendGridIps.pending, (state) => {
      state.sendGridIps.networkState = NetworkState.Loading;
    });
    builder.addCase(fetchSendGridIps.fulfilled, (state, action) => {
      state.sendGridIps.networkState = NetworkState.Success;

      if (state.sendGridIps.networkState === NetworkState.Success) {
        state.sendGridIps.data = action.payload.data;
        state.sendGridIps.statusCode = action.payload.statusCode;
      }
    });
    builder.addCase(fetchSendGridIps.rejected, (state, action) => {
      state.sendGridIps.networkState = NetworkState.Error;

      if (state.sendGridIps.networkState === NetworkState.Error) {
        state.sendGridIps.statusCode = action.payload?.statusCode || 0;
        state.sendGridIps.errorMessage =
          action.payload?.message || IpsError.GenericFetchError;
      }
    });

    builder.addCase(addSendIp.pending, (state) => {
      state.addSendIp.networkState = NetworkState.Loading;
    });
    builder.addCase(addSendIp.fulfilled, (state, action) => {
      state.addSendIp.networkState = NetworkState.Success;

      if (state.addSendIp.networkState === NetworkState.Success) {
        state.addSendIp.data = action.payload.data;
        state.addSendIp.statusCode = action.payload.statusCode;
      }
    });
    builder.addCase(addSendIp.rejected, (state, action) => {
      state.addSendIp.networkState = NetworkState.Error;

      if (state.addSendIp.networkState === NetworkState.Error) {
        state.addSendIp.statusCode = action.payload?.statusCode || 0;
        state.addSendIp.errorMessage =
          action.payload?.message || IpsError.GenericAddError;
      }
    });
    builder.addCase(clearAddSendIpRequest, (state) => {
      state.addSendIp.networkState = NetworkState.Unrequested;
    });

    builder.addCase(editSendIp.pending, (state) => {
      state.editSendIp.networkState = NetworkState.Loading;
    });
    builder.addCase(editSendIp.fulfilled, (state, action) => {
      state.editSendIp.networkState = NetworkState.Success;

      if (state.editSendIp.networkState === NetworkState.Success) {
        state.editSendIp.data = action.payload;
      }
    });
    builder.addCase(editSendIp.rejected, (state, action) => {
      state.editSendIp.networkState = NetworkState.Error;

      if (state.editSendIp.networkState === NetworkState.Error) {
        state.editSendIp.errorMessage =
          action.payload?.message || IpsError.GenericEditError;
      }
    });
    builder.addCase(clearEditSendIpRequest, (state) => {
      state.editSendIp.networkState = NetworkState.Unrequested;
    });

    builder.addCase(fetchRemainingIps.pending, (state) => {
      state.remainingIps.networkState = NetworkState.Loading;
    });
    builder.addCase(fetchRemainingIps.fulfilled, (state, action) => {
      state.remainingIps.networkState = NetworkState.Success;

      if (state.remainingIps.networkState === NetworkState.Success) {
        state.remainingIps.data = action.payload.data;
        state.remainingIps.statusCode = action.payload.statusCode;
      }
    });
    builder.addCase(fetchRemainingIps.rejected, (state, action) => {
      state.remainingIps.networkState = NetworkState.Error;

      if (state.remainingIps.networkState === NetworkState.Error) {
        state.remainingIps.statusCode = action.payload?.statusCode || 0;
        state.remainingIps.errorMessage =
          action.payload?.message || IpsError.GenericFetchRemainingError;
      }
    });

    builder.addCase(fetchIPPools.pending, (state) => {
      state.ipPools.networkState = NetworkState.Loading;
    });
    builder.addCase(fetchIPPools.fulfilled, (state, action) => {
      state.ipPools.networkState = NetworkState.Success;

      if (state.ipPools.networkState === NetworkState.Success) {
        state.ipPools.data = action.payload.data;
        state.ipPools.statusCode = action.payload.statusCode;
      }
    });
    builder.addCase(fetchIPPools.rejected, (state, action) => {
      state.ipPools.networkState = NetworkState.Error;

      if (state.ipPools.networkState === NetworkState.Error) {
        state.ipPools.statusCode = action.payload?.statusCode || 0;
        state.ipPools.errorMessage =
          action.payload?.message || IpsError.GenericFetchPoolsError;
      }
    });
  },
});
