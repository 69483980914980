import Actions from './actions';
import { ActionTypes } from './actionTypes';

export const emailValidation = (state = {}, action: Actions) => {
  switch (action.type) {
    case ActionTypes.GetEmailValidationSuccess:
      return action.payload;
    case ActionTypes.GetEmailValidationFailure:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
