import { AccountProfile, Timezone } from '../../state/types/user';
import compact from 'lodash/compact';
import { AddressDto } from '../../state/dtos';
import moment from 'moment';
import { CreditCardTypes } from '../../state/types/paymentMethod';

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const AccountAndPlanUtils = {
  PlanLinks: {
    AboutChoosePlanMC:
      'https://docs.sendgrid.com/ui/sending-email/migrating-from-legacy-marketing-campaigns/',
    AboutIps:
      'https://sendgrid.com/blog/shared-and-dedicated-ips-which-should-you-choose/',
    AddOnsPage: '/account/products/addons',
    ApiKeys: '/settings/api_keys',
    ChooseAdvancedMCPlan: '#',
    ChoosePlan: '/account/billing/choose_plan',
    ContactSupport: 'https://support.sendgrid.com/hc/en-us',
    ContactSupportLoginIssue:
      'https://support.sendgrid.com/hc/en-us/requests/new#login-issue',
    EmailActivity: '/email_activity',
    EmailActivityPurchaseSuccess:
      '/settings/billing/addons/email_activity/success',
    EmailImplementation:
      'https://sendgrid.com/en-us/solutions/expert-services/set-up-assistance/email-implementation',
    EmailUsageSettings: '/settings/alerts',
    ExpertInsights: '/expert_insights',
    ExpertInsightsExamplePDF:
      'https://sendgrid.com/wp-content/uploads/pdf/Expert-Insights-Sample.pdf',
    ExpertServicesImplementation:
      '/account/billing/expert_services/implementation',
    ExpertServicesOngoing: '/account/billing/expert_services/ongoing',
    ExpertServicesQuestions: 'https://sendgrid.com/expert-services-questions/',
    ExplainMyInvoice:
      'https://docs.sendgrid.com/ui/account-and-settings/reading-your-invoice/',
    Pricing: 'https://sendgrid.com/pricing',
    IpAddresses: '/settings/ip_addresses',
    KnowledgeBase: 'https://docs.sendgrid.com/ui/account-and-settings/billing/',
    LearnAboutContacts:
      'https://docs.sendgrid.com/ui/account-and-settings/billing/#understanding-contact-counts-in-marketing-campaigns',
    LegacyContacts: 'https://sendgrid.com/marketing_campaigns/ui/contacts',
    LiveAutomations: '#',
    ManageEase: '/settings/billing/addons/email_activity',
    ManageIps: '/settings/ip_addresses',
    LearnAboutTestingCredits:
      'https://sendgrid.com/en-us/solutions/email-marketing/email-testing',
    LearnAboutEmailValidation:
      'https://sendgrid.com/en-us/solutions/email-api/email-address-validation-api',
    MarketingChoosePlan: '/account/billing/choose_plan_marketing_campaigns',
    MarketingCampaignsExternal: 'http://mc.sendgrid.com/single-sends',
    MCAutomations: 'https://mc.sendgrid.com/automations',
    MCContacts: 'https://mc.sendgrid.com/contacts',
    MCSignupForms: 'https://mc.sendgrid.com/forms/signup',
    MigrateLegacyMc:
      'https://docs.sendgrid.com/ui/sending-email/migrating-from-legacy-marketing-campaigns/',
    Plan: '/account/billing',
    Products: '/account/products',
    SubuserDocs: 'https://docs.sendgrid.com/ui/account-and-settings/subusers',
    Subusers: '/settings/subusers',
    Teammates: '/settings/teammates',
    TOS: 'https://www.twilio.com/legal/tos',
    Webhooks: 'https://docs.sendgrid.com/for-developers/tracking-events/event/',
    WebhookSettings: '/settings/mail_settings/webhook_settings',
    WhatIsEase:
      'https://docs.sendgrid.com/ui/analytics-and-reporting/email-activity-feed/',
    EmailValidationPricing:
      'https://sendgrid.com/en-us/solutions/email-api/email-address-validation-api',
    SenderIdentity:
      'https://docs.sendgrid.com/for-developers/sending-email/sender-identity/',
  },

  // create util, intersection type, and picker to grab data from either account or billing
  formatAddress: (addressInfo: AccountProfile | AddressDto): string[] => {
    const { address, address2, city, country, state, zip } = addressInfo;
    let cityStateZip = '';
    if (city) {
      cityStateZip = `${city},  ${state} ${zip}`;
    }
    return compact([address, address2, cityStateZip, country]);
  },

  formatRegion: (region: string): string => {
    switch (region) {
      case 'global':
        return 'Global';
      case 'eu':
        return 'EU';
      default:
        return 'Global';
    }
  },

  // Format payment method credit card to show last 4 numbers
  // e.g "************0141" => "0141"
  formatCardNumber: (rawCardNumber = '') => {
    return rawCardNumber.replace(/\*/gi, '');
  },

  // Format card types returned from Zuora's API to have proper copy
  formatCardType: (cardType: CreditCardTypes) => {
    switch (cardType) {
      case 'Discover':
      case 'Visa':
        return cardType;
      case 'AmericanExpress':
        return 'American Express';
      case 'MasterCard':
        return 'Mastercard';
      default:
        return '';
    }
  },

  select: {
    createSelectOptions: (arr: string[]) =>
      arr.map((item) => ({ label: item, value: item })),

    createSelectTimezoneOptions: (timezoneOptions: Timezone[] = []) =>
      timezoneOptions.map(({ code, name, offset }) => ({
        label: `${offset} - ${name}`,
        value: code,
      })),
  },

  dates: {
    getCurrentMonth: (): string => {
      return moment().format('MMMM');
    },
    getStartOfNextMonth: (): string => {
      const nextMonth = moment().add(1, 'month');
      const nextMonthText = months[nextMonth.month()];
      const nextMonthYear = nextMonth.year();

      return `${nextMonthText} 1, ${nextMonthYear}`;
    },
    getNextMonth: (): string => {
      return moment().add(1, 'M').format('MMMM');
    },
    getYear: (): string => {
      return moment().format('Y');
    },
  },

  transform: {
    capitalizeFirstLetter: (string: string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
  },

  dollarize: (n: string | number) => {
    // handles 0 since it can be falsy
    if (n !== undefined) {
      // make sure it's a number
      const num = typeof n === 'string' ? parseFloat(n) : n;
      // convert it to a string with two decimals
      const numStr = num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      return `$${numStr}`;
    }
    return '';
  },

  // Converts numbers in thousands to have proper commas i.e. 4500 to "4,500" or "4500" to "4,500"
  numberWithCommas: (n: string | number = '') => {
    return n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  },

  dollarizeWithoutCents: (n: string | number) => {
    // handles 0 since it can be falsy
    if (n !== undefined) {
      // make sure it's a number
      const num = typeof n === 'string' ? parseFloat(n) : n;
      // add commas if necessary
      return `$${num.toLocaleString()}`;
    }
    return '';
  },

  flash: {
    showErrorBanner: (
      errorMsg = 'An error occurred, please try again.',
      timeToDisplay?: number
    ) => {
      window.scrollTo(0, 0);
      window.EventBus.trigger('flash', errorMsg, true, timeToDisplay);
    },
    showErrorBannerHTML: (
      errorMsg = 'An error occurred, please try again.',
      timeToDisplay?: number
    ) => {
      window.scrollTo(0, 0);
      window.EventBus.trigger('flash_html', errorMsg, true, timeToDisplay);
    },
    showSuccessBanner: (successMsg = 'Success!', timeToDisplay?: number) => {
      window.scrollTo(0, 0);
      window.EventBus.trigger('flash', successMsg, false, timeToDisplay);
    },
    showSuccessBannerHTML: (
      successMsg = 'Success!',
      timeToDisplay?: number
    ) => {
      window.scrollTo(0, 0);
      window.EventBus.trigger('flash_html', successMsg, false, timeToDisplay);
    },
  },
};
