import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import { ResponseError } from '../../../helperTypes/responseError';
import { SettingsIPAddressesApiRoutes } from '../apiRoutes';
import { AuthedHttp } from '../../../helpers/AuthedHttp';
import {
  GenericNetworkSlice,
  NetworkState,
  DtoWrapper,
} from '../../../state/store/sliceNetworkTypes';
import {
  SendGridRootState,
  SendGridAppDispatch,
} from '../../../state/store/sendgridAppStore';

export enum IPPoolsError {
  GenericFetchIpPoolsError = 'Network Error. Failed to fetch IP Pools',
  GenericDeleteIpPoolError = 'Network Error. Failed to delete IP Pool',
  GenericCreateIpPoolError = 'Network Error. Failed to create IP Pool',
  GenericEditIpPoolError = 'Network Error. Failed to edit IP Pool',
}

/** Response for GET /v3/send_ips/pools */
export interface IPPoolsCursorDto {
  result: IPPoolsDto[];
  _metadata: {
    next_params: {
      after_key: string | null;
      // following are only included if they were provided in the request
      ip?: string;
      limit?: string;
      region?: string;
    };
  };
}

/** Response for GET /v3/send_ips/pools/{poolid} */
export interface IPPoolsDto {
  name: string;
  id: string;
  ips_preview: string[];
  total_ip_count: number;
  regions?: string[];
}

/** Adapted response for GET /v3/send_ips/pools/{poolid} */
export interface AdaptedIPPools
  extends Omit<IPPoolsDto, 'total_ip_count' | 'ips_preview'> {
  totalIPCount: number;
  ips: string[];
}

export interface IPPoolAssignedIPsDto {
  ip: string;
  pools: { id: string; name: string }[];
  region?: string;
}

/** Response for GET /v3/send_ips/pools/{poolid}/ips */
export interface IPPoolAssignedIpsCursorDto {
  result: IPPoolAssignedIPsDto[];
  _metadata: {
    next_params: {
      after_key: string | null;
      // following is only included if provided in the request
      limit?: string;
    };
  };
}

interface IPPoolsArgs {
  ip?: string; // For filtering by ip address
  limit: number;
  after_key?: number;
  include_region: boolean;
  region?: string;
}

export interface IPPoolsState {
  ipPools: GenericNetworkSlice<AdaptedIPPools[]>;
  deleteIpPool: GenericNetworkSlice<DeleteIpPoolDto>;
  createIpPool: GenericNetworkSlice<CreateIpPoolDto>;
  editIpPool: GenericNetworkSlice<UpdatedIpPool>;
  // Set this after initial load, creating a pool, or deleting a pool. We need to know
  // all existing pool names for inline validation and also how many pools the user has
  // unfiltered to determine if we need to show the max IP pools warning banner or empty card.
  unfilteredPoolNames: string[] | null;
}

const initialIPPoolsState: IPPoolsState = {
  ipPools: {
    networkState: NetworkState.Unrequested,
    data: null,
  },
  deleteIpPool: {
    networkState: NetworkState.Unrequested,
    data: null,
  },
  createIpPool: {
    networkState: NetworkState.Unrequested,
    data: null,
  },
  editIpPool: {
    networkState: NetworkState.Unrequested,
    data: null,
  },
  unfilteredPoolNames: null,
};

/** Get list of IP pools and their associated IP addresses with /v3/send_ips/pools */
export const getIPPools = createAsyncThunk<
  DtoWrapper<AdaptedIPPools[]>,
  IPPoolsArgs,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('ipPools/getIpPools', async (args, thunkApi) => {
  const { ip, limit, after_key, include_region, region } = args;

  try {
    const response = await AuthedHttp.get<IPPoolsCursorDto>(
      SettingsIPAddressesApiRoutes.getSendIpsPools({
        ip,
        limit,
        after_key,
        include_region,
        region,
      })
    );

    if (response.ok) {
      const ipPools = await response.json();
      const adaptedIPPools = ipPools.result.map((pool) => ({
        ...pool,
        ips: pool.ips_preview,
        totalIPCount: pool.total_ip_count,
        regions: pool.regions,
      }));

      return {
        statusCode: response.status,
        data: adaptedIPPools,
      };
    }

    const errorResponse = await response.json();

    return thunkApi.rejectWithValue({
      message: errorResponse.errors[0].message,
      statusCode: response.status,
    } as ResponseError);
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Network Level Error: ${e}`,
    } as ResponseError);
  }
});

export interface DeleteIpPoolArgs {
  poolId: string;
  poolName: string;
}

/** Response for DELETE /v3/send_ips/pools/{poolid} */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface DeleteIpPoolDto {}

export interface DeleteIpPoolData {
  poolName: string;
}

/** Delete an IP Pool with DELETE /v3/send_ips/pools/{poolid} */
export const deleteIpPool = createAsyncThunk<
  DtoWrapper<DeleteIpPoolData>,
  DeleteIpPoolArgs,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('ipPools/deleteIpPool', async (args, thunkApi) => {
  const { poolId, poolName } = args;

  try {
    const response = await AuthedHttp.del<DeleteIpPoolDto>(
      `${SettingsIPAddressesApiRoutes.deleteSendIpPool(poolId)}`
    );

    if (response.ok) {
      const data = { poolName };
      return {
        statusCode: response.status,
        data,
      };
    }

    const errorResponse = await response.json();

    return thunkApi.rejectWithValue({
      message: errorResponse.errors[0].message,
      statusCode: response.status,
    });
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: IPPoolsError.GenericDeleteIpPoolError,
    } as ResponseError);
  }
});

/** Request body for POST /v3/send_ips/pools */
export interface CreateIpPoolRequest {
  name: string;
  ips?: string[];
}

/** Response for POST /v3/send_ips/pools */
export interface CreateIpPoolDto extends CreateIpPoolRequest {
  id: string;
}

/** Create an IP Pool with POST /v3/send_ips/pools */
export const createIpPool = createAsyncThunk<
  DtoWrapper<CreateIpPoolDto>,
  CreateIpPoolRequest,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('ipPools/createIpPool', async (args, thunkApi) => {
  const { name, ips } = args;
  const createIpPoolRequest: CreateIpPoolRequest = {
    name,
    ips,
  };

  try {
    const response = await AuthedHttp.post<CreateIpPoolDto>(
      `${SettingsIPAddressesApiRoutes.createSendIpPool}`,
      createIpPoolRequest
    );

    if (response.ok) {
      const data = await response.json();

      return {
        statusCode: response.status,
        data,
      };
    }

    const errorResponse = await response.json();

    return thunkApi.rejectWithValue({
      message: errorResponse.errors[0].message,
      statusCode: response.status,
    });
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: IPPoolsError.GenericCreateIpPoolError,
    } as ResponseError);
  }
});

export interface EditIpPoolArgs {
  id: string;
  currentPoolName: string;
  newPoolName: string;
  ipsToAssign: string[];
  ipsToUnassign: string[];
}

/** Request body for PUT /v3/send_ips/pools/{poolid} */
export interface EditIpPoolRequest {
  name: string;
}

/** Response for PUT /v3/send_ips/pools/{poolid} */
export interface EditIpPoolDto extends EditIpPoolRequest {
  id: string;
}

/** Request body for POST /v3/send_ips/pools/{poolid}/ips:batchAdd */
export interface AssignIpsRequest {
  ips: string[];
}

/** Response for POST /v3/send_ips/pools/{poolid}/ips:batchAdd */
export interface AssignIpsDto extends AssignIpsRequest {
  name: string;
  id: string;
}

/** Request body for POST /v3/send_ips/pools/{pooli}/ips:batchDelete */
export interface UnassignIpsRequest {
  ips: string[];
}

/** Response for POST /v3/send_ips/pools/{poolid}/ips:batchDelete */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface UnassignIpsDto {}

export interface UpdatedIpPool
  extends Partial<EditIpPoolDto>,
    Partial<AssignIpsDto> {
  previousPoolName: string;
  updatedPoolName: string;
}

/** Edit an IP Pool name and bulk assign/unassign IPs */
export const editIpPool = createAsyncThunk<
  UpdatedIpPool,
  EditIpPoolArgs,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('ipPools/editIpPool', async (args, thunkApi) => {
  const { id, currentPoolName, newPoolName, ipsToAssign, ipsToUnassign } = args;

  try {
    const editRequests = [];
    let combinedDto: UpdatedIpPool = {
      previousPoolName: currentPoolName,
      updatedPoolName: newPoolName,
    };

    const editIpPoolRequestBody: EditIpPoolRequest = {
      name: newPoolName,
    };
    /** Update an IP pool name with PUT /v3/send_ips/pools/{poolid} */
    const editIpPoolRequest = AuthedHttp.put<EditIpPoolDto>(
      `${SettingsIPAddressesApiRoutes.editSendIpPool(id)}`,
      editIpPoolRequestBody
    );
    editRequests.push(editIpPoolRequest);

    if (ipsToAssign.length > 0) {
      const assignIpsRequestBody: AssignIpsRequest = {
        ips: ipsToAssign,
      };
      /** Append a list of IP address to an IP pool with POST /v3/send_ips/{poolid}/ips:batchAdd */
      const assignIpsRequest = AuthedHttp.post<AssignIpsDto>(
        `${SettingsIPAddressesApiRoutes.assignIpsToSendIpPool(id)}`,
        assignIpsRequestBody
      );
      editRequests.push(assignIpsRequest);
    }

    if (ipsToUnassign.length > 0) {
      const unassignIpsRequestBody: UnassignIpsRequest = {
        ips: ipsToUnassign,
      };
      /** Remove IP addresses from an IP pool with POST /v3/send_ips/{poolid}/ips:batchDelete */
      const unassignIpsRequest = AuthedHttp.post<UnassignIpsDto>(
        `${SettingsIPAddressesApiRoutes.unassignIpsFromSendIpPool(id)}`,
        unassignIpsRequestBody
      );
      editRequests.push(unassignIpsRequest);
    }

    await Promise.all(
      editRequests.map(async (request) => {
        const response = await request;

        if (response.ok) {
          if (response.status !== 204) {
            const data = await response.json();
            combinedDto = { ...data, ...combinedDto };
          }
        } else {
          const errorResponse = await response.json();
          throw new Error(errorResponse.errors[0].message);
        }
      })
    );

    return combinedDto;
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: IPPoolsError.GenericEditIpPoolError,
    } as ResponseError);
  }
});

export const clearIpPoolCrudRequests = createAction('clearIpPoolCrudRequests');

export const ipPoolsSlice = createSlice({
  name: 'ipPools',
  initialState: initialIPPoolsState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIPPools.pending, (state) => {
      state.ipPools.networkState = NetworkState.Loading;
    });
    builder.addCase(getIPPools.fulfilled, (state, action) => {
      state.ipPools.networkState = NetworkState.Success;

      if (state.ipPools.networkState === NetworkState.Success) {
        state.ipPools.data = action.payload.data;
        state.ipPools.statusCode = action.payload.statusCode;

        if (!state.unfilteredPoolNames) {
          state.unfilteredPoolNames = action.payload.data.map(
            (pool) => pool.name
          );
        }
      }
    });
    builder.addCase(getIPPools.rejected, (state, action) => {
      state.ipPools.networkState = NetworkState.Error;

      if (state.ipPools.networkState === NetworkState.Error) {
        state.ipPools.errorMessage =
          action.payload?.message || IPPoolsError.GenericFetchIpPoolsError;
        state.ipPools.statusCode = action.payload?.statusCode || 0;
      }
    });

    builder.addCase(deleteIpPool.pending, (state) => {
      state.deleteIpPool.networkState = NetworkState.Loading;
    });
    builder.addCase(deleteIpPool.fulfilled, (state, action) => {
      state.deleteIpPool.networkState = NetworkState.Success;

      if (state.deleteIpPool.networkState === NetworkState.Success) {
        state.deleteIpPool.data = action.payload.data;
        state.deleteIpPool.statusCode = action.payload.statusCode;

        state.unfilteredPoolNames = state.unfilteredPoolNames
          ? state.unfilteredPoolNames.filter(
              (poolName) => poolName !== action.payload.data.poolName
            )
          : null;
      }
    });
    builder.addCase(deleteIpPool.rejected, (state, action) => {
      state.deleteIpPool.networkState = NetworkState.Error;

      if (state.deleteIpPool.networkState === NetworkState.Error) {
        state.deleteIpPool.errorMessage =
          action.payload?.message || IPPoolsError.GenericDeleteIpPoolError;
        state.deleteIpPool.statusCode = action.payload?.statusCode || 0;
      }
    });

    builder.addCase(createIpPool.pending, (state) => {
      state.createIpPool.networkState = NetworkState.Loading;
    });
    builder.addCase(createIpPool.fulfilled, (state, action) => {
      state.createIpPool.networkState = NetworkState.Success;

      if (state.createIpPool.networkState === NetworkState.Success) {
        state.createIpPool.data = action.payload.data;
        state.createIpPool.statusCode = action.payload.statusCode;

        state.unfilteredPoolNames = [
          action.payload.data.name,
          ...[...(state.unfilteredPoolNames ?? [])],
        ];
      }
    });
    builder.addCase(createIpPool.rejected, (state, action) => {
      state.createIpPool.networkState = NetworkState.Error;

      if (state.createIpPool.networkState === NetworkState.Error) {
        state.createIpPool.errorMessage =
          action.payload?.message || IPPoolsError.GenericCreateIpPoolError;
        state.createIpPool.statusCode = action.payload?.statusCode || 0;
      }
    });

    builder.addCase(editIpPool.pending, (state) => {
      state.editIpPool.networkState = NetworkState.Loading;
    });
    builder.addCase(editIpPool.fulfilled, (state, action) => {
      state.editIpPool.networkState = NetworkState.Success;

      if (state.editIpPool.networkState === NetworkState.Success) {
        const { previousPoolName, updatedPoolName } = action.payload;

        state.editIpPool.data = action.payload;

        // Remove old pool name from names list and add new pool name
        const updatedPoolNames = state.unfilteredPoolNames
          ? state.unfilteredPoolNames.filter(
              (poolName) => poolName !== previousPoolName
            )
          : [];
        updatedPoolNames.push(updatedPoolName);
        state.unfilteredPoolNames = updatedPoolNames;
      }
    });
    builder.addCase(editIpPool.rejected, (state, action) => {
      state.editIpPool.networkState = NetworkState.Error;

      if (state.editIpPool.networkState === NetworkState.Error) {
        state.editIpPool.errorMessage =
          action.payload?.message || IPPoolsError.GenericEditIpPoolError;
      }
    });

    builder.addCase(clearIpPoolCrudRequests, (state) => {
      state.deleteIpPool.networkState = NetworkState.Unrequested;
      state.createIpPool.networkState = NetworkState.Unrequested;
      state.editIpPool.networkState = NetworkState.Unrequested;
    });
  },
});
