import { MakoApi } from '../middle_tier/services/mako_api/service';
// types
import { EmailApiUsage } from '../types/emailApiUsage';
import { mapEmailApiUsage } from './adapters';

export async function getEmailApiUsage(): Promise<EmailApiUsage> {
  try {
    const usageStatsDto = await MakoApi.getUsageStats();
    return mapEmailApiUsage(usageStatsDto);
  } catch (error) {
    console.error(`getEmailUsage - ${error}`);
    return Promise.reject({
      message: 'unable to fetch email usage',
    });
  }
}
