import React from 'react';
import { Button } from '@twilio-paste/core/button';
import { Anchor } from '@twilio-paste/core/anchor';
import { BounceClassificationTypes } from './features/types';
import { DeliverabilityTabRoutes } from './routes';

// This is the custom MSP name we give to the remaining MSPs outside of the listed top X MSP names
export const remainingMSPsName = 'Remaining';

const getRandomInt = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};

const getGreenSuffix = () => {
  const congrats = ['🚀 Nice job!', '✨ Awesome!', '🎉 Great work!'];
  const selectedCongrats = congrats[getRandomInt(0, congrats.length - 1)];
  return selectedCongrats;
};

const classificationsToDisplayNameMap: {
  [oldName: string]: string;
} = {
  [BounceClassificationTypes.Content]: 'content',
  [BounceClassificationTypes.Frequency]: 'frequency or volume',
  [BounceClassificationTypes.InvalidAddress]: 'invalid address',
  [BounceClassificationTypes.Reputation]: 'reputation',
  [BounceClassificationTypes.MailboxUnavailable]: 'mailbox unavailable',
  [BounceClassificationTypes.Technical]: 'technical',
  [BounceClassificationTypes.Unclassified]: 'unclassified',
};

export const mapClassificationToDisplayName = (classification: string) => {
  return classificationsToDisplayNameMap[classification] ?? classification;
};

const classificationAccordionCopy: {
  [key in BounceClassificationTypes]: {
    header: string;
    description: string;
    linkDescription: string;
    linkUrl: string;
    lineChartYAxis: string;
  };
} = {
  [BounceClassificationTypes.Content]: {
    header: 'Content',
    description: `Content rejections occur when mailbox providers detect content in your messages that is 
      consistent with messages they have previously classified as spam or malicious. This can include 
      attachments, URLs, and in rare cases, even the words used in the body of your email.`,
    linkDescription: 'Learn how to minimize Content rejections.',
    linkUrl:
      'https://docs.sendgrid.com/ui/analytics-and-reporting/bounce-and-block-classifications#content',
    lineChartYAxis: 'Content',
  },
  [BounceClassificationTypes.Frequency]: {
    header: 'Frequency or volume too high',
    description: `Messages can be rejected when you send mail too quickly. In most cases, these rejections occur 
      when a mailbox provider receives too much email from you in a short period of time. Frequency-related 
      rejections can also occur when you send too much email to a single recipient or you send to a single 
      recipient too quickly.`,
    linkDescription:
      'Learn how to minimize rejections caused by sending frequency and sending volume.',
    linkUrl:
      'https://docs.sendgrid.com/ui/analytics-and-reporting/bounce-and-block-classifications#frequencyvolume-too-high',
    lineChartYAxis: 'Frequency',
  },
  [BounceClassificationTypes.InvalidAddress]: {
    header: 'Invalid address',
    description: `Invalid Address rejections indicate that you attempted to send a message to an address that 
      doesn’t exist. Excessive invalid address bounces can negatively impact your reputation.`,
    linkDescription: 'Learn how to minimize Invalid Address rejections.',
    linkUrl:
      'https://docs.sendgrid.com/ui/analytics-and-reporting/bounce-and-block-classifications#invalid-address',
    lineChartYAxis: 'Invalid Address',
  },
  [BounceClassificationTypes.Reputation]: {
    header: 'Reputation',
    description: `Reputation-related rejections occur when the mailbox provider determines you have a poor sender 
      reputation. Sender reputation is determined by dozens of factors. The most common causes of reputation-related 
      rejections are sudden spikes in sending volume, excessive spam reports, excessive bounces, and poor engagement.`,
    linkDescription:
      'Learn how to minimize rejections caused by poor reputation.',
    linkUrl:
      'https://docs.sendgrid.com/ui/analytics-and-reporting/bounce-and-block-classifications#reputation',
    lineChartYAxis: 'Reputation',
  },
  [BounceClassificationTypes.MailboxUnavailable]: {
    header: 'Mailbox unavailable',
    description: `Mailbox Unavailable is a broad classification. Mailbox unavailable rejections indicate that there 
      was a problem with a specific recipient address. Common causes for Mailbox Unavailable rejections are mailboxes 
      that are full, mailboxes that have been temporarily disabled, or mailboxes that are not currently accepting mail.`,
    linkDescription: 'Learn how to minimize Mailbox Unavailable rejections.',
    linkUrl:
      'https://docs.sendgrid.com/ui/analytics-and-reporting/bounce-and-block-classifications#mailbox-unavailable',
    lineChartYAxis: 'Mailbox Unavailable',
  },
  [BounceClassificationTypes.Technical]: {
    header: 'Technical',
    description: `Technical rejections can occur for a variety of reasons. Missing DNS record or authentication 
      problems on the sender’s side, or temporary network problems or other technical issues on the mailbox provider’s 
      side are common reasons for technical rejections.`,
    linkDescription: 'Learn how to minimize Technical rejections.',
    linkUrl:
      'https://docs.sendgrid.com/ui/analytics-and-reporting/bounce-and-block-classifications#technical',
    lineChartYAxis: 'Technical',
  },
  [BounceClassificationTypes.Unclassified]: {
    header: 'Unclassified',
    description: `Some rejections are difficult to accurately categorize. Rejections that fall under Unclassified are most 
      often rejections that occur with a message that does not clearly indicate the reason for the rejection.`,
    linkDescription: 'Learn how to minimize Unclassified rejections.',
    linkUrl:
      'https://docs.sendgrid.com/ui/analytics-and-reporting/bounce-and-block-classifications#unclassified',
    lineChartYAxis: 'Unclassified',
  },
};

export const words = {
  pageHeader: 'Deliverability Insights',
  comboDateRangePickerLabel: 'Date Range',
  comboDateRangePickerOptionLabels: {
    past7Days: 'Past 7 days',
    past30Days: 'Past 30 days',
    past90Days: 'Past 90 days',
    past6Months: 'Past 6 months',
    past12Months: 'Past 12 months',
    chooseDateRange: 'Choose a Date Range',
  },
  statsAggregationSelectLabel: 'Trending',
  statsAggregationSelectOptionLabels: {
    daily: 'Daily',
    weekly: 'Weekly',
    monthly: 'Monthly',
  },
  comboboxFetchError: 'Failed to fetch accounts. Please retry.',
  deliverabilityHelpInfo: 'Need deliverability help?',
  contactDeliveryTeamLink: 'Contact Delivery Team',
  contactDeliveryTeamLinkUrl: 'https://sendgrid.com/solutions/expert-services/',
  mailboxProviderInfo: 'Curious about mailbox providers?',
  mailboxProviderLearnMoreLink: 'Learn More',
  mailboxProviderLearnMoreLinkUrl:
    'https://docs.sendgrid.com/ui/analytics-and-reporting/mailbox-provider-statistics',
  tabs: {
    overview: {
      tabName: 'Overview',
      deliverabilityMetricsCard: {
        header: 'Deliverability Metrics',
        headerPopover: {
          heading: 'Primary deliverability metrics',
          body:
            "Watch for trends in your primary deliverability metrics. Aggregated delivery, bounce/block, and open percentages won't tell your entire deliverability story, but are great for monitoring changes in your email program at-a-glance.",
          primaryDeliverabilityMetricsLink:
            'Learn more about your primary deliverability metrics and why they matter.',
          primaryDeliverabilityMetricsLinkUrl:
            'https://docs.sendgrid.com/ui/analytics-and-reporting/deliverability-insights#understanding-your-primary-deliverability-metrics',
        },
        processedCard: 'Processed',
        deliveredCard: 'Delivered',
        bouncedAndBlockedCard: 'Bounced & Blocked',
        uniqueOpensCard: 'Unique Opens',
        popovers: {
          sharedCopy: {
            dateRangeComparison: {
              presetRange: (range: string) => `the previous ${range} period.`,
              customRange: 'the previous comparable time period.',
            },
            noTrendVerbiage:
              'For trend information, please choose a date range of 6 months or less. The comparison range must fall within the last 13 months to be displayed.',
          },
          delivered: {
            title: 'Delivered Metrics Popover Button',
            popoverFragment: ({
              href,
              linkText,
            }: {
              href: string;
              linkText: string;
            }) => <Anchor href={href}>{linkText}</Anchor>,
            baseId: 'deliveredMetricsPopover',
            ariaLabel: 'Delivered Metrics Popover',
            prefix: 'Your delivered rate was',
            thresholdVerbiage: {
              green: (trendPercent: number) => `${trendPercent}% higher than`,
              neutral: () => 'about the same as',
              yellow: (trendPercent: number) => `${trendPercent}% lower than`,
              red: (trendPercent: number) => `${trendPercent}% lower than`,
            },
            suffix: {
              green: getGreenSuffix(),
              neutral: `Continue to monitor your delivered rate - we'll let you know if there is a more significant change in your trends that you should look into.`,
              yellow: `A small decrease in delivery rate signals an increase in negative signals like bounces and blocks. Keep an eye on your delivered, bounce, and block rates by mailbox provider to see if this trend is impacting one or more mailbox providers.`,
              red: `A large decrease in delivery rate signals an increase in negative signals like bounces and blocks. We recommend taking a look at your delivered, bounce, and block rates by mailbox provider to see if this trend is impacting one or more mailbox providers.`,
            },
            anchors: {
              green: [],
              neutral: [],
              yellow: [
                {
                  anchorText:
                    'View your delivered rate by top mailbox providers',
                  anchorLink: DeliverabilityTabRoutes.MailboxProviders,
                },
                {
                  anchorText:
                    'View your bounce & block rates by top mailbox providers',
                  anchorLink: DeliverabilityTabRoutes.BouncedAndBlocked,
                },
              ],
              red: [
                {
                  anchorText:
                    'View your delivered rate by top mailbox providers',
                  anchorLink: DeliverabilityTabRoutes.MailboxProviders,
                },
                {
                  anchorText:
                    'View your bounce & block rates by top mailbox providers',
                  anchorLink: DeliverabilityTabRoutes.BouncedAndBlocked,
                },
              ],
            },
          },
          bouncedAndBlocked: {
            title: 'Bounced and Blocked Metrics Popover Button',
            popoverFragment: ({
              href,
              linkText,
            }: {
              href: string;
              linkText: string;
            }) => <Anchor href={href}>{linkText}</Anchor>,
            baseId: 'bouncedAndBlockedMetricsPopover',
            ariaLabel: 'Bounced and Blocked Metrics Popover',
            prefix: 'Your bounced & blocked rate was',
            thresholdVerbiage: {
              green: (trendPercent: number) => `${trendPercent}% lower than`,
              neutral: () => 'about the same as',
              yellow: (trendPercent: number) => `${trendPercent}% higher than`,
              red: (trendPercent: number) => `${trendPercent}% higher than`,
            },
            suffix: {
              green: getGreenSuffix(),
              neutral: `Continue to monitor your bounce & block rate - we'll let you know if there is a more significant change in your trends that you should look into.`,
              yellow: `An increase in your bounces and block rate means a higher percentage of your processed email failed to deliver to your recipients. We recommend taking a look at your bounce & blocks by mailbox providers to understand where these negative signals are occurring.`,
              red: `A large increase in your bounces and block rate means a significant higher percentage of your processed email failed to deliver to your recipients. We recommend taking a look at your bounce & blocks by mailbox providers to understand where these negative signals are occurring.`,
            },
            anchors: {
              green: [],
              neutral: [],
              yellow: [
                {
                  anchorText:
                    'View your bounces and blocks by top mailbox providers',
                  anchorLink: DeliverabilityTabRoutes.BouncedAndBlocked,
                },
              ],
              red: [
                {
                  anchorText:
                    'View your bounces and blocks by top mailbox providers',
                  anchorLink: DeliverabilityTabRoutes.BouncedAndBlocked,
                },
              ],
            },
          },
          uniqueOpens: {
            title: 'Unique Opens Metrics Popover Button',
            popoverFragment: ({
              href,
              linkText,
            }: {
              href: string;
              linkText: string;
            }) => <Anchor href={href}>{linkText}</Anchor>,
            baseId: 'uniqueOpensMetricsPopover',
            ariaLabel: 'Unique Opens Metrics Popover',
            prefix: 'Your unique open rate was',
            thresholdVerbiage: {
              green: () => 'higher',
              neutral: () => 'about the same as',
              yellow: () => 'slightly lower',
              red: () => 'significantly lower',
            },
            suffix: {
              green: getGreenSuffix(),
              neutral: `Continue to monitor your open rate - we'll let you know if there is a more significant change in your trends that you should look into.`,
              yellow: `A small decrease in your open rate trend may mean that recent mail you sent was less engaging to your recipients, but could also signal that one or more mailbox providers are sending your email to the spam folder. Keep on eye on your open rate by mailbox provider and spam complaints.`,
              red: `A large decrease in your open rate may signal that one or more mailbox providers are sending your email to the spam folder. We recommend taking a look at your open rates by mailbox provider and spam complaints as a next step.`,
            },
            anchors: {
              green: [],
              neutral: [],
              yellow: [
                {
                  anchorText: 'View your open rates by top mailbox providers',
                  anchorLink: DeliverabilityTabRoutes.MailboxProviders,
                },
                {
                  anchorText: 'View your spam complaints',
                  anchorLink: DeliverabilityTabRoutes.SpamAndUnsubscribes,
                },
              ],
              red: [
                {
                  anchorText: 'View your open rates by top mailbox providers',
                  anchorLink: DeliverabilityTabRoutes.MailboxProviders,
                },
                {
                  anchorText: 'View your spam complaints',
                  anchorLink: DeliverabilityTabRoutes.SpamAndUnsubscribes,
                },
              ],
            },
          },
        },
      },
      deliverabilityMetricsTrends: {
        header: 'How are your overall deliverability metrics trending?',
        popover: {
          heading: 'Trending over time',
          body:
            'Watching trends in your metrics over time is invaluable. Use this chart to determine when a particular metric started to improve or worsen. If there is a clear date when a metric changed, look into what might have changed in your sending behavior on or around that date.',
          fallingDeliverabilityRatesLink:
            'What can I do about falling deliverability rates?',
          fallingDeliverabilityRatesLinkUrl:
            'https://docs.sendgrid.com/ui/analytics-and-reporting/deliverability-insights#what-can-i-do-about-falling-deliverability-rates',
          increasingBounceRatesLink:
            'What can I do about increasing bounce or block rates?',
          increasingBounceRatesLinkUrl:
            'https://docs.sendgrid.com/ui/analytics-and-reporting/deliverability-insights#what-can-i-do-about-increasing-bounce-or-block-rates',
          decreasingOpenClickRatesLink:
            'What can I do about decreasing open or click rates?',
          decreasingOpenClickRatesLinkUrl:
            'https://docs.sendgrid.com/ui/analytics-and-reporting/deliverability-insights#what-can-i-do-about-decreasing-open-rates',
        },
        legendHeader: 'Email Status',
        table: {
          dateTableHeader: 'Date',
          deliveredTableHeader: 'Delivered',
          bouncedAndBlockedTableHeader: 'Bounced & Blocked',
          uniqueOpensTableHeader: 'Unique Opens',
        },
      },
    },
    mailboxProviders: {
      tabName: 'Mailbox providers',
      emailsSentToMSPMetrics: {
        header: 'What mailbox providers are your emails being sent to?',
        popover: {
          heading: 'Your top five recipient mailbox providers',
          body:
            "Mailbox providers are companies that provide email mailboxes to consumers and businesses. It is important to know which mailbox providers you are sending a significant amount of email to. For example, if most of your email is going to Gmail and Microsoft, you'll want to keep close tabs on your deliverablity metrics at those two providers.",
        },
        checkboxLegendHeader: 'Mailbox providers',
        table: {
          dateHeader: 'Date',
          eventHeader: 'Event',
          processedDescription: 'Processed:',
        },
      },
      emailsDeliveredToMSPsMetrics: {
        header: 'Are your emails being delivered to mailbox providers?',
        popover: {
          heading: 'Delivered rates by mailbox provider',
          body:
            "Having a high percentage of your email delivered to your top providers is a good first step towards healthy email deliverability. However, it is important to know that delivery does not guarantee that the message made it into your recipients' primary inbox.",
          learnMoreLink: 'Learn more about your delivered rate',
          learnMoreLinkUrl:
            'https://docs.sendgrid.com/ui/analytics-and-reporting/deliverability-insights#understanding-your-delivered-rates-by-mailbox-provider',
        },
        checkboxLegendHeader: 'Mailbox providers',
        metricName: 'Delivered',
        divisorName: 'Processed',
      },
      openRatesByMSPMetrics: {
        header: 'What are your open rates by mailbox provider?',
        popover: {
          heading: 'Open rates by mailbox provider',
          body:
            "Your open percentages should be fairly consistent across your mailbox providers. When you see a mailbox provider with open rates significantly lower than your other mailbox providers, it's a strong signal that the underperforming mailbox provider is sending some of your email to the spam folder.",
          learnMoreLink: 'Learn more about monitoring your open rate',
          learnMoreLinkUrl:
            'https://docs.sendgrid.com/ui/analytics-and-reporting/deliverability-insights#understanding-your-open-rates-by-mailbox-provider',
        },
        checkboxLegendHeader: 'Mailbox providers',
        metricName: 'Unique Opens',
        divisorName: 'Delivered',
      },
    },
    bouncedAndBlocked: {
      tabName: 'Bounced & blocked',
      bounceRateByMsp: {
        header: 'Are your emails being bounced by mailbox providers?',
        popover: {
          heading: 'Why do bounces matter?',
          body:
            'A bounce indicates that you attempted to send a message to a mailbox that does not exist. Mailbox providers consider excessive bounces, or spikes in bounces, to be an indicator that you are sending unwanted email. Use this chart to determine if your bounces are concentrated at a single mailbox provider or several so that you can prioritize your troubleshooting efforts.',
          learnMoreLink: 'Learn how to reduce your bounces',
          learnMoreLinkUrl:
            'https://docs.sendgrid.com/ui/analytics-and-reporting/deliverability-insights#understanding-and-reducing-bounces',
        },
        checkboxLegendHeader: 'Negative Signals',
        bounceRateCheckbox: 'Bounce rate',
        bounceRateComparisonCheckbox: 'Bounce rate comparison',
        table: {
          metricName: 'Bounced',
          divisorName: 'Processed',
        },
      },
      blockRateByMsp: {
        header: 'Are your emails being blocked by mailbox providers?',
        popover: {
          heading: 'Why do blocks matter?',
          body:
            'A block event indicates that the message you attempted to send failed to deliver. Unlike Bounces, a block does not indicate the recipient address was invalid. Rather, it means that your message failed for one of several different reasons that mailbox providers specifiy.',
          learnHowToReduceLink: 'Learn how to reduce your blocks',
          learnHowToReduceLinkUrl:
            'https://docs.sendgrid.com/ui/analytics-and-reporting/deliverability-insights#understanding-and-reducing-blocks',
        },
        checkboxLegendHeader: 'Negative Signals',
        blockRateCheckbox: 'Block rate',
        blockRateComparisonCheckbox: 'Block rate comparison',
        table: {
          metricName: 'Blocked',
          divisorName: 'Processed',
        },
      },
      bounceClassifications: {
        header: 'What is causing your bounces and blocks?',
        popover: {
          heading: 'Bounce & Block Classifications',
          body: `Use bounce & block classification data to determine what mailbox providers think of your sending 
            behavior and which of your sending characteristics might be putting your email deliverability at risk.`,
        },
        totalCountText: 'Total bounces & blocks:',
        accordions: classificationAccordionCopy,
        checkboxLegendHeader: 'Mailbox providers',
        infoBannerDescription:
          'Bounce & block classifications are limited to the past 30 days.',
      },
    },
    spamAndUnsubscribes: {
      tabName: 'Spam & unsubscribes',
      spamComplaintsByMSP: {
        header: 'Are customers marking your emails as spam?',
        popover: {
          heading: 'Spam Reports',
          body:
            'A spam report is logged any time a user marks one of your emails as spam in their mailbox provider. It is important to know that some mailbox providers, most notably Gmail, do not send back Spam Report data. A significant percentage of spam reports can have a lasting impact on your deliverability to mailbox providers.',
          learnHowToReduceLink: 'Learn how to reduce your spam reports',
          learnHowToReduceLinkUrl:
            'https://docs.sendgrid.com/ui/analytics-and-reporting/deliverability-insights#monitoring-and-reducing-spam-reports',
        },
        checkboxLegendHeader: 'Negative Signals',
        spamComplaintsCheckbox: 'Spam complaints',
        spamComplaintsComparisonCheckbox: 'Spam complaints comparison',
        spamComplaintsNoDataCard: {
          header: 'No spam complaints received in current selected date range.',
          description: 'Change the date range to refresh data.',
        },
        infoBannerDescription:
          "Some mailbox providers, like Gmail, don't return spam complaints.",
        infoBannerLink: 'Learn More',
        infoBannerLinkUrl:
          'https://docs.sendgrid.com/ui/analytics-and-reporting/deliverability-insights#monitoring-and-reducing-spam-reports',
        table: {
          metricName: 'Spam Complaints',
          divisorName: 'Delivered',
        },
      },
      unsubscribes: {
        header: 'Are customers unsubscribing from your emails?',
        popover: {
          heading: 'Unsubscribe Rates',
          body:
            'Monitoring trends in unsubscribe rates can give you great insights into what your recipients think about the email you are sending them. When you see a spike in unsubscribe rates, investigate what types of mail you sent around the time of the spike. Try to determine why the message or campaign might have missed the mark and why it caused more users than normal to unsubscribe from your mailing list.',
          learnHowToReduceLink: 'Learn how to reduce your unsubscribe rate',
          learnHowToReduceLinkUrl:
            'https://docs.sendgrid.com/ui/analytics-and-reporting/deliverability-insights#monitoring-and-reducing-your-unsubscribe-rates',
        },
        checkboxLegendHeader: 'Negative Signals',
        unsubscribesCheckbox: 'Unsubscribes',
        table: {
          dateHeader: 'Date',
          unsubscribesHeader: 'Unsubscribes (count)',
          unsubscribesPercentHeader:
            'Unsubscribes (percent of total delivered)',
        },
      },
    },
  },
  exportDropdown: {
    exportDropdownButton: 'Export',
    exportingDropdownButton: 'Exporting...',
    downloadCsvOption: 'Download CSV',
    downloadJpegOption: 'Download JPEG',
    downloadPdfOption: 'Download PDF',
    downloadPngOption: 'Download PNG',
    downloadSvgOption: 'Download SVG',
  },
  card: {
    noTrendAvailable: 'No trend available',
  },
  loadingCard: {
    description: 'Loading...',
  },
  noDataCard: {
    header: 'No data to display for the current date range.',
    description: 'Change the date range to refresh data.',
  },
  errorCard: {
    header: "We're sorry, we failed to load this data.",
    description: (onClickRefresh: () => void) => (
      <>
        Please{' '}
        <Button variant="link" onClick={onClickRefresh}>
          refresh
        </Button>{' '}
        to try again.
      </>
    ),
  },
  downloadCsvErrorBanner: (onClickTryAgain: () => void) => (
    <>
      We're sorry, we were unable to generate the file for download. Please{' '}
      <button className="as-anchor" onClick={onClickTryAgain}>
        try again
      </button>
      .
    </>
  ),
  TopMSPMetricRatesByDateTable: {
    headers: {
      date: 'Date',
      events: 'Events',
    },
  },
  topMSPMetricRatesComparisonTable: {
    headers: {
      date: 'Date',
      events: 'Events',
    },
  },
  classificationTable: {
    headers: {
      mailboxProviders: 'Top mailbox providers',
      percent: (classification: BounceClassificationTypes) =>
        `Percent of total ${mapClassificationToDisplayName(
          classification
        )} bounces & blocks`,
      count: (classification: BounceClassificationTypes) =>
        `Number of ${mapClassificationToDisplayName(
          classification
        )} bounces & blocks`,
    },
  },
  otherMailboxProvidersTooltip: {
    content: `Twilio SendGrid helps to clean up your mailbox provider data by grouping domains together. 
      "Other providers" refers to any domain that does not map to a mailbox provider. `,
    learnMoreLink:
      'https://docs.sendgrid.com/ui/analytics-and-reporting/mailbox-provider-statistics#the-other-providers-grouping',
    learnMoreDescription: 'Click the link to learn more.',
  },
};

export default words;
