import { Action } from '../types/action';
import { ActionTypes } from './actionTypes';
import { NextInvoice } from '../types/nextInvoice';

const initialState = {
  totalRecurringCost: 0,
};

interface NextInvoiceAction extends Action {
  payload: NextInvoice;
}

export const nextInvoice = (
  state = initialState,
  action: NextInvoiceAction
) => {
  switch (action.type) {
    case ActionTypes.GetNextInvoiceSuccess:
      return action.payload as NextInvoice;
    default:
      return state;
  }
};
