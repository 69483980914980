import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { TestingCreditsSubscription } from './types';

export const Actions = {
  getTestingCreditsSuccess: (
    testingCreditsSubscription: TestingCreditsSubscription
  ) =>
    createAction(
      ActionTypes.GetTestingCreditsSubscriptionSuccess,
      testingCreditsSubscription
    ),
  getTestingCreditsFailure: () =>
    createAction(ActionTypes.GetTestingCreditsSubscriptionFailure),

  updateTestingCreditSubscription: (planName: string) =>
    createAction(ActionTypes.UpdateTestingCreditSubscription, planName),
  updateTestingCreditSubscriptionSuccess: (data: TestingCreditsSubscription) =>
    createAction(ActionTypes.UpdateTestingCreditSubscriptionSuccess, data),
  updateTestingCreditSubscriptionFailure: () =>
    createAction(ActionTypes.UpdateTestingCreditSubscriptionFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
