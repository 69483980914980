import { DtoWrapper } from './sliceNetworkTypes';
import { ResponseError } from '../../helperTypes/responseError';
import { AuthedHttp, RequestOptions } from '../../helpers/AuthedHttp';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { SendGridAppDispatch, SendGridRootState } from './sendgridAppStore';

export const createGetThunk = <Dto, AdaptedData>(
  thunkName: string,
  route: string,
  adapter: (data: Dto) => AdaptedData,
  requestOptions?: RequestOptions
) => {
  return createAsyncThunk<
    DtoWrapper<AdaptedData>,
    void,
    {
      dispatch: SendGridAppDispatch;
      state: SendGridRootState;
      rejectValue: ResponseError;
    }
  >(thunkName, async (_, thunkApi) => {
    try {
      const response = await AuthedHttp.get<Dto>(route, requestOptions);

      if (response.ok) {
        const data = await response.json();
        return {
          data: adapter(data),
          statusCode: response.status,
        };
      }

      const errors = await response.json();

      return thunkApi.rejectWithValue({
        message: errors.errors[0].message,
        statusCode: response.status,
      });
    } catch (err) {
      return thunkApi.rejectWithValue({
        message:
          err.message || `Something went wrong. Failed to fetch ${thunkName}`,
      });
    }
  });
};

export default createGetThunk;
