import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';
import { ActionTypes } from './actionTypes';
import { UpgradeEligibility } from '../types/upgradeEligibility';

const initialState = {
  isUpgradeEligible: false,
  reasons: [],
  error: undefined,
} as UpgradeEligibility;

interface UpgradeEligibilityAction extends Action {
  payload: MakoStateType['upgradeEligibility'];
}

export const upgradeEligibility = (
  state = initialState,
  action: UpgradeEligibilityAction
) => {
  switch (action.type) {
    case ActionTypes.UpgradeEligibilityRequest:
      return {
        ...state,
        error: undefined,
      };
    case ActionTypes.UpgradeEligibilitySuccess:
      return {
        ...state,
        ...action.payload,
      };
    case ActionTypes.UpgradeEligibilityFailure:
      return {
        error: action.payload || 'unexpected error fetching upgrade eligibilty',
      };
    default:
      return state;
  }
};
