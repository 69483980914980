import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { PlanTierFeatures } from '../types/planTierFeatures';

export const Actions = {
  getMarketingCampaignsFeaturesSuccess: (featureList: PlanTierFeatures) =>
    createAction(ActionTypes.GetMarketingCampaignsFeaturesSuccess, featureList),
  getMarketingCampaignsFeaturesFailure: () =>
    createAction(ActionTypes.GetMarketingCampaignsFeaturesFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
