import { Action } from '../types/action';
import { ActionTypes } from './actionTypes';
import { EmailApiSubscription } from '../types/emailApiSubscription';

interface EmailApiPlanAction extends Action {
  payload: EmailApiSubscription;
}

export const emailApiSubscription = (
  state = null,
  action: EmailApiPlanAction
) => {
  switch (action.type) {
    case ActionTypes.GetEmailApiSubscriptionSuccess:
      return action.payload as EmailApiSubscription;
    default:
      return state;
  }
};
