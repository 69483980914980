import { Account } from '../types/user';
import { CreateUserAccountDto } from '../dtos';
import { sanitizer } from '../dtoSanitizer';
import { isVerified } from './utils';

export function accountAdapter(payload: CreateUserAccountDto): Account {
  const dto = { ...payload.account, ...payload.signupStatus };
  const sanitizedAccount = sanitizer(dto);

  const {
    email_complete,
    email_required,
    is_reseller_customer,
    mfa_complete,
    mfa_required,
    payment_complete,
    payment_required,
    profile_complete,
    profile_required,
    whitelabel_complete,
    whitelabel_required,
    payment_method_verification_complete,
    payment_method_verification_required,
    manual_vetting_complete,
    manual_vetting_required,
    ...sanitized
  } = sanitizedAccount;

  const { billing_org } = payload.camAccount;

  const completeAccount = {
    emailComplete: email_complete,
    emailRequired: email_required,
    isResellerCustomer: is_reseller_customer,
    isVerified: isVerified(dto),
    mfaComplete: mfa_complete,
    mfaRequired: mfa_required,
    paymentComplete: payment_complete,
    paymentRequired: payment_required,
    profileComplete: profile_complete,
    profileRequired: profile_required,
    whitelabelComplete: whitelabel_complete,
    whitelabelRequired: whitelabel_required,
    paymentMethodVerificationComplete: payment_method_verification_complete,
    paymentMethodVerificationRequired: payment_method_verification_required,
    manualVettingComplete: manual_vetting_complete,
    manualVettingRequired: manual_vetting_required,
    billingOrg: billing_org,
    ...sanitized,
  } as Account;

  return completeAccount;
}
