import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { catchError, concatMap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
// actions
import { Actions as UserProductsActions } from './actions';
import { Actions as EaseActions } from '../../ease_subscription/actions';
import { Actions as IpsActions } from '../../ips/actions';
import { Actions as TestingCreditsActions } from '../../testing_credits_subscription/actions';
import { Actions as EmailApiSubscriptionActions } from '../../email_api_subscription/actions';
import { Actions as MarketingCampaignsSubscriptionActions } from '../../marketing_campaigns_subscription/actions';
import { Actions as NextInvoiceActions } from '../../next_invoice/actions';
// types
import { ActionTypes } from './actionTypes';
// service
import { getUserProducts } from './service';
import { Cache } from '../cache';
import { CacheKeys } from '../cache/keys';
import { EmailApiSubscription } from '../../types/emailApiSubscription';
import { MarketingCampaignsSubscription } from '../../types/marketingCampaignsSubscription';
import { MakoStateType } from '../../types/makoStateType';
import { EaseSubscription } from '../../ease_subscription/types';
import { EmailValidationActions } from '../../email_validation/actions';

const shouldDeleteEmailApiSubscriptionCache = (
  cachedResponse: EmailApiSubscription,
  apiResponse: EmailApiSubscription
): boolean => {
  if (cachedResponse.downgradePlanId) {
    return apiResponse.downgradePlanId === cachedResponse.downgradePlanId;
  }
  return cachedResponse.planId === apiResponse.planId;
};

const shouldDeleteMarketingCampaignsSubscriptionCache = (
  cachedResponse: MarketingCampaignsSubscription,
  apiResponse: MarketingCampaignsSubscription
): boolean => {
  if (cachedResponse.downgradePlanId) {
    return apiResponse.downgradePlanId === cachedResponse.downgradePlanId;
  }
  return cachedResponse.planId === apiResponse.planId;
};

const shouldDeleteCachedEase = (
  cachedEase: EaseSubscription,
  apiEase: EaseSubscription
): boolean =>
  cachedEase.isPendingDowngrade === apiEase.isPendingDowngrade &&
  cachedEase.hasPurchasedEase === apiEase.hasPurchasedEase;

const userProductsEpics = (
  action$: ActionsObservable<
    ReturnType<typeof UserProductsActions.getUserProducts>
  >,
  state$: StateObservable<MakoStateType>
): Observable<
  | ReturnType<typeof UserProductsActions.getUserProductsSuccess>
  | ReturnType<typeof UserProductsActions.getUserProductsFailure>
  | ReturnType<typeof IpsActions.getIpsSuccess>
  | ReturnType<typeof EaseActions.getEaseSuccess>
  | ReturnType<typeof EmailValidationActions.getEmailValidationSuccess>
  | ReturnType<typeof TestingCreditsActions.getTestingCreditsSuccess>
  | ReturnType<
      typeof EmailApiSubscriptionActions.getEmailApiSubscriptionSuccess
    >
  | ReturnType<
      typeof MarketingCampaignsSubscriptionActions.getMarketingCampaignsSubscriptionSuccess
    >
  | ReturnType<typeof NextInvoiceActions.getNextInvoiceSuccess>
> => {
  return action$.pipe(
    ofType(ActionTypes.GetUserProductsRequest),
    concatMap(() => {
      return from(getUserProducts()).pipe(
        concatMap((data) => {
          // tslint:disable-next-line:max-line-length
          const { userId } = state$.value.accountProfile;
          const emailApiSubscription = Cache.resolveOptimism(
            userId,
            CacheKeys.EmailApiSubscription,
            data.emailApiSubscription,
            shouldDeleteEmailApiSubscriptionCache
          );
          // tslint:disable-next-line:max-line-length
          const marketingCampaignSubscription = Cache.resolveOptimism(
            userId,
            CacheKeys.MarketingCampaignSubscription,
            data.marketingCampaignsSubscription,
            shouldDeleteMarketingCampaignsSubscriptionCache
          );

          const ease = Cache.resolveOptimism(
            userId,
            CacheKeys.Ease,
            data.ease,
            shouldDeleteCachedEase
          );

          return [
            IpsActions.getIpsSuccess(data.ips),
            EaseActions.getEaseSuccess(ease),
            // should cache testing credits
            TestingCreditsActions.getTestingCreditsSuccess(
              data.testingCreditsSubscription
            ),
            EmailApiSubscriptionActions.getEmailApiSubscriptionSuccess(
              emailApiSubscription
            ),
            MarketingCampaignsSubscriptionActions.getMarketingCampaignsSubscriptionSuccess(
              marketingCampaignSubscription
            ),
            NextInvoiceActions.getNextInvoiceSuccess(data.nextInvoice),
            EmailValidationActions.getEmailValidationSuccess(
              data.emailValidation
            ),
            // getUserProductsSuccess should always be last
            UserProductsActions.getUserProductsSuccess(),
          ];
        }),
        catchError(() => {
          // Should this include the other failures as well? ips, ease, testing credits?
          return of(UserProductsActions.getUserProductsFailure());
        })
      );
    })
  );
};

export default [userProductsEpics];
