import { ActionsObservable, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import * as TeammateRequestAccessActions from './actions';
import { ActionTypes } from './actionTypes';
import { teammateRequestAccess } from './service';
import { TeammateRequestAccessScopeDto } from '../dtos';

const teammateRequestAccessEpic = (
  action$: ActionsObservable<
    ReturnType<
      typeof TeammateRequestAccessActions.Actions.teammateRequestAccessRequest
    >
  >
): Observable<
  | ReturnType<
      typeof TeammateRequestAccessActions.Actions.teammateRequestAccessFailure
    >
  | ReturnType<
      typeof TeammateRequestAccessActions.Actions.teammateRequestAccessSuccess
    >
> => {
  return action$.pipe(
    ofType(ActionTypes.TeammateRequestAccessRequest),
    switchMap((action) => {
      return from(teammateRequestAccess(action.payload));
    }),
    map((data: TeammateRequestAccessScopeDto) => {
      if (data.errors) {
        return TeammateRequestAccessActions.Actions.teammateRequestAccessFailure(
          data
        );
      }

      return TeammateRequestAccessActions.Actions.teammateRequestAccessSuccess();
    })
  );
};

export default [teammateRequestAccessEpic];
