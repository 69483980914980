import { ActionsObservable, ofType, StateObservable } from 'redux-observable';
import { Actions } from './actions';
import { Actions as ChangeSubscriptionActions } from '../subscription_changes/actions';
import { ActionTypes } from './actionTypes';
import { Observable, of, from } from 'rxjs';
import { catchError, map, concatMap } from 'rxjs/operators';
import { updateTestingCreditsSubscription } from './service';
import { MakoStateType } from '../types/makoStateType';
import { Actions as UIActions } from '../ui/actions';
import { Alert } from '../types/ui';
import { AccountAndPlanUtils } from '../../pages/AccountAndBilling/utils';

const updateTestingCreditsSubscriptionEpic = (
  action$: ActionsObservable<
    ReturnType<typeof Actions.updateTestingCreditSubscription>
  >,
  state$: StateObservable<MakoStateType>
): Observable<
  | ReturnType<typeof Actions.updateTestingCreditSubscriptionSuccess>
  | ReturnType<typeof Actions.updateTestingCreditSubscriptionFailure>
  | ReturnType<typeof ChangeSubscriptionActions.changeSubscriptionSuccess>
  | ReturnType<typeof ChangeSubscriptionActions.changeSubscriptionFailure>
  | ReturnType<typeof UIActions.createAlert>
> => {
  return action$.pipe(
    ofType(ActionTypes.UpdateTestingCreditSubscription),
    concatMap((action) => {
      return from(
        updateTestingCreditsSubscription(
          action.payload,
          state$.value.testingCreditsPlans,
          state$.value.testingCreditsSubscription
        )
      ).pipe(
        concatMap((updatedSubscription) => {
          let text;

          if (updatedSubscription.isDowngrading) {
            text = `You've initiated a downgrade to ${
              updatedSubscription.downgradeCount
            } Testing Credits. The downgrade will take effect on 
            ${AccountAndPlanUtils.dates.getStartOfNextMonth()} and will be
            reflected on your next bill.`;
          } else {
            text = `Congrats! You have added ${updatedSubscription.purchasedCount} Testing Credits to your plan`;
          }

          const alert = {
            type: 'success',
            text,
          } as Alert;

          return [
            Actions.updateTestingCreditSubscriptionSuccess(updatedSubscription),
            ChangeSubscriptionActions.changeSubscriptionSuccess(),
            UIActions.createAlert(alert),
          ];
        }),
        catchError(() => {
          return [
            Actions.updateTestingCreditSubscriptionFailure(),
            ChangeSubscriptionActions.changeSubscriptionFailure(),
          ];
        })
      );
    })
  );
};

export default [updateTestingCreditsSubscriptionEpic];
