import { RumInitConfiguration } from '@datadog/browser-rum';
import { isFeatureBranch } from '../helpers/navigation';
import APIConfig from '../apiconfig';
import { beforeSend } from './beforeSend';

const { environment, release_version } = APIConfig;
const datadogEnabledEnvs = ['staging', 'production'];

const shouldRunDataDog =
  localStorage.getItem('enable_datadog_rum') === 'true' ||
  (process.env.NODE_ENV === 'production' &&
    !isFeatureBranch() &&
    datadogEnabledEnvs.includes(environment));

export const getDatadogRumOptions = (): RumInitConfiguration | null => {
  if (!shouldRunDataDog) {
    return null;
  }
  return {
    applicationId: 'ef0422c3-b0f4-44a3-b068-059bf2844794',
    clientToken: 'pub73769a4ba8f5d24f8a9c6b71349e569a',
    site: 'datadoghq.com',
    service: 'email-console',
    env: environment,
    version: release_version || '1.0.0',
    sampleRate: 100,
    premiumSampleRate: 100,
    trackInteractions: false,
    beforeSend,
  };
};
