import { EmailApiUsage } from '../types/emailApiUsage';
import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { ResponseError } from '../types/responseError';

export const Actions = {
  getEmailApiUsage: () => createAction(ActionTypes.GetEmailApiUsageRequest),
  getEmailApiUsageSuccess: (emailApiUsage: EmailApiUsage) =>
    createAction(ActionTypes.GetEmailApiUsageSuccess, emailApiUsage),
  getEmailApiUsageFailure: (error: ResponseError) =>
    createAction(ActionTypes.GetEmailApiUsageFailure, error),
};

export type Actions = ActionsUnion<typeof Actions>;
export { Actions as UserProductsActions };
export default Actions;
