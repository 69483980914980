import { UpgradeEligibilityDto } from '../dtos';
import { UpgradeEligibility } from '../types/upgradeEligibility';

export const mapUpgradeEligibility = (
  dto: UpgradeEligibilityDto
): UpgradeEligibility => {
  return {
    isUpgradeEligible: dto.is_upgrade_eligible,
    reasons: dto.reasons,
  } as UpgradeEligibility;
};
