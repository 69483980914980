import USERNAMEERRORS from '../constants/usernameErrors';
import { ResponseError } from '../types/responseError';
import { AccountAndPlanUtils } from '../../pages/AccountAndBilling/utils';

export const getAccountInfoRefinedErrorMessage = (
  serverErrorMessage: string
): string => {
  let refinedErrorMessage = '';

  switch (serverErrorMessage) {
    case 'Input cannot be an empty string.':
      refinedErrorMessage = 'Fields are required.';
      break;
    case 'must contain non-numeric characters':
      refinedErrorMessage = 'Your name must have at least 1 letter.';
      break;
    case 'must be at least 2 characters and at most 50 characters':
      refinedErrorMessage = 'Your name must be between 2 and 50 characters.';
      break;
    case 'must be at least 2 characters and at most 32 characters':
      refinedErrorMessage =
        'Your phone number must have between 2 and 32 characters.';
      break;
    default:
      refinedErrorMessage = serverErrorMessage
        ? serverErrorMessage
        : 'An error has occured. Please try again.';
  }

  return refinedErrorMessage;
};

export const getEmailRefinedErrorMessage = (
  serverErrorMessage: string
): string => {
  let refinedErrorMessage = '';

  switch (serverErrorMessage) {
    case 'email must be at least 2 characters and at most 255 characters':
      refinedErrorMessage =
        'Your email address must have more than 5 and fewer than 256 characters.';
      break;
    case 'invalid email address':
      refinedErrorMessage = 'The email address you entered is not valid.';
      break;
    case 'must be more than 5 characters':
      refinedErrorMessage =
        'Your email address must have more than 5 characters.';
      break;
    default:
      // Other errors include: "This field is required."
      refinedErrorMessage = serverErrorMessage
        ? serverErrorMessage
        : 'An error has occured. Please try again.';
  }

  return refinedErrorMessage;
};

export const getCheckUsernameExistsRefinedErrorMessage = (
  serverErrorMessage: string
) => {
  let refinedErrorMessage: string = '';

  switch (serverErrorMessage) {
    case 'cannot be less than 3 characters':
      refinedErrorMessage = 'Your username must have more than 3 characters.';
      break;
    case 'cannot be more than 64 characters':
      refinedErrorMessage = 'Your username must have fewer than 64 characters.';
      break;
    case 'invalid characters':
      refinedErrorMessage = 'Your username must not have any spaces.';
      break;
    case 'cannot be all numbers':
      refinedErrorMessage = 'Your username must have at least 1 letter.';
      break;
    default:
      refinedErrorMessage = USERNAMEERRORS.USERNAME_DEFAULT;
  }

  return refinedErrorMessage;
};

export const getUsernameRefinedErrorMessage = (serverErrorMessage: string) => {
  let refinedErrorMessage: string = '';
  if (serverErrorMessage === `username has invalid characters, includes ' '`) {
    refinedErrorMessage = 'Your username must not have any spaces.';
  } else if (serverErrorMessage.includes('username has invalid characters')) {
    refinedErrorMessage = 'Your username must not have any invalid characters.';
  } else {
    switch (serverErrorMessage) {
      case 'An error occurred attempting to update your username. Please try again.':
        refinedErrorMessage =
          'An error occurred attempting to update your username. Please try again.';
        break;
      case 'already exists':
        refinedErrorMessage = 'That username is taken. Please try another.';
        break;
      case 'username must be at least 3 characters and at most 64 characters':
        refinedErrorMessage =
          'Your username must have more than 3 and fewer than 64 characters, including at least 1 letter.';
        break;
      case 'username is required':
        refinedErrorMessage = 'This field is required.';
        break;
      default:
        refinedErrorMessage = serverErrorMessage
          ? serverErrorMessage
          : 'An error has occured. Please try again.';
    }
  }
  return refinedErrorMessage;
};

export const getCompanyNameRefinedErrorMessage = (
  serverErrorMessage: string
) => {
  let refinedErrorMessage: string = '';

  if (serverErrorMessage === 'Input cannot be an empty string.') {
    refinedErrorMessage = 'This field is required.';
  } else if (
    serverErrorMessage ===
    'must be at least 2 characters and at most 255 characters'
  ) {
    refinedErrorMessage =
      'Your company name must be at least 2 characters and at most 255 characters.';
  } else {
    refinedErrorMessage = serverErrorMessage
      ? serverErrorMessage
      : 'An error has occurred. Please try again.';
  }

  return refinedErrorMessage;
};

export const getCompanyWebsiteRefinedErrorMessage = (
  serverErrorMessage: string
) => {
  let refinedErrorMessage: string = '';

  if (serverErrorMessage === 'Input cannot be an empty string.') {
    refinedErrorMessage = 'This field is required.';
  } else if (
    serverErrorMessage ===
    'must be at least 2 characters and at most 32 characters'
  ) {
    refinedErrorMessage =
      'Your website must be at least 2 characters and at most 255 characters.';
  } else {
    refinedErrorMessage = serverErrorMessage
      ? serverErrorMessage
      : 'An error has occurred. Please try again.';
  }
  return refinedErrorMessage;
};

const getCompanyAddressInputField = (field: string) => {
  let inputField = field;
  if (field === 'zip') {
    inputField = 'Postal code';
  }
  if (field === 'address') {
    inputField = 'Street Address';
  }
  if (field === 'address2') {
    inputField = 'Street Address 2';
  }
  if (field === 'city' || field === 'country') {
    inputField = AccountAndPlanUtils.transform.capitalizeFirstLetter(field);
  }
  return inputField;
};

export const getCompanyAddressRefinedErrorMessage = (
  serverError: ResponseError
) => {
  const { field, message: serverErrorMessage } = serverError;
  let refinedErrorMessage = '';
  const inputField: string = getCompanyAddressInputField(field || '');
  const lowercasedInputField: string = inputField.toLowerCase();

  switch (serverErrorMessage) {
    case 'Input cannot be an empty string.':
      refinedErrorMessage = `${inputField} field is required.`;
      break;
    case 'must be at least 2 characters and at most 100 characters':
      refinedErrorMessage = `Your ${lowercasedInputField} must have more than 2 and fewer than 100 characters.`;
      break;
    case 'must be at least 2 characters and at most 30 characters':
      refinedErrorMessage = `Your ${lowercasedInputField} must have more than 2 and fewer than 30 characters.`;
      break;

    default:
      refinedErrorMessage = serverErrorMessage
        ? serverErrorMessage
        : 'An error has occurred. Please try again.';
  }

  return refinedErrorMessage;
};
