var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div role=\"provisionNeeded\" class=\"provision-status provision-status-yellow hidden\">\n  Your account is currently being provisioned. You can start exploring, and we'll let you know when you can begin sending email. <a target=\"_blank\" class=\"spaced\" href=\"https://support.sendgrid.com/hc/en-us/articles/200181628\">Learn More.</a>\n</div>\n\n<div role=\"provisionFail\" class=\"provision-status provision-status-orange hidden\">\n    Unfortunately, your account did not pass provisioning. We've sent you an email outlining why your account was not activated. <a target=\"_blank\" class=\"spaced\" href=\"https://support.sendgrid.com/hc/en-us/articles/200181638\">Learn More.</a>\n</div>\n\n<div role=\"provisionDeactivated\" class=\"provision-status provision-status-orange hidden\">\n    Hi. We haven’t seen you in a while. For your protection, we have closed this account. If you would like to use Twilio Sendgrid, please set up a new account <a target=\"_blank\" class=\"spaced\" href=\""
    + container.escapeExpression(((helper = (helper = lookupProperty(helpers,"signupHost") || (depth0 != null ? lookupProperty(depth0,"signupHost") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"signupHost","hash":{},"data":data,"loc":{"start":{"line":10,"column":200},"end":{"line":10,"column":214}}}) : helper)))
    + "\">here.</a>\n</div>\n";
},"useData":true});