import { AuthedHttp } from '../../helpers/AuthedHttp';
import { Routes } from './routes';
import { AddExpertInsightsDto } from '../dtos';
import { AddExpertInsightsRequest } from '../types/addExpertInsights';
import { ResponseError } from '../types/responseError';

export function addExpertInsights({
  recipients,
  subscriberId: subscriber_id,
}: AddExpertInsightsRequest): Promise<AddExpertInsightsDto> {
  const addExpertInsightsRequestBody = { recipients, subscriber_id };

  return AuthedHttp.post<AddExpertInsightsDto>(
    `${Routes.ExpertInsights}`,
    addExpertInsightsRequestBody
  )
    .then((res) => {
      if (res.ok) {
        return {} as AddExpertInsightsDto;
      }
      throw new Error('failed to add expert insights');
    })
    .catch(() => {
      return {
        errors: [
          {
            message: 'Failed to add expert insights due to some reason!',
          },
        ] as ResponseError[],
      };
    });
}
