const $ = require("jquery");

module.exports = {
  init() {
    // eslint-disable-next-line consistent-return
    $(document).on("click", "a[href^='#']", (event) => {
      const href = $(event.currentTarget).attr("href");

      if (
        !event.altKey &&
        !event.ctrlKey &&
        !event.metaKey &&
        !event.shiftKey
      ) {
        event.preventDefault();
        const url = href.replace(/^\//, "").replace("#!/", "");
        window.EventBus.trigger("navigate", url);
        return false;
      }
    });

    // check mako routes from tiara
    $(document).on("click", "a[data-mako-route]", (event) => {
      if (event.bubbles) {
        // prevent clicking through tiara's account menu to page links
        const route = $(event.currentTarget).attr("data-mako-route");
        window.EventBus.trigger("navigate", route);
      }
      event.preventDefault();
      return false;
    });

    $(document).on("blur change", ".input-text-wrap input", (event) => {
      const $input = $(event.currentTarget);
      const $parent = $input.parent();
      $parent.removeClass("is-focused");
      if ($input.val()) {
        $parent.addClass("has-value");
      } else {
        $parent.removeClass("has-value");
      }
    });

    $(document).on("focus", ".input-text-wrap input", (event) => {
      $(event.currentTarget).parent().addClass("is-focused");
    });

    $(document).on("blur", ".textarea-wrap textarea", (event) => {
      $(event.currentTarget).parent().removeClass("is-focused");
      if ($(event.currentTarget).val()) {
        $(event.currentTarget).parent().addClass("has-value");
      } else {
        $(event.currentTarget).parent().removeClass("has-value");
      }
    });

    $(document).on("focus", ".textarea-wrap textarea", (event) => {
      $(event.currentTarget).parent().addClass("is-focused");
    });

    // automatically expand the text area
    $(document).on("input change", ".textarea-wrap textarea", (event) => {
      const maxHeight =
        $(event.currentTarget).data("max-height") !== undefined
          ? $(event.currentTarget).data("max-height")
          : 500;
      $(event.currentTarget).css(
        "height",
        `${Math.min(
          $(event.currentTarget).prop("scrollHeight") + 1,
          maxHeight
        )}px`
      );
    });

    $(document).click((event) => {
      const $container = $("[role=container]");
      const $target = $(event.target);
      const $dropdowns = $container.find("[data-dropdown-toggle]");

      // if a dropdown is already active, close all dropdown-menus
      if ($dropdowns.hasClass("is-active")) {
        // don't close when clicking a menu item like 'search'
        if ($target.closest("[data-dropdown-no-close]").length === 0) {
          $dropdowns.removeClass("is-active");
        }
        // if they're clicking an inactive dropdown in tiara, open it
      } else if ($target.closest($container).length > 0) {
        $target.closest("[data-dropdown-toggle]").addClass("is-active");
      }
    });

    $(document).on("click", ".btn-selectable", function () {
      const $this = $(this);
      $this
        .siblings(".btn-selectable.is-active")
        .removeClass("is-active")
        .find("input:radio")
        .removeAttr("checked");
      $this
        .addClass("is-active")
        .find("input:radio")
        .prop("checked", true)
        .trigger("change");
    });
  },

  checkInputFields() {
    const inputSelector =
      ".input-text-wrap input[type='text'], " +
      ".input-text-wrap input[type='password'], " +
      ".input-text-wrap input[type='number'], " +
      ".input-text-wrap input[type='email'], " +
      ".input-text-wrap input[type='tel']";

    $(inputSelector).each((index, input) => {
      const $input = $(input);
      const $parent = $input.parent();
      if ($input.val()) {
        $parent.addClass("has-value");
      } else {
        $parent.removeClass("has-value");
      }
    });
  },

  select2Everywhere() {
    $("select")
      .not("[data-custom='true']")
      .each((i, select) => {
        const $select = $(select);
        const params = {
          minimumResultsForSearch: Infinity,
        };
        if ($select.data("placeholder")) {
          params.placeholder = $select.data("placeholder");
        }
        if ($select.data("searchable")) {
          // forces the search input field to render
          params.minimumResultsForSearch = 0;
        }

        $(select)
          .select2(params)
          .focus(function () {
            $(this).select2("open");
          });
      });
  },

  tooltipify() {
    $(".input-info-tooltip-container").each(function () {
      const $container = $(this);
      if (!$container.data("initialized")) {
        const $icon = $container.find(".input-info-icon");
        const $content = $container.find(".tooltip-js-content");
        const pos = $container.data("pos") || "up"; // defaults to 'up'

        const hoverFunc = function () {
          $icon.addClass("is-hovered");

          if (pos === "left" || pos === "right") {
            $content.css("top", `${$content.outerHeight() / -2 + 5}px`);
          } else {
            $content.css("left", `${$content.outerWidth() / -2 + 4}px`);
          }

          $content.addClass("is-visible");
        };

        const unhoverFunc = function () {
          $icon.removeClass("is-hovered");
          $content.removeClass("is-visible");
        };

        // apply hover listeners
        $icon.on("mouseover", hoverFunc).on("mouseout", unhoverFunc);

        $content.on("mouseover", hoverFunc).on("mouseout", unhoverFunc);

        // don't needlessly re-initialize
        $container.data("initialized", "true");
      }
    });
  },
};
