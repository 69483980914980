const Marionette = require("backbone.marionette");
const qs = require("qs");

const SuppressionsListView = require("./views/suppressions_list_view");
const SuppressionsLayoutView = require("./views/suppressions_layout_view.js");
const SuppressionsHeaderView = require("./views/suppressions_header_view.js");

const SuppressionsCollection = require("./collections/suppressions_collection");

// route helpers
const RouterHelpers = require("./router_helpers");

module.exports = Marionette.AppRouter.extend({
  routes: {
    suppressions: "redirectSuppressions",
    "suppressions/advanced_suppression_manager":
      "suppressionsUnsubscribeGroups",
    "suppressions/group_unsubscribes": "suppressionsGroupUnsubscribes",
  },

  initialize() {
    this.errorRouter = this.getOption("errorRouter");
    this.authController = this.getOption("authController");
  },

  before(route) {
    RouterHelpers.before(this, route);
  },

  onRoute() {
    RouterHelpers.onRoute();
  },

  suppressionsGroupUnsubscribes(queryParams) {
    import("routes/suppressions_group_unsubscribes").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
        queryParams,
      });
    });
  },

  suppressionsUnsubscribeGroups() {
    import("routes/suppressions_unsubscribe_groups").then((view) => {
      view.default({
        errorRouter: this.errorRouter,
        layoutController: this.getOption("layoutController"),
      });
    });
  },

  redirectSuppressions() {
    window.EventBus.trigger("navigate", "suppressions/global_unsubscribes");
  },

  createSuppressionsRoute(params) {
    const { type, title, showReason } = params;
    const queryParams = qs.parse(params.queryParams);

    const suppressionsCollection = new SuppressionsCollection();
    suppressionsCollection.type = type;

    const suppressionsListView = new SuppressionsListView({
      collection: suppressionsCollection,
      templateHelpers: { showReason },
    });
    const suppressionsHeaderView = new SuppressionsHeaderView({
      collection: suppressionsCollection,
      templateHelpers: { title },
    });

    const suppressionsLayoutView = new SuppressionsLayoutView({
      suppressionsListView,
      suppressionsHeaderView,
    });

    this.getOption("layoutController").display(suppressionsLayoutView);

    if (queryParams.page) {
      suppressionsCollection.offset =
        (queryParams.page - 1) * suppressionsCollection.limit;
    }

    if (queryParams.email) {
      suppressionsCollection.searchEmail = queryParams.email;
    }

    if (queryParams.start_time) {
      suppressionsCollection.startTime = queryParams.start_time;
    }

    if (queryParams.end_time) {
      suppressionsCollection.endTime = queryParams.end_time;
    }

    suppressionsCollection.fetch();

    window.EventBus.trigger("setActiveOnNavigate", `suppressions/${type}`);
  },
});
