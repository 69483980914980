import { ActionsObservable, ofType } from 'redux-observable';
import * as UpdatePlanActions from './actions';
import { from, Observable, of } from 'rxjs';
import { ActionTypes } from './actionTypes';
import { switchMap, map } from 'rxjs/operators';
import { updatePlan as updatePlanService } from './service';
import { UpdatedPlan } from '../types/updatedPlan';

const updatePlan = (
  action$: ActionsObservable<
    ReturnType<typeof UpdatePlanActions.Actions.updatePlan>
  >
): Observable<
  | ReturnType<typeof UpdatePlanActions.Actions.updatePlanSuccess>
  | ReturnType<typeof UpdatePlanActions.Actions.updatePlanFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.UpdatePlanRequest),
    switchMap((action) => {
      return from(updatePlanService(action.payload));
    }),
    map((updatedPlan: UpdatedPlan) => {
      if (updatedPlan.isUpdated) {
        return UpdatePlanActions.Actions.updatePlanSuccess(updatedPlan);
      }
      return UpdatePlanActions.Actions.updatePlanFailure(updatedPlan);
    })
  );
};

export default [updatePlan];
