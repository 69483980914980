import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { MarketingCampaignsPlan } from '../types/marketingCampaignsPlan';

export const Actions = {
  getMarketingCampaignsPlansSuccess: (
    MarketingCampaignsPlans: MarketingCampaignsPlan[]
  ) =>
    createAction(
      ActionTypes.GetMarketingCampaignsPlansSuccess,
      MarketingCampaignsPlans
    ),
  getMarketingCampaignsPlansFailure: () =>
    createAction(ActionTypes.GetMarketingCampaignsPlansFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
