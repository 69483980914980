import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { ResponseError } from '../../helperTypes/responseError';
import { SendGridRootState, SendGridAppDispatch } from './sendgridAppStore';
import { ResponseBody } from '../../helpers/AuthedHttp';
import { GenericNetworkSlice, NetworkState } from './sliceNetworkTypes';

export interface ScopesDto {
  scopes: string[];
}

export type ScopeStore = GenericNetworkSlice<ScopesDto>;

const initialScopeState = {
  networkState: NetworkState.Unrequested,
} as ScopeStore;

export const getScopesFromTiara = createAsyncThunk<
  string[],
  ResponseBody<ScopesDto>,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('GET_SCOPES_FROM_TIARA', async (args, thunkApi) => {
  try {
    const scopes = await args;

    if (scopes.ok) {
      const response = await scopes.json();

      return response.scopes;
    }

    throw new Error('Network Response be Janky');
  } catch {
    return thunkApi.rejectWithValue({
      message: 'Network Level Error',
    } as ResponseError);
  }
});

export const scopeSlice = createSlice({
  name: 'scopes',
  initialState: initialScopeState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getScopesFromTiara.pending, (state, action) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(getScopesFromTiara.fulfilled, (state, action) => {
      state.networkState = NetworkState.Success;

      if (state.networkState === NetworkState.Success) {
        state.data = {
          scopes: action.payload,
        };
      }
    });
    builder.addCase(getScopesFromTiara.rejected, (state, action) => {
      state.networkState = NetworkState.Error;

      if (state.networkState === NetworkState.Error) {
        state.errorMessage = action?.payload?.message ?? 'Unknown Error';
      }
    });
  },
});
