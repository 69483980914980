const CONSTANTS = require("./constants");
const PUBLIC_ROUTES = CONSTANTS.PUBLIC_ROUTES;
const StatsLoadingView = require("./views/loading_view");
const StatsLoadingLayoutView = require("./views/loading_layout_view");
const StatsEmptyDataView = require("./views/stats/stats_empty_data_view");
const SignupStatus = require("./models/signup_status_model");
const {
  getIsSetup2FARequired,
  setIs2FASetupRequired,
} = require("./helpers/setup2FARequired");
const {
  isOnSetup2FARequiredCheckpoint,
  Setup2FARequiredCheckpoint,
} = require("./helpers/setup2FARequiredCheckpoint");
const {
  getIsPasswordResetRequired,
} = require("./helpers/passwordResetRequired");
const { AuthedHttp } = require("./helpers/AuthedHttp");
const qs = require("qs");
const $ = require("jquery");
const APIConfig = require("apiconfig");
const { analytics } = require("@sendgrid/bloodhound");
const { sendgridAppStore } = require("./state/store/sendgridAppStore");
const { resetMocks } = require("./state/mocks/mockslice");
const {
  updateMakoAuthCookieHeader,
} = require("src/helpers/default_json_headers");
const Cookies = require("js-cookie");
const { show } = require("src/helpers/modal");
const { shouldShowLogout } = require("src/helpers/shouldShowLogout");

module.exports = {
  // eslint-disable-next-line consistent-return
  before(router, route) {
    // Turn off the pong game event listener in case you redirect from
    // the pong game to another route while it is still playing
    if (window.Reqres) {
      window.Reqres.request("stopPongGame");
    }

    window.SendGridTiara.bannerReady().finally(() => {
      this.toggleSenderVerificationBanner(route, PUBLIC_ROUTES);
      this.toggleSendFirstEmailBanner(route, PUBLIC_ROUTES);
      this.toggleVerificationStartBanner(route, PUBLIC_ROUTES);
      this.toggleOnboardingChoosePlanBanner(route, PUBLIC_ROUTES);
      this.toggleFinishAccountSetupBanner(route, PUBLIC_ROUTES);
      this.togglePaymentDeclinedBanner(route, PUBLIC_ROUTES);
      this.toggleSuspendedBanner();
    });

    const authController = router.getOption("authController");

    /* This forces the page to refresh if we have any trackers. Emails and other PII's in the querystring
    are typically leaked to trackers like Facebook, GTM, or FE stat aggregation like New Relic DO NOT DELETE */
    if (!window.OnlyDenylist && !window.twilio) {
      if (
        /** If the route is in the denylist and Mako has been initialized with 3rd party scripts reload
         * If the route is in not in the denylist and mako has been initialized without 3rd party scripts reload
         */
        (window.urlDenylist.includes(route) && !window.denylistInit) ||
        (!window.urlDenylist.includes(route) && window.denylistInit)
      ) {
        window.location.reload();
      }
    }
    if (PUBLIC_ROUTES.indexOf(route) < 0) {
      if (!authController.checkAuthToken()) {
        const shouldShowLogoutPage = shouldShowLogout();
        if (shouldShowLogoutPage) {
          authController.logout();
          return false;
        }
        this.redirectToLoginAppPageRedirect();
        return false;
      }
      // If your logged in with an Auth Token make
      // sure the authorization header is always set.
      updateMakoAuthCookieHeader();

      const isSetup2FARequired = getIsSetup2FARequired();
      if (isSetup2FARequired) {
        const isOnSetup2FARequiredEmailCheckpoint = isOnSetup2FARequiredCheckpoint(
          Setup2FARequiredCheckpoint.EMAIL
        );
        const acceptListSetup2FARequiredEmailCheckpoint = [
          "2fa_email_checkpoint",
        ];
        const acceptList2FA = ["security_checkup"];
        const isInOauth = route.includes("oauth");
        const isInSubuserAccess = route.includes("subuser/access");

        // For the scenario where a user may be switching between accounts that set off the 2FA required flags
        // and then logged into an SSO teammate that shouldn't fall into the email checkpoint flow but stay in the subuser access page,
        // We can clear the setup 2fa required local storage value so they won't ever get into these conditions on subsequent route visits
        if (isInSubuserAccess) {
          setIs2FASetupRequired(false);
        }

        if (
          isOnSetup2FARequiredEmailCheckpoint &&
          !acceptListSetup2FARequiredEmailCheckpoint.includes(route) &&
          !isInOauth &&
          !isInSubuserAccess
        ) {
          window.location.href = `${APIConfig.mako_host}/2fa_email_checkpoint`;
          return false;
        }

        if (
          !isOnSetup2FARequiredEmailCheckpoint &&
          !acceptList2FA.includes(route) &&
          !isInSubuserAccess
        ) {
          window.location.href = `${APIConfig.mako_host}/security_checkup`;
          return false;
        }
      }

      const acceptListPasswordReset = [
        "password_reset_required",
        "validate_2fa",
        "security_checkup",
      ];

      if (
        getIsPasswordResetRequired() &&
        !acceptListPasswordReset.includes(route)
      ) {
        window.location.href = `${APIConfig.mako_host}/password_reset_required`;
        return false;
      }

      window.track("Finished starting mako (authenticated)");
    } else {
      window.track("Finished starting mako (public)");
    }
    $("[data-role=main-loader-mako]").fadeOut();

    // hook so automation can know the page is transitioning
    $("body").attr("data-mako-ready", "starting");

    const signupStatus = new SignupStatus(
      window.Reqres.request("signupStatus")
    );
    if (
      route.indexOf("/account_details") !== 0 &&
      signupStatus.needsToStartProfile()
    ) {
      const unifiedUser = Cookies.get(CONSTANTS.UNIFIED_USER_INFO_COOKIE_NAME);
      if (unifiedUser === undefined) {
        // need to do a hard refresh since we don't want tiara anymore
        window.location.href = `${APIConfig.signup_host}/account_details?s2s=true`;
      } else {
        window.location.href = `${APIConfig.signup_host}/unified_login/account_details`;
      }
    }

    const params = qs.parse(window.location.search.substr(1)); // remove initial '?'

    // show s2s banner UNLESS we just finished the profile and are on the guide page
    if (!(route === "guide" && params.from === "profile")) {
      $("#hide-s2s-banner-style").remove();
    }

    // when loading mako in an iframe, pass noFeedback=true to slay WebEngage
    if (params.noFeedback !== "true") {
      $("#hide-webengage").remove();
    }
  },

  redirectToLoginAppPageRedirect() {
    window.EventBus.trigger("redirectToLoginAppPageRedirect");
  },

  toggleSenderVerificationBanner(route, publicRoutes) {
    const antiSpoofBannerRouteDenylist = [
      "", // Dashboard - should be redirected to guide if user should complete this step
      "guide",
      ...publicRoutes,
    ];
    // We should not show the sender verification banner on all the sender_auth routes
    const isSenderAuthRoute = route.includes("settings/sender_auth");
    const isRouteInAntiSpoofDenylist =
      antiSpoofBannerRouteDenylist.includes(route) || isSenderAuthRoute;
    const senderVerificationBanner = $("[data-role=antiSpoofBanner]");
    this.toggleBanner(senderVerificationBanner, !isRouteInAntiSpoofDenylist);
  },

  toggleSendFirstEmailBanner(route, publicRoutes) {
    const denyList = ["", ...publicRoutes];
    // Hide this on the guide and the integrate pages (guide/integrate/**)
    const isGuideRoute = route.includes("guide");
    const sendFirstEmailBanner = $("[data-role=sendFirstEmailBanner]");
    const showBanner = !denyList.includes(route) && !isGuideRoute;
    this.toggleBanner(sendFirstEmailBanner, showBanner);
  },

  toggleVerificationStartBanner(route, publicRoutes) {
    const denyList = ["", ...publicRoutes];
    const isGuideRoute = route.includes("guide");
    const sendFirstEmailBanner = $("[data-role=verificationStartBanner]");
    const showBanner = !denyList.includes(route) && !isGuideRoute;
    this.toggleBanner(sendFirstEmailBanner, showBanner);
  },

  toggleOnboardingChoosePlanBanner(route, publicRoutes) {
    const denyList = [
      "",
      "guide",
      "account/billing/choose_plan",
      "account/billing/choose_plan_marketing_campaigns",
      ...publicRoutes,
    ];
    const choosePlanBanner = $("[data-role=onboardingChoosePlanBanner]");
    const showBanner = !denyList.includes(route);
    this.toggleBanner(choosePlanBanner, showBanner);
  },

  toggleFinishAccountSetupBanner(route, publicRoutes) {
    // Hide banners on pages where account setup steps (Domain Auth, MFA, Billing Payment)
    // are being completed or on the setup guide.
    const denyList = [
      "",
      "guide",
      "settings/auth",
      "account/billing",
      ...publicRoutes,
    ];
    const isDomainAuthRoute = route.includes("settings/sender_auth/domain");
    const choosePlanBanner = $(
      "[data-role=onboardingFinishAccountSetupBanner]"
    );
    const showBanner = !denyList.includes(route) && !isDomainAuthRoute;
    this.toggleBanner(choosePlanBanner, showBanner);
  },

  togglePaymentDeclinedBanner(route, publicRoutes = []) {
    const denyList = ["account/billing", "/account/billing", ...publicRoutes];
    const choosePlanBanner = $("[data-role=paymentDeclinedBanner]");
    const showBanner = !denyList.includes(route);
    this.toggleBanner(choosePlanBanner, showBanner);
  },

  toggleSuspendedBanner() {
    const signupStatus = new SignupStatus(
      window.Reqres.request("signupStatus")
    );

    if (signupStatus.needsToCompleteMfa()) {
      AuthedHttp.get(`${APIConfig.host}user/status`).then((response) =>
        response
          .json()
          .then((data) => {
            const { status, prompt_appeal: promptAppeal } = data;
            if (status === "suspended") {
              if (promptAppeal) {
                $("[data-role=consumerTrustSuspendedBanner]").removeClass(
                  "hidden"
                );
                return;
              }
              $("[data-role=complianceSuspendedBanner]").removeClass("hidden");
            }
          })
          .catch((error) => {
            console.error(error);
          })
      );
    }
  },

  toggleBanner(banner, shouldShow) {
    if (!banner) {
      return;
    }
    if (!shouldShow) {
      banner.addClass("hidden");
    } else {
      banner.removeClass("hidden");
    }
  },

  wrapInLoadingView(view, collection, loadingOptions) {
    const options = loadingOptions || {};
    options.emptyView = options.emptyView || StatsEmptyDataView;
    options.emptyViewOptions = options.emptyViewOptions || {};
    if (options.emptyView === StatsEmptyDataView) {
      options.emptyViewOptions.messageText =
        options.emptyViewOptions.messageText || "No data to display";
      options.emptyViewOptions.subMessageText =
        options.emptyViewOptions.subMessageText ||
        "The date range selected contains no data";
    }

    const loadingView = new StatsLoadingView();
    // eslint-disable-next-line new-cap
    const emptyView = new options.emptyView(options.emptyViewOptions);

    const loadingLayout = new StatsLoadingLayoutView({
      mainView: view,
      loadingView,
      emptyView,
      collection,
    });

    return loadingLayout;
  },

  onRoute() {
    analytics.page();
    if (process.env.NODE_ENV === "development") {
      sendgridAppStore.dispatch(resetMocks());
    }
  },
};
