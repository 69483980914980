import { createAsyncThunk, createSlice, unwrapResult } from '@reduxjs/toolkit';
import { SendGridAppDispatch, SendGridRootState } from './sendgridAppStore';
import { ResponseError } from '../../helperTypes/responseError';
import { RequestOptions, ResponseBody } from '../../helpers/AuthedHttp';
import {
  DtoWrapper,
  GenericNetworkSlice,
  NetworkState,
  Unrequested,
} from './sliceNetworkTypes';
import objectToQueryParam from '../../helpers/objectToQueryParams';
import { SSOTeammateSubuserPermissionType } from '../../pages/Teammates/components/SSOTeammateSubusersTable';
import createGetThunk from './createGetThunk';

export const Routes = {
  subuserAccess: (username: string) => `teammates/${username}/subuser_access`,
  subusersCursor: 'subusers_cursor',
};

enum SubuserAccessErrorMessages {
  GenericError = 'Something went wrong. Failure to fetch subuser access',
  FailedToLoadAdditionalSubbusers = 'Something went wrong. Failure to fetch additional subusers',
  FetchInitialSubusersFirst = 'Please fetch initial subusers prior to fetching next subusers',
  FailedToFetchUsername = 'Failed to fetch username. Username needed to fetch subuser access',
  FailedToFetchProfile = 'Failed to fetch user profile',
  FailedToFetchSubuserAccess = 'Failed to fetch subuser access',
  NoSubuserAccessPermissions = 'Failure to fetch subuser access. Does not have permissions',
}

const subuserAdapter = (subusers: SubuserDto[]): Subuser[] =>
  subusers.map((subuser) => {
    const { id, username, email, disabled, permission_type } = subuser;
    return {
      id,
      username,
      email,
      isDisabled: disabled,
      permissionType: permission_type,
    };
  });

export const subuserAccessAdapter = (dto: SubuserAccessDto): SubuserAccess => {
  return {
    hasRestrictedSubuserAccess: dto.has_restricted_subuser_access,
    subuserAccess: subuserAdapter(dto.subuser_access),
    nextSubuserAccess: dto._metadata.next_params,
  };
};

export const subusersCursorAdapter = (
  dto: SubusersCursorDto
): SubuserAccess => {
  return {
    hasRestrictedSubuserAccess: false,
    subuserAccess: subuserAdapter(dto.subusers),
    nextSubuserAccess: dto._metadata.next_params,
  };
};

interface SubuserDto {
  id: number;
  username: string;
  email: string;
  disabled: boolean;
  permission_type?: SSOTeammateSubuserPermissionType;
}
export interface Subuser {
  id: number;
  username: string;
  email: string;
  isDisabled: boolean;
  permissionType?: SSOTeammateSubuserPermissionType;
}

export interface NextParams {
  limit?: number;
  after_subuser_id?: number;
  username?: string;
}

export interface SubuserAccessDto {
  has_restricted_subuser_access: boolean;
  subuser_access: SubuserDto[];
  _metadata: {
    next_params: NextParams;
  };
}

export interface SubusersCursorDto {
  subusers: SubuserDto[];
  _metadata: {
    next_params: NextParams;
  };
}

export interface SubuserAccess {
  hasRestrictedSubuserAccess: boolean;
  subuserAccess: Subuser[];
  nextSubuserAccess: NextParams;
}

export type SubuserAccessStore = GenericNetworkSlice<SubuserAccess>;

const initialState: Unrequested = {
  networkState: NetworkState.Unrequested,
  data: null,
};

const createSubuserAccessThunk = (
  route: string,
  requestOptions?: RequestOptions
) =>
  createGetThunk<SubuserAccessDto, SubuserAccess>(
    'subuserAccess/getSubuserAccess',
    route,
    subuserAccessAdapter,
    requestOptions
  );
const createSubusersCursorThunk = (
  route: string,
  requestOptions?: RequestOptions
) =>
  createGetThunk<SubusersCursorDto, SubuserAccess>(
    'subusersCursor/getSubusersCursor',
    route,
    subusersCursorAdapter,
    requestOptions
  );

export const createHandleGetSubuserAccessThunk = (name: string) =>
  createAsyncThunk<
    DtoWrapper<SubuserAccess>,
    {
      getNext?: boolean;
      params?: NextParams;
    },
    {
      dispatch: SendGridAppDispatch;
      state: SendGridRootState;
      rejectValue: ResponseError;
    }
  >(name, async ({ getNext, params }, thunkApi) => {
    const {
      nonImpersonatedProfile,
      nonImpersonatedUsername,
      subuserAccess,
    } = thunkApi.getState();

    try {
      if (getNext && subuserAccess.networkState !== NetworkState.Success) {
        return thunkApi.rejectWithValue({
          message: SubuserAccessErrorMessages.FetchInitialSubusersFirst,
        });
      }

      if (nonImpersonatedProfile.networkState !== NetworkState.Success) {
        return thunkApi.rejectWithValue({
          message: SubuserAccessErrorMessages.FailedToFetchProfile,
        });
      }

      // We omit the impersonation header on this request since we want the request to be made
      // by the parent account, even if they are currently impersonating a subuser so that
      // they can see other subusers they can impersonate
      const requestOptions = {
        omitImpersonationHeader: true,
      };
      const queryParams =
        getNext && subuserAccess.networkState === NetworkState.Success
          ? subuserAccess.data.nextSubuserAccess
          : params;
      const queryString = objectToQueryParam({ ...queryParams });

      if (nonImpersonatedProfile.data.type === 'sso_teammate') {
        if (nonImpersonatedUsername.networkState !== NetworkState.Success) {
          return thunkApi.rejectWithValue({
            message: SubuserAccessErrorMessages.FailedToFetchUsername,
          });
        }

        const subuserAccessRoute = `${Routes.subuserAccess(
          nonImpersonatedUsername.data.username
        )}${queryString}`;
        const subuserAccessThunk = createSubuserAccessThunk(
          subuserAccessRoute,
          requestOptions
        );

        const result = await thunkApi.dispatch(subuserAccessThunk());
        return unwrapResult(result);
      } else {
        const subusersCursorRoute = `${Routes.subusersCursor}${queryString}`;
        const subusersCursorThunk = createSubusersCursorThunk(
          subusersCursorRoute,
          requestOptions
        );

        const response = await thunkApi.dispatch(subusersCursorThunk());
        return unwrapResult(response);
      }
    } catch {
      return thunkApi.rejectWithValue({
        message: SubuserAccessErrorMessages.FailedToFetchSubuserAccess,
      });
    }
  });

export const handleGetSubuserAccess = createHandleGetSubuserAccessThunk(
  'subuserAccess/handleGetSubuserAccess'
);

export const getSubuserAccessFromTiara = createAsyncThunk<
  DtoWrapper<SubuserAccess>,
  Promise<ResponseBody<SubuserAccessDto>>,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>(
  'subuserAccess/getSubuserAccessFromTiara',
  async (subuserAccessPromise, thunkApi) => {
    try {
      const response = await subuserAccessPromise;

      if (response.ok) {
        const data = await response.json();
        return {
          statusCode: response.status,
          data: subuserAccessAdapter(data),
        };
      }

      if (response.status === 403) {
        return thunkApi.rejectWithValue({
          message: SubuserAccessErrorMessages.NoSubuserAccessPermissions,
          statusCode: response.status,
        });
      }

      const errors = await response.json();

      return thunkApi.rejectWithValue({
        message: errors.errors[0].message,
        statusCode: response.status,
      });
    } catch {
      return thunkApi.rejectWithValue({
        message: SubuserAccessErrorMessages.GenericError,
      });
    }
  }
);

export const subuserAccessSlice = createSlice<SubuserAccessStore, any>({
  name: 'subuserAccess',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getSubuserAccessFromTiara.pending, (state) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(getSubuserAccessFromTiara.fulfilled, (_, action) => ({
      networkState: NetworkState.Success,
      statusCode: action.payload.statusCode,
      data: action.payload.data,
    }));
    builder.addCase(getSubuserAccessFromTiara.rejected, (_, action) => ({
      networkState: NetworkState.Error,
      statusCode: action.payload?.statusCode ?? 0,
      errorMessage:
        action.payload?.message ?? SubuserAccessErrorMessages.GenericError,
      data: null,
    }));
    builder.addCase(handleGetSubuserAccess.fulfilled, (state, action) => {
      if (state.networkState === NetworkState.Success) {
        const allSubuserAccess = [
          ...state.data.subuserAccess,
          ...action.payload.data.subuserAccess,
        ];
        return {
          ...state,
          statusCode: action.payload.statusCode,
          data: {
            ...state.data,
            nextSubuserAccess: action.payload.data.nextSubuserAccess,
            subuserAccess: allSubuserAccess,
          },
        };
      }

      return {
        networkState: NetworkState.Success,
        statusCode: action.payload.statusCode,
        data: { ...action.payload.data },
      };
    });
  },
});
