import mapKeys from 'lodash/mapKeys';
import camelCase from 'lodash/camelCase';
import { CamelCasedProperties } from 'type-fest';
import { isPlainObject, SerializedError } from '@reduxjs/toolkit';
import { StartQueryActionCreatorOptions } from '@reduxjs/toolkit/dist/query/core/buildInitiate';
import { GraphQLServerError } from './graphqlBaseQuery';

import { sendgridAppStore } from '../sendgridAppStore';

export const UnknownError =
  'An unknown network error occurred. Please try again in a few minutes. If the problem persists, please contact support.';

export type RtkQueryDataInjection = {
  data: any;
  error: boolean;
  status: number;
};

/**
 * If this key is present in the query, the value of this key is
 * assumed to be data fetched from tiara and the developer wants to
 * hydrate the related endpoint with that data.
 *
 * The data assigned to this key should be the result of tiaraDefToRtkQueryDataInjection
 * from ./utils.ts
 */
export const dataInjectionKey = 'dataInjection';

/**
 * You can not pass functions into rtk since they are not serializable (an issue for the cache key).
 * So we deconstruct the deferred stuff from tiara here first before it's
 * passed as an input/arg to rtk query.
 *
 * tiaraDef is in form like:
 *   $.Deferred().resolve(<some data>) case:
 *     [<some data>]
 *   Request success case:
 *     [<parsed response body (json)>, <jquery textStatus>, <jqXHR object>]
 *   Request error/fail case:
 *     [<jqXHR object>, <jquery textStatus>, <generic text err message, like "Internal Server Error" for 500>]
 */
export const tiaraDefToRtkQueryDataInjection = (
  tiaraDef: any
): RtkQueryDataInjection => {
  // Some extra run time safety in case something goes haywire w/ tiara
  if (!Array.isArray(tiaraDef)) {
    return {
      data: UnknownError,
      error: true,
      status: 0,
    };
  }

  // Sometimes tiara passes in a $.Deferred().resovle({}) instead of a 3 item array with a jqxhr
  if (tiaraDef.length === 1) {
    return {
      data: tiaraDef[0] ?? {},
      error: false,
      status: 0,
    };
  }

  // Request was successful, jqXhr is in last position
  if (tiaraDef[1] === 'success') {
    return {
      data: tiaraDef[0] ?? {},
      error: false,
      status: tiaraDef[2]?.status,
    };
  }

  // Rquest was successful, but returned no content (204)
  if (tiaraDef[1] === 'nocontent') {
    return {
      data: {},
      error: false,
      status: tiaraDef[2]?.status,
    };
  }

  // Request errored and now jqXhr is in first position.
  // Get the text status like "Internal Server Error" if it exists,
  // otherwise use a generic error message
  let errorData = tiaraDef[2] ?? UnknownError;
  // Then if there is a custom error object or message from the server,
  // use that instead
  if (typeof tiaraDef[0] === 'object') {
    if ('responseJSON' in tiaraDef[0]) {
      errorData = tiaraDef[0]?.['responseJSON'];
    } else if ('responseText' in tiaraDef[0]) {
      errorData = tiaraDef[0]?.['responseText'];
    }
  }
  return {
    data: errorData,
    error: true,
    status: tiaraDef[0]?.status ?? 0,
  };
};

/** The type of e.g. userEmailApi.endpoints[Endpoints.fetchUserEmail].initiate */
export type EndpointInitiateFunc = (
  arg: void | {
    dataInjection?: RtkQueryDataInjection | undefined;
  },
  options?: StartQueryActionCreatorOptions | undefined
) => any;

/**
 * Given an rtkQuery initiate function from a rtkQuery endpoint and a data object inject,
 * injects the data and status (i.e. success or failure and status code) into the rtkQuery endpoint.
 * When used in a test, "await" the function to be sure the the data has been injected before continuing.
 */
export const injectDataIntoRtkQuery = async (
  initiate: EndpointInitiateFunc,
  data: RtkQueryDataInjection,
  store: typeof sendgridAppStore
) => {
  store.dispatch(initiate({ [dataInjectionKey]: data }));
};

/**
 * Given an rtkQuery initiate function from a rtkQuery endpoint and a tiara deferred object,
 * injects the tiara deferred's data and status (i.e. success or failure and status code)
 * into the rtkQuery endpoint.
 */
export const injectTiaraDefsIntoRtkQuery = (
  initiatesAndDefs: [EndpointInitiateFunc, any][],
  store: typeof sendgridAppStore
) => {
  initiatesAndDefs.forEach(([initiate, tiaraDef]) => {
    const dataFromTiara = tiaraDefToRtkQueryDataInjection(tiaraDef);
    injectDataIntoRtkQuery(initiate, dataFromTiara, store);
  });
};

/**
 * Given a url and an object representing url query params,
 * combines them into a single url.
 */
export const constructUrl = (url: string, queryParams?: Record<string, any>) =>
  `${url}${
    queryParams == null ? '' : `?${new URLSearchParams(queryParams).toString()}`
  }`;

/**
 * Given an object with strings as keys, will return a new object with all
 * the snake_case keys converted to camelCase (the type passed in will be
 * converted as well)
 */
export const convertAllKeysToCamelCase = <T extends Record<string, any>>(
  obj: Record<string, any>
) => {
  // Run time sanity check since we're dealing with data returned from an api
  if (typeof obj != 'object') {
    return obj;
  }
  return mapKeys(obj, (_, key) => camelCase(key)) as CamelCasedProperties<T>;
};

/**
 * Returns a copy of queryArgs with the property [keyToOmit] omitted (if it exists)
 */
export const cleanQueryArgs = (
  queryArgs: Record<string, any>,
  keyToOmit: string
) => {
  let cleanedQueryArgs: Record<string, any> | undefined = queryArgs;
  if (keyToOmit in (queryArgs ?? {})) {
    if (Object.keys(queryArgs).length === 1) {
      // dataFromTiara is the only arg passed in in this case
      // We set to undefined so an empty object isn't serialized in the cache key
      cleanedQueryArgs = undefined;
    } else {
      // Copy of og queryArgs so we're not modifying it directly w/ the delete
      cleanedQueryArgs = { ...queryArgs };
      delete cleanedQueryArgs[keyToOmit];
    }
  }
  return cleanedQueryArgs;
};

/**
 * This function is copied directly from rtk and creates a cache key string from endpointName & queryArgs.
 * Unfortunately rtk does not export it so we copy & paste it here.
 * See: https://github.com/reduxjs/redux-toolkit/blob/master/packages/toolkit/src/query/defaultSerializeQueryArgs.ts
 */
export const defaultSerializeQueryArgs = ({
  endpointName,
  queryArgs,
}: {
  endpointName: string;
  queryArgs: any;
}) => {
  // Sort the object keys before stringifying, to prevent useQuery({ a: 1, b: 2 }) having a different cache key than useQuery({ b: 2, a: 1 })
  return `${endpointName}(${JSON.stringify(queryArgs, (key, value) =>
    isPlainObject(value)
      ? Object.keys(value)
          .sort()
          .reduce<any>((acc, key) => {
            acc[key] = (value as any)[key];
            return acc;
          }, {})
      : value
  )})`;
};

/**
 * Returns graphql error extension fields like code and message if they exist.
 */
export function gqlErrorExtensions<T>(
  results:
    | { data: T }
    | {
        error: GraphQLServerError | SerializedError;
      }
): { status?: number; code?: string; message?: string } {
  if ('error' in results) {
    if ('data' in results.error) {
      const { code, message } = results.error.data[0]?.extensions;

      return {
        code,
        message,
      };
    }
  }

  return {};
}
