import { Action } from '../types/action';
import { EmailApiPlan } from '../types/emailApiPlan';
import { ActionTypes } from './actionTypes';

const initialState: EmailApiPlan[] = new Array<EmailApiPlan>();

interface EmailApiPlanAction extends Action {
  payload: EmailApiPlan[];
}

export const emailApiPlans = (
  state = initialState,
  action: EmailApiPlanAction
) => {
  switch (action.type) {
    case ActionTypes.GetEmailApiPlansSuccess:
      return action.payload as EmailApiPlan[];

    default:
      return state;
  }
};
