import {
  mapPlan,
  getPriceByName,
  getOverageRate,
  getIncludedCredits,
  getEmailApiPlan,
} from '../../common/adapters';
import {
  EmailApiTierDto,
  OfferingDto,
  PriceNamesDto,
  OesAccountDto,
} from '../../services/oes/dtos';
import { EmailApiPlan, EmailApiPlanTier } from '../../../types/emailApiPlan';
import { PlanType } from '../../../types/planType';

export function getEmailApiSendingCredits(offering: OfferingDto): number {
  const { entitlements, pricing } = offering;
  if (pricing) {
    return getIncludedCredits(pricing, PriceNamesDto.EI_SENDS);
  }
  return entitlements.ei_sends_cap_monthly || 0;
}

export function getEmailApiTier(tier?: EmailApiTierDto): EmailApiPlanTier {
  switch (tier) {
    case EmailApiTierDto.Free:
      return EmailApiPlanTier.Free;
    case EmailApiTierDto.Essentials:
      return EmailApiPlanTier.Essentials;
    case EmailApiTierDto.Pro:
      return EmailApiPlanTier.Pro;
    case EmailApiTierDto.Premier:
      return EmailApiPlanTier.Premier;
    default:
      return EmailApiPlanTier.Unknown;
  }
}

export function mapEmailApiPlan(
  offering: OfferingDto,
  account?: OesAccountDto
): EmailApiPlan {
  const { pricing, entitlements, is_active } = offering;
  const { ei_tier, ei_tier_rank } = entitlements;
  return {
    ...mapPlan(offering, PlanType.EmailApi),
    price: getPriceByName(pricing, PriceNamesDto.EI_BASE),
    emailSendingCredits: getEmailApiSendingCredits(offering),
    emailSendingOverageRate: getOverageRate(pricing, PriceNamesDto.EI_SENDS),
    tier: getEmailApiTier(ei_tier),
    daysOfEase: entitlements.ease_days || 0,
    emailTestingCredits: entitlements.email_testing_cap_monthly || 0,
    isDisabled: (account && account.entitlements.is_upgrade_locked) || false,
    tierRank: ei_tier_rank,
    isActive: is_active,
  } as EmailApiPlan;
}
