import { Action } from '../types/action';
import { EmailApiPlanTier } from '../types/emailApiPlan';
import { PlanTierFeatures } from '../types/planTierFeatures';
import { ActionTypes } from './actionTypes';

const initialState = {
  [EmailApiPlanTier.Free as string]: [],
  [EmailApiPlanTier.Essentials as string]: [],
  [EmailApiPlanTier.Pro as string]: [],
  [EmailApiPlanTier.Premier as string]: [],
} as PlanTierFeatures;

interface EmailApiFeaturesAction extends Action {
  payload: PlanTierFeatures;
}

export const emailApiFeatures = (
  state = initialState,
  action: EmailApiFeaturesAction
) => {
  switch (action.type) {
    case ActionTypes.GetEmailApiFeaturesSuccess:
      return action.payload as PlanTierFeatures;

    default:
      return state;
  }
};
