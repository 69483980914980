import { Teammates } from './../types/teammates';
import { Action } from './../types/action';
import { MakoStateType } from './../types/makoStateType';
import { ActionTypes } from './actionTypes';
import { ResponseError } from '../types/responseError';
import { TeammateDto, TeammatesDto } from '../dtos';

const initialState: MakoStateType['teammates'] = {
  teammates: [],
  errors: [],
};

interface TeammatesAction extends Action {
  payload: TeammatesDto;
}

export const teammates = (state = initialState, action: TeammatesAction) => {
  switch (action.type) {
    case ActionTypes.GetTeammatesSuccess:
      const currentTeammates = action.payload as { result: TeammateDto[] };
      return {
        teammates: currentTeammates.result,
        errors: '',
      };
    case ActionTypes.GetTeammatesFailure:
      const payload = action.payload as { errors: ResponseError[] };
      const errors =
        (payload.errors &&
          payload.errors[0].message &&
          payload.errors.length) ||
        '';
      return {
        teammates: [],
        errors,
      };
    default:
      return state;
  }
};
