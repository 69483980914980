import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { PlanTierFeatures } from '../types/planTierFeatures';

export const Actions = {
  getEmailApiFeaturesSuccess: (featureList: PlanTierFeatures) =>
    createAction(ActionTypes.GetEmailApiFeaturesSuccess, featureList),
  getEmailApiFeaturesFailure: () =>
    createAction(ActionTypes.GetEmailApiFeaturesFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
