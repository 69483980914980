import { PlanType } from '../../types/planType';
import {
  OfferingTypeDto,
  OfferingDto,
  PricingDto,
  PriceNamesDto,
  PriceDto,
  PricingTierDto,
  AccountOfferingDto,
} from '../services/oes/dtos';
import { TypedMap } from '../../../@types/TypedMap';
import { Plan } from '../../types/plan';

const EMAIL_API_PLAN_PREFIX = 'sg.ei';
const MARKETING_CAMPAIGNS_PLAN_PREFIX = 'sg.mc';
const TESTING_CREDITS_PREFIX = 'sg.x.email-testing';
const EASE_PLAN_PREFIX = 'sg.x.ease';

export function getTierSummaryMap(
  offerings: OfferingDto[],
  planType: PlanType
): TypedMap<OfferingDto> {
  const summaryOfferings = offerings.filter(
    (offering) => offering.type === OfferingTypeDto.SUMMARY
  );
  const summaries = {} as TypedMap<OfferingDto>;
  summaryOfferings.reduce((summaryMap, summary) => {
    const { entitlements } = summary;
    const tier =
      planType === PlanType.MarketingCampaigns
        ? entitlements.mc_tier
        : entitlements.ei_tier;
    if (tier) {
      summaryMap[tier] = summary;
    }
    return summaryMap;
  }, summaries);

  return summaries;
}

export function mapPlan(offering: OfferingDto, planType: PlanType): Plan {
  const { name, display, entitlements } = offering;
  return {
    id: name,
    name: display && display.en_us,
    dedicatedIps: entitlements.compat_pkg_ip_count || 0,
    maxSubusers: entitlements.users_cap_total || 0,
    maxTeammates: entitlements.teammates_cap_total || 0,
    maxWebhooks: entitlements.webhooks_workspace_cap_total || 0,
    planType,
    isSelfServe: entitlements.compat_self_serve || false,
    startDate: offering.start_date,
  } as Plan;
}

export function getPricingByName(
  pricing: PricingDto,
  name: PriceNamesDto
): PriceDto | undefined {
  if (!pricing || !pricing.products) {
    return undefined;
  }
  return pricing.products[name];
}

export function getPriceByName(
  pricing: PricingDto,
  name: PriceNamesDto
): number {
  const price = getPricingByName(pricing, name);
  if (!price || !price.amount) {
    return 0;
  }
  return Number.parseFloat(price.amount) || 0;
}

export function getIncludedCredits(
  pricing: PricingDto,
  name: PriceNamesDto,
  defaultValue = 0
): number {
  // Offerings that represent plans have 2 price tiers (base and overage),
  // the first item in the price tier array contains the
  // included credits (e.g. sends) that are included with the plan.
  // If you are dealing with an offering that has more than 2
  // price tiers make sure this function is what you actually want.
  const tiers = getPricingTiers(pricing, name);
  const baseTier = tiers[0];
  return (baseTier && baseTier.size) || defaultValue;
}

export function getPricingTiers(
  pricing: PricingDto,
  name: PriceNamesDto
): PricingTierDto[] {
  const priceInfo = getPricingByName(pricing, name);
  if (!priceInfo || !priceInfo.tiers) {
    return [];
  }
  return priceInfo.tiers;
}

export function getOverageRate(
  pricing: PricingDto,
  name: PriceNamesDto,
  defaultValue = 0
): number {
  const tiers = getPricingTiers(pricing, name);
  // Offerings that represent plans have 2 price tiers (base and overage),
  // the last item in the price tier array contains the
  // overage rate: what you pay if you exceed all other tiers.
  // If you are dealing with an offering that has more than 2
  // price tiers make sure this function is what you actually want.
  const overageTier = tiers[tiers.length - 1];
  return (overageTier && Number.parseFloat(overageTier.amount)) || defaultValue;
}

export const calculateEstimatedProratedPrice = (price: number) => {
  const currentDate = new Date();
  const currentDay = currentDate.getDate();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();
  const daysInMonth = new Date(currentYear, currentMonth + 1, 0).getDate();
  const remainingMonth = (daysInMonth - currentDay + 1) / daysInMonth;
  // the percentage of month left times the price
  return remainingMonth * price;
};

// TODO: Replace name filtering with entitlement-based check(s)
export function isMarketingCampaignsPlan(offering: OfferingDto): boolean {
  return (
    offering &&
    offering.name.toLowerCase().includes(MARKETING_CAMPAIGNS_PLAN_PREFIX) &&
    offering.type === OfferingTypeDto.PACKAGE
  );
}

// TODO: Replace name filtering with entitlement-based check(s)
export function isEmailApiPlan(offering: OfferingDto): boolean {
  return (
    offering &&
    offering.name.toLowerCase().includes(EMAIL_API_PLAN_PREFIX) &&
    offering.type === OfferingTypeDto.PACKAGE
  );
}

// TODO: Replace name filtering with entitlement-based check(s)
export function isTestingCreditsPlan(offering: OfferingDto): boolean {
  return (
    offering &&
    offering.name.toLowerCase().includes(TESTING_CREDITS_PREFIX) &&
    offering.entitlements.compat_self_serve === true
  );
}

// TODO: Replace name filtering with entitlement-based check(s)
export function isEasePlan(offering: OfferingDto): boolean {
  return (
    offering &&
    offering.name.toLowerCase().includes(EASE_PLAN_PREFIX) &&
    offering.entitlements.compat_self_serve === true
  );
}

export function getEmailApiPlan(
  offerings: AccountOfferingDto[]
): AccountOfferingDto | undefined {
  return offerings.find(
    (offering) =>
      offering.name.includes(EMAIL_API_PLAN_PREFIX) &&
      offering.type === OfferingTypeDto.PACKAGE
  );
}

export function getMarketingCampaignsPlan(
  offerings: AccountOfferingDto[]
): AccountOfferingDto | undefined {
  return offerings.find(
    (offering) =>
      offering.name.includes(MARKETING_CAMPAIGNS_PLAN_PREFIX) &&
      offering.type === OfferingTypeDto.PACKAGE
  );
}

export function getOfferingByName(
  offerings: AccountOfferingDto[],
  productId: string
): AccountOfferingDto | undefined {
  return offerings.find((offering) => offering.name === productId);
}
