import { UsageStatsDto } from '../dtos';
import { UsageStats } from '../types/user';
import { sanitizer } from '../dtoSanitizer';

export const usageStatsAdapter = (usageStats: UsageStatsDto): UsageStats => {
  const sanitizedDto = sanitizer(usageStats);
  const {
    is_hard_limit,
    last_reset,
    next_reset,
    overage,
    remain,
    reset_frequency,
    used,
  } = sanitizedDto;

  return {
    isHardLimit: is_hard_limit,
    lastReset: last_reset,
    nextReset: next_reset,
    overage,
    remain,
    resetFrequency: reset_frequency,
    used,
  } as UsageStats;
};
