(function ($) {
  $.fn.multirangeSlider = function (action, args) {
    return this.each(function () {
      const $elt = $(this);

      if (action === "update") {
        update($elt);
      } else {
        init($elt);
      }
    });
  };

  function init($elt) {
    $elt.find(".input-multi-range").click(function () {
      const $this = $(this);

      update($this);

      $this.find("input:radio:checked").trigger("change");
    });

    const $selected = $elt.find(".is-selected").first();
    update($selected);
  }

  function update($elt) {
    $elt
      .siblings(".input-multi-range")
      .removeClass("is-selected")
      .find("input:radio")
      .removeAttr("checked");
    $elt.prevAll(".input-multi-range").addClass("is-not-selected");
    $elt
      .addClass("is-selected")
      .removeClass("is-not-selected")
      .find("input:radio")
      .prop("checked", true);

    const $parent = $elt.parents(".input-range-wrap");

    let count = 0;
    let selectedIndex = 0;

    $parent.children(".input-multi-range").each((index, child) => {
      if ($(child).find("input:radio:checked").length !== 0) {
        selectedIndex = index;
      }

      count++;
    });

    const fill = count <= 1 ? 0 : (selectedIndex / (count - 1)) * 100;
    $parent.find(".input-multi-range-fill").css("width", `${fill}%`);
  }
})(jQuery);
