import { IMPERSONATING_USERNAME_COOKIE } from '../constants';

const MAKO_COOKIE_NAME = 'mako_auth_token';

const getCookies = () => {
  return document.cookie
    .split(';')
    .map((ele) => {
      const [key, value] = ele.trim().split('=');
      return { [key]: value };
    })
    .reduce((one, two) => ({ ...one, ...two }));
};
const cookies = getCookies();
const { [MAKO_COOKIE_NAME]: authCookie = null } = cookies;
let onBehalfOf = {};

if (cookies) {
  const impersonatedUser = cookies[IMPERSONATING_USERNAME_COOKIE];
  if (impersonatedUser) {
    onBehalfOf = { 'On-behalf-of': decodeURIComponent(impersonatedUser) };
  }
}

// Probably a bad typing but not really sure how to resolve better
const defaultJSONHeaders = {
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `token ${authCookie}`,
    ...onBehalfOf,
  } as any,
};

// When we change the mako_auth_token after logging in i.e. changing password,
// we need to make sure our Authorization header has the updated cookie in fetches
// or else 401s will occur
export const updateMakoAuthCookieHeader = () => {
  const updatedCookies = getCookies();
  const updatedAuthCookie = updatedCookies[MAKO_COOKIE_NAME] || null;
  defaultJSONHeaders.headers.Authorization = `token ${updatedAuthCookie}`;
};

// Should try to attach this header once we've generate an EHawk Talon (browser fingerprint)
// so it gets attached to all subsequent requests. Used for anti abuse downstream.
export const updateBrowserFingerprintHeader = (fingerprint: string) => {
  if (fingerprint) {
    defaultJSONHeaders.headers['Browser-Fingerprint'] = fingerprint;
  }
};

export { getCookies };
export default defaultJSONHeaders;
