import { AuthedHttp, ResponseBodySuccess } from '../../helpers/AuthedHttp';
import { Routes } from './routes';
import { ExpertServicesOngoingDto } from '../dtos';
import { ResponseError } from '../types/responseError';

export function getExpertServicesOngoingData(): Promise<
  ExpertServicesOngoingDto
> {
  return AuthedHttp.get<ExpertServicesOngoingDto>(
    `${Routes.ExpertServicesOngoing}`
  )
    .then((res: ResponseBodySuccess<ExpertServicesOngoingDto>) => res.json())
    .catch(() => {
      return {
        errors: [
          {
            message:
              'Failed to retrieve ongoing expert services due to some reason!',
          },
        ] as ResponseError[],
      };
    });
}
