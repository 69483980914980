import { Timezone } from '../types/user';
import { TimezoneDto } from '../dtos';

export function timezoneOptionsAdapter(
  timezoneOptions: TimezoneDto[]
): Timezone[] {
  return timezoneOptions.map(({ offset_seconds = 0, ...timezone }) => {
    return {
      offsetSeconds: offset_seconds,
      ...timezone,
    } as Timezone;
  });
}
