import { getPriceByName } from '../../common/adapters';
import {
  PriceNamesDto,
  OfferingDto,
  EntitlementsDto,
} from '../../services/oes/dtos';
import { ProductId } from '../../../types/productId';
import { EasePlan } from '../../../ease_plans/types';

function getCorrespondingEiPlanIds(entitlements: EntitlementsDto): string[] {
  const { compat_ease_pkg_offerings } = entitlements;

  if (compat_ease_pkg_offerings) {
    return compat_ease_pkg_offerings as string[];
  }

  return [] as string[];
}

export function mapEasePlan(offering: OfferingDto): EasePlan {
  const { pricing, entitlements, name, display } = offering;
  const isSelfServe = name !== ProductId.SG_X_EASE_30_CUSTOM_V1;

  return {
    displayName: display ? display.en_us : '',
    name,
    price: getPriceByName(pricing, PriceNamesDto.EASE_BASE),
    days: entitlements.ease_days || 0,
    eiPlanIds: getCorrespondingEiPlanIds(entitlements),
    isSelfServe,
  };
}
