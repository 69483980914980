import {
  configureStore,
  combineReducers,
  ThunkAction,
  Action,
} from '@reduxjs/toolkit';
import { useDispatch, useSelector, TypedUseSelectorHook } from 'react-redux';
import { ssoIntegrationsSlice } from '../../pages/SettingsSSO/features/ssoIntegrations/ssoIntegrationsSlice';
import { certificateSlice } from '../../pages/SettingsSSO/features/ssoCertificates/ssoCertificatesSlice';
import { scopeSelectorSlice } from '../../pages/Teammates/features/scopeSelector/scopeSelectorSlice';
import { overviewStatsSlice } from '../../pages/EmailInsights/Deliverability/features/overviewStats/overviewStatsSlice';
import { mailboxProviderStatsSlice } from '../../p../../pages/EmailInsights/Deliverability/features/mailboxProviderStats/mailboxProviderStatsSlice';
import { bouncedAndBlockedStatsSlice } from '../../pages/EmailInsights/Deliverability/features/bouncedAndBlockedStats/bouncedAndBlockedStatsSlice';
import { spamAndUnsubscribesStatsSlice } from '../../pages/EmailInsights/Deliverability/features/spamAndUnsubscribesStats/spamAndUnsubscribesStatsSlice';
import { mfaSettingsSlice } from '../mfaSettings/mfaSettingsSlice';
import { domainConnectSlice } from '../../pages/SenderAuthentication/Verify/DomainConnect/features/domainConnectSlice';
import { sendGridIpsSlice } from '../../pages/SettingsIPAddresses/features/ipsSlice';
import { ipPoolsSlice } from '../../pages/SettingsIPAddresses/features/ipPoolsSlice';

// RTK slices
import { api } from './rtkQuery/apiSlice';
import { api as graphqlApi } from './rtkQuery/graphqlApiSlice';
import { featureToggleSlice } from './featureToggleSlice';
import { scopeSlice } from './scopeSlice';
import { userTypeSlice } from './userTypeSlice';
import { accountSlice } from './accountSlice';
import { profileSlice } from './profileSlice';
import { accountProfileSlice } from './accountProfileSlice';
import { invoiceAddressSlice } from '../invoice_address/invoiceAddressSlice';
import { invoicesSlice } from '../invoices/invoicesSlice';
import { mockSlice } from '../mocks/mockslice';
import { appConfigSlice } from './appConfigSlice';
import { oesAccountSlice } from '../oesAccount/oesAccountSlice';
import { userEmailSlice } from './userEmailSlice';
import { senderStepsCompletedSlice } from '../sender_steps_completed/senderStepsCompletedSlice';
import { signupToSendSlice } from '../signup_to_send/signupToSendSlice';
import { paymentFormSlice } from './paymentFormSlice';
import { billingAccountSlice } from './billingAccountSlice';
import { subuserAccessSlice } from './subuserAccessSlice';
import { subuserAccessSearchSlice } from './subuserAccessSearchSlice';
import { nonImpersonatedUsernameSlice } from './nonImpersonatedUsernameSlice';
import { nonImpersonatedProfileSlice } from './nonImpersonatedProfileSlice';

const rootReducer = combineReducers({
  // RTK Query reducer
  [api.reducerPath]: api.reducer,
  // GraphQL RTK Query reducer
  [graphqlApi.reducerPath]: graphqlApi.reducer,
  // AppConfig from api_config.js
  AppConfig: appConfigSlice.reducer,
  // Eventually Tiara stuff
  // App Slices
  ssoIntegrations: ssoIntegrationsSlice.reducer,
  ssoCertificates: certificateSlice.reducer,
  scopeSelector: scopeSelectorSlice.reducer,
  emailInsightsOverviewStats: overviewStatsSlice.reducer,
  emailInsightsMailboxProviderStats: mailboxProviderStatsSlice.reducer,
  emailInsightsBouncedAndBlockedStats: bouncedAndBlockedStatsSlice.reducer,
  emailInsightsSpamAndUnsubscribesStats: spamAndUnsubscribesStatsSlice.reducer,
  mfaSettings: mfaSettingsSlice.reducer,
  domainConnectSlice: domainConnectSlice.reducer,
  featureToggles: featureToggleSlice.reducer,
  scopes: scopeSlice.reducer,
  userType: userTypeSlice.reducer,
  account: accountSlice.reducer,
  accountProfile: accountProfileSlice.reducer,
  profile: profileSlice.reducer,
  nonImpersonatedProfile: nonImpersonatedProfileSlice.reducer,
  userEmail: userEmailSlice.reducer,
  invoiceAddress: invoiceAddressSlice.reducer,
  invoices: invoicesSlice.reducer,
  mockSlice: mockSlice.reducer,
  oesAccount: oesAccountSlice.reducer,
  senderStepsCompleted: senderStepsCompletedSlice.reducer,
  signupToSend: signupToSendSlice.reducer,
  paymentForm: paymentFormSlice.reducer,
  billingAccount: billingAccountSlice.reducer,
  nonImpersonatedUsername: nonImpersonatedUsernameSlice.reducer,
  subuserAccess: subuserAccessSlice.reducer,
  subuserAccessSearch: subuserAccessSearchSlice.reducer,
  sendGridIps: sendGridIpsSlice.reducer,
  ipPools: ipPoolsSlice.reducer,
});

export type SendGridRootState = ReturnType<typeof rootReducer>;

/**
 * A factory that returns a new sendgridAppStore.
 * We only have one instance running in feature code (the exported sendgridAppStore)
 * but may create multiple instances in test files which is why this is here.
 */
export const createSendgridAppStoreInstance = () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      // Connect up rtk query middleware
      getDefaultMiddleware()
        .concat(api.middleware)
        .concat(graphqlApi.middleware),
  });

export const sendgridAppStore = createSendgridAppStoreInstance();

export type SendGridAppStore = typeof sendgridAppStore;
export type SendGridAppDispatch = typeof sendgridAppStore.dispatch;
export const useAppDispatch = () => useDispatch<SendGridAppDispatch>();
export const useAppSelector: TypedUseSelectorHook<SendGridRootState> = useSelector;
export type SendGridAppThunk = ThunkAction<
  void,
  SendGridRootState,
  unknown,
  Action<string>
>;
