const Marionette = require("backbone.marionette");
const qs = require("qs");

// views
const AccessDeniedView = require("./views/errors/access_denied_view");
const ErrorLayoutView = require("./views/error_layout_view");

// models
const ScopeRequestVerifyModel = require("./models/scope_request_verify_model");

// route helpers
const RouterHelpers = require("./router_helpers");
const _ = require("underscore");

/* eslint-disable no-param-reassign */
module.exports = Marionette.AppRouter.extend({
  routes: {
    "errors/access_denied": "errorsAccessDenied",
  },

  initialize() {
    this.layoutController = this.getOption("layoutController");
  },

  before(route) {
    RouterHelpers.before(this, route);
  },

  onRoute() {
    RouterHelpers.onRoute();
  },

  convertParamsToOptions(queryParams) {
    const params = qs.parse(queryParams);
    const options = {
      title: params.title,
      scopeGroupName: params.scope_group_name,
      previousRoute: params.previous_route,
    };

    return options;
  },

  // verifyAccess routes to the access denied page if the users doesn't have a scope
  verifyAccess(scope, accessDeniedOptions) {
    accessDeniedOptions = accessDeniedOptions || {};
    accessDeniedOptions.requiredScope = scope;

    if (accessDeniedOptions.scopeGroupName === "IP Management") {
      const billingScopeRequestVerifyModel = new ScopeRequestVerifyModel({
        scopeGroupName: "billing",
      });
      billingScopeRequestVerifyModel.fetch({ async: false });

      // If the account does not have billing.update and is not a reseller, then show the access denied page and don't let them request access
      const isReseller = window.Reqres.request("account").is_reseller_customer;
      if (
        !_.contains(
          billingScopeRequestVerifyModel.get("scopes"),
          "billing.update"
        ) &&
        !isReseller
      ) {
        this.errorsAccessDenied({
          scopeGroupName: "IP Management",
          requiredScope: "billing.update",
        });
        return false;
      }
    }

    if (!this.getOption("authController").verify(scope)) {
      this.errorsAccessDenied(accessDeniedOptions);
      return false;
    }
    return true;
  },

  // errorsAccessDenied routes the user to the Access Denied page
  errorsAccessDenied(options) {
    if (_.isString(options)) {
      options = this.convertParamsToOptions(options);
    }

    options = options || {};

    const displayTitle = {
      Whitelabel: "Sender Authentication",
      "Email Validation": "Email Address Validation",
    };

    const title = displayTitle[options.scopeGroupName]
      ? displayTitle[options.scopeGroupName]
      : options.title || options.scopeGroupName;

    const scopeRequestVerifyModel = new ScopeRequestVerifyModel({
      scopeGroupName: options.scopeGroupName,
    });

    const errorView = new AccessDeniedView({
      usernameModel: this.getOption("usernameModel"),
      accountProfileModel: this.getOption("accountProfileModel"),
      scopeRequestVerifyModel,
      restrictAccessRequest: options.restrictAccessRequest,
      scopeGroupName: options.scopeGroupName,
      previousRoute: options.previousRoute,
      requiredScope: options.requiredScope,
      bypassScopeCheck: options.bypassScopeCheck,
      canTeammateRequestAccess: options.canTeammateRequestAccess,
    });

    const layoutView = new ErrorLayoutView({
      contentView: errorView,
      title,
    });

    this.layoutController.display(layoutView);

    this.getOption("usernameModel").fetch();
    this.getOption("accountProfileModel").fetch();
    scopeRequestVerifyModel.fetch();
  },
});
