import { ActionsObservable, ofType } from 'redux-observable';
import { map, switchMap, concatMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';

import * as RemoveExpertInsightsActions from './actions';
import { ActionTypes } from './actionTypes';
import { RemoveExpertInsightsRequest } from '../types/removeExpertInsights';
import { RemoveExpertInsightsDto } from '../dtos';
import { removeExpertInsights } from './service';
import { DisplayOngoingServiceNames } from '../types/expertServiceOngoing';
import { Actions as UIActions } from '../ui/actions';
import { Actions as ExpertServiceOngoingActions } from '../expert_services_ongoing/actions';
import { AlertContentKey } from '../types/ui';

const removeExpertInsightsEpic = (
  action$: ActionsObservable<
    ReturnType<typeof RemoveExpertInsightsActions.Actions.removeExpertInsights>
  >
): Observable<
  | ReturnType<
      typeof RemoveExpertInsightsActions.Actions.removeExpertInsightsSuccess
    >
  | ReturnType<
      typeof RemoveExpertInsightsActions.Actions.removeExpertInsightsFailure
    >
> => {
  return action$.pipe(
    ofType(ActionTypes.RemoveExpertInsightsRequest),
    switchMap((action) => {
      return from(
        removeExpertInsights(action.payload as RemoveExpertInsightsRequest)
      );
    }),
    map((data: RemoveExpertInsightsDto) => {
      if (data.errors) {
        return RemoveExpertInsightsActions.Actions.removeExpertInsightsFailure(
          data
        );
      }
      return RemoveExpertInsightsActions.Actions.removeExpertInsightsSuccess();
    })
  );
};

// This epic listens for the remove expert insights failure action to be dispatched
// before showing the red alert banner
const removeExpertInsightsFailureAlertEpic = (
  actions$: ActionsObservable<
    ReturnType<
      typeof RemoveExpertInsightsActions.Actions.removeExpertInsightsFailure
    >
  >
): Observable<
  | ReturnType<typeof UIActions.createAlert>
  | ReturnType<typeof UIActions.hideModal>
> => {
  return actions$.pipe(
    ofType(ActionTypes.RemoveExpertInsightsFailure),
    concatMap(() => {
      return [
        UIActions.hideModal(),
        UIActions.createAlert({
          type: 'danger',
          contentKey: AlertContentKey.GenericFailureContactSales,
        }),
      ];
    })
  );
};

// This epic listens for the remove expert insights success action to be dispatched
// before showing the green success banner
const removeExpertInsightsSuccessAlertEpic = (
  actions$: ActionsObservable<
    ReturnType<
      typeof RemoveExpertInsightsActions.Actions.removeExpertInsightsSuccess
    >
  >
): Observable<
  | ReturnType<typeof UIActions.createAlert>
  | ReturnType<typeof UIActions.hideModal>
  | ReturnType<typeof ExpertServiceOngoingActions.getExpertServicesOngoing>
> => {
  return actions$.pipe(
    ofType(ActionTypes.RemoveExpertInsightsSuccess),
    concatMap(() => {
      const text = `Please contact support to have ${DisplayOngoingServiceNames.ExpertInsights} removed from your plan.`;
      return [
        UIActions.hideModal(),
        UIActions.createAlert({ type: 'danger', text }),
        ExpertServiceOngoingActions.getExpertServicesOngoing(),
      ];
    })
  );
};

export default [
  removeExpertInsightsEpic,
  removeExpertInsightsFailureAlertEpic,
  removeExpertInsightsSuccessAlertEpic,
];
