import { Action } from '../types/action';
import { ActionTypes } from './actionTypes';

import { Ips } from './types';

interface IpsAction extends Action {
  payload: Ips;
}

const initialState = {} as Ips;

export const ips = (state = initialState, action: IpsAction) => {
  switch (action.type) {
    case ActionTypes.GetIpsSuccess:
      return action.payload;
    case ActionTypes.GetIpsFailure:
    default:
      return state;
  }
};
