import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { NextInvoice } from '../types/nextInvoice';

export const Actions = {
  getNextInvoiceSuccess: (nextInvoice: NextInvoice) =>
    createAction(ActionTypes.GetNextInvoiceSuccess, nextInvoice),
  getNextInvoiceFailure: () => createAction(ActionTypes.GetNextInvoiceFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
