import { Action } from '../types/action';
import { ActionTypes } from './actionTypes';
import { SubscriptionChanges } from './actions';

const initialState: SubscriptionChanges = {
  isChangeSuccessful: false,
  hasErrors: false,
} as SubscriptionChanges;

interface SubscriptionChangesActions extends Action {
  payload: SubscriptionChanges;
}

export const subscriptionChanges = (
  state = initialState,
  action: SubscriptionChangesActions
) => {
  switch (action.type) {
    case ActionTypes.SubscriptionChangesSuccess:
      return {
        isChangeSuccessful: true,
        hasErrors: false,
      };
    case ActionTypes.SubscriptionChangesFailure:
      return {
        isChangeSuccessful: false,
        hasErrors: true,
      };
    case ActionTypes.SubscriptionChangesReset:
      return {
        isChangeSuccessful: false,
        hasErrors: false,
      };
    default:
      return state;
  }
};
