export enum ActionTypes {
  GetEase = 'getEase',
  GetEaseRequest = 'getEase.request',
  GetEaseSuccess = 'getEase.success',
  GetEaseFailure = 'getEase.failure',
  // delete ease
  DeleteEase = 'deleteEase',
  DeleteEaseRequest = 'deleteEase.request',
  DeleteEaseSuccess = 'deleteEase.success',
  DeleteEaseFailure = 'deleteEase.failure',
  // add ease
  AddEase = 'addEase',
  AddEaseRequest = 'addEase.request',
  AddEaseSuccess = 'addEase.success',
  AddEaseFailure = 'addEase.failure',
}
