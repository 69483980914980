import { ScopeMap } from '../scopes/adapters';

export enum Scopes {
  BillingRead = 'billing.read',
  BillingUpdate = 'billing.update',
  MarketingRead = 'marketing.read',
  MarketingAutomationRead = 'marketing.automation.read',
  MarketingCampaignsRead = 'marketing_campaigns.read',
  MarketingCampaignsCreate = 'marketing_campaigns.create',
  MarketingCampaignsUpdate = 'marketing_campaigns.update',
  MarketingCampaignsDelete = 'marketing_campaigns.delete',
  // TODO: remove this if scopes change or we use the OES diff endpoint to properly show the
  // anti-spoof downgrade warning
  // Net new sign ups after scopes are turned on should have the eligible scope assigned once only; legacy/existing users
  // created before the anti-spoof scopes were turned on should not have the eligible scopes
  AntiSpoofEligible = 'sender_verification_eligible',
  // The exempt scope will be applied or removed after upgrade/downgrade completion
  // Legacy/existing users before the scopes are turned on will always have the exempt scope
  // Users signed up after the scopes are turned on will have both the eligible and exempt scope
  // if they have an Essentials 100k (until they are no longer part of the control group), pro, and premier Email API plans
  // If users signed up after scopes were turned on as Free/Trial 40K and Essentials 40K or say downgraded from Pro/Premier to Essentials 40K,
  // the user will not have the exempt scope after upgrade/downgrade completion
  AntiSpoofExempt = 'sender_verification_exempt',
  WhiteLabelCreate = 'whitelabel.create',
}

export enum ScopeGroupNames {
  Billing = 'Billing',
}

export interface ScopesReduxState {
  data: ScopeMap;
  hasTneAccess: boolean;
  hasMCLegacyAccess: boolean;
  hasBillingReadAccess: boolean;
  hasBillingUpdateAccess: boolean;
}
