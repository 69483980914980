import { AccountPricingDto } from '../../services/oes/dtos';
import { NextInvoice } from '../../../types/nextInvoice';

export function mapNextInvoice(accountPricingDto: AccountPricingDto) {
  const total = accountPricingDto && accountPricingDto.total;
  const totalRecurring = total && total.recurring;
  const totalRecurringCost = Number.parseFloat(totalRecurring) || 0;

  return {
    totalRecurringCost,
  } as NextInvoice;
}
