import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthedHttp } from '../../helpers/AuthedHttp';
import { sanitizer } from '../dtoSanitizer';
import {
  SendGridAppDispatch,
  SendGridRootState,
} from '../store/sendgridAppStore';

export enum Routes {
  InvoiceAddress = 'billing/contact_information',
}

export enum InvoiceAddressError {
  NoInvoiceAddress = 'User does not have an invoice address',
  GenericFetchError = 'Network Error. Failed to fetch invoice address',
}

export interface InvoiceAddressDto {
  first_name: string;
  last_name: string;
  email: string;
  company: string;
  phone: string;
  address: string;
  address2: string;
  city: string;
  country: string;
  zip: string;
  state: string;
  tax_id: string;
  secondary_tax_id: string;
}

export interface InvoiceAddress {
  address: string;
  address2: string;
  city: string;
  country: string;
  state: string;
  zip: string;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  phone: string;
  taxID: string;
  secondaryTaxID: string;
}

export enum RequestStatus {
  Idle = 'idle',
  Pending = 'pending',
  Success = 'success',
  Failure = 'failure',
}

export interface InvoiceAddressSuccess {
  status: RequestStatus.Success;
  data: InvoiceAddress;
  error: null;
}

export interface InvoiceAddressFailure {
  status: RequestStatus.Failure;
  data: null;
  error: string;
}

export interface InvoiceAddressIdle {
  status: RequestStatus.Idle;
  data: null;
  error: null;
}

export interface InvoiceAddressPending {
  status: RequestStatus.Pending;
  data: null;
  error: null;
}

export type InvoiceAddressState =
  | InvoiceAddressSuccess
  | InvoiceAddressFailure
  | InvoiceAddressPending
  | InvoiceAddressIdle;

export const fetchInvoiceAddress = createAsyncThunk<
  InvoiceAddress,
  void,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
  }
>('invoiceAddress/fetchInvoiceAddress', async () => {
  const response = await AuthedHttp.get<InvoiceAddressDto>(
    Routes.InvoiceAddress
  );

  if (response.ok) {
    const data = await response.json();
    return invoiceAddressAdapter(data);
  }

  if (response.status === 404) {
    throw new Error(InvoiceAddressError.NoInvoiceAddress);
  }

  throw new Error(InvoiceAddressError.GenericFetchError);
});

export const invoiceAddressAdapter = (
  dto: InvoiceAddressDto
): InvoiceAddress => {
  const sanitizedInvoiceAddress = sanitizer(dto);
  const {
    first_name,
    last_name,
    tax_id,
    secondary_tax_id,
    ...sanitized
  } = sanitizedInvoiceAddress;

  return {
    firstName: first_name,
    lastName: last_name,
    taxID: tax_id,
    secondaryTaxID: secondary_tax_id,
    ...sanitized,
  } as InvoiceAddress;
};

const initialState: InvoiceAddressIdle = {
  status: RequestStatus.Idle,
  data: null,
  error: null,
};

export const invoiceAddressSlice = createSlice<InvoiceAddressState, any>({
  name: 'invoiceAddress',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInvoiceAddress.pending, (state) => {
      state.status = RequestStatus.Pending;
    });
    builder.addCase(fetchInvoiceAddress.fulfilled, (_, action) => {
      return {
        data: action.payload,
        status: RequestStatus.Success,
        error: null,
      };
    });
    builder.addCase(fetchInvoiceAddress.rejected, (_, action) => {
      return {
        status: RequestStatus.Failure,
        data: null,
        error: action.error.message || InvoiceAddressError.GenericFetchError,
      };
    });
  },
});
