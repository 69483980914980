export const loading = (state = {}, action: any) => {
  const { type } = action;
  const matches = /(.*)\.(request|success|failure)/.exec(type);

  // not a *.request / *.success /  *.failure actions, so we ignore them
  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;

  return {
    ...state,
    // Store whether a request is happening at the moment or not
    [requestName]: requestState === 'request',
  };
};

export const error = (state = {}, action: any) => {
  const { type, payload } = action;
  const matches = /(.*)\.(request|success|failure)/.exec(type);

  // not a *_REQUEST / *_FAILURE actions, so we ignore them
  if (!matches) {
    return state;
  }

  const [, requestName, requestState] = matches;
  return {
    ...state,
    // Store errorMessage
    [requestName]:
      requestState === 'failure' ? payload.error || 'unknown-error' : '',
  };
};
