import { AuthedHttp } from '../../helpers/AuthedHttp';
import { Routes } from './routes';
import { getTestingCreditsPlanByName } from '../testing_credits_plans/selectors';
import { isDowngradingTestingCredits } from './selectors';
import { TestingCreditsSubscription } from './types';
import { ProductId } from '../types/productId';
import { TestingCreditsPlan } from '../testing_credits_plans/types';
import { defaultTestingCreditsPlan } from './defaultTestingCreditsPlan';

export const updateTestingCreditsSubscription = async (
  planName: string,
  testingCreditsPlans: TestingCreditsPlan[],
  testingCreditsSubscription: TestingCreditsSubscription
): Promise<TestingCreditsSubscription> => {
  const isMovingToFree = planName === ProductId.SG_X_EMAIL_TESTING_0_V1;

  // plan user is attemping to upgrade/downgrade to
  let futurePlan;

  if (isMovingToFree) {
    futurePlan = defaultTestingCreditsPlan;
  } else {
    futurePlan = getTestingCreditsPlanByName(planName, testingCreditsPlans);
  }

  // if we cannot get future plan we don't know what plan to send to the api; this should never return as undefined
  if (!futurePlan) {
    throw new Error('Could not get testing credits future plan');
  }

  const isDowngrading = isDowngradingTestingCredits(
    testingCreditsSubscription,
    futurePlan
  );

  let updatedSubscription = {} as TestingCreditsSubscription;

  if (isDowngrading) {
    updatedSubscription = {
      ...testingCreditsSubscription,
      isDowngrading: true,
      downgradeCount: futurePlan.count,
      downgradeId: futurePlan.name as string,
    };
  } else {
    updatedSubscription = {
      ...testingCreditsSubscription,
      downgradeCount: 0,
      downgradeId: '' as string,
      isDowngrading,
      price: futurePlan.price,
      purchasedCount: futurePlan.count,
      totalCount: testingCreditsSubscription.freeCount + futurePlan.count,
      name: futurePlan.name as string,
    };
  }

  let requestString;

  if (isMovingToFree) {
    if (testingCreditsSubscription.isDowngrading) {
      requestString = testingCreditsSubscription.downgradeId;
    } else {
      requestString = testingCreditsSubscription.name;
    }
  } else {
    requestString = futurePlan.name;
  }

  const getReqBody = (name: string): string => {
    switch (name) {
      case ProductId.SG_X_EMAIL_TESTING_30_V1:
        return 'testing-credits.small';
      case ProductId.SG_X_EMAIL_TESTING_60_V1:
        return 'testing-credits.medium';
      case ProductId.SG_X_EMAIL_TESTING_100_V1:
        return 'testing-credits.large';
      default:
        return '';
    }
  };

  const reqBody = { addon_name: getReqBody(requestString) };

  try {
    let upgradeResponse;

    // if moving to free we want to send the downgrade plan to the delete endpoint
    if (isMovingToFree) {
      upgradeResponse = await AuthedHttp.del<{}>(Routes.AddOn, reqBody);
    } else {
      upgradeResponse = await AuthedHttp.post<{}>(Routes.AddOn, reqBody);
    }

    if (!upgradeResponse.ok) {
      return Promise.reject();
    }
    return Promise.resolve(updatedSubscription);
  } catch {
    return Promise.reject();
  }
};
