/** The endpoint names used by rtk query */
export enum Endpoints {
  fetchUserEmail = 'fetchUserEmail',
  fetchAccount = 'fetchAccount',
  fetchCamAccount = 'fetchCamAccount',
  fetchScopes = 'fetchScopes',
  fetchResellerLinks = 'fetchResellerLinks',
  fetchAccountProfile = 'fetchAccountProfile',
  fetchUsername = 'fetchUsername',

  // Validate 2FA
  fetchAccessSettingsMultifactor = 'fetchAccessSettingsMultifactor',
  send2FACode = 'send2FACode',
  validate2FACode = 'validate2FACode',
}
