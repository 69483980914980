import React from 'react';
import { createEpicMiddleware } from 'redux-observable';
import { applyMiddleware, createStore, compose } from 'redux';
import { Connect, connect, ReactReduxContextValue } from 'react-redux';
import { rootReducer } from './rootReducer';
import { rootEpic } from './rootEpic';

const reduxDevTools = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;
// process.env.ENABLE_REDUX_DEV_TOOLS is set in webpack prod if we are on a feature branch
const shouldUseReduxDevTools =
  process.env.NODE_ENV === 'development' || process.env.ENABLE_REDUX_DEV_TOOLS;

// only use dev tools if available and (in development env OR ENABLE_REDUX_DEV_TOOLS set)
const composeEnhancers = (shouldUseReduxDevTools && reduxDevTools) || compose;

export function configureStore(initialState = {}) {
  const epicMiddleware = createEpicMiddleware();
  // billingStore is also used by mail settings, tracking settings, and a couple other projects
  const billingStore = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(epicMiddleware))
  );
  epicMiddleware.run(rootEpic);
  return billingStore;
}

export const billingStore = configureStore();

export const legacyBillingStoreContext = React.createContext({
  store: billingStore,
  storeState: {},
} as ReactReduxContextValue);

export const legacyBillingStoreConnect: Connect = (
  mapStateToProps?: any,
  mapDispatchToProps?: any,
  mergeProps?: any,
  options?: any
) => {
  return connect(mapStateToProps, mapDispatchToProps, mergeProps, {
    ...options,
    context: legacyBillingStoreContext,
  });
};
