import { api } from '../apiSlice';
import { RequestTypes } from '../../../../helpers/AuthedHttp';
import { CamelCasedProperties } from 'type-fest';
import {
  convertAllKeysToCamelCase,
  dataInjectionKey,
  RtkQueryDataInjection,
} from '../utils';
import { Endpoints } from '../Endpoints';
import { AccountProfileDto } from '../../accountProfileSlice';
import { createSelector } from '@reduxjs/toolkit';

export type AccountProfile = CamelCasedProperties<AccountProfileDto>;

export const accountProfileApi = api.injectEndpoints({
  endpoints: (builder) => ({
    [Endpoints.fetchAccountProfile]: builder.query<
      AccountProfile,
      { [dataInjectionKey]?: RtkQueryDataInjection } | void
    >({
      query: ({ dataInjection } = {}) => ({
        url: 'account/profile',
        method: RequestTypes.Get,
        dataInjection,
      }),
      transformResponse: (data: AccountProfileDto) =>
        convertAllKeysToCamelCase<AccountProfileDto>(data),
    }),
  }),
});

export const selectAccountProfileResult = accountProfileApi.endpoints[
  Endpoints.fetchAccountProfile
].select();

export const selectAccountName = createSelector(
  selectAccountProfileResult,
  (accountProfile) => `${accountProfile?.data?.username}`
);

export const { useFetchAccountProfileQuery } = accountProfileApi;
