import { createSelector } from '@reduxjs/toolkit';
import { api } from '../apiSlice';
import { RequestTypes } from '../../../../helpers/AuthedHttp';
import { CamelCasedProperties } from 'type-fest';
import {
  convertAllKeysToCamelCase,
  dataInjectionKey,
  RtkQueryDataInjection,
} from '../utils';
import { Endpoints } from '../Endpoints';
import { AccountDto } from '../../accountSlice';

export { UserType } from '../../accountSlice';

export type Account = CamelCasedProperties<AccountDto>;

export const accountApi = api.injectEndpoints({
  endpoints: (builder) => ({
    [Endpoints.fetchAccount]: builder.query<
      Account,
      { [dataInjectionKey]?: RtkQueryDataInjection } | void
    >({
      query: ({ dataInjection } = {}) => ({
        url: 'user/account',
        method: RequestTypes.Get,
        dataInjection,
      }),
      transformResponse: (data: AccountDto) =>
        convertAllKeysToCamelCase<AccountDto>(data),
    }),
  }),
});

const selectAccountResult = accountApi.endpoints[
  Endpoints.fetchAccount
].select();

export const selectIsSubuser = createSelector(
  selectAccountResult,
  (accountResult) => accountResult?.data?.type === 'subuser'
);

export const selectIsReseller = createSelector(
  selectAccountResult,
  (accountResult) => accountResult?.data?.isResellerCustomer ?? false
);

export const { useFetchAccountQuery } = accountApi;
