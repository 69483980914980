import { ActionsObservable, ofType } from 'redux-observable';
import { catchError, concatMap } from 'rxjs/operators';
import { from, Observable, of } from 'rxjs';
// actions
import { Actions as PlansActions } from './actions';
import { Actions as EmailApiPlansActions } from '../../email_api_plans/actions';
import { Actions as MarketingCampaignsActions } from '../../marketing_campaigns_plans/actions';
import { Actions as TestingCreditsPlansActions } from '../../testing_credits_plans/actions';
import { Actions as EasePlansActions } from '../../ease_plans/actions';
import { Actions as EmailApiFeaturesActions } from '../../email_api_features/actions';
import { Actions as MarketingCampaignsFeaturesActions } from '../../marketing_campaigns_features/actions';
// types
import { ActionTypes } from './actionTypes';
// service
import { getPlans } from './service';

const productsEpics = (
  action$: ActionsObservable<ReturnType<typeof PlansActions.getProducts>>
): Observable<
  | ReturnType<typeof PlansActions.getProductsSuccess>
  | ReturnType<typeof PlansActions.getProductsFailure>
  | ReturnType<
      typeof MarketingCampaignsActions.getMarketingCampaignsPlansSuccess
    >
  | ReturnType<typeof EmailApiPlansActions.getEmailApiPlansSuccess>
  | ReturnType<typeof TestingCreditsPlansActions.getTestingCreditsSuccess>
  | ReturnType<typeof EasePlansActions.getEasePlansSuccess>
  | ReturnType<typeof EmailApiFeaturesActions.getEmailApiFeaturesSuccess>
  | ReturnType<
      typeof MarketingCampaignsFeaturesActions.getMarketingCampaignsFeaturesSuccess
    >
> => {
  return action$.pipe(
    ofType(ActionTypes.GetProductsRequest),
    concatMap(() => {
      return from(getPlans()).pipe(
        concatMap((data) => [
          EmailApiPlansActions.getEmailApiPlansSuccess(data.emailApiPlans),
          MarketingCampaignsActions.getMarketingCampaignsPlansSuccess(
            data.marketingCampaignsPlans
          ),
          TestingCreditsPlansActions.getTestingCreditsSuccess(
            data.testingCreditsPlans
          ),
          EasePlansActions.getEasePlansSuccess(data.easePlans),
          EmailApiFeaturesActions.getEmailApiFeaturesSuccess(
            data.emailApiFeatures
          ),
          MarketingCampaignsFeaturesActions.getMarketingCampaignsFeaturesSuccess(
            data.marketingCampaignsFeatures
          ),
          // getProducts should always be last
          PlansActions.getProductsSuccess(),
        ]),
        catchError(() => {
          return of(PlansActions.getProductsFailure());
        })
      );
    })
  );
};

export default [productsEpics];
