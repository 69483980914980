// both prod and staging IDs included; user with one of these packages will not see the 'Change Plan' button or EASE section
const premierPackagesOver50M = {
  // staging - legacy type plans
  "8b8625ad-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 100M Package",
  "8b863d89-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 200M Package",
  "8b8fb996-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 300M Package",
  "8b910245-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 400M Package",
  "8b9cbf14-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 500M Package",

  // staging - current type plans
  "c0dd906b-c314-407b-8d6e-5809344612a4": "Premier - 100M",
  "18b9583f-e0b4-4116-ae7a-2e256191380b": "Premier - 200M",
  "d3d38d94-90dc-4222-b563-bf5b264bcf5d": "Premier - 300M",
  "c6d00576-d605-4d45-a660-e2648d60ed5e": "Premier - 400M",
  "8a716102-e1fb-494b-8d35-6c9792addcb4": "Premier - 500M",
  "03d76598-6c8b-410e-8ff7-fd065424b1be": "Premier - 600M",

  // prod - legacy type plans
  // eslint-disable-next-line
  // eslint-disable-next-line
  "8b8625ad-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 100M Package",
  // eslint-disable-next-line
  "8b863d89-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 200M Package",
  // eslint-disable-next-line
  "8b8fb996-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 300M Package",
  // eslint-disable-next-line
  "8b910245-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 400M Package",
  // eslint-disable-next-line
  "8b9cbf14-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 500M Package",

  // prod - current type plans
  "59990257-e139-47ca-8ce2-8dbc1cc06fe0": "Premier - 100M",
  "7a417492-9188-4d34-b754-57807cbee831": "Premier - 200M",
  "4483457b-8f26-4055-82f0-44887b372e06": "Premier - 300M",
  "0e4cd4b3-20db-413a-bf74-5392aeb731b2": "Premier - 400M",
  "9cea7417-5102-4013-a97f-754580fd64be": "Premier - 500M",
  "651510ad-8665-4842-bf9a-2dc0976497cd": "Premier - 600M",

  // Multi-product combination ids (they will be the same in staging and prod)
  "c701b9f0-8a5c-58db-a530-bec2272f5254": "EI Premier 50M - MC Custom Pricing",
  "d3881e47-bf3c-5732-a1f2-5994945b5565": "EI Premier 50M - MC Basic 5K",
  "7bf28bc0-43c4-579c-9b9b-1b9c042b0821": "EI Premier 50M - MC Basic 10K",
  "a1c0d951-bab6-56dc-bbdc-019bfff7329a": "EI Premier 50M - MC Basic 20K",
  "d0660310-03e5-50b6-aaee-bfb43355d2f3": "EI Premier 50M - MC Basic 50K",
  "e7b62d75-9bd3-5b82-a180-17224fe98e6d": "EI Premier 50M - MC Basic 100K",
  "9fa46449-2fe1-5eb0-867e-08897dd3e2c9": "EI Premier 50M - MC Advanced 10K",
  "19bec384-7698-5cd3-9ce4-2a741d1cd71d": "EI Premier 50M - MC Advanced 20K",
  "e9b17eda-a12f-5741-9276-85f27550f2e0": "EI Premier 50M - MC Advanced 50K",
  "e6a92f82-c543-5f15-9425-5c9146dbb989": "EI Premier 50M - MC Advanced 100K",
  "37d06a90-0531-5e97-830a-781f34029bdc": "EI Premier 50M - MC Advanced 200K",
  "06e4df1c-e742-53ab-8901-9169a53e28c5": "EI Premier 100M - MC Custom Pricing",
  "78d027a9-305c-533f-aa0d-98fbe6ddd0f1": "EI Premier 100M - MC Basic 5K",
  "dcd971b2-7240-5eb8-8ac1-7a7426d976c1": "EI Premier 100M - MC Basic 10K",
  "7058f292-ccdc-57b1-9de2-9cafa04ad5d3": "EI Premier 100M - MC Basic 20K",
  "bf4b2789-4f6a-5642-b5b1-6b394f32fc9b": "EI Premier 100M - MC Basic 50K",
  "191ce83c-18ea-5192-93ba-189cd9f324ca": "EI Premier 100M - MC Basic 100K",
  "59ec25f2-b3c6-56c6-8ad4-30ed05878ea7": "EI Premier 100M - MC Advanced 10K",
  "fe36fe1b-704f-5932-89a5-95596d939276": "EI Premier 100M - MC Advanced 20K",
  "1737a651-948e-565f-9895-cf78c4bf468b": "EI Premier 100M - MC Advanced 50K",
  "6885c8e6-9c7f-5a18-9a15-c4eb75ec72d5": "EI Premier 100M - MC Advanced 100K",
  "7cd10ee5-6322-56f7-8c13-46f9ccbd7c6f": "EI Premier 100M - MC Advanced 200K",
  "85139bcb-cd5f-55c3-8b9a-793e2a06131d": "EI Premier 200M - MC Custom Pricing",
  "5799dc85-42cc-5f2c-b5df-6e0dbb76a137": "EI Premier 200M - MC Basic 5K",
  "c58eb348-ac68-512b-8167-a375ddda4c27": "EI Premier 200M - MC Basic 10K",
  "73493901-5e63-5754-bb12-e4a21f3c3853": "EI Premier 200M - MC Basic 20K",
  "96897633-db12-5fe6-af1f-2e6431f88480": "EI Premier 200M - MC Basic 50K",
  "d32429ec-a393-553c-9401-6bccc7249b42": "EI Premier 200M - MC Basic 100K",
  "85f005d7-60cd-5269-9486-6f402a88a0c6": "EI Premier 200M - MC Advanced 10K",
  "2046beb2-a8dc-5b64-8115-7bb74abc0e81": "EI Premier 200M - MC Advanced 20K",
  "cadf17b7-6e45-564d-8215-11fa985206c7": "EI Premier 200M - MC Advanced 50K",
  "cfd667ec-fab1-5835-a3dc-19e7a967c878": "EI Premier 200M - MC Advanced 100K",
  "e484ff10-225d-5aef-bc12-be2a951ba9e6": "EI Premier 200M - MC Advanced 200K",
  "068ec2c2-e643-536c-b6cb-5dc5aa64cbb7": "EI Premier 300M - MC Custom Pricing",
  "59759a12-6666-502c-a24e-ef26f7edc67b": "EI Premier 300M - MC Basic 5K",
  "5068d79d-3cd9-5f15-8f6b-761623540e28": "EI Premier 300M - MC Basic 10K",
  "3781e24a-5dce-5f55-80fc-976963e1df46": "EI Premier 300M - MC Basic 20K",
  "bf6af57e-0bd1-5364-9d04-1545edab6222": "EI Premier 300M - MC Basic 50K",
  "3af19758-5c36-5a28-b92f-3663d9f0c08f": "EI Premier 300M - MC Basic 100K",
  "8e72603b-da34-5354-8d42-e9c4353fb240": "EI Premier 300M - MC Advanced 10K",
  "cd5f0c9d-ecfd-5d93-ae66-690968992eee": "EI Premier 300M - MC Advanced 20K",
  "f466a4f6-8c93-5767-9c9c-d8ce7b9b7e3b": "EI Premier 300M - MC Advanced 50K",
  "10c37d73-f07e-5082-bf58-453e5850fb19": "EI Premier 300M - MC Advanced 100K",
  "32a623f4-0853-57e4-ac0b-218bc59b3782": "EI Premier 300M - MC Advanced 200K",
  "6a96348b-0e3b-526f-9d26-aac29ccc2239": "EI Premier 400M - MC Custom Pricing",
  "2237de30-9c3c-5cb4-920a-253892f6cf04": "EI Premier 400M - MC Basic 5K",
  "40dcaafe-f55d-543c-9261-701e2938838c": "EI Premier 400M - MC Basic 10K",
  "e47cb887-6ffa-514d-a94b-1d3574ea35dc": "EI Premier 400M - MC Basic 20K",
  "b6ae774d-44e5-50ee-906d-8aed0e754b50": "EI Premier 400M - MC Basic 50K",
  "d46c0bad-de1c-561c-b842-765398111c26": "EI Premier 400M - MC Basic 100K",
  "1a6654c7-b884-51fb-b138-925e8d5ab776": "EI Premier 400M - MC Advanced 10K",
  "67872593-5eb0-52cc-90d9-7f5d4b695d94": "EI Premier 400M - MC Advanced 20K",
  "e5741fec-d5b5-54ec-ae9e-a65d0dd00eb4": "EI Premier 400M - MC Advanced 50K",
  "42bd1da9-3f66-5c33-9467-ff3bd1255a92": "EI Premier 400M - MC Advanced 100K",
  "8e1877ae-e99c-57f0-92af-7f9463b43bdd": "EI Premier 400M - MC Advanced 200K",
  "eae9d702-4926-5ace-ba2c-4bc59423e872": "EI Premier 500M - MC Custom Pricing",
  "165a970e-a286-5124-9a54-05deffc67d1a": "EI Premier 500M - MC Basic 5K",
  "b6933181-caa5-5b92-aca6-3cc1549b215c": "EI Premier 500M - MC Basic 10K",
  "2838e8af-45a5-5a2e-8904-8341241b7cb1": "EI Premier 500M - MC Basic 20K",
  "ccdab9c3-9ebd-5cbf-b139-5019f40a5771": "EI Premier 500M - MC Basic 50K",
  "3bf6b467-6215-5d4a-acc9-8443637178a1": "EI Premier 500M - MC Basic 100K",
  "63060e7c-4dd4-5e05-b95b-b46d939b11b5": "EI Premier 500M - MC Advanced 10K",
  "5ee56850-a672-526b-b83b-a223f66c7cbe": "EI Premier 500M - MC Advanced 20K",
  "77d6d639-15cd-5fb8-b7aa-056a04248aa3": "EI Premier 500M - MC Advanced 50K",
  "e72c752f-05cd-57a5-a45d-0802442b4b69": "EI Premier 500M - MC Advanced 100K",
  "9a40e69d-1215-5da7-8819-25b4a290554f": "EI Premier 500M - MC Advanced 200K",
  "d5f91cf6-77fe-5d96-b03d-90a6a81a8033": "EI Premier 600M - MC Custom Pricing",
  "8aa8389d-4418-554a-bbb5-fff81fac84bd": "EI Premier 600M - MC Basic 5K",
  "9e142c2c-f5d0-5a36-8b18-0cdf0d45cc80": "EI Premier 600M - MC Basic 10K",
  "206f3528-adda-5bd2-a108-55d09df611a9": "EI Premier 600M - MC Basic 20K",
  "8fc5ab5c-aed3-5abd-8d58-2f4a17fc52f4": "EI Premier 600M - MC Basic 50K",
  "96bbda6e-8621-57aa-9948-696ba83b5fbf": "EI Premier 600M - MC Basic 100K",
  "51ed2717-b3ff-5a3f-b77e-3b9157212918": "EI Premier 600M - MC Advanced 10K",
  "f6116742-d34c-5d46-b4c9-ec94c7e35652": "EI Premier 600M - MC Advanced 20K",
  "7807e26f-c820-5865-8e68-32054bcf7db1": "EI Premier 600M - MC Advanced 50K",
  "f4459d7a-4699-5a76-a1d1-19e11bbc2671": "EI Premier 600M - MC Advanced 100K",
  "e5a724b8-0772-5d40-bfcc-edb93130e522": "EI Premier 600M - MC Advanced 200K",
};

// both prod and staging IDs included; user with one of these packages will not see the 'Change Plan' button
const premierPackagesOver25M = {
  ...premierPackagesOver50M,
  // staging - legacy type plans
  "8b860844-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 50M Package",
  // staging - current type plans
  "c0c444da-499a-4f17-a7f7-ac2ce4771923": "Premier - 50M",
  // prod - legacy type plans
  // eslint-disable-next-line
  "8b860844-ce8a-11e4-b4e5-5fcde71ee009": "Premier 50M Package",
  // prod - current type plans
  "130066f7-112c-4fdd-8074-f8260485f7c8": "Premier - 50M",
};

const premierPackages25MAndUnder = {
  // Legacy
  // staging
  "8b85bfcc-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 2.5M Package",
  "8b85cd0e-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 3M Plan",
  "8b85d7ea-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 5M Package",
  "8b85e7c0-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 10M Package",
  "8b85ebff-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 25M Package",
  // production (IDs are the same as staging, legacy plans)
  // eslint-disable-next-line
  "8b85bfcc-ce8a-11e4-b4e5-5fcde71ee009": "Premier 2.5M Package",
  // eslint-disable-next-line
  "8b85cd0e-ce8a-11e4-b4e5-5fcde71ee009": "Premier 3M Package",
  // eslint-disable-next-line
  "8b85d7ea-ce8a-11e4-b4e5-5fcde71ee009": "Premier 5M Package",
  "8b8a2328-ce8a-11e4-b4e5-5fcde71ee009": "Premier 7.5 M Package",
  // eslint-disable-next-line
  "8b85e7c0-ce8a-11e4-b4e5-5fcde71ee009": "Premier 10M Package",
  "8b89fb2b-ce8a-11e4-b4e5-5fcde71ee009": "Premier - 15M Package",
  "8b8a1857-ce8a-11e4-b4e5-5fcde71ee009": "Premier - 20M Package",
  // eslint-disable-next-line
  "8b85ebff-ce8a-11e4-b4e5-5fcde71ee009": "Premier 25M Package",
  // Current Plans
  // staging
  "0f302e1d-4df5-457b-bc68-78990ea9124b": "Premier - 5M",
  "bea64b21-50df-4dc7-8b6d-b52bdd3a3d9c": "Premier - 7.5M",
  "7f5e1809-c3e1-42d8-84b0-ecb03fe60411": "Premier - 10M",
  "37e8b4f2-243c-4ab3-8f3f-274a11c9f725": "Premier - 15M",
  "05e0b81e-902b-4abd-bac7-697caee47891": "Premier - 20M",
  "d2999580-8427-4973-b073-bdf86736a125": "Premier - 25M",
  // production
  "401aee44-5d1a-4d7f-9b64-8196cbe7bd76": "Premier - 5M",
  "0a0bc258-4980-45c7-9839-eaed27a92814": "Premier - 7.5M",
  "a7856473-f98a-421a-9810-cd16905fc49a": "Premier - 10M",
  "e79cd326-4193-4e8c-b461-207d3da437b6": "Premier - 15M",
  "f2ae9db6-731b-45f6-b234-fde67c317d28": "Premier - 20M",
  "c18fdaee-c23a-4611-a4ee-b31778d8c2a1": "Premier - 25M",
  // Multi-product combination ids (they will be the same in staging and production)
  "21329db0-6761-5134-8563-7ad23f627c29": "EI Premier 5M - MC Custom Pricing",
  "cdca573a-30dd-5eb4-94f0-8f85879d6740": "EI Premier 5M - MC Basic 5K",
  "bb242424-19c2-5351-8a12-5fa3ed38cc67": "EI Premier 5M - MC Basic 10K",
  "3f61ef8b-e040-58bf-b239-75a83ea14e70": "EI Premier 5M - MC Basic 20K",
  "893e8925-f883-5d69-a616-452b658aad22": "EI Premier 5M - MC Basic 50K",
  "566f9c82-a43a-5328-a3a2-660b5b450e02": "EI Premier 5M - MC Basic 100K",
  "d2a89d91-1025-528a-a4b9-8295e80aa5a2": "EI Premier 5M - MC Advanced 10K",
  "a1c84110-8c4f-5dfc-8dbb-e0a3bd0fbca2": "EI Premier 5M - MC Advanced 20K",
  "f3166604-8552-53de-8e46-3b534123a9b2": "EI Premier 5M - MC Advanced 50K",
  "ae70f923-e220-5069-8a34-6c0bbcaf082f": "EI Premier 5M - MC Advanced 100K",
  "8a3f8a0f-22e3-5184-8137-b55e792a9066": "EI Premier 5M - MC Advanced 200K",
  "88c799ec-a740-5d49-9287-e0c4a78a1fe6": "EI Premier 7.5M - MC Custom Pricing",
  "0dbfc093-7a33-5bb5-9814-b9a96d85a9d9": "EI Premier 7.5M - MC Basic 5K",
  "711cb547-74bc-50c4-960e-3c11603ef31b": "EI Premier 7.5M - MC Basic 10K",
  "eae96cd8-7a6d-58c9-b28a-166b4e18e5a1": "EI Premier 7.5M - MC Basic 20K",
  "55eebc99-196f-533a-9525-280002f9ec5f": "EI Premier 7.5M - MC Basic 50K",
  "c49df956-1b3f-52f0-bb5b-b832295a324d": "EI Premier 7.5M - MC Basic 100K",
  "d23cda46-c90d-53d4-a35f-2f67727ad61c": "EI Premier 7.5M - MC Advanced 10K",
  "005a4b3a-fdd0-58cd-a002-c91dc474e303": "EI Premier 7.5M - MC Advanced 20K",
  "ea7dbb29-e8fe-511d-8273-78218e859274": "EI Premier 7.5M - MC Advanced 50K",
  "d2cb136d-b4ed-59d7-83e6-85626887bf3d": "EI Premier 7.5M - MC Advanced 100K",
  "75c9c793-28fb-57b8-9d63-df4fd664fb7a": "EI Premier 7.5M - MC Advanced 200K",
  "0bb9bbc9-8f43-5cbd-86af-8e9d0562738b": "EI Premier 10M - MC Custom Pricing",
  "fcb57fcf-cc6e-588e-87ef-840ecd1a93a5": "EI Premier 10M - MC Basic 5K",
  "9614882e-c452-5766-a5d8-0b5feb3f90d8": "EI Premier 10M - MC Basic 10K",
  "ab0ae625-dfb7-5383-8d90-b00b1a901c90": "EI Premier 10M - MC Basic 20K",
  "dea74e17-46b6-52e5-a85a-f84641e1a018": "EI Premier 10M - MC Basic 50K",
  "36099742-cc29-55a8-b5ae-7058b265d03b": "EI Premier 10M - MC Basic 100K",
  "fee7c1d1-43ff-54c2-a5c3-abdc53facaec": "EI Premier 10M - MC Advanced 10K",
  "591ce346-eed0-5dbd-8c11-e2fd6b21cab1": "EI Premier 10M - MC Advanced 20K",
  "93a73ba3-9e99-5bf2-baad-54b79cc8f421": "EI Premier 10M - MC Advanced 50K",
  "a94218e5-8f05-5402-a278-279b0cf25775": "EI Premier 10M - MC Advanced 100K",
  "898c1394-4deb-5d85-95ac-193f13edaac2": "EI Premier 10M - MC Advanced 200K",
  "bdc4f812-fb61-597c-991a-5ca0caa71713": "EI Premier 15M - MC Custom Pricing",
  "c9763b0d-f17b-5d13-a0e1-551ecf2724f0": "EI Premier 15M - MC Basic 5K",
  "7d71f342-2202-5a9b-adb1-f98d8e13fd7e": "EI Premier 15M - MC Basic 10K",
  "c2857757-4808-5792-83d2-27404554612f": "EI Premier 15M - MC Basic 20K",
  "c3666548-0266-5619-8907-92b0fa150702": "EI Premier 15M - MC Basic 50K",
  "07ee1eba-61b7-5903-8068-194f2ec0bf8e": "EI Premier 15M - MC Basic 100K",
  "a6a15558-8293-571a-be17-98ac22a9a8a4": "EI Premier 15M - MC Advanced 10K",
  "83af8c5b-b35e-5107-a7f8-bbc775eeeaad": "EI Premier 15M - MC Advanced 20K",
  "58a94d75-cad1-5c70-a68e-bc411c58661d": "EI Premier 15M - MC Advanced 50K",
  "53df2432-5035-55ff-b3de-0eca464f9007": "EI Premier 15M - MC Advanced 100K",
  "fd8e8312-cd4e-5212-b06d-af877e8f6771": "EI Premier 15M - MC Advanced 200K",
  "0c9e81c1-90fe-5417-9ea7-5196a93043ba": "EI Premier 20M - MC Custom Pricing",
  "8e48b76d-869c-59ce-ba4b-9c1b25c5b7d1": "EI Premier 20M - MC Basic 5K",
  "aad83179-3a1b-5fd7-aee7-220df5747c41": "EI Premier 20M - MC Basic 10K",
  "a2f57e1e-1186-5a3b-9513-b203959c8ff0": "EI Premier 20M - MC Basic 20K",
  "f6a42cde-45f1-5f05-b09f-1d63748a158f": "EI Premier 20M - MC Basic 50K",
  "46a3b09a-e123-5e41-80b8-40e683dac6ed": "EI Premier 20M - MC Basic 100K",
  "9fef2b84-abd2-55b9-b3a7-7f769fa2aafb": "EI Premier 20M - MC Advanced 10K",
  "461d78bc-a4d9-5b90-966d-449fec3d2dcd": "EI Premier 20M - MC Advanced 20K",
  "98ba39e6-b68d-55e6-9ec4-bb701845eff4": "EI Premier 20M - MC Advanced 50K",
  "c0a01637-5f4f-5a33-af76-941bf1a36d88": "EI Premier 20M - MC Advanced 100K",
  "d2afc92b-1af8-50b5-b8f9-2dda92eeb35f": "EI Premier 20M - MC Advanced 200K",
  "e84541b9-19fa-582d-a7e1-7a071ccf5e2f": "EI Premier 25M - MC Custom Pricing",
  "2d98efb8-f8dd-564b-bcd7-ab3c352a5ba1": "EI Premier 25M - MC Basic 5K",
  "7b44b591-0891-55fa-97dc-4d3ef467afdb": "EI Premier 25M - MC Basic 10K",
  "9b473028-d007-5c62-b589-271558dc23f2": "EI Premier 25M - MC Basic 20K",
  "c84000f0-db82-5a3d-8f96-9196fcf3eb0b": "EI Premier 25M - MC Basic 50K",
  "3d231a7f-c6e5-5e94-92f7-bca2fab56fe5": "EI Premier 25M - MC Basic 100K",
  "e532dc8b-a06a-5753-94a6-59674224ce95": "EI Premier 25M - MC Advanced 10K",
  "f678cc46-9d9d-56b8-9a3e-e47b73afbebe": "EI Premier 25M - MC Advanced 20K",
  "635c4fee-717d-59e1-9621-0b17db74b526": "EI Premier 25M - MC Advanced 50K",
  "54b99928-5e41-5e3e-9916-52db874a72dd": "EI Premier 25M - MC Advanced 100K",
  "46e9d730-d9cd-52d1-b006-8852c0eccf69": "EI Premier 25M - MC Advanced 200K",
};

const packageConfig = {
  hiddenPackages: {
    "b545e102-055d-4c7e-9b3e-fe09e538f384": "Pro 2.5 Million Legacy",
    "8b9ce1c9-ce8a-11e4-b4e5-5fcde71ee009": "Essentials 40k Old Pricing",
    "8b9cf53b-ce8a-11e4-b4e5-5fcde71ee009": "Essentials 100k Old Pricing",
  },
  freePackages: {
    "8b9ae93b-ce8a-11e4-b4e5-5fcde71ee009": "Free 12K",
    "02a5084c-94d7-458d-9a88-1d2cfd37b262": "Free 100",
    "71713987-9f01-4dea-b3d4-8d0bcd9d53ed": "Trial 40K",
    "8b9b079e-ce8a-11e4-b4e5-5fcde71ee009": "Free Package GitHub Education",
    // TODO: update with multi-product free packages
  },
  essentialsPackages: {
    "8b9ce1c9-ce8a-11e4-b4e5-5fcde71ee009": "Essentials 40k Old Pricing", // also in hiddenPackages
    "8b9cf53b-ce8a-11e4-b4e5-5fcde71ee009": "Essentials 100k Old Pricing", // also in hiddenPackages
    "441b7834-59bc-4d25-8b54-7ff1f6c0e3c7": "Essentials 40k",
    "a99239c9-1e90-4d83-9686-617feff18a05": "Essentials 100k",
    // TODO: update with multi-product essentials packages
  },
  proPackages: {
    "8b9d04af-ce8a-11e4-b4e5-5fcde71ee009": "Pro 100K",
    "8b9d0cf5-ce8a-11e4-b4e5-5fcde71ee009": "Pro 300K",
    "8b9d1f56-ce8a-11e4-b4e5-5fcde71ee009": "Pro 700K",
    "0c37e79f-8c17-453a-b72d-061ca2fa5c54": "Pro 1.5 Million",
    "fddb569d-5745-48cd-af8c-e78ba8e3b478": "Pro 2.5 Million",
    // Multi-product combination package ids (they will be same in staging and production)
    "3b752c73-4779-5d7b-b4db-2f3014298ca0": "EI Pro 100K - MC Custom Pricing",
    "22789c56-2b52-5014-947c-1dcf0171c4e0": "EI Pro 100K - MC Basic 5K",
    "9c10b1fe-6a5b-5134-901d-a2fa01c29aa1": "EI Pro 100K - MC Basic 10K",
    "73b956ea-3509-5d9e-a516-1eb782a96249": "EI Pro 100K - MC Basic 20K",
    "e9bf511b-8821-519b-b52d-a23a1a0d94a5": "EI Pro 100K - MC Basic 50K",
    "893f710a-be09-58ac-a796-383ded87125b": "EI Pro 100K - MC Basic 100K",
    "7b972452-1584-59c8-919f-38a6362a0cf3": "EI Pro 100K - MC Advanced 10K",
    "907f14e5-9af2-5579-8c8f-8818dbc5996c": "EI Pro 100K - MC Advanced 20K",
    "4547aeb0-75a3-574b-8c70-3f9d7d1129ff": "EI Pro 100K - MC Advanced 50K",
    "ee7486ba-4140-5277-9102-5097f264a61b": "EI Pro 100K - MC Advanced 100K",
    "82627447-8e49-5552-ac0e-9798abba4112": "EI Pro 100K - MC Advanced 200K",
    "cd8bac58-a293-51a3-a3d9-af3fd07cf018": "EI Pro 300K - MC Custom Pricing",
    "4d00f1dc-a86a-5487-9963-826daba1e833": "EI Pro 300K - MC Basic 5K",
    "08e71c3c-3c2c-5318-8ec1-2efebc0518f1": "EI Pro 300K - MC Basic 10K",
    "70835b30-0f0b-50bc-bd82-07e85a006d0a": "EI Pro 300K - MC Basic 20K",
    "134dc5c0-5e56-5976-888c-80c7835f8cae": "EI Pro 300K - MC Basic 50K",
    "dc1397c1-9808-56f0-ae7e-84f531cd73ba": "EI Pro 300K - MC Basic 100K",
    "41f76948-1967-5b48-9128-66fb57c92f14": "EI Pro 300K - MC Advanced 10K",
    "05f40852-9e09-5564-b7ca-12f4671afabe": "EI Pro 300K - MC Advanced 20K",
    "23294044-8d83-59e4-96d4-f36ca1f67eae": "EI Pro 300K - MC Advanced 50K",
    "b682e1d0-d082-50e9-9e57-97a8a88a4af4": "EI Pro 300K - MC Advanced 100K",
    "a32015b9-fa70-5d7b-ade6-b1f293dfb01e": "EI Pro 300K - MC Advanced 200K",
    "3a3cee20-ac6e-54a3-a68c-c83ffe7d26a3": "EI Pro 700K - MC Custom Pricing",
    "a70fc363-acb6-5c02-83ca-b860e02ebd85": "EI Pro 700K - MC Basic 5K",
    "64e770d5-13f9-59cd-b523-3a9dc928ea0a": "EI Pro 700K - MC Basic 10K",
    "8a11ea10-ee46-5de0-aff9-6512bc44f963": "EI Pro 700K - MC Basic 20K",
    "1cd79a51-3ec8-5c8e-bcc4-22da58fff092": "EI Pro 700K - MC Basic 50K",
    "a3f0ceb5-18c1-5df4-a7fa-6e0488db8471": "EI Pro 700K - MC Basic 100K",
    "4eed1fd5-c096-5b44-ae7f-75041189fedb": "EI Pro 700K - MC Advanced 10K",
    "f962d74f-3f96-5e0f-9deb-bb6e09fd8fcd": "EI Pro 700K - MC Advanced 20K",
    "68c5e0b9-ef7d-58f2-b18f-ae3f9824b704": "EI Pro 700K - MC Advanced 50K",
    "18e69aec-0c9c-5397-8555-3d1ee74091b2": "EI Pro 700K - MC Advanced 100K",
    "c5cfecfc-ac11-5e50-bb6a-d994570bc955": "EI Pro 700K - MC Advanced 200K",
    "cdbdc55e-a2ac-534b-984e-36fac8aca16b": "EI Pro 1.5M - MC Custom Pricing",
    "734184a6-d29c-506e-99bc-aafdd833e439": "EI Pro 1.5M - MC Basic 5K",
    "a6c8ffd3-fd7f-540c-b7f5-bd14c8a117ca": "EI Pro 1.5M - MC Basic 10K",
    "ee11460f-9642-5857-9071-a651f8e94b18": "EI Pro 1.5M - MC Basic 20K",
    "b92969e5-c11d-59f4-86b6-f84465ad2d3a": "EI Pro 1.5M - MC Basic 50K",
    "342b61c2-dd86-56ba-930c-6ca02e27e031": "EI Pro 1.5M - MC Basic 100K",
    "72a2134a-5385-56c9-a627-54f0c6f8ed82": "EI Pro 1.5M - MC Advanced 10K",
    "0c04259b-3c2d-5b48-9bd6-472d5f989c8c": "EI Pro 1.5M - MC Advanced 20K",
    "e179338c-652a-50b5-b337-ce8ac4391800": "EI Pro 1.5M - MC Advanced 50K",
    "31a9b7dd-ea37-5953-b2f6-0b5720a171ad": "EI Pro 1.5M - MC Advanced 100K",
    "6f6a6b85-afd1-5988-9cfa-a4cd0b8ef818": "EI Pro 1.5M - MC Advanced 200K",
    "4aef00eb-3979-5af5-b88f-272a8552b05d": "EI Pro 2.5M - MC Custom Pricing",
    "104ce005-f1e8-56ae-bcc6-21176456d6c4": "EI Pro 2.5M - MC Basic 5K",
    "8c70f928-aa13-581e-9c79-10ea5979f994": "EI Pro 2.5M - MC Basic 10K",
    "5a91247d-b6ee-59ee-99a1-5a4a212a0ffb": "EI Pro 2.5M - MC Basic 20K",
    "d22d808e-d4cf-54a2-a84e-07a0de4c0b7d": "EI Pro 2.5M - MC Basic 50K",
    "c3309a05-690f-5071-8554-ce65459424ad": "EI Pro 2.5M - MC Basic 100K",
    "5d31191f-046e-528a-9994-a078313c391f": "EI Pro 2.5M - MC Advanced 10K",
    "4a7b50b1-b58d-560e-8657-aa367642be9c": "EI Pro 2.5M - MC Advanced 20K",
    "fac211db-8580-57e3-9612-aae15d4729ea": "EI Pro 2.5M - MC Advanced 50K",
    "e4c92fd0-af42-5dd3-9e9a-96e6bc5da3be": "EI Pro 2.5M - MC Advanced 100K",
    "674551cb-eaad-5d68-9b18-4f1ae0863c2a": "EI Pro 2.5M - MC Advanced 200K",
  },
  adsPackages: {
    "76559ee6-9cbc-42ee-abb7-02efb53672f5": "Ads",
    "97982829-c3ea-40af-b989-e9905943c0d5": "Ads",
    "1c139ecc-7717-4037-ac53-e9377cb58adc": "Ads",
  },
  premierPackages: {
    ...premierPackages25MAndUnder,
    ...premierPackagesOver25M,
  },
  customPackageGroups: {
    "8b83ef22-ce8a-11e4-b4e5-5fcde71ee009": "Basic Plan",
    "8b844d8a-ce8a-11e4-b4e5-5fcde71ee009": "Silver Package - wozai",
    "8b84553c-ce8a-11e4-b4e5-5fcde71ee009": "Sinan - Reseller Plan",
    "8b84602c-ce8a-11e4-b4e5-5fcde71ee009": "Silver Package  - Tiered",
    "8b849af5-ce8a-11e4-b4e5-5fcde71ee009": "Basic Package - Plus IP",
    "8b84f73b-ce8a-11e4-b4e5-5fcde71ee009": "Silver Package - Reseller",
    "8b85006e-ce8a-11e4-b4e5-5fcde71ee009":
      "Silver Package - Reseller - Tiered",
    "8b850c2a-ce8a-11e4-b4e5-5fcde71ee009": "Basic Package - Reseller",
    "8b851ab8-ce8a-11e4-b4e5-5fcde71ee009": "Basic Package - Whitelabel - IP",
    "8b855637-ce8a-11e4-b4e5-5fcde71ee009": "MapMyFitness - Tiered",
    "8b858962-ce8a-11e4-b4e5-5fcde71ee009": "Unlimited Package - Efficiency",
    "8b859f75-ce8a-11e4-b4e5-5fcde71ee009": "Unlimited Package - CommonFloor",
    "8b85a7d5-ce8a-11e4-b4e5-5fcde71ee009": "Formspring Plan",
    "8b85ad4f-ce8a-11e4-b4e5-5fcde71ee009": "Platinum Package - build.com",
    "8b85b238-ce8a-11e4-b4e5-5fcde71ee009": "Silver Package - 20% off",
    "8b867efb-ce8a-11e4-b4e5-5fcde71ee009": "Foursquare - Custom",
    "8b882049-ce8a-11e4-b4e5-5fcde71ee009": "Platinum Package",
    "8b889b5b-ce8a-11e4-b4e5-5fcde71ee009": "Doostang - Custom",
    "8b88c46a-ce8a-11e4-b4e5-5fcde71ee009": "MyYearbook Custom 1",
    "8b88dbea-ce8a-11e4-b4e5-5fcde71ee009": "MyYearbook Custom 2",
    "8b890293-ce8a-11e4-b4e5-5fcde71ee009": "Gold Package",
    "8b890d3b-ce8a-11e4-b4e5-5fcde71ee009": "Lite Package",
    "8b8a08d9-ce8a-11e4-b4e5-5fcde71ee009": "lite package here",
    "8b8b5134-ce8a-11e4-b4e5-5fcde71ee009": "Azure",
    "8b8c80ab-ce8a-11e4-b4e5-5fcde71ee009": "High Volume 1M Package",
    "8b8d027c-ce8a-11e4-b4e5-5fcde71ee009": "Chirpme Tiered",
    "8b8e5cee-ce8a-11e4-b4e5-5fcde71ee009": "Spotify Tiered",
    "8b8e8684-ce8a-11e4-b4e5-5fcde71ee009": "Needish Tiered",
    "8b8ea57b-ce8a-11e4-b4e5-5fcde71ee009": "Booking.com Tiered",
    "8b9169d4-ce8a-11e4-b4e5-5fcde71ee009": "Enterprise Tiered",
    "8b926635-ce8a-11e4-b4e5-5fcde71ee009": "Switch Labs, Inc. Tiered",
    "8b94a223-ce8a-11e4-b4e5-5fcde71ee009": "Slideshare/LinkedIn Tiered",
    "8b983246-ce8a-11e4-b4e5-5fcde71ee009": "Booking.com Tiered - 2",
    "8b9cce3d-ce8a-11e4-b4e5-5fcde71ee009": "Vevo Tiered",
    "00fb078d-1814-4083-8f44-cc4be22f9e9d": "Custom Pricing Plan",
  },
  premierPackages25MAndUnder,
  premierPackagesOver25M,
  premierPackagesOver50M,
  adsDefaultPackage: {
    adjusted_price: 50.0,
    base_price: 50.0,
    credits: 0,
    description: "",
    name: "Ads",
    newsletter_price: 0.0,
    overage_price: 0.0,
    plan_type: "paid",
    total: 50.0,
  },
  overage_rates: {
    "Essentials 100K": 0.00075,
    "Pro 300K": 0.0005,
    "Pro 700K": 0.00045,
  },
};

export default packageConfig;
