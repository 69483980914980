import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';

import { EaseSubscription } from './types';

export const Actions = {
  // get ease
  getEase: () => createAction(ActionTypes.GetEaseRequest),
  getEaseSuccess: (ease: EaseSubscription) =>
    createAction(ActionTypes.GetEaseSuccess, ease),
  getEaseFailure: () => createAction(ActionTypes.GetEaseFailure),
  // delete ease
  deleteEase: () => createAction(ActionTypes.DeleteEaseRequest),
  deleteEaseSuccess: (ease: EaseSubscription) =>
    createAction(ActionTypes.DeleteEaseSuccess, ease),
  deleteEaseFailure: () => createAction(ActionTypes.DeleteEaseFailure),
  // add ease
  addEase: () => createAction(ActionTypes.AddEaseRequest),
  addEaseSuccess: (ease: EaseSubscription) =>
    createAction(ActionTypes.AddEaseSuccess, ease),
  addEaseFailure: () => createAction(ActionTypes.AddEaseFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
