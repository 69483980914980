import {
  EntitlementsDto,
  OesAccountDto,
  PricingDto,
} from '../../services/oes/dtos';
import { EmailValidation } from '../../../types/emailValidation';
import { ApiKeyDto, UsageStatsDto } from '../../services/mako_api/dtos';

export function getEmailValidationCredits(pricing: PricingDto) {
  if (
    !pricing ||
    !pricing.products ||
    !pricing.products.ev_usage ||
    !pricing.products.ev_usage.tiers
  ) {
    return 0;
  }
  const freeTier = pricing.products.ev_usage.tiers.find(
    (tier) => tier.amount === '0'
  );
  return freeTier && freeTier.size ? freeTier.size : 0;
}

export function getEmailValidationMinOverageCost(oesAccount: OesAccountDto) {
  if (
    !oesAccount ||
    !oesAccount.pricing ||
    !oesAccount.pricing.products ||
    !oesAccount.pricing.products.ev_usage ||
    !oesAccount.pricing.products.ev_usage.tiers
  ) {
    return 0;
  }
  const tierAmounts = oesAccount.pricing.products.ev_usage.tiers
    .map((tier) => {
      return Number.parseFloat(tier.amount);
    })
    .filter((amount) => amount > 0);
  return tierAmounts.length ? tierAmounts[0] : 0;
}

export function isEmailValidationActive(apiKeys: ApiKeyDto[], usage: number) {
  const hasEmailValidationApiKey = !!apiKeys.find((apiKey) => {
    const subScope = 'validations.email';
    return (
      !!apiKey.scopes &&
      !!apiKey.scopes.find((scope) => scope.indexOf(subScope) !== -1)
    );
  });
  return hasEmailValidationApiKey || usage > 0;
}

export function getEmailValidationTotalCost(
  oesAccount: OesAccountDto,
  usage: number
) {
  if (
    !oesAccount ||
    !oesAccount.pricing ||
    !oesAccount.pricing.products ||
    !oesAccount.pricing.products.ev_usage ||
    !oesAccount.pricing.products.ev_usage.tiers
  ) {
    return 0;
  }
  const { tiers } = oesAccount.pricing.products.ev_usage;
  return tiers.reduce((acc, tier) => {
    if (
      tier.min === undefined ||
      tier.max === undefined ||
      tier.size === undefined
    ) {
      console.error(
        'Invalid pricing tier while calculating EV total cost',
        tier
      );
      return acc;
    }

    const amount = parseFloat(tier.amount) || 0;
    if (usage > tier.max) {
      return acc + tier.size * amount;
    }
    if (usage >= tier.min && usage <= tier.max) {
      const usageInTier = usage - tier.min + 1;
      return acc + usageInTier * amount;
    }
    return acc;
  }, 0);
}

function isEmailValidationPendingDowngrade(diffEntitlements?: EntitlementsDto) {
  if (!diffEntitlements) {
    return false;
  }
  const {
    email_validations_cap_monthly,
    email_validations_cap_daily,
  } = diffEntitlements;
  return (
    (email_validations_cap_monthly !== undefined &&
      email_validations_cap_monthly < 0) ||
    (email_validations_cap_daily !== undefined &&
      email_validations_cap_daily < 0)
  );
}

export function mapEmailValidation(
  oesAccount: OesAccountDto,
  apiKeys: ApiKeyDto[],
  usageStats: UsageStatsDto,
  diffEntitlements?: EntitlementsDto
): EmailValidation {
  const usage = usageStats.email_validations || 0;
  return {
    usage,
    isActive: isEmailValidationActive(apiKeys, usage),
    isPendingDowngrade: isEmailValidationPendingDowngrade(diffEntitlements),
    totalCost: getEmailValidationTotalCost(oesAccount, usage),
    includedCredits: getEmailValidationCredits(oesAccount.pricing),
    startingOverageCost: getEmailValidationMinOverageCost(oesAccount),
  };
}
