import { Action } from '../types/action';
import { ActionTypes } from './actionTypes';
import { EasePlan } from './types';

const initialState = [] as EasePlan[];

interface EasePlansAction extends Action {
  payload: EasePlan[];
}

export const easePlans = (state = initialState, action: EasePlansAction) => {
  switch (action.type) {
    case ActionTypes.GetEasePlansSuccess:
      return action.payload;
    case ActionTypes.GetEasePlansFailure:
    default:
      return state;
  }
};
