import { AuthedHttp, ResponseBodySuccess } from '../../helpers/AuthedHttp';
import { TeammateRequestAccessScope } from '../types/teammateRequestAccess';
import { TeammateRequestAccessScopeDto } from '../dtos';

const teammateRequestAccessRoute = 'scopes/requests';

export function teammateRequestAccess(
  teammateRequestAccessScope: TeammateRequestAccessScope
): Promise<TeammateRequestAccessScopeDto> {
  const { scopeGroupName: scope_group_name } = teammateRequestAccessScope;
  const teammateRequestAccessBody = {
    scope_group_name,
  } as TeammateRequestAccessScopeDto;

  return AuthedHttp.post<TeammateRequestAccessScopeDto>(
    teammateRequestAccessRoute,
    teammateRequestAccessBody
  )
    .then((response: ResponseBodySuccess<TeammateRequestAccessScopeDto>) => {
      // Old access denied page would return this default error for 500s specifically
      if (response.status === 500) {
        return {
          errors: [
            {
              field: 'teammates_request_access',
              message: 'Unable to request access at this time.',
            },
          ],
        };
      }
      return response.json();
    })
    .catch((err) => {
      // We also give the default error for network offline errors
      return {
        errors: [
          {
            field: 'teammates_request_access',
            message: 'Unable to request access at this time.',
          },
        ],
      };
    });
}
