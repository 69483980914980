import { AccountOfferingDto, EntitlementsDto } from '../../services/oes/dtos';
import { EaseSubscription } from '../../../ease_subscription/types';

const EASE_ADDON_PREFIX = 'sg.x.ease-30';

export function getEaseAddon(
  offerings: AccountOfferingDto[]
): AccountOfferingDto | undefined {
  return offerings.find((x) =>
    x.name.toLowerCase().includes(EASE_ADDON_PREFIX)
  );
}

export function mapEase(
  currentOfferings: AccountOfferingDto[],
  futureOfferings: AccountOfferingDto[],
  currentEntitlements: EntitlementsDto
): EaseSubscription {
  const currentEase = getEaseAddon(currentOfferings);
  const futureEase = getEaseAddon(futureOfferings);
  const isEaseEnding = !!currentEase && !!currentEase.end_date;
  const isPendingDowngrade = isEaseEnding && futureEase === undefined;
  const easeDays = currentEntitlements.ease_days || 0;

  return {
    planId: currentEase && currentEase.name,
    hasPurchasedEase: currentEase !== undefined,
    isPendingDowngrade,
    easeDays,
  } as EaseSubscription;
}
