import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { PaymentMethodDto, PaymentFormDto } from '../dtos';
import { SubscriptionPayment } from '../types/subscriptionPayment';
import { ResponseError } from '../types/responseError';

export const Actions = {
  getPaymentMethod: () => createAction(ActionTypes.GetPaymentMethodRequest),
  getPaymentMethodSuccess: (paymentMethodDto: PaymentMethodDto) =>
    createAction(ActionTypes.GetPaymentMethodSuccess, paymentMethodDto),
  getPaymentMethodFailure: (paymentMethodDto: PaymentMethodDto) =>
    createAction(ActionTypes.GetPaymentMethodFailure, paymentMethodDto),
  updateDefaultPaymentMethod: (subscriptionPayment: SubscriptionPayment) =>
    createAction(
      ActionTypes.UpdateDefaultPaymentMethodRequest,
      subscriptionPayment
    ),
  updateDefaultPaymentMethodSuccess: (paymentMethodDto: PaymentMethodDto) =>
    createAction(
      ActionTypes.UpdateDefaultPaymentMethodSuccess,
      paymentMethodDto
    ),
  updateDefaultPaymentMethodFailure: (errors: ResponseError[]) =>
    createAction(ActionTypes.UpdateDefaultPaymentMethodFailure, errors),
  reactivateUser: () => createAction(ActionTypes.ReactivateUser),
  reactivateUserSuccess: () => createAction(ActionTypes.ReactivateUserSuccess),
  reactivateUserFailure: () => createAction(ActionTypes.ReactivateUserFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
