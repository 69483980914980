import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';
import { ActionTypes } from './actionTypes';
import { timezoneAdapter } from './adapters';
import { Timezone } from '../types/user';
import { TimezoneDto } from '../dtos';

const initialState: MakoStateType['timezone'] = {} as Timezone;

interface TimezoneAction extends Action {
  payload: MakoStateType['timezone'];
}

export const timezone = (state = initialState, action: TimezoneAction) => {
  switch (action.type) {
    case ActionTypes.GetUserWithTimezone:
    case ActionTypes.UpdateTimezoneSuccess:
      return timezoneAdapter(action.payload as Timezone);
    case ActionTypes.UpdateTimezoneFailure:
      const { errors } = action.payload as TimezoneDto;
      const serverErrorMessage = (errors && errors[0].message) || '';
      const defaultErrorMessage =
        'An error occurred attempting to update your timezone. Please try again.';

      if (serverErrorMessage === defaultErrorMessage) {
        return {
          ...state,
          errors: {
            timezoneErrorMessage: defaultErrorMessage,
          },
        };
      }
      return {
        ...state,
        errors: {
          timezoneErrorMessage:
            serverErrorMessage || 'An error has occured. Please try again.',
        },
      };
    case ActionTypes.ClearTimezoneServerErrors:
      return { ...state, errors: {} };
    default:
      return state;
  }
};
