import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';

import { RemoveExpertInsightsRequest } from '../types/removeExpertInsights';
import { RemoveExpertInsightsDto } from '../dtos';

import { ActionTypes } from './actionTypes';

const initialState: MakoStateType['removeExpertInsights'] = {
  isRemovingService: false,
  errorMessage: '',
};

interface RemoveExpertInsightsAction extends Action {
  payload: RemoveExpertInsightsRequest | RemoveExpertInsightsDto;
}

export const removeExpertInsights = (
  state = initialState,
  action: RemoveExpertInsightsAction
) => {
  switch (action.type) {
    case ActionTypes.RemoveExpertInsightsRequest:
      return {
        isRemovingService: true,
        errorMessage: '',
      };
    case ActionTypes.RemoveExpertInsightsSuccess:
      return {
        isRemovingService: false,
        errorMessage: '',
      };
    case ActionTypes.RemoveExpertInsightsFailure:
      const payload = action.payload as RemoveExpertInsightsDto;
      const errorMessage = (payload.errors && payload.errors[0].message) || '';
      return {
        isRemovingService: false,
        errorMessage,
      };
    default:
      return state;
  }
};
