export const IMPERSONATING_USERNAME_COOKIE = 'sendgrid-impersonating-username';
export const TWILIO_SENDGRID_LOGO =
  'https://uiux-assets.sendgrid.com/logo/sg-twilio-lockup.svg';
export const LOGO_ALT = 'Twilio SendGrid Logo';
export const UNIFIED_USER_INFO_COOKIE_NAME = 'unified_user_info';
export const PUBLIC_ROUTES = [
  'login',
  'login/oauth',
  'oauth/error',
  // login route below is being used for SSO - notify reseller team before removing
  'login/from-site?token=:token',
  'forgot_password',
  'reset_password',
  'signup/validate',
  'errors/expired_reset_password',
  'teammates/confirm',
  'public/sender_auth/verification',
  'reset_credentials',
  'logout',
  'token_exchange',
  'token_exchange/verify',
  'reseller/sso/error',
  'login/identifier',
  'login/password',
  'auth0',
  'auth0/callback',
];
export const DOCS_LINKS = {
  en: {
    IMPLEMENT_DMARC:
      'https://docs.sendgrid.com/ui/sending-email/how-to-implement-dmarc',
    SETUP_DOMAIN_AUTH:
      'https://docs.sendgrid.com/ui/account-and-settings/how-to-set-up-domain-authentication',
    VALI_MAIL: 'https://sendgrid.com/en-us/partners/valimail',
  },
};
