import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';
import { ActionTypes } from './actionTypes';
import { CancelAccountReduxState } from '../types/cancelAccount';
import { CancelAccountInfoDto } from '../dtos';
import { transformErrors } from './errors';

const initialState: MakoStateType['cancelAccountInfo'] = {
  cancelErrorMessage: '',
  cancelErrorField: '',
  isCancellingAccount: false,
  cancelAccountSuccess: false,
} as CancelAccountReduxState;

interface CancelAccountAction extends Action {
  payload: MakoStateType['cancelAccountInfo'];
}

export const cancelAccountInfo = (
  state = initialState,
  action: CancelAccountAction
) => {
  switch (action.type) {
    case ActionTypes.CancelAccountRequest: {
      return { ...state, isCancellingAccount: true };
    }
    case ActionTypes.CancelAccountSuccess:
      return { ...initialState, cancelAccountSuccess: true };
    case ActionTypes.ResetCancelAccountInfo:
      return initialState;
    case ActionTypes.CancelAccountFailure:
      const { errors } = action.payload as CancelAccountInfoDto;
      return {
        ...transformErrors(errors),
        isCancellingAccount: false,
        cancelAccountSuccess: false,
      };
    default:
      return state;
  }
};
