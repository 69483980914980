import {
  BounceClassificationTypes,
  BounceClassificationTotalsDto,
} from '../types';
import { calculateMetricRate } from './helpers';

type CountsByBounceClassification = {
  [classification in BounceClassificationTypes]: {
    count: number;
    percent: number;
  };
};

export interface BounceClassificationTotals
  extends CountsByBounceClassification {
  overallCount: number;
}

/*
  Calculate the overall counts and percentages for each bounce classification

  Output: 
  {
    overallCount: 25,
    content: {
      count: 5,
      percent: 20,
    },
    frequency: {
      count: 10,
      percent: 40,
    },
    ...
  }
*/
export function calculateBounceClassificationTotals(
  data: BounceClassificationTotalsDto
): BounceClassificationTotals {
  const classificationCounts: CountsByBounceClassification = {
    [BounceClassificationTypes.Content]: {
      count: 0,
      percent: 0,
    },
    [BounceClassificationTypes.Frequency]: {
      count: 0,
      percent: 0,
    },
    [BounceClassificationTypes.InvalidAddress]: {
      count: 0,
      percent: 0,
    },
    [BounceClassificationTypes.Reputation]: {
      count: 0,
      percent: 0,
    },
    [BounceClassificationTypes.MailboxUnavailable]: {
      count: 0,
      percent: 0,
    },
    [BounceClassificationTypes.Technical]: {
      count: 0,
      percent: 0,
    },
    [BounceClassificationTypes.Unclassified]: {
      count: 0,
      percent: 0,
    },
  };
  let overallCount = 0;

  // Sum up the counts for each classification
  data.result.forEach((date) => {
    date.stats.forEach((classification) => {
      const classificationType = classification.classification;
      // If a classification was updated or a new one was created, we need to add it to
      // our map of classification types and update the accordions accordingly.
      // We will check if the classification exists in our current map before adding it
      // to our classificationCounts so things don't break.
      if (
        Object.values<string>(BounceClassificationTypes).includes(
          classificationType
        )
      ) {
        classificationCounts[
          classificationType as BounceClassificationTypes
        ].count += classification.count;
        overallCount += classification.count;
      }
    });
  });

  // Calculate the percentages if the overallCount is not 0; otherwise, percentages will remain 0
  if (overallCount) {
    Object.keys(classificationCounts).forEach(
      (classification: BounceClassificationTypes) => {
        classificationCounts[classification].percent = calculateMetricRate(
          classificationCounts[classification].count,
          overallCount
        );
      }
    );
  }

  return {
    ...classificationCounts,
    overallCount,
  };
}
