import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SendGridAppDispatch, SendGridRootState } from './sendgridAppStore';
import { ResponseError } from '../../helperTypes/responseError';
import { ResponseBody } from '../../helpers/AuthedHttp';
import { GenericNetworkSlice, NetworkState } from './sliceNetworkTypes';

export interface UsernameDto {
  user_id: number;
  username: string;
}

export interface UsernameData {
  userId: number;
  username: string;
}

export type NonImpersonatedUsernameStore = GenericNetworkSlice<UsernameData>;

const initialState = {
  networkState: NetworkState.Unrequested,
} as NonImpersonatedUsernameStore;

const usernameAdapter = (dto: UsernameDto): UsernameData => {
  const { user_id, username } = dto;
  return {
    userId: user_id,
    username,
  };
};

export const getNonImpersonatedUsernameFromTiara = createAsyncThunk<
  UsernameData,
  ResponseBody<UsernameDto>,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('nonImpersonatedUsername/getProfileFromTiara', async (args, thunkApi) => {
  try {
    const response = await args;

    if (response.ok) {
      const data = await response.json();
      return usernameAdapter(data);
    }

    const errorData = await response.json();

    return thunkApi.rejectWithValue({
      message: errorData.errors[0].message,
      statusCode: response.status,
    });
  } catch {
    return thunkApi.rejectWithValue({
      message: 'Network Level Error',
    } as ResponseError);
  }
});

export const nonImpersonatedUsernameSlice = createSlice({
  name: 'nonImpersonatedUsername',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getNonImpersonatedUsernameFromTiara.pending, (state) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(
      getNonImpersonatedUsernameFromTiara.fulfilled,
      (state, action) => {
        state.networkState = NetworkState.Success;

        if (state.networkState === NetworkState.Success) {
          state.data = action.payload;
        }
      }
    );
    builder.addCase(
      getNonImpersonatedUsernameFromTiara.rejected,
      (state, action) => {
        state.networkState = NetworkState.Error;

        if (state.networkState === NetworkState.Error) {
          state.errorMessage = action?.payload?.message ?? 'Unknown Error';
        }
      }
    );
  },
});
