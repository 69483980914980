import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';

import { ExpertServicesOngoingDto, ExpertServiceOngoingDto } from '../dtos';
import { ResponseError } from '../types/responseError';

import { ActionTypes } from './actionTypes';
import { expertServicesOngoingAdapter } from './adapters';

const initialState: MakoStateType['expertServicesOngoing'] = {
  ongoingServices: [],
  errorMessage: '',
};

interface ExpertServicesOngoingAction extends Action {
  payload: ExpertServicesOngoingDto;
}

export const expertServicesOngoing = (
  state = initialState,
  action: ExpertServicesOngoingAction
) => {
  switch (action.type) {
    case ActionTypes.GetExpertServicesOngoingSuccess:
      return {
        ongoingServices: expertServicesOngoingAdapter(
          action.payload as ExpertServiceOngoingDto[]
        ),
        errorMessage: '',
      };
    case ActionTypes.GetExpertServicesOngoingFailure:
      const payload = action.payload as { errors: ResponseError[] };
      const errorMessage = (payload.errors && payload.errors[0].message) || '';
      return {
        ongoingServices: [],
        errorMessage,
      };
    default:
      return state;
  }
};
