import moment from 'moment';
import isEqual from 'lodash/isEqual';

import { CacheKeys as Keys } from './keys';

// NOTE: See ./Readme.md for documentation

const PREFIX = 'mako__';

export namespace Cache {
  // private functions
  const getStorage = (key: string) => {
    const cache = localStorage.getItem(key);
    return cache ? JSON.parse(cache) : cache;
  };

  const getStorageData = <T>(key: string) => {
    const cache = getStorage(key);
    return cache ? (cache.response as T) : cache;
  };

  const deleteStorageData = (key: string) => {
    return localStorage.removeItem(key);
  };

  const deleteStorageIfStale = <T>(
    key: string,
    response: T,
    shouldDeleteCachedObject: (cacheObj: T, response: T) => boolean
  ) => {
    const cache = getStorage(key);
    // If stored data exists
    if (cache) {
      const isExpired = cache.expiration < moment().unix();
      // if it is expired or if the object in storage matches the response object
      if (isExpired || shouldDeleteCachedObject(cache.response, response)) {
        return deleteStorageData(key);
      }
    }
  };
  const getMostRecentData = <T>(key: string, response: T) => {
    const stored = getStorageData(key);
    return stored || response;
  };

  const createKey = (userId: number, key: Keys): string =>
    `${PREFIX}${userId}__${key}`;

  // public functions
  export const resolveOptimism = <T>(
    userId: number,
    key: Keys,
    response: T,
    shouldDeleteCachedObject: (cacheObj: T, response: T) => boolean
  ) => {
    const cacheKey = createKey(userId, key);
    deleteStorageIfStale(cacheKey, response, shouldDeleteCachedObject);
    return getMostRecentData(cacheKey, response);
  };

  export const setStorageData = <T>(userId: number, key: Keys, data: T) => {
    const storedData = {
      expiration: moment().add(10, 'minutes').unix(),
      response: data,
    };

    return localStorage.setItem(
      createKey(userId, key),
      JSON.stringify(storedData)
    );
  };
}
