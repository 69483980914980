import moment from 'moment';
import { AuthedHttp } from './AuthedHttp';

/*
  We will show the Twilio SMS promo card on the dashboard if the user's
  email has been confirmed for at least 30 days and the user has never 
  dismissed the card. If the user does not have a confirmed_at timestamp 
  or the endpoint errors out, we will default return false and not show
  the card.
*/
export const determineShouldShowTwilioSMSPromoCard = async (): Promise<
  boolean
> => {
  // For testing purposes. If the user has override local storage value,
  // we will show the card regardless of checks
  if (hasOverrideLocalStorage()) {
    return true;
  }

  if (hasDismissedCardLocalStorage()) {
    return false;
  }

  try {
    const emailConfirmationStatus = await fetchEmailConfirmationStatus();
    const { confirmed_at: confirmedAt } = emailConfirmationStatus;

    if (confirmedAt) {
      const isAtLeast30Days =
        moment().diff(moment.unix(confirmedAt), 'days') >= 30;
      return isAtLeast30Days;
    } else {
      return false;
    }
  } catch (e) {
    return false;
  }
};

interface EmailConfirmationStatusDto {
  confirmed_at: number | null;
  email: string;
}

export const fetchEmailConfirmationStatus = async (): Promise<
  EmailConfirmationStatusDto
> => {
  const response = await AuthedHttp.get<EmailConfirmationStatusDto>(
    `tokens/email`
  );

  if (response.ok) {
    const emailConfirmationStatus = await response.json();
    return emailConfirmationStatus;
  }

  throw new Error('Failed to get email confirmation status');
};

/*
  The "TWILIO_SMS_PROMO_DISMISSED" localStorage value is used to determine
  if the user has dismissed the card before.
*/
const TWILIO_SMS_PROMO_DISMISSED = 'TWILIO_SMS_PROMO_DISMISSED';

export const hasDismissedCardLocalStorage = () => {
  return Boolean(window.localStorage.getItem(TWILIO_SMS_PROMO_DISMISSED));
};

export const setHasDismissedCardLocalStorage = () => {
  return Boolean(
    window.localStorage.setItem(TWILIO_SMS_PROMO_DISMISSED, 'TRUE')
  );
};

/*
  The "TWILIO_SMS_PROMO_OVERRIDE" localStorage value can be used to override all
  checks and force the display of the Twilio SMS card for testing purposes
*/
const TWILIO_SMS_PROMO_OVERRIDE = 'TWILIO_SMS_PROMO_OVERRIDE';

export const hasOverrideLocalStorage = () => {
  return Boolean(window.localStorage.getItem(TWILIO_SMS_PROMO_OVERRIDE));
};
