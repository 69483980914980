import { EmailApiSubscription } from '../types/emailApiSubscription';
import { MarketingCampaignsSubscription } from '../types/marketingCampaignsSubscription';

export const buildEmailSubscriptionOptimisticState = (
  currentSubscription: EmailApiSubscription,
  newPlanId: string,
  isUpgrade: boolean
): EmailApiSubscription => {
  if (isUpgrade) {
    return {
      ...currentSubscription,
      downgradePlanId: undefined,
      planId: newPlanId,
    } as EmailApiSubscription;
  } else {
    return {
      ...currentSubscription,
      downgradePlanId: newPlanId,
    } as EmailApiSubscription;
  }
};

export const buildMarketingCampaignSubscriptionOptimisticState = (
  currentSubscription: MarketingCampaignsSubscription,
  newPlanId: string,
  isUpgrade: boolean
): MarketingCampaignsSubscription => {
  if (isUpgrade) {
    return {
      ...currentSubscription,
      downgradePlanId: undefined,
      planId: newPlanId,
    } as MarketingCampaignsSubscription;
  } else {
    return {
      ...currentSubscription,
      downgradePlanId: newPlanId,
    } as MarketingCampaignsSubscription;
  }
};
