import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthedHttp } from '../../helpers/AuthedHttp';
import {
  SendGridAppDispatch,
  SendGridRootState,
} from '../store/sendgridAppStore';
import {
  DtoWrapper,
  GenericNetworkSlice,
  NetworkState,
} from '../store/sliceNetworkTypes';
import { ResponseError } from '../../helperTypes/responseError';

export const paymentFormAdapter = (
  paymentFormDto: PaymentFormDto
): PaymentForm => {
  const { result } = paymentFormDto;
  return {
    id: result.page_id,
    key: result.key,
    signature: result.signature,
    token: result.token,
    url: result.url,
    tenantId: result.tenant_id,
  };
};

enum Routes {
  PaymentForm = 'public/billing/payment_form',
}

export enum PaymentFormError {
  GenericFetchError = 'Network Error. Failed to fetch payment form.',
}

export interface PaymentFormResultDto {
  key: string;
  page_id: string;
  signature: string;
  tenant_id: string;
  token: string;
  url: string;
}

export interface PaymentFormDto {
  result: PaymentFormResultDto;
}

export interface PaymentForm {
  id: string;
  key: string;
  signature: string;
  tenantId: string;
  token: string;
  url: string;
}

export const fetchPaymentForm = createAsyncThunk<
  DtoWrapper<PaymentFormDto>,
  void,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('paymentForm/fetchPaymentForm', async (_, { rejectWithValue }) => {
  try {
    const response = await AuthedHttp.get<PaymentFormDto>(Routes.PaymentForm);

    if (response.ok) {
      const data = await response.json();
      return {
        statusCode: response.status,
        data,
      };
    }

    const errorResponse = await response.json();

    return rejectWithValue({
      message: errorResponse.errors[0].message,
      statusCode: response.status,
    });
  } catch (e) {
    return rejectWithValue({
      message: PaymentFormError.GenericFetchError,
    });
  }
});

export type PaymentFormStore = GenericNetworkSlice<PaymentForm>;

const initialState = {
  networkState: NetworkState.Unrequested,
  data: null,
} as PaymentFormStore;

export const paymentFormSlice = createSlice({
  name: 'paymentForm',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPaymentForm.pending, (state) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(fetchPaymentForm.fulfilled, (_, action) => {
      return {
        data: paymentFormAdapter(action.payload.data),
        statusCode: action.payload.statusCode,
        networkState: NetworkState.Success,
      };
    });
    builder.addCase(fetchPaymentForm.rejected, (_, action) => {
      return {
        statusCode: action.payload?.statusCode || 0,
        networkState: NetworkState.Error,
        errorMessage:
          action.payload?.message || PaymentFormError.GenericFetchError,
      };
    });
  },
});
