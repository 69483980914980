import { createSlice } from '@reduxjs/toolkit';
import {
  GenericNetworkNonNullLoadingErrorSlice,
  NetworkState,
} from './sliceNetworkTypes';
import {
  SubuserAccess,
  createHandleGetSubuserAccessThunk,
  NextParams,
} from './subuserAccessSlice';

enum SubuserAccessSearchErrors {
  Generic = 'Something went wrong. Failure to fetch filtered subuser access',
}

// We omit the "nextSubuserAccess" property here as search UI will not support "Load more" functionality
type SubuserAccessSearchData = Pick<
  SubuserAccess,
  'hasRestrictedSubuserAccess' | 'subuserAccess'
>;

export type SubuserAccessSearchStore = GenericNetworkNonNullLoadingErrorSlice<
  SubuserAccessSearchData
>;

const initialSubuserAccessSearchState = {
  networkState: NetworkState.Unrequested,
} as SubuserAccessSearchStore;

// We omit the "lastSubuserId" property here as search UI will not support "Load more" functionality
export type SearchSubuserAccessParams = Pick<NextParams, 'username' | 'limit'>;

export const searchSubuserAccess = createHandleGetSubuserAccessThunk(
  'subuserAccessSearch/searchSubuserAccess'
);

/**
 * This slice hits the same api as subuserAccessSlice but we need to
 * keep the data, loading, & error states seperate from that slice since
 * we want search functionality independent of viewing all the subusers the user has access to
 */
export const subuserAccessSearchSlice = createSlice<
  SubuserAccessSearchStore,
  any
>({
  name: 'subuserAccessSearch',
  initialState: initialSubuserAccessSearchState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(searchSubuserAccess.pending, (state) => ({
      ...state,
      networkState: NetworkState.Loading,
    }));

    builder.addCase(searchSubuserAccess.fulfilled, (_, action) => ({
      networkState: NetworkState.Success,
      statusCode: action.payload.statusCode,
      data: action.payload.data,
    }));

    builder.addCase(searchSubuserAccess.rejected, (_, action) => ({
      networkState: NetworkState.Error,
      statusCode: action.payload?.statusCode ?? 0,
      errorMessage:
        action.payload?.message ?? SubuserAccessSearchErrors.Generic,
      data: null,
    }));
  },
});
