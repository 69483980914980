import { PaymentMethodDto, PaymentFormDto } from '../dtos';
import { AuthedHttp, ResponseBodySuccess } from '../../helpers/AuthedHttp';
import { Routes } from './routes';
import { SubscriptionPayment } from '../types/subscriptionPayment';
import { generateBrowserFingerprint } from '../../helpers/browserFingerprint';

export const getPaymentMethod = (): Promise<PaymentMethodDto> => {
  return AuthedHttp.get<PaymentMethodDto>(Routes.PaymentMethods)
    .then((res: ResponseBodySuccess<PaymentMethodDto>) => res.json())
    .then((data) => {
      if (data.errors) {
        throw data;
      }
      return data;
    });
};

export const reactivateUser = (): Promise<PaymentMethodDto> => {
  return AuthedHttp.post<PaymentMethodDto>(Routes.ReactivateUser, {})
    .then((res: ResponseBodySuccess<PaymentMethodDto>) => res.json())
    .then((data) => {
      if (data.errors) {
        throw data;
      }
      return data;
    });
};

export function updatePaymentMethodDefault(
  paymentMethodId: string
): Promise<PaymentFormDto> {
  const requestBody = {
    payment_method_id: paymentMethodId,
  };
  let result = {} as PaymentFormDto;
  return AuthedHttp.put<PaymentFormDto>(
    Routes.PaymentMethodsDefault,
    requestBody
  )
    .then((response: ResponseBodySuccess<PaymentFormDto>) => response.json())
    .then((data) => {
      if (data.errors) {
        throw data;
      }
      result = data;
      return reactivateUser();
    })
    .then(() => {
      // When a user updates their payment method, the backend may try to reprocess payment
      // if their current card wasn't charged successfully in the past. This checks to
      // see if the updated card fixes any existing payment issues and removes the error banner.
      if (window.SendGridTiara.pollPaymentStatus) {
        window.SendGridTiara.pollPaymentStatus();
      }
      return result;
    });
}

export const createSubscriptionPayment = async (
  subscriptionPayment: SubscriptionPayment
): Promise<void> => {
  const { paymentMethodId, billingProviderAccountId } = subscriptionPayment;
  if (!paymentMethodId) {
    throw new Error('Missing payment method id');
  }

  if (!billingProviderAccountId) {
    await createBillingAccount(paymentMethodId);
  } else {
    await updatePaymentMethodDefault(paymentMethodId);
  }
  await authorizePayment(paymentMethodId);
};

export async function authorizePayment(paymentMethodId: string): Promise<void> {
  const talon = await generateBrowserFingerprint();
  const requestBody = {
    payment_method_id: paymentMethodId,
    talon,
  };

  const response = await AuthedHttp.post(Routes.AuthorizePayment, requestBody);
  if (response.ok) {
    return;
  }
  throw new Error('Failed to authorize payment');
}

export async function createBillingAccount(paymentMethodId: string) {
  const requestUrl = `${Routes.BillingAccounts}?payment_method_id=${paymentMethodId}`;
  return await AuthedHttp.post<unknown>(requestUrl);
}
