import React, { useState, CSSProperties, useEffect } from 'react';
import ReactDOM from 'react-dom';
import cn from 'classnames';
import {
  useAppSelector,
  SendGridRootState,
} from '../../state/store/sendgridAppStore';
import { Mock, MockShape } from '../../state/mocks/mockslice';

interface CheckboxProps {
  checked: boolean;
  disabled?: boolean;
  id: string;
  info?: string;
  label?: React.ReactNode;
  children?: any;
  value?: string;
  style?: CSSProperties;
}

// UI Comp has the WORST checkbox implementation
export const Checkbox: React.SFC<CheckboxProps> = ({
  checked,
  disabled,
  id,
  info,
  label,
  children,
  value,
  style,
  ...attributes
}) => {
  return (
    <div className={cn('input-checkbox-wrap')} style={style}>
      <input
        checked={checked}
        disabled={disabled}
        id={id}
        type="checkbox"
        value={value}
        {...attributes}
        // For some reason I could not get onChange to work for this checkbox
        onChange={() => {}}
      />
      <label className={cn('input-checkbox-label')} htmlFor={id}>
        {children ? children : label}
        {info ? (
          <span className={cn('input-checkbox-info')}>{info}</span>
        ) : null}
      </label>
    </div>
  );
};

export const MockUIPortal = ({ children }: { children: React.ReactNode }) => {
  let bannerSpace = document.querySelector('#mockUI');

  if (!bannerSpace) {
    const reactBanner = document.createElement('div');
    reactBanner.setAttribute('id', 'mockUI');

    document.querySelector('[data-tiara-nav="true"]')!.append(reactBanner);
    bannerSpace = document.querySelector('#mockUI')!;
  }
  if (process.env.NODE_ENV === 'development') {
    return ReactDOM.createPortal(children, bannerSpace);
  }
  return <></>;
};

interface MockUIProps {
  mocks?: Mock;
  children?: React.ReactNode;
}

const queryStringUpdate = (yarl: string) => {
  window.history.pushState(
    { path: new URL(window.location.href).href },
    '',
    yarl
  );
};

const MockSelector = (state: SendGridRootState) => state.mockSlice;

export const MockUI: React.FC<MockUIProps> = ({ children, mocks = {} }) => {
  const reduxMocks = useAppSelector(MockSelector);
  const AvailableMocks = Object.entries(
    Object.assign({}, reduxMocks.mock, mocks)
  );
  const AppURL = new URL(location.toString());

  // I was gonna do some fancy smancy dynamic thing but truly this is easier and I doubt the types will update super often
  const GlobalMocks = AvailableMocks.filter(
    ([, value]) => value.type === 'global'
  );
  const PageMocks = AvailableMocks.filter(([, value]) => value.type === 'page');
  const ExtraMocks = AvailableMocks.filter(
    ([, value]) => value.type === 'extra'
  );

  const [showUi, setShowUi] = useState(false);
  // This is a super janky way to force re-renders. we need this because I couldn't get location.search listening to work
  // correctly and this was the easiest way  for me to get re-renders
  const [, setForceRerender] = useState(1);

  // If we're mockin stuff already lets just show that UI save a few clicks
  useEffect(() => {
    if (location.search.includes('mock_')) {
      setShowUi(true);
    }
  }, []);

  const onClick = (onClickHandler: () => void) => {
    return (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
      switch (e.button) {
        // Left Mouse Click
        case 0:
          e.preventDefault();
          setForceRerender(Date.now());
          onClickHandler();
          break;
        default:
          break;
      }
    };
  };

  const RenderCheckboxMock = ({ mocks }: { mocks: [string, MockShape][] }) => {
    return (
      <>
        {mocks.map(([mock, MockShape]) => {
          const inUrl = AppURL.searchParams.has(mock);

          return (
            <div style={{ marginLeft: 5 }} key={mock}>
              <Checkbox
                style={{ marginBottom: 5 }}
                checked={inUrl}
                id=""
                label={
                  <strong
                    onClick={() => {
                      setForceRerender(Date.now());

                      if (inUrl) {
                        AppURL.searchParams.delete(mock);
                        queryStringUpdate(AppURL.toString());
                      }
                    }}
                  >
                    {mock.replace(/_/g, ' ').replace(/(mock )/, '')}
                  </strong>
                }
              />
              <ul style={{ marginLeft: 10 }}>
                {MockShape.mocks.map((mockType) => {
                  const paramInUrl = AppURL.searchParams.get(mock) === mockType;

                  return (
                    <React.Fragment key={`${mock}${mockType}`}>
                      <li>
                        <Checkbox
                          style={{ marginBottom: 5 }}
                          checked={paramInUrl}
                          id=""
                          label={
                            <a
                              href={AppURL.toString()}
                              onClick={onClick(() => {
                                if (paramInUrl) {
                                  AppURL.searchParams.delete(mock);
                                } else {
                                  AppURL.searchParams.set(mock, mockType);
                                }

                                queryStringUpdate(AppURL.toString());
                              })}
                            >
                              {mockType}
                            </a>
                          }
                        />
                      </li>
                    </React.Fragment>
                  );
                })}
              </ul>
            </div>
          );
        })}
      </>
    );
  };

  return (
    <MockUIPortal>
      <div style={{ textAlign: 'center' }}>
        <button
          onClick={() => {
            setShowUi(!showUi);
          }}
        >
          {showUi && <>Hide Mocks</>}
          {!showUi && <>Show Mocks</>}
        </button>
      </div>
      {showUi && (
        <React.Fragment>
          {PageMocks.length > 0 && (
            <>
              <h4 style={{ marginLeft: 10, marginTop: 10 }}>Page</h4>
              <RenderCheckboxMock mocks={PageMocks} />
            </>
          )}
          {ExtraMocks.length > 0 && (
            <>
              <hr />
              <h4 style={{ marginLeft: 10 }}>Extra</h4>
              <RenderCheckboxMock mocks={ExtraMocks} />
            </>
          )}
          {GlobalMocks.length > 0 && (
            <>
              <hr />
              <h4 style={{ marginLeft: 10 }}>Global</h4>
              <RenderCheckboxMock mocks={GlobalMocks} />
            </>
          )}
        </React.Fragment>
      )}
      {children}
    </MockUIPortal>
  );
};
