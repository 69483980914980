import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import moment from 'moment';
import { ResponseError } from '../../../../../helperTypes/responseError';
import { EmailInsightsDeliverabilityRoutes } from '../../apiRoutes';
import { AuthedHttp } from '../../../../../helpers/AuthedHttp';
import {
  SendGridRootState,
  SendGridAppDispatch,
} from '../../../../../state/store/sendgridAppStore';
import {
  MailboxProviderStatsDto,
  BounceClassificationTypes,
  BounceClassificationTotalsDto,
  BounceClassificationByMailboxProviderDto,
} from '../types';
import {
  calculateMSPMetricRates,
  MSPMetricRates,
} from '../statCalculators/calculateMSPMetricRates';
import {
  BounceClassificationTotals as BounceClassificationTotalsData,
  calculateBounceClassificationTotals,
} from '../statCalculators/calculateBounceClassificationTotals';
import {
  calculateBounceClassificationCountsByMSP,
  getTopMSPNamesFromBounceClassificationByMSP,
} from '../statCalculators/calculateBounceClassificationCountsByMSP';
import {
  BounceClassificationPercentagesByMSP,
  calculateBounceClassificationPercentagesByMSP,
} from '../statCalculators/calculateBounceClassificationPercentagesByMSP';
import {
  BounceClassificationCountsByMSPOverTime,
  calculateBounceClassificationCountsByMSPOverTime,
} from '../statCalculators/calculateBounceClassificationCountsByMSPOverTime';
import {
  getTopMSPNames,
  isWithin6Months,
  isWithin13MonthCap,
  getBounceClassificationDateRange,
} from '../statCalculators/helpers';
import { dateQueryFormat } from '../../apiRoutes';

export interface MSPMetricRatesComparison {
  current: MSPMetricRates | null;
  comparison: MSPMetricRates | null;
}

export type BounceRateByMSPMetrics = MSPMetricRatesComparison | null;
export type BlockRateByMSPMetrics = MSPMetricRatesComparison | null;

export interface FetchedMailboxProviderStats {
  bounceRateByMSPMetrics: BounceRateByMSPMetrics;
  blockRateByMSPMetrics: BlockRateByMSPMetrics;
}

export interface MailboxProviderStats extends FetchedMailboxProviderStats {
  isLoading: boolean;
  isError: boolean;
}

export type FetchedBounceClassificationTotals = BounceClassificationTotalsData | null;

export interface BounceClassificationTotals {
  data: FetchedBounceClassificationTotals;
  isLoading: boolean;
  isError: boolean;
}

interface BounceClassificationDetailsData {
  bounceClassificationPercentagesByMSP: BounceClassificationPercentagesByMSP;
  bounceClassificationCountsByMSPOverTime: BounceClassificationCountsByMSPOverTime;
}

type FetchedBounceClassificationDetails = BounceClassificationDetailsData | null;

export interface BounceClassificationDetail {
  isLoading: boolean;
  isError: boolean;
  data: FetchedBounceClassificationDetails;
}

type BounceClassificationsDetails = {
  [classification in BounceClassificationTypes]: BounceClassificationDetail;
};

interface BouncedAndBlockedStatsState {
  mailboxProviderStats: MailboxProviderStats;
  bounceClassificationTotals: BounceClassificationTotals;
  bounceClassificationDetails: BounceClassificationsDetails;
}

interface MailboxProviderStatsArgs {
  startDate: string;
  endDate?: string;
  subuser?: string;
  isCustomDateRange?: boolean;
}

interface BounceClassificationArgs {
  startDate: string;
  endDate?: string;
  subuser?: string;
}

interface BounceClassificationDetailsArgs extends BounceClassificationArgs {
  classification: BounceClassificationTypes;
}

const initialState: BouncedAndBlockedStatsState = {
  mailboxProviderStats: {
    bounceRateByMSPMetrics: {
      current: null,
      comparison: null,
    },
    blockRateByMSPMetrics: {
      current: null,
      comparison: null,
    },
    isLoading: true,
    isError: false,
  },
  bounceClassificationTotals: {
    data: null,
    isLoading: true,
    isError: false,
  },
  bounceClassificationDetails: {
    [BounceClassificationTypes.Content]: {
      isLoading: true,
      isError: false,
      data: null,
    },
    [BounceClassificationTypes.Frequency]: {
      isLoading: true,
      isError: false,
      data: null,
    },
    [BounceClassificationTypes.InvalidAddress]: {
      isLoading: true,
      isError: false,
      data: null,
    },
    [BounceClassificationTypes.Reputation]: {
      isLoading: true,
      isError: false,
      data: null,
    },
    [BounceClassificationTypes.MailboxUnavailable]: {
      isLoading: true,
      isError: false,
      data: null,
    },
    [BounceClassificationTypes.Technical]: {
      isLoading: true,
      isError: false,
      data: null,
    },
    [BounceClassificationTypes.Unclassified]: {
      isLoading: true,
      isError: false,
      data: null,
    },
  },
};

export const blockRateByMSPMetricsAdapter = (
  mailboxProviderStatsDto: MailboxProviderStatsDto,
  topMSPNames: string[]
): MSPMetricRates => {
  const MSPBlockRates = calculateMSPMetricRates(
    'blocks',
    'processed',
    mailboxProviderStatsDto,
    topMSPNames
  );

  return MSPBlockRates;
};

export const bounceRateByMSPMetricsAdapter = (
  mailboxProviderStatsDto: MailboxProviderStatsDto,
  topMSPNames: string[]
): MSPMetricRates => {
  const MSPBounceRates = calculateMSPMetricRates(
    'bounces',
    'processed',
    mailboxProviderStatsDto,
    topMSPNames
  );

  return MSPBounceRates;
};

export const fetchMailboxProviderStats = createAsyncThunk<
  FetchedMailboxProviderStats,
  MailboxProviderStatsArgs,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>(
  'BOUNCED_AND_BLOCKED_STATS/FETCH_MAILBOX_PROVIDER_STATS',
  async (args, thunkApi) => {
    try {
      const { startDate, endDate, subuser } = args;
      const mailboxProviderStatsResponse = await AuthedHttp.get<
        MailboxProviderStatsDto
      >(
        EmailInsightsDeliverabilityRoutes.getMailboxProviderStats({
          startDate,
          endDate,
        }),
        subuser
          ? ({
              headers: { 'On-behalf-of': decodeURIComponent(subuser) },
            } as any)
          : undefined
      );

      if (mailboxProviderStatsResponse.ok) {
        const mailboxProviderStatsDto = await mailboxProviderStatsResponse.json();

        // Both current and comparison bounce/block rates will use top MSPs
        // from current date range stats
        const topMSPNames = getTopMSPNames(mailboxProviderStatsDto);

        const adaptedBounceRateByMSPMetrics = bounceRateByMSPMetricsAdapter(
          mailboxProviderStatsDto,
          topMSPNames
        );

        const adaptedBlockRateByMSPMetrics = blockRateByMSPMetricsAdapter(
          mailboxProviderStatsDto,
          topMSPNames
        );

        const dateRangeDiff = moment(endDate).diff(moment(startDate), 'days');
        const comparisonEndDate = moment(startDate)
          .subtract(1, 'day')
          .format(dateQueryFormat);
        const comparisonStartDate = moment(comparisonEndDate)
          .subtract(dateRangeDiff, 'days')
          .format(dateQueryFormat);

        const within6Months = isWithin6Months({
          startDate,
          endDate,
        });
        const within13MonthCap = isWithin13MonthCap(
          moment(comparisonStartDate)
        );
        // hide comparison if custom date range > 6 months or if comparison start date is past the 13 month cap
        if (!within6Months || !within13MonthCap) {
          return {
            bounceRateByMSPMetrics: {
              current: adaptedBounceRateByMSPMetrics,
              comparison: null,
            },
            blockRateByMSPMetrics: {
              current: adaptedBlockRateByMSPMetrics,
              comparison: null,
            },
          };
        }
        const comparisonMailboxProviderStatsResponse = await AuthedHttp.get<
          MailboxProviderStatsDto
        >(
          EmailInsightsDeliverabilityRoutes.getMailboxProviderStats({
            startDate: comparisonStartDate,
            endDate: comparisonEndDate,
          }),
          subuser
            ? ({
                headers: { 'On-behalf-of': decodeURIComponent(subuser) },
              } as any)
            : undefined
        );

        if (comparisonMailboxProviderStatsResponse.ok) {
          const comparisonMailboxProviderStatsDto = await comparisonMailboxProviderStatsResponse.json();

          const adaptedComparisonBounceRateByMSPMetrics = bounceRateByMSPMetricsAdapter(
            comparisonMailboxProviderStatsDto,
            topMSPNames
          );
          const adaptedComparisonBlockRateByMSPMetrics = blockRateByMSPMetricsAdapter(
            comparisonMailboxProviderStatsDto,
            topMSPNames
          );

          return {
            bounceRateByMSPMetrics: {
              current: adaptedBounceRateByMSPMetrics,
              comparison: adaptedComparisonBounceRateByMSPMetrics,
            },
            blockRateByMSPMetrics: {
              current: adaptedBlockRateByMSPMetrics,
              comparison: adaptedComparisonBlockRateByMSPMetrics,
            },
          };
        }
      }

      throw new Error('Unable to get mailbox provider stats!');
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: 'Unable to get mailbox provider stats!',
      });
    }
  }
);

export const fetchBounceClassificationTotals = createAsyncThunk<
  FetchedBounceClassificationTotals,
  BounceClassificationArgs,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>(
  'BOUNCED_AND_BLOCKED_STATS/FETCH_BOUNCE_CLASSIFICATION_TOTALS',
  async (args, thunkApi) => {
    try {
      const { startDate, endDate, subuser } = args;

      const { startDateQuery, endDateQuery } = getBounceClassificationDateRange(
        startDate,
        endDate
      );

      const bounceClassificationTotalsResponse = await AuthedHttp.get<
        BounceClassificationTotalsDto
      >(
        EmailInsightsDeliverabilityRoutes.getBounceClassificationsTotals({
          startDate: startDateQuery,
          endDate: endDateQuery,
        }),
        subuser
          ? ({
              headers: { 'On-behalf-of': decodeURIComponent(subuser) },
            } as any)
          : undefined
      );

      if (bounceClassificationTotalsResponse.ok) {
        const bounceClassificationTotalsDto = await bounceClassificationTotalsResponse.json();
        const bounceClassificationTotals = calculateBounceClassificationTotals(
          bounceClassificationTotalsDto
        );

        return bounceClassificationTotals;
      }

      throw new Error('Unable to get bounce classification totals!');
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: 'Unable to get bounce classification totals!',
      });
    }
  }
);

export const fetchBounceClassificationDetails = createAsyncThunk<
  FetchedBounceClassificationDetails,
  BounceClassificationDetailsArgs,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>(
  'BOUNCED_AND_BLOCKED_STATS/FETCH_BOUNCE_CLASSIFICATION_DETAILS',
  async (args, thunkApi) => {
    const { startDate, endDate, subuser, classification } = args;

    const { startDateQuery, endDateQuery } = getBounceClassificationDateRange(
      startDate,
      endDate
    );

    try {
      const bounceClassificationByMSPResponse = await AuthedHttp.get<
        BounceClassificationByMailboxProviderDto
      >(
        EmailInsightsDeliverabilityRoutes.getBounceClassificationByMailboxProvider(
          {
            startDate: startDateQuery,
            endDate: endDateQuery,
            classification,
          }
        ),
        subuser
          ? ({
              headers: { 'On-behalf-of': decodeURIComponent(subuser) },
            } as any)
          : undefined
      );

      if (bounceClassificationByMSPResponse.ok) {
        const bounceClassificationByMSPDto = await bounceClassificationByMSPResponse.json();

        const bounceClassificationCountsByMSP = calculateBounceClassificationCountsByMSP(
          bounceClassificationByMSPDto
        );
        const top5MSPNames = getTopMSPNamesFromBounceClassificationByMSP(
          5,
          bounceClassificationCountsByMSP
        );

        const bounceClassificationPercentagesByMSP = calculateBounceClassificationPercentagesByMSP(
          bounceClassificationCountsByMSP.bounceClassificationCounts,
          top5MSPNames
        );
        const bounceClassificationCountsByMSPOverTime = calculateBounceClassificationCountsByMSPOverTime(
          bounceClassificationByMSPDto,
          top5MSPNames
        );

        return {
          bounceClassificationPercentagesByMSP,
          bounceClassificationCountsByMSPOverTime,
        };
      }

      throw new Error(
        `Unable to get bounce classification ${classification} details!`
      );
    } catch (e) {
      return thunkApi.rejectWithValue({
        message: `Unable to get bounce classification ${classification} details!`,
      });
    }
  }
);

export const bouncedAndBlockedStatsSlice = createSlice({
  name: 'bouncedAndBlockedStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMailboxProviderStats.pending, (state, action) => {
      state.mailboxProviderStats.isLoading = true;
      state.mailboxProviderStats.bounceRateByMSPMetrics = null;
      state.mailboxProviderStats.blockRateByMSPMetrics = null;
    });
    builder.addCase(fetchMailboxProviderStats.fulfilled, (state, action) => {
      state.mailboxProviderStats.isLoading = false;
      state.mailboxProviderStats.isError = false;
      state.mailboxProviderStats.bounceRateByMSPMetrics =
        action.payload.bounceRateByMSPMetrics;
      state.mailboxProviderStats.blockRateByMSPMetrics =
        action.payload.blockRateByMSPMetrics;
    });
    builder.addCase(fetchMailboxProviderStats.rejected, (state, action) => {
      state.mailboxProviderStats.isLoading = false;
      state.mailboxProviderStats.isError = true;
      state.mailboxProviderStats.bounceRateByMSPMetrics = null;
      state.mailboxProviderStats.blockRateByMSPMetrics = null;
    });
    builder.addCase(
      fetchBounceClassificationTotals.pending,
      (state, action) => {
        state.bounceClassificationTotals.isLoading = true;
        state.bounceClassificationTotals.data = null;
        // Whenever we load the card, we will also reset each accordion
        Object.keys(state.bounceClassificationDetails).forEach(
          (classification: BounceClassificationTypes) => {
            state.bounceClassificationDetails[classification].isLoading = true;
            state.bounceClassificationDetails[classification].data = null;
          }
        );
      }
    );
    builder.addCase(
      fetchBounceClassificationTotals.fulfilled,
      (state, action) => {
        state.bounceClassificationTotals.isLoading = false;
        state.bounceClassificationTotals.isError = false;
        state.bounceClassificationTotals.data = action.payload;
      }
    );
    builder.addCase(
      fetchBounceClassificationTotals.rejected,
      (state, action) => {
        state.bounceClassificationTotals.isLoading = false;
        state.bounceClassificationTotals.isError = true;
        state.bounceClassificationTotals.data = null;
      }
    );
    builder.addCase(
      fetchBounceClassificationDetails.pending,
      (state, action) => {
        const classification = action.meta.arg.classification;
        state.bounceClassificationDetails[classification].isLoading = true;
        state.bounceClassificationDetails[classification].data = null;
      }
    );
    builder.addCase(
      fetchBounceClassificationDetails.fulfilled,
      (state, action) => {
        const classification = action.meta.arg.classification;
        state.bounceClassificationDetails[classification].isLoading = false;
        state.bounceClassificationDetails[classification].isError = false;
        state.bounceClassificationDetails[classification].data = action.payload;
      }
    );
    builder.addCase(
      fetchBounceClassificationDetails.rejected,
      (state, action) => {
        const classification = action.meta.arg.classification;
        state.bounceClassificationDetails[classification].isLoading = false;
        state.bounceClassificationDetails[classification].isError = true;
        state.bounceClassificationDetails[classification].data = null;
      }
    );
  },
});
