export const TiaraDefToPromise = (jQueryDeferred: any) => {
  return new Promise((resolve, reject) => {
    if (jQueryDeferred.length === 1) {
      return resolve({
        ok: true,
        status: 200,
        json: () => Promise.resolve(jQueryDeferred[0]),
      });
    }

    /* 
      jqXHR failure => [jqXHR, textStatus, errorThrown]
      jqXHR success => [data, textStatus, jqXHR]
      We need to check which index contains the jqXHR in order to .done/.fail
    */
    const jqXHR: JQuery.Deferred<any, any> =
      jQueryDeferred[2] && jQueryDeferred[2].done
        ? jQueryDeferred[2]
        : jQueryDeferred[0];

    jqXHR.done((data, textStatus, jqXHR) => {
      if (jqXHR === undefined) {
        // Tiara sometimes does a deferred.resolve and doesn't make it look like a network
        // request, so lets just not error out and send on okayness to everything
        return resolve({
          ok: true,
          status: 200,
          json: () => Promise.resolve({}),
        });
      }
      const { status } = jqXHR;
      const ok = status >= 200 && status <= 300;

      resolve({
        ok,
        status: status,
        json: () => Promise.resolve(data),
      });
    });
    /*
      When the deferred fails (in this case an API request failure), we want to resolve
      the promise with the necessary data for the consuming function
    */
    jqXHR.fail((jqXHR, textStatus, errorThrown) => {
      resolve({
        ok: false,
        status: jqXHR.status || 0,
        /* errorThrown is a string and does not return the error response, responseJSON provides the error response*/
        json: () =>
          Promise.resolve(
            jqXHR.responseJSON || {
              errors: ['Something went wrong, Tiara promise failure'],
            }
          ),
      });
    });
  }).catch((e) => {
    // After we actually consume the rejection in the
    console.warn('Tiara Promise Failure', e);
  });
};
