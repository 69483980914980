import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';
import { BillableContactsInfoDto } from '../dtos';
import { Contacts } from '../types/contacts';
import { ActionTypes } from './actionTypes';
import { contactsAdapter } from './adapters';

const initialState = {} as Contacts;

interface ContactsAction extends Action {
  payload: MakoStateType['contacts'] | BillableContactsInfoDto;
}

export const contacts = (state = initialState, action: ContactsAction) => {
  switch (action.type) {
    case ActionTypes.GetBillableContactsInfoSuccess:
      const updatedState = contactsAdapter(
        action.payload as BillableContactsInfoDto
      );
      return {
        ...state,
        ...updatedState,
      };
    default:
      return state;
  }
};
