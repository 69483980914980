import React from 'react';
import ReactDOM from 'react-dom';
import { sendgridAppStore } from './state/store/sendgridAppStore';
import { MockUI } from './components/MockUI';
import $ from 'jquery';
import { addMocks } from './state/mocks/mockslice';
import { Provider } from 'react-redux';

const isDev = process.env.NODE_ENV === 'development';

if (isDev) {
  $(document).ajaxSend((event, jqxhr, settings) => {
    try {
      const url = new URL(settings.url ?? '');
      const currentUrl = new URL(location.toString());

      currentUrl.searchParams.forEach((value, key) => {
        if (key.startsWith('mock_')) {
          url.searchParams.set(key, value);
        }
      });
      // Adding mock url for mock server stuff
      settings.url = url.toString();
    } catch (e) {
      // We're just gonna not do this. This mostly occurs when we download tiara, and not getting this
      // done and ignoring it is better than breaking stuff
    }
  });
  // Register these in the global jQuery so we can get them in for the Tiara responses
  $(document).ajaxComplete((asdf, jqueryAjaxResponse, settings) => {
    const availableMockRequests = jqueryAjaxResponse.getResponseHeader(
      'x-mock-query-params'
    );

    if (availableMockRequests) {
      try {
        const parsedMock = JSON.parse(availableMockRequests);
        sendgridAppStore.dispatch(addMocks(parsedMock));
      } catch (e) {
        // if it errors ¯\_(ツ)_/¯ this is for funzies, but we should probably fix that
        console.warn(`We probably want to fix this ${settings.url}`);
      }
    }
  });
}

export const RenderMockUI = () => {
  const tiara = document.querySelector('[data-tiara-nav="true"]');
  if (tiara) {
    ReactDOM.render(
      <Provider store={sendgridAppStore}>
        <MockUI />
      </Provider>,
      document.getElementById('basemockui')
    );
  } else setTimeout(RenderMockUI, 500);
};
