const Backbone = require("backbone");
const APIConfig = require("apiconfig");
const FeatureToggleModel = require("../models/feature_toggle_model");
const _ = require("underscore");

module.exports = Backbone.Collection.extend({
  url: `${APIConfig.host}feature_toggles`,
  model: FeatureToggleModel,

  hasFeature(app_name, feature_name) {
    return !!_.find(
      this.models,
      (feature_toggle) =>
        feature_toggle.get("app_name") === app_name &&
        feature_toggle.get("feature_name") === feature_name
    );
  },
});
