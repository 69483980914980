import { Action } from '../types/action';
import { Alert, Modal } from '../types/ui';
import { MakoStateType } from '../types/makoStateType';
import { ActionTypes } from './actionTypes';

const initialState: MakoStateType['ui'] = {
  alerts: [],
  modals: [],
};

interface UIAction extends Action {
  // add more payload types here as we add more UI actions
  payload: Alert | Modal;
}

export const ui = (state = initialState, action: UIAction) => {
  switch (action.type) {
    case ActionTypes.CreateAlert:
      const newAlert = {
        timeStamp: Date.now(),
        ...action.payload,
      } as Alert;

      return { ...state, alerts: state.alerts.concat(newAlert) };
    case ActionTypes.DeleteAlert:
      const payload = action.payload as Alert;
      const filteredAlerts = state.alerts.filter(
        (alert) => alert.timeStamp !== payload.timeStamp
      );
      return {
        ...state,
        alerts: filteredAlerts,
      };
    case ActionTypes.ShowModal:
      const newModal = action.payload as Modal;
      // Don't allow multiple modals of the same type to exist
      // in the store until we have a specific use case
      const filteredModals = state.modals.filter(
        (modal) => modal.type !== newModal.type
      );
      return {
        ...state,
        modals: [...filteredModals, newModal],
      };
    case ActionTypes.HideModal:
      return {
        ...state,
        modals: [],
      };
    default:
      return state;
  }
};
