import Highcharts from 'highcharts';
import { BounceClassificationByMailboxProviderDto } from '../types';
import { MSPColors } from '../../../components/Highcharts/chartStyles';
import { formatDisplayStatDate } from '../../../utils/formatDisplayStatDate';
import numberWithCommas from '../../../../../helpers/numberWithCommas';
import { MSPAggregation } from './helpers';

interface BounceClassificationCountsOverTime {
  [mspName: string]: {
    [date: string]: number;
  };
}

export interface BounceClassificationCountsByMSPOverTime {
  bounceClassificationCountsOverTime: BounceClassificationCountsOverTime;
  overallCounts: MSPAggregation;
  topMSPsLookup: string[];
  dates: string[];
}

/*
  Calculate the counts by MSP over time for the given bounce classification data

  Output: 
  {
    bounceClassificationCountsOverTime: {
     Gmail: {
        ['2020-01-01']: 5,
        ['2020-01-02']: 7,
        ['2020-01-03']: 10,
        ...
      },
      Yahoo: {
        ['2020-01-01']: 2,
        ['2020-01-02']: 5,
        ['2020-01-03']: 4,
      }
      ...
    }
    topMSPsLookup: ["Gmail", "Yahoo", ...]
    dates: ["2020-01-01", "2020-01-02", "2020-01-03", ...]
  }
*/
export function calculateBounceClassificationCountsByMSPOverTime(
  data: BounceClassificationByMailboxProviderDto,
  topMSPsNames: string[]
): BounceClassificationCountsByMSPOverTime {
  const bounceClassificationCountsOverTime: BounceClassificationCountsOverTime = {};
  const overallCounts: MSPAggregation = {};
  const dates: string[] = [];

  // Seed all the known MSPs to handle the empty data case where a user with no stats will get back an empty array
  Object.values(topMSPsNames).forEach((msp) => {
    bounceClassificationCountsOverTime[msp] = {};
    overallCounts[msp] = 0;
  });

  data.result.forEach(({ date, stats }) => {
    dates.push(date);

    // Seed date for all known MSPs to handle case where a user has no data for some MSPs
    Object.keys(bounceClassificationCountsOverTime).forEach((msp) => {
      bounceClassificationCountsOverTime[msp][date] = 0;
    });

    stats.forEach(({ domain, count }) => {
      if (topMSPsNames.includes(domain)) {
        bounceClassificationCountsOverTime[domain][date] = count;
        overallCounts[domain] = overallCounts[domain] + count;
      }
    });
  });

  return {
    bounceClassificationCountsOverTime,
    overallCounts,
    topMSPsLookup: topMSPsNames,
    dates,
  };
}

export const countsByMSPOverTimeToLineChartSeriesAdapter = (
  bounceClassificationCountsByMSPOverTime: BounceClassificationCountsByMSPOverTime
) => {
  const {
    bounceClassificationCountsOverTime,
    overallCounts,
  } = bounceClassificationCountsByMSPOverTime;

  const series: Highcharts.SeriesLineOptions[] = [];
  Object.entries(bounceClassificationCountsOverTime).forEach(
    ([msp, dateData], colorKey) => {
      const seriesLineOptions: Highcharts.SeriesLineOptions = {
        name: `${msp} (${numberWithCommas(overallCounts[msp])})`,
        type: 'line',
        color: MSPColors[colorKey % MSPColors.length],
        data: [],
        visible: true,
        pointPlacement: 'on',
        custom: {
          mailboxProvider: msp,
        },
        tooltip: {
          pointFormatter: function () {
            return `<span style="color:${
              this.color
            }">\u25CF</span> ${msp}: <b>${numberWithCommas(this.y)}</b><br/>`;
          },
        },
      };

      Object.entries(dateData).forEach(([date, classificationCount], index) => {
        seriesLineOptions.data?.push({
          name: formatDisplayStatDate(date),
          y: classificationCount,
          x: index,
        });
      });

      series.push(seriesLineOptions);
    }
  );
  return series;
};
