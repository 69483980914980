const Backbone = require("backbone");
const APIConfig = require("apiconfig");
const $ = require("jquery");
const _ = require("underscore");

module.exports = Backbone.Collection.extend({
  constructor() {
    this.type = "bounces";
    this.currentPage = 1;
    this.lastPage = null;
    this.limit = 50;
    this.offset = 0;

    Backbone.Collection.apply(this, arguments);
  },

  initialize() {
    this.listenTo(this, "sync", this.setOffsetForLastPage);
  },

  decorateUrl(endpoint) {
    const params = {};
    params.limit = this.limit;
    if (this.searchEmail) {
      params.email = this.searchEmail;
    }
    if (this.startTime && this.endTime) {
      params.start_time = this.startTime;
      params.end_time = this.endTime;
    }
    if (this.offset) {
      params.offset = this.offset;
    }
    endpoint += `?${$.param(params)}`;

    return endpoint;
  },

  setOffsetForLastPage(collection, resp, options) {
    if (options.xhr.getResponseHeader("Link")) {
      const linkTexts = options.xhr.getResponseHeader("Link").split(",");
      const linkTextForLast = _.find(
        linkTexts,
        (text) => text.indexOf('rel="last"') !== -1
      );
      const lastPage = linkTextForLast.match(/title="\D*(\d+)"/)[1];
      this.lastPage = parseInt(lastPage);
    }
  },
});
