import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SendGridAppDispatch, SendGridRootState } from './sendgridAppStore';
import { ResponseError } from '../types/responseError';
import { ResponseBody } from '../../helpers/AuthedHttp';
import { GenericNetworkSlice, NetworkState } from './sliceNetworkTypes';

export interface ProfileDto {
  address: string;
  address2: string;
  authy_id: number;
  city: string;
  company: string;
  country: string;
  first_name: string;
  last_name: string;
  multifactor_phone: string;
  phone: string;
  state: string;
  type: UserType;
  userid: number;
  website: string;
  zip: string;
}

export type ProfileStore = GenericNetworkSlice<ProfileData>;

export interface ProfileData {
  address: string;
  address2: string;
  authyId: number;
  city: string;
  company: string;
  country: string;
  firstName: string;
  lastName: string;
  mutlifactorPhone: string;
  phone: string;
  state: string;
  type: UserType;
  userid: number;
  website: string;
  zip: string;
}

export enum UserType {
  User = 'user', // this can be either a parent or subuser
  Teammate = 'teammate',
  SSOTeammate = 'sso_teammate',
}

export const initialProfileState = {
  networkState: NetworkState.Unrequested,
} as ProfileStore;

export const profileAdapter = (dto: ProfileDto): ProfileData => {
  const { authy_id, first_name, last_name, multifactor_phone, ...rest } = dto;
  return {
    ...rest,
    authyId: authy_id,
    firstName: first_name,
    lastName: last_name,
    mutlifactorPhone: multifactor_phone,
  };
};

export const getProfileFromTiara = createAsyncThunk<
  ProfileData,
  ResponseBody<ProfileDto>,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('GET_PROFILE_FROM_TIARA', async (args, thunkApi) => {
  try {
    const profileResp = await args;

    if (profileResp.ok) {
      const response = await profileResp.json();
      return profileAdapter(response);
    }

    throw new Error('Network Response be Janky');
  } catch {
    return thunkApi.rejectWithValue({
      message: 'Network Level Error',
    } as ResponseError);
  }
});

export const profileSlice = createSlice({
  name: 'profile',
  initialState: initialProfileState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProfileFromTiara.pending, (state) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(getProfileFromTiara.fulfilled, (state, action) => {
      state.networkState = NetworkState.Success;

      if (state.networkState === NetworkState.Success) {
        state.data = action.payload;
      }
    });
    builder.addCase(getProfileFromTiara.rejected, (state, action) => {
      state.networkState = NetworkState.Error;

      if (state.networkState === NetworkState.Error) {
        state.errorMessage = action?.payload?.message ?? 'Unknown Error';
      }
    });
  },
});
