import { from, Observable } from 'rxjs';
import { ActionTypes } from './actionTypes';
import * as CancelAccountDiffActions from './actions';
import { switchMap, map } from 'rxjs/operators';
import { ActionsObservable, ofType } from 'redux-observable';
import { OES } from '../middle_tier/services/oes/service';
import { OesAccountDto } from '../middle_tier/services/oes/dtos';
import { ProductId } from '../../state/types/productId';

const cancelAccountDiffEpic = (
  action$: ActionsObservable<
    ReturnType<typeof CancelAccountDiffActions.Actions.cancelAccountDiffRequest>
  >
): Observable<
  ReturnType<typeof CancelAccountDiffActions.Actions.cancelAccountDiffSuccess>
> => {
  return action$.pipe(
    ofType(ActionTypes.CancelAccountDiffRequest),
    switchMap(() => {
      // Get catalog org, defaulting to 'sg'
      const { catalog_org = 'sg' } = window.Reqres.request('camAccount');

      return from(
        OES.getFreeMCOffering(catalog_org).then((freeMC) => {
          const request = {
            offerings: [
              { name: ProductId.SG_EI_FREE_100_V1 },
              { name: freeMC.name },
            ],
          };
          return OES.getNewPlanAccountDiff(request);
        })
      );
    }),
    map((cancelAccountDiff: OesAccountDto) => {
      return CancelAccountDiffActions.Actions.cancelAccountDiffSuccess(
        cancelAccountDiff
      );
    })
  );
};

export default [cancelAccountDiffEpic];
