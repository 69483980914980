import Highcharts from 'highcharts';
import { MailboxProviderStatsDto } from '../types';
import { calculateMetricRate, MSPMetricNames, MSPAggregation } from './helpers';
import numberWithCommas from '../../../../../helpers/numberWithCommas';

export interface MSPMetricRates {
  MetricCounts: MSPAggregation;
  DivisorMetricCounts: MSPAggregation;
  MetricRates: MSPAggregation;
  TopMSPsLookup: string[];
  Overall: {
    MetricCount: number;
    DivisorMetricCount: number;
    MetricRate: number;
  };
}

/*
  Calculate the metric counts, metric rates (metricName / divisorName), and top MSP names sorted based on divisorMetric in descending order 
  This supports the double bar comparison charts like bounced by MSP and blocked by MSP

  Usage: calculateMSPMetricRates("bounces", "processed", mspStatsDto, ["Gmail", "Yahoo", "AOL", "Microsoft Outlook Live", "Remaining"]); for bounce rates by MSP (bounce / processed)
  Output: 
  {
    // Bounce Counts by MSP
    MetricCounts: {
      Gmail: 10,
      Yahoo: 9,
      AOL: 6,
      Microsoft Outlook Live: 5,
      ...
    },
    // Processed Counts by MSP
    DivisorMetricCounts: {
      // MSP properties like MetricsCounts...
    }
    // Bounce Rates by MSP
    MetricRates: {
      Gmail: 5.0,
      Yahoo: 4.5,
      AOL: 3.2,
      Microsoft Outlook Live: 2.2,
      ....
    },
    // Counts/rates across all MSPs
    Overall: {
      MetricCount: 100,
      DivisorMetricCount: 1000,
      MetricRate: 10,
    },
    // Top X MSP names sorted in descending order based on the MSP's processed count
    TopMSPsLookup: ["Gmail", "Yahoo", "AOL", "Microsoft Outlook Live"]
  }
*/
export function calculateMSPMetricRates(
  metricName: MSPMetricNames,
  divisorName: MSPMetricNames,
  stats: MailboxProviderStatsDto,
  topMSPNames: string[]
): MSPMetricRates {
  const MetricCounts: MSPAggregation = {};
  const DivisorMetricCounts: MSPAggregation = {};
  const MetricRates: MSPAggregation = {};
  let OverallMetricCount = 0;
  let OverallDivisorMetricCount = 0;
  let OverallMetricRate = 0;

  // Seed all the known MSPs to handle the empty data case where a user with no stats will get back an empty array
  Object.values(topMSPNames).forEach((topMSPName) => {
    MetricCounts[topMSPName] = 0;
    DivisorMetricCounts[topMSPName] = 0;
    MetricRates[topMSPName] = 0;
  });

  // Sum up counts for metrics involved for each MSP
  stats.forEach((byDate) => {
    byDate.stats.forEach((metricByMsp) => {
      const mspName: string = metricByMsp.name;
      if (topMSPNames.includes(mspName)) {
        const metricCount = metricByMsp.metrics[metricName] ?? 0;
        MetricCounts[mspName] = MetricCounts[mspName] + metricCount;
        OverallMetricCount = OverallMetricCount + metricCount;

        const divisorMetricCount = metricByMsp.metrics[divisorName] ?? 0;
        DivisorMetricCounts[mspName] =
          DivisorMetricCounts[mspName] + divisorMetricCount;
        OverallDivisorMetricCount =
          OverallDivisorMetricCount + divisorMetricCount;
      }
    });
  });

  // Calculate metric rates for each MSP
  Object.keys(MetricCounts).forEach((mspName) => {
    const metricRate = calculateMetricRate(
      MetricCounts[mspName],
      DivisorMetricCounts[mspName]
    );
    MetricRates[mspName] = metricRate;
  });
  OverallMetricRate = calculateMetricRate(
    OverallMetricCount,
    OverallDivisorMetricCount
  );

  return {
    MetricCounts,
    DivisorMetricCounts,
    MetricRates,
    Overall: {
      MetricCount: OverallMetricCount,
      DivisorMetricCount: OverallDivisorMetricCount,
      MetricRate: OverallMetricRate,
    },
    TopMSPsLookup: topMSPNames,
  };
}

export const MSPMetricRatesToBarChartSeriesOptionAdapter = ({
  MSPMetricRates,
  metricName,
  displayDateRange,
  barColor,
}: {
  MSPMetricRates: MSPMetricRates;
  metricName: string;
  displayDateRange: string;
  barColor: string;
}) => {
  const seriesOption: Highcharts.SeriesOptionsType = {
    name: `${metricName}\r\n(${displayDateRange})`,
    type: 'column',
    color: barColor,
    visible: true,
    data: MSPMetricRates.TopMSPsLookup.map((topMSP) => ({
      name: topMSP,
      y: MSPMetricRates.MetricRates[topMSP],
    })),
    tooltip: {
      valueSuffix: '%',
      pointFormatter: function () {
        const metricCount = numberWithCommas(
          MSPMetricRates.MetricCounts[this.name]
        );

        return `<span style="color:${this.color}">\u25CF</span> ${metricName}: <b>${this.y}% (${metricCount})</b><br/>`;
      },
    },
  };

  return seriesOption;
};

export type TopMSPMetricRatesTableRowData = TopMSPMetricRatesTableCellData[];

export interface TopMSPMetricRatesTableCellData {
  topMSPName: string;
  metricCount: number;
  divisorMetricCount: number;
  metricRate: number;
}

export const TopMSPMetricRatesToTableRowDataAdapter = (
  TopMSPMetricRates: MSPMetricRates
) => {
  const {
    MetricCounts,
    DivisorMetricCounts,
    MetricRates,
    TopMSPsLookup,
  } = TopMSPMetricRates;

  const tableRowData: TopMSPMetricRatesTableCellData[] = [];

  TopMSPsLookup.forEach((topMSP) => {
    const metricCount = MetricCounts[topMSP];
    const divisorMetricCount = DivisorMetricCounts[topMSP];
    const metricRate = MetricRates[topMSP];

    tableRowData.push({
      topMSPName: topMSP,
      metricCount,
      divisorMetricCount,
      metricRate,
    });
  });

  return tableRowData;
};
