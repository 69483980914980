// Analytics based on https://docs.google.com/spreadsheets/d/1ZtyOAUNJh6h_JscqICy4aqvcjlJglmphnkQFNkk8HtQ/edit?ts=5f51373a#gid=887399610
import { ElementTypes, EventTypes } from '../__shared__';

enum Pages {
  SuppressionsGroupUnsubscribes = 'suppressions group unsubscribes',
}

enum Sections {
  GroupUnsubscribes = 'group unsubscribes',
}

enum Subsections {
  SearchByEmail = 'search by email',
  Table = 'table',
}

export const GroupEvents = {
  refreshButton: {
    event: 'group unsubscribes refreshed',
    properties: {
      page: Pages.SuppressionsGroupUnsubscribes,
      section: Sections.GroupUnsubscribes,
      elementType: ElementTypes.Button,
      eventType: EventTypes.Click,
    },
  },

  datePickerButton: {
    event: 'date picker clicked',
    properties: {
      page: Pages.SuppressionsGroupUnsubscribes,
      section: Sections.GroupUnsubscribes,
      elementType: ElementTypes.Button,
      eventType: EventTypes.Click,
    },
  },

  selectDropdownMenu: {
    event: 'selected groups dropdown opened',
    properties: {
      page: Pages.SuppressionsGroupUnsubscribes,
      section: Sections.GroupUnsubscribes,
      elementType: ElementTypes.DropdownToggle,
      eventType: EventTypes.Click,
    },
  },

  removeSelectedGroupsButton: {
    event: 'selected group(s) removed',
    properties: {
      page: Pages.SuppressionsGroupUnsubscribes,
      section: Sections.GroupUnsubscribes,
      elementType: ElementTypes.ListItem,
      eventType: EventTypes.Click,
    },
  },

  manageGroupsButton: {
    event: 'manage groups button clicked',
    properties: {
      page: Pages.SuppressionsGroupUnsubscribes,
      section: Sections.GroupUnsubscribes,
      elementType: ElementTypes.ListItem,
      eventType: EventTypes.Click,
    },
  },

  searchInput: {
    event: 'unsubscribe groups searched',
    properties: {
      page: Pages.SuppressionsGroupUnsubscribes,
      section: Sections.GroupUnsubscribes,
      subsection: Subsections.SearchByEmail,
      elementType: ElementTypes.Input,
      eventType: EventTypes.Blur,
    },
  },

  selectCheckbox: {
    event: 'unsubscribe group select toggled',
    properties: {
      page: Pages.SuppressionsGroupUnsubscribes,
      section: Sections.GroupUnsubscribes,
      subsection: Subsections.Table,
      elementType: ElementTypes.Checkbox,
      eventType: EventTypes.Click,
    },
  },

  selectAllCheckbox: {
    event: 'all unscubscribe groups select toggled',
    properties: {
      page: Pages.SuppressionsGroupUnsubscribes,
      section: Sections.GroupUnsubscribes,
      subsection: Subsections.Table,
      elementType: ElementTypes.Checkbox,
      eventType: EventTypes.Click,
    },
  },
};
