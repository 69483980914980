const validationRules = {
  PASSWORD_LENGTH_MIN: 16,
  PASSWORD_LENGTH_MAX: 128,
  USERNAME_LENGTH_MIN: 3,
  USERNAME_LENGTH_MAX: 64,
  EMAIL_LENGTH_MIN: 5,
  EMAIL_LENGTH_MAX: 256,
  NAME_LENGTH_MIN: 2,
  NAME_LENGTH_MAX: 50,
  PHONE_LENGTH_MIN: 2,
  PHONE_LENGTH_MAX: 32,
  COMPANY_NAME_LENGTH_MAX: 255,
  WEBSITE_LENGTH_MAX: 255,
};

export default {
  USERNAME_DEFAULT: `Your username must have more than ${validationRules.USERNAME_LENGTH_MIN} and fewer than ${validationRules.USERNAME_LENGTH_MAX} characters, including at least 1 letter.`,
  USERNAME_TAKEN: 'That username is taken. Please try another.',
  USERNAME_TOO_MANY_REQUESTS:
    'Too many requests. Please try again in a few minutes.',
};
