import {
  MSPMetrics,
  GlobalStatMetrics,
  MailboxProviderStatsDto,
} from '../types';
import {
  calculateMSPMetricCounts,
  getTopMSPNamesFromMSPMetricCounts,
} from './calculateMSPMetricCounts';
import moment, { Moment } from 'moment';
import { dateQueryFormat } from '../../apiRoutes';

export const calculateMetricRate = (
  metric: number | null,
  divisorMetric: number | null
) => {
  let metricRate = ((metric ?? 0) / (divisorMetric ?? 0)) * 100;

  // Lol we divided by 0 set the result to 0
  if (!Number.isFinite(metricRate)) {
    metricRate = 0;
  }

  if (metricRate >= 0.1) {
    // This rounds to the nearest tenth ie. 1.254 -> 1.3
    metricRate = Number(metricRate.toFixed(1));
  } else {
    // This floors to the first significant digit ie. 0.0025 -> 0.002
    metricRate = Number(metricRate.toPrecision(2).slice(0, -1));
  }

  return metricRate;
};

export type MSPMetricNames = keyof MSPMetrics;

export type MSPAggregation = {
  [mspName: string]: number;
};

export type DateToMSPMetrics = {
  [date: string]: MSPAggregation;
};

export type GlobalMetricNames = keyof GlobalStatMetrics;

export type DateToGlobalMetric = {
  [date: string]: number;
};

export const calculateTopMSPsLookupFromMetricsCounts = (
  MetricCounts: MSPAggregation
) => {
  return Object.entries(MetricCounts)
    .map(([mspName, metricCount]) => ({ mspName, metricCount }))
    .sort(
      ({ metricCount: metricCountA }, { metricCount: metricCountB }) =>
        metricCountB - metricCountA
    )
    .map(({ mspName }) => mspName);
};

export const getTopMSPNames = (
  mailboxProviderStatsDto: MailboxProviderStatsDto
): string[] => {
  const MSPProcessedCounts = calculateMSPMetricCounts(
    'processed',
    mailboxProviderStatsDto
  );
  const topMSPNames = getTopMSPNamesFromMSPMetricCounts(5, MSPProcessedCounts);

  return topMSPNames;
};

// TODO: replace this RTK state when available, for now we will reference the timezone_model in Reqres
// Get the user's timezone UTC offset minutes; timezone is set in their account details area and affects their suppressions/stats
export const getUtcOffsetMinutes = () => {
  return -window.Reqres.request('timezone').getOffsetMinutes() || 0;
};

export type DateRangeType = {
  startDate: string;
  endDate?: string | Moment; // use today's date as default
};

export type WithinValidComparisonDateRangeType = {
  startDate: string;
  endDate?: string | Moment; // use today's date as default
  withinValidTimeFrameNum: number;
  withinValidTimeFrameUnit: 'days' | 'months';
};

// Compute the difference between startDate and endDate and make sure it's within the valid time frame
export const isWithinValidComparisonDuration = ({
  startDate,
  endDate,
  withinValidTimeFrameNum,
  withinValidTimeFrameUnit,
}: WithinValidComparisonDateRangeType) => {
  const currentEndDate = endDate
    ? moment(endDate, dateQueryFormat)
    : moment().utcOffset(getUtcOffsetMinutes());
  const comparisonDuration = currentEndDate.diff(
    startDate,
    withinValidTimeFrameUnit,
    true // This doesn't truncate the result to zero decimal places but returns back a float for more exact diffs
  );

  const isWithinValidComparisonDuration =
    comparisonDuration <= withinValidTimeFrameNum;

  return isWithinValidComparisonDuration;
};

export const isWithinLast30Days = ({ startDate }: DateRangeType) => {
  return isWithinValidComparisonDuration({
    startDate,
    withinValidTimeFrameNum: 30,
    withinValidTimeFrameUnit: 'days',
  });
};

export const isWithin6Months = ({ startDate, endDate }: DateRangeType) => {
  return isWithinValidComparisonDuration({
    startDate,
    endDate,
    withinValidTimeFrameNum: 6,
    withinValidTimeFrameUnit: 'months',
  });
};

export const isWithin13MonthCap = (startDate: moment.Moment) => {
  const leastRecentDateAvailable = moment()
    .utcOffset(getUtcOffsetMinutes())
    .subtract(13, 'months');
  const isWithin13MonthCap = startDate >= leastRecentDateAvailable;
  return isWithin13MonthCap;
};

export const getBounceClassificationDateRange = (
  startDate: string,
  endDate: string | undefined
) => {
  // Since backend only has 30 days of bounce classification data, we will clip the start date to 30
  // days ago if the date range is partially within the last 30 days. If the date range is completely
  // outside of the last 30 days window, we will keep the date range the same since it'll return empty
  // array and show an empty state anyways
  let startDateQuery = startDate;
  let endDateQuery = endDate;
  const thirtyDaysAgo = moment()
    .subtract(30, 'days')
    .utcOffset(getUtcOffsetMinutes())
    .format('YYYY-MM-DD');
  if (
    moment(startDate).isBefore(thirtyDaysAgo, 'day') &&
    moment(endDate).isSameOrAfter(thirtyDaysAgo, 'day')
  ) {
    startDateQuery = thirtyDaysAgo;
  }

  // If the start date and end date are the same, we will add a day to the end date so there are 2
  // days of stats to compare.
  if (startDateQuery === endDateQuery) {
    endDateQuery = moment(endDateQuery).add(1, 'day').format('YYYY-MM-DD');
  }

  return { startDateQuery, endDateQuery };
};

export const roundPercentDifference = (percent: number) => {
  let displayedPercent = 0;

  if (Math.abs(percent) >= 0.1) {
    // This rounds to the nearest tenth ie. 1.254 -> 1.3
    displayedPercent = Number(percent.toFixed(1));
  } else {
    // This floors to the first significant digit ie. 0.0025 -> 0.002
    displayedPercent = Number(percent.toPrecision(2).slice(0, -1));
  }
  return displayedPercent;
};
