var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    return "\n  <div class=\"clearfix\"></div>\n  <p>Add email addresses to your global unsubscribe list. You can add up to 500 addresses at a time.</p>\n  <div class=\"textarea-wrap is-required\" role=\"unsubsWrapper\">\n    <label class=\"textarea-label\" for=\"unsubs\">Email Addresses</label>\n    <textarea id=\"unsubs\" data-max-height=\"250\" role=\"unsubs\"></textarea>\n    <span class=\"input-info\" role=\"unsubsError\">Separate addresses with commas or new lines.</span>\n  </div>\n  <br>\n  <div class=\"btn-list\">\n    <div class=\"btn btn-secondary\" role=\"cancel\">Cancel</div>\n    <div class=\"btn btn-primary\" role=\"addUnsub\" data-permissions=\"suppression.create\">Add</div>\n  </div>\n  \n";
},"3":function(container,depth0,helpers,partials,data) {
    return "          <div class=\"conf-alert-actions\">\n            <div class=\"btn btn-secondary\" role=\"cancelClear\">Cancel</div>\n            <div class=\"btn btn-primary\" role=\"confirmClear\">Confirm</div>\n          </div>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "            <div class=\"btn btn-secondary btn-dropdown dropdown\" data-dropdown-toggle=\"true\" role=\"selectDropdownMenu\">\n              <span role=\"selectCount\">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"selectedCount") : depth0), depth0))
    + "</span> Selected\n\n              <ul class=\"dropdown-menu\" data-dropdown-menu=\"true\" role=\"selectMenu\">\n                <li>\n                  <a role=\"removeSelected\" class=\"dropdown-link\">\n                    <i class=\"icon-minus\"></i> Remove Selected "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "\n                  </a>\n                  <a role=\"removeAll\" class=\"dropdown-link "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"selectAll") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":46,"column":59},"end":{"line":46,"column":97}}})) != null ? stack1 : "")
    + "\">\n                    <i class=\"icon-minus\"></i> Remove All "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "\n                  </a>\n                  <a role=\"manageGroups\" class=\"dropdown-link "
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depth0 != null ? lookupProperty(depth0,"selectAllGroups") : depth0),{"name":"unless","hash":{},"fn":container.program(6, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":49,"column":62},"end":{"line":49,"column":106}}})) != null ? stack1 : "")
    + "\">\n                    <i class=\"icon-table-view\"></i> Manage Groups\n                  </a>\n                </li>\n              </ul>\n            </div>\n";
},"6":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"8":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"downloadable") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":56,"column":12},"end":{"line":81,"column":19}}})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "              <div class=\"btn btn-secondary btn-dropdown dropdown gear\" data-dropdown-toggle=\"true\" role=\"settingsDropdown\">\n                <i class=\"icon-settings\"></i>\n\n                <ul class=\"dropdown-menu\" data-dropdown-menu=\"true\" role=\"settingsMenu\">\n                  <li>\n                    <div class=\"arrow\"></div>\n                    <a class=\"dropdown-link\" role=\"downloadCSV\">\n                      Download as CSV\n                    </a>\n                  </li class=\"dropdown-link\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"canAdd") : depth0),{"name":"if","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":67,"column":18},"end":{"line":73,"column":25}}})) != null ? stack1 : "")
    + "                  <li>\n                    <a class=\"dropdown-link\" role=\"clearAll\">\n                      Clear all\n                    </a>\n                  </li>\n                </ul>\n              </div>\n";
},"10":function(container,depth0,helpers,partials,data) {
    return "                  <li>\n                    <a class=\"dropdown-link\" role=\"manualAdd\">\n                      Manually add recipients\n                    </a>\n                  </li>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(require("/opt/sendgrid/mako/src/templates/partials/sideModal.hbs"),depth0,{"name":"sideModal","hash":{"title":"Add Global Unsubscribes"},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n\n<div>\n    <div class=\"pull-left\">\n        <h1 title="
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"titleInfo") : depth0), depth0))
    + ">"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"title") : depth0), depth0))
    + "</h1>\n    </div>\n    <div class=\"pull-right\">\n"
    + ((stack1 = container.invokePartial(require("/opt/sendgrid/mako/src/templates/partials/confirmationModal.hbs"),depth0,{"name":"confirmationModal","hash":{"title":__default(require("/opt/sendgrid/mako/src/templates/helpers/concat.js")).call(alias3,"Clear All ",(depth0 != null ? lookupProperty(depth0,"title") : depth0),"?",{"name":"concat","hash":{},"data":data,"loc":{"start":{"line":28,"column":16},"end":{"line":28,"column":47}}}),"role":"confirmClearAllModal"},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "\n        <div class=\"btn-group btn-group-icons\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depth0 != null ? lookupProperty(depth0,"isSelected") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.program(8, data, 0),"data":data,"loc":{"start":{"line":37,"column":10},"end":{"line":82,"column":17}}})) != null ? stack1 : "")
    + "          <div class=\"btn btn-secondary btn-svg-icon\" role=\"refresh\">\n            <img src=\"" + require("../../public/assets_mako/images/palette/refresh.svg") + "\" class=\"svg refresh\">\n          </div>\n\n          <div class=\"btn btn-secondary btn-svg-icon\" role=\"dateRangeButton\">\n            <img src=\"" + require("../../public/assets_mako/images/palette/calendar.svg") + "\" class=\"svg calendar\">\n          </div>\n          <div class=\"calendar-container\" role=\"calendarContainer\"></div>\n        </div>\n    </div>\n</div>\n";
},"usePartial":true,"useData":true});