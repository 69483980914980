import { GlobalStatsDto } from '../types';
import { GlobalMetricNames } from './helpers';

/*
  Calculate the overall count for a list of metric names (summed together)

  Usage: calculateGlobalMetricCount(["bounces"], globalStatsDto); for overall bounce count
  Output: 20

  calculateGlobalMetricCount["bounces", "blocks"], globalStatsDto); for overall sum of bounce and block counts
  Output: 40
*/
export function calculateGlobalMetricCount(
  metricNames: GlobalMetricNames[],
  stats: GlobalStatsDto
): number {
  let Overall = 0;

  // Sum up the overall count for the metric names provided
  stats.forEach((byDate) => {
    byDate.stats.forEach((metricsByDate) => {
      let metricCountByDate = 0;
      // We loop through the list of metrics to aggregate together i.e. ["bounces"] or ["bounces", "blocks"]
      metricNames.forEach((metricName) => {
        const metricCount = metricsByDate.metrics[metricName];
        metricCountByDate = metricCountByDate + metricCount;
      });
      Overall = Overall + metricCountByDate;
    });
  });

  return Overall;
}
