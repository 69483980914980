var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, alias1=container.lambda, alias2=container.escapeExpression, alias3=depth0 != null ? depth0 : (container.nullContext || {}), lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"stat rank-"
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"rank") : depth0), depth0))
    + " "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\">\n  <div role=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"key") : depth0), depth0))
    + "\">\n    <span role=\"percentages\" class=\""
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"displayNumbers") : depths[1]),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":4,"column":36},"end":{"line":4,"column":74}}})) != null ? stack1 : "")
    + "\">"
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/percentStat.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"key") : depth0),depths[1],{"name":"percentStat","hash":{},"data":data,"loc":{"start":{"line":4,"column":76},"end":{"line":4,"column":103}}}))
    + "</span>\n    <span role=\"numbers\" class=\""
    + ((stack1 = lookupProperty(helpers,"unless").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"displayNumbers") : depths[1]),{"name":"unless","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":5,"column":32},"end":{"line":5,"column":78}}})) != null ? stack1 : "")
    + "\">"
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/stat.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"key") : depth0),depths[1],{"name":"stat","hash":{},"data":data,"loc":{"start":{"line":5,"column":80},"end":{"line":5,"column":100}}}))
    + "</span>\n  </div>\n  <div class=\"rate "
    + ((stack1 = lookupProperty(helpers,"if").call(alias3,(depths[1] != null ? lookupProperty(depths[1],"displayNumbers") : depths[1]),{"name":"if","hash":{},"fn":container.program(2, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":7,"column":19},"end":{"line":7,"column":57}}})) != null ? stack1 : "")
    + "\" style=\"width:"
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/percentStatBarWidth.js")).call(alias3,(depth0 != null ? lookupProperty(depth0,"key") : depth0),depths[1],{"name":"percentStatBarWidth","hash":{},"data":data,"loc":{"start":{"line":7,"column":72},"end":{"line":7,"column":107}}}))
    + "\"></div>\n</td>\n";
},"2":function(container,depth0,helpers,partials,data) {
    return "hidden";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"each").call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"columns") : depth0),{"name":"each","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":9,"column":9}}})) != null ? stack1 : "");
},"useData":true,"useDepths":true});