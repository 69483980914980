import React, { useEffect, useState } from 'react';
import { Navbar } from './Navbar';
import { PUBLIC_ROUTES, UNIFIED_USER_INFO_COOKIE_NAME } from '../../constants';
import * as Cookies from 'js-cookie';
import { UnifiedUserInfo } from '../../helpers/getUnifiedUserInfoCookie';
import { sendgridAppStore } from 'src/state/store/sendgridAppStore';
import { Provider } from 'react-redux';

export const DO_NOT_SHOW_PATH_PREFIXES = [
  'validate_2fa',
  'signup',
  'security_checkup',
  'password_reset_required',
  'oauth',
  'errors',
  '2fa_email_checkpoint',
  'reseller',
  'public',
  'subuser',
  'onboarding',
  ...PUBLIC_ROUTES,
];

export const NavbarContainer = () => {
  const shouldRenderNavForPath = !DO_NOT_SHOW_PATH_PREFIXES.some((prefix) =>
    window.location.pathname.startsWith(`/${prefix}`)
  );
  const [unifiedUserInfo, setUnifiedUseInfo] = useState<
    UnifiedUserInfo | undefined
  >(undefined);

  useEffect(() => {
    const unifiedUserInfoCookie = Cookies.get(UNIFIED_USER_INFO_COOKIE_NAME);
    if (unifiedUserInfoCookie !== undefined) {
      setUnifiedUseInfo(JSON.parse(unifiedUserInfoCookie));
    }
  }, []);

  return shouldRenderNavForPath && Boolean(unifiedUserInfo) ? (
    <Provider store={sendgridAppStore}>
      <Navbar
        showProductSwitcher={true}
        name={`${unifiedUserInfo?.given_name ?? ''} ${
          unifiedUserInfo?.family_name ?? ''
        }`}
        email={unifiedUserInfo?.email ?? ''}
      />
    </Provider>
  ) : null;
};
