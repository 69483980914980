import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthedHttp } from '../../helpers/AuthedHttp';
import { sanitizer } from '../dtoSanitizer';
import {
  SendGridAppDispatch,
  SendGridRootState,
} from '../store/sendgridAppStore';
import {
  GenericNetworkSlice,
  NetworkState,
  Unrequested,
} from '../store/sliceNetworkTypes';
import { ResponseError } from '../../helperTypes/responseError';

export enum Routes {
  SignupToSend = 's2s/signup/status',
}

const GenericFetchError = 'Network Error. Failed to fetch signup to send';

export interface SignupToSendDto {
  whitelabel_complete: boolean;
  whitelabel_required: boolean;
  mfa_complete: boolean;
  mfa_required: boolean;
  email_complete: boolean;
  email_required: boolean;
  payment_complete: boolean;
  payment_required: boolean;
  profile_complete: boolean;
  profile_required: boolean;
  is_reseller_customer: boolean;
  payment_method_verification_complete: boolean;
  payment_method_verification_required: boolean;
  manual_vetting_complete: boolean;
  manual_vetting_required: boolean;
}

export interface SignupToSend {
  senderAuthComplete: boolean;
  senderAuthRequired: boolean;
  mfaComplete: boolean;
  mfaRequired: boolean;
  emailComplete: boolean;
  emailRequired: boolean;
  paymentComplete: boolean;
  paymentRequired: boolean;
  profileComplete: boolean;
  profileRequired: boolean;
  isResellerCustomer: boolean;
  paymentMethodVerificationComplete: boolean;
  paymentMethodVerificationRequired: boolean;
  manualVettingComplete: boolean;
  manualVettingRequired: boolean;
}

export const fetchSignupToSend = createAsyncThunk<
  SignupToSend,
  void,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('signupToSend/fetchSignupToSend', async (_, thunkApi) => {
  try {
    const response = await AuthedHttp.get<SignupToSendDto>(Routes.SignupToSend);

    if (response.ok) {
      const data = await response.json();
      return signupToSendAdapter(data);
    }

    const errorResponse = await response.json();

    return thunkApi.rejectWithValue({
      message: errorResponse.errors[0].message,
      statusCode: response.status,
    });
  } catch {
    return thunkApi.rejectWithValue({
      message: GenericFetchError,
    });
  }
});

export const signupToSendAdapter = (dto: SignupToSendDto): SignupToSend => {
  const sanitizedSignupToSend = sanitizer(dto);
  const {
    whitelabel_complete,
    whitelabel_required,
    mfa_complete,
    mfa_required,
    email_complete,
    email_required,
    payment_complete,
    payment_required,
    profile_complete,
    profile_required,
    is_reseller_customer,
    payment_method_verification_complete,
    payment_method_verification_required,
    manual_vetting_complete,
    manual_vetting_required,
  } = sanitizedSignupToSend;

  return {
    senderAuthComplete: whitelabel_complete,
    senderAuthRequired: whitelabel_required,
    mfaComplete: mfa_complete,
    mfaRequired: mfa_required,
    emailComplete: email_complete,
    emailRequired: email_required,
    paymentComplete: payment_complete,
    paymentRequired: payment_required,
    profileComplete: profile_complete,
    profileRequired: profile_required,
    isResellerCustomer: is_reseller_customer,
    paymentMethodVerificationComplete: payment_method_verification_complete,
    paymentMethodVerificationRequired: payment_method_verification_required,
    manualVettingComplete: manual_vetting_complete,
    manualVettingRequired: manual_vetting_required,
  } as SignupToSend;
};

export type SignupToSendState = GenericNetworkSlice<SignupToSend>;

const initialState: Unrequested = {
  networkState: NetworkState.Unrequested,
  data: null,
};

export const signupToSendSlice = createSlice<SignupToSendState, any>({
  name: 'signupToSend',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchSignupToSend.pending, (state) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(fetchSignupToSend.fulfilled, (state, action) => {
      state.networkState = NetworkState.Success;

      if (state.networkState === NetworkState.Success) {
        state.data = action.payload;
      }
    });
    builder.addCase(fetchSignupToSend.rejected, (state, action) => {
      state.networkState = NetworkState.Error;

      if (state.networkState === NetworkState.Error) {
        (state.statusCode = action.payload?.statusCode || 0),
          (state.errorMessage = action.payload?.message || GenericFetchError);
      }
    });
  },
});
