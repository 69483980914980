const Marionette = require("backbone.marionette");
const Backbone = require("backbone");
const $ = require("jquery");
require("bootstrap-sass/assets/javascripts/bootstrap/transition");
require("bootstrap-sass/assets/javascripts/bootstrap/tooltip");
const { analytics } = require("@sendgrid/bloodhound");
const { GroupEvents } = require("../analytics/suppressions/groupEvents");

const SuppressionView = require("./suppression_view");
const Palette = require("palette");

module.exports = Marionette.CompositeView.extend({
  childView: SuppressionView,
  childViewContainer: "tbody",
  template: require("templates/suppressions_list.hbs"),
  collectionEvents: {
    sync: "render updateUrl",
  },
  ui: {
    searchEmail: "[role=searchEmailContainer] [role=input]",
  },
  events: {
    "keypress @ui.searchEmail": "getSearchResults",
    "change [role=selectAllBoxes]": "selectAllBoxes",
    "click .searchDisplayButton": "resetSearchResults",
  },

  serializeData() {
    const results = {};
    results.url = this.collection.url();

    results.limit = this.collection.limit;
    results.lastPage = this.collection.lastPage;
    results.currentPage = this.collection.offset / this.collection.limit + 1;
    results.queryParams = {};
    if (this.collection.searchEmail) {
      results.queryParams.email = this.collection.searchEmail;
    }
    if (this.collection.startTime) {
      results.queryParams.start_time = this.collection.startTime;
    }
    if (this.collection.endTime) {
      results.queryParams.end_time = this.collection.endTime;
    }

    return results;
  },

  onRender() {
    Palette.bindGravatars(this.$el);
    Palette.convertSvgsToInline();
    if (this.collection.searchEmail && this.collection.searchEmail.length > 0) {
      this.$("[role=searchDisplayBar]").removeClass("hidden");
      this.$("[role=searchDisplayBar] td div.searchDisplayBarText").text(
        `Displaying results for: ${this.collection.searchEmail}`
      );
    }
  },

  buildChildView(item, ItemView) {
    return new ItemView({
      model: item,
      templateHelpers: {
        showReason: this.options.templateHelpers.showReason,
        showGroup: this.options.templateHelpers.showGroup,
        showCheckbox: this.options.templateHelpers.showCheckbox,
      },
    });
  },

  getSearchResults(e) {
    if (e.which === 13) {
      analytics.track(GroupEvents.searchInput);
      const { collection } = this;
      this.collection.searchEmail = e.target.value;
      this.collection.offset = 0;
      this.collection.fetch({
        success() {
          collection.trigger("updateCounter");
        },
        error(model, response) {
          let msg = "An error has occurred";
          if (response.responseJSON) {
            const { errors } = response.responseJSON;
            if (errors) {
              // MAKOAPI returns 'errors'
              msg = errors[0].message;
            }
            const { error } = response.responseJSON;
            if (error) {
              // ASMAPI returns 'error'
              msg = error;
            }
          }
          window.EventBus.trigger("flash", msg, true);
        },
      });
    }
  },

  resetSearchResults() {
    const { collection } = this;
    this.collection.searchEmail = "";
    this.collection.offset = 0;
    this.collection.fetch({
      success() {
        collection.trigger("updateCounter");
      },
    });
  },

  selectAllBoxes() {
    analytics.track(GroupEvents.selectAllCheckbox);
    const state = this.$("[role=selectAllBoxes] input").prop("checked");
    this.collection.each((model) => {
      model.set("checked", state);
    });
    this.collection.trigger("selectAll", state);
    this.collection.trigger("updateCounter");
    Palette.bindGravatars(this.$el);
  },

  updateUrl() {
    const route = Backbone.history.fragment.split("?")[0];
    const queryParams = {};

    const currentPage = this.collection.offset / this.collection.limit + 1;
    if (currentPage !== 1) {
      queryParams.page = currentPage;
    }
    if (this.collection.searchEmail) {
      queryParams.email = this.collection.searchEmail;
    }
    if (this.collection.startTime) {
      queryParams.start_time = this.collection.startTime;
    }
    if (this.collection.endTime) {
      queryParams.end_time = this.collection.endTime;
    }
    Backbone.history.navigate(`${route}?${$.param(queryParams)}`);
  },
});
