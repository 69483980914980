import {
  getPriceByName,
  calculateEstimatedProratedPrice,
} from '../../common/adapters';
import { PriceNamesDto, OfferingDto } from '../../services/oes/dtos';
import { TestingCreditsPlan } from '../../../testing_credits_plans/types';

export function mapTestingCreditsPlan(
  offeringDto: OfferingDto
): TestingCreditsPlan {
  const tcPrice = getPriceByName(offeringDto.pricing, PriceNamesDto.TC_BASE);

  return {
    displayName: offeringDto.display.en_us,
    name: offeringDto.name,
    price: tcPrice,
    count: offeringDto.entitlements.email_testing_cap_monthly || 0,
    // temporary fix un til OES returns prorated price
    // should be ripped out ASAP
    proratedPrice: calculateEstimatedProratedPrice(tcPrice) || 0,
  };
}
