import { Action } from '../types/action';
import { ActionTypes } from './actionTypes';
import { accountAdapter } from './adapters';
import { CreateUserAccountDto } from '../dtos';

const initialState = {};
interface AccountAction extends Action {
  payload: CreateUserAccountDto;
}

export const account = (state = initialState, action: AccountAction) => {
  switch (action.type) {
    case ActionTypes.CreateUserAccount: {
      return accountAdapter(action.payload);
    }
    default:
      return state;
  }
};
