import { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { request, ClientError } from 'graphql-request';
import APIConfig from '../../../apiconfig';
import defaultJSONHeaders from '../../../helpers/default_json_headers';
import { GraphQLError } from 'graphql-request/dist/types';

/**
 * See https://spec.graphql.org/draft/#sec-Error
 */
export interface GraphQLServerError {
  data: GraphQLError[];
  status: number;
}

// Adapted from https://github.com/reduxjs/redux-toolkit/blob/master/packages/rtk-query-graphql-request-base-query/src/index.ts
export const graphqlBaseQuery: BaseQueryFn<
  {
    document: string;
    variables?: any;
  },
  unknown,
  GraphQLServerError,
  unknown,
  Partial<Pick<ClientError, 'request' | 'response'>>
> = async ({
  document,
  variables,
}: {
  document: string;
  variables: Record<string, any>;
}) => {
  try {
    return {
      data: await request({
        url: APIConfig.graphqlHost,
        document,
        variables,
        requestHeaders: defaultJSONHeaders.headers,
      }),
      meta: {},
    };
  } catch (error) {
    if (error instanceof ClientError) {
      const { request, response } = error;
      return {
        error: {
          status: response.status,
          data: response.errors || [],
        },
        meta: { request, response },
      };
    }
    throw error;
  }
};
