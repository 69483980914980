export enum EmailInsightsDeliverabilityTabs {
  Base = '/email_insights/deliverability',
  Overview = '/email_insights/deliverability/overview',
  MailboxProviders = '/email_insights/deliverability/mailbox_providers',
  BouncedAndBlocked = '/email_insights/deliverability/bounced_and_blocked',
  SpamAndUnsubscribes = '/email_insights/deliverability/spam_and_unsubscribes',
}
export const DeliverabilityTabRoutes = {
  ...EmailInsightsDeliverabilityTabs,
};
