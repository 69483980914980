import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { UpgradeEligibility } from '../types/upgradeEligibility';

export const Actions = {
  upgradeEligibilityRequest: () =>
    createAction(ActionTypes.UpgradeEligibilityRequest),
  upgradeEligibilitySuccess: (upgradeEligibility: UpgradeEligibility) =>
    createAction(ActionTypes.UpgradeEligibilitySuccess, upgradeEligibility),
  upgradeEligibilityFailure: (error: string) =>
    createAction(ActionTypes.UpgradeEligibilityFailure, error),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
