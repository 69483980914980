import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import flatten from 'lodash/flatten';
import cloneDeep from 'lodash/cloneDeep';
import {
  SSOTeammateDto,
  SSOMultisubuserTeammateDto,
  SSOTeammateSubuserAccessRequestBody,
  PersonasDto,
  PersonaDto,
  ScopesNameDto,
  ScopesNamesDto,
} from './types';
import { ResponseError } from '../../../../helperTypes/responseError';
import { TeammatesRoutes } from '../../apiRoutes';
import { AuthedHttp } from '../../../../helpers/AuthedHttp';
import {
  SendGridAppDispatch,
  SendGridRootState,
} from '../../../../state/store/sendgridAppStore';

// Mapping scope name to group and display name
// { [scopeName]: [groupName, displayName] }
// i.e. { "validations.email.read": ["Email Address Validation", "Reporting"] }
export interface ScopeLookup {
  [scopeName: string]: string[];
}

// Mapping group name to the group scopes we can select
export interface ScopeStore {
  [groupName: string]: {
    allFullAccess: string[]; // To make it easy to submit the all full access values and read access values when you use the parent accordion selector. Can also use it to calculate if the children are full or partially (read only) selected
    allReadAccess: string[];
    scopeInfoList: ScopeInfo[];
  };
}

interface ScopeFromNetwork {
  display_name: string;
  full_access_scopes: string[];
  read_access_scopes: string[];
}

interface ScopeInfo extends ScopeFromNetwork {
  selected_scopes: 'none' | 'read' | 'full';
}

interface TeammatePersonas {
  Developer: string[];
  Observer: string[];
  Marketer: string[];
  Accountant: string[];
}

interface SelectedTeammate {
  email: string;
  scopes: string[];
  isAdmin: boolean;
}
export interface ScopeSelectorState {
  // These loading states should probably be migrated to something that can allow type narrowing or something else. This is pretty messy with the various loading/not loading states.
  isFirstLoad: boolean;
  loading: boolean;
  isScopesAndPersonasFetchError: boolean;
  saving: boolean;
  personas: TeammatePersonas;
  scopeStore: ScopeStore;
  scopeLookup: ScopeLookup;
  // Keeping track of the scopes we get back from fetching scopes/names that the user cannot modify access level to in the scope selector so we can include them on PATCH calls
  scopesNotInSelector: string[];
  selectedTeammate: SelectedTeammate;
  // Used for create errors
  errorID: string;
}

const initialState: ScopeSelectorState = {
  isFirstLoad: true,
  loading: true,
  isScopesAndPersonasFetchError: false,
  saving: false,
  personas: {
    Developer: [],
    Observer: [],
    Marketer: [],
    Accountant: [],
  },
  scopeStore: {},
  scopeLookup: {},
  scopesNotInSelector: [],
  selectedTeammate: {
    email: '',
    scopes: [],
    isAdmin: false,
  },
  errorID: '',
};

export const buildStoreAndLookup = (payload: ScopesNameDto[]) => {
  const updatedScopeStore: ScopeStore = {};
  const updatedScopeLookup: ScopeLookup = {};

  // Populate scopes store by mapping group names to group scopes information
  payload.forEach((scopesNameDto: ScopesNameDto) => {
    const {
      group_name,
      display_name,
      full_access_scopes,
      read_access_scopes,
    } = scopesNameDto;

    updatedScopeStore[group_name] = updatedScopeStore[group_name] ?? {
      allFullAccess: [],
      allReadAccess: [],
      scopeInfoList: [],
    };
    const currScopeInfoList =
      updatedScopeStore[group_name]?.scopeInfoList ?? [];

    currScopeInfoList.push({
      display_name,
      full_access_scopes,
      read_access_scopes,
      selected_scopes: 'none',
    });
    updatedScopeStore[group_name].scopeInfoList = currScopeInfoList;
    updatedScopeStore[group_name].allFullAccess.push(...full_access_scopes);
    updatedScopeStore[group_name].allReadAccess.push(...read_access_scopes);

    updatedScopeStore[group_name].scopeInfoList.sort((a, b) =>
      a.display_name.toLowerCase().localeCompare(b.display_name.toLowerCase())
    );

    // Create Scope Lookup
    const scopeNames = full_access_scopes.concat(read_access_scopes);
    scopeNames.forEach((scopeName) => {
      updatedScopeLookup[scopeName] = [group_name, display_name];
    });
  });

  return {
    scopeStore: updatedScopeStore,
    scopeLookup: updatedScopeLookup,
  };
};

export const updateStoreFromScopeList = ({
  scopeList = [],
  prevScopeStore,
  prevScopeLookup,
  scopesNotInSelector: newScopesNotInSelector = [],
  fetchedTeammate = false,
}: {
  scopeList: string[];
  prevScopeStore: ScopeStore;
  prevScopeLookup: ScopeLookup;
  scopesNotInSelector?: string[];
  fetchedTeammate?: boolean;
}) => {
  const updatedScopeStore: ScopeStore = cloneDeep(prevScopeStore);

  const fullAccessScopesTracker: { [key: string]: boolean } = {};

  scopeList.forEach((scope) => {
    if (prevScopeLookup[scope]) {
      const [groupName, displayName] = prevScopeLookup[scope];
      const { scopeInfoList } = updatedScopeStore[groupName];
      const lookedUpScopeIndex = scopeInfoList.findIndex(
        (scopeNames) => scopeNames.display_name === displayName
      );
      const lookedUpScope = scopeInfoList[lookedUpScopeIndex];

      if (lookedUpScope) {
        // fullAccessScopesTracker is used to make sure a read scope doesn't override a create/update/delete scope
        let updatedSelected = lookedUpScope.selected_scopes;
        if (lookedUpScope.full_access_scopes.includes(scope)) {
          updatedSelected = 'full';
          fullAccessScopesTracker[lookedUpScope.display_name] = true;
        } else if (
          lookedUpScope.read_access_scopes.includes(scope) &&
          (!fullAccessScopesTracker[lookedUpScope.display_name] ||
            fullAccessScopesTracker[lookedUpScope.display_name] === false)
        ) {
          updatedSelected = 'read';
        }

        const newScope: ScopeInfo = {
          ...lookedUpScope,
          selected_scopes: updatedSelected,
        };

        const newScopeInfoList = [
          ...updatedScopeStore[groupName].scopeInfoList,
        ];
        newScopeInfoList[lookedUpScopeIndex] = newScope;

        updatedScopeStore[groupName] = {
          ...updatedScopeStore[groupName],
          scopeInfoList: newScopeInfoList,
        };
      }
    } else if (fetchedTeammate) {
      // Add to scopesNotInSelector if fetched teammate scopes
      // Reset scopesNotInSelector before each new teammate fetch
      const scopesForbiddenFromRequestBody = ['2fa_exempt', '2fa_required'];
      const isAllowedScope =
        scopesForbiddenFromRequestBody.indexOf(scope) === -1;
      // Assuming the teammate's scope is not one of the forbidden ones and not part of the selectors, we need to make
      // sure to add it to the newScopesNotInSelector so we pass along all the scopes, those in the selectors and not in the selectors,
      // in the PATCH call and don't accidentally remove them
      // NOTE: We don't pass in extra scopes that aren't in the selectors if we make a POST call before fetching an SSO teammate's details because the backend will make sure to add the scopes not part of the selector for us
      if (isAllowedScope) {
        newScopesNotInSelector.push(scope);
      }
    }
  });

  return {
    scopeStore: updatedScopeStore,
    scopesNotInSelector: newScopesNotInSelector,
  };
};

export const formatPersonas = ({
  personasList,
  scopeStore,
}: {
  personasList: PersonaDto[];
  scopeStore: ScopeStore;
}) => {
  const personaStore: TeammatePersonas = {
    Developer: [],
    Observer: [],
    Marketer: [],
    Accountant: [],
  };

  personasList.forEach((persona, idx) => {
    const foundScopeItem =
      scopeStore[persona.group_name] &&
      scopeStore[persona.group_name].scopeInfoList.find(
        (scopeNames) => scopeNames.display_name === persona.display_name
      );

    if (foundScopeItem) {
      switch (persona.selected) {
        case 'full':
          personaStore[persona.persona].push(
            ...foundScopeItem?.full_access_scopes
          );
        // eslint-disable-next-line no-fallthrough
        case 'read':
          personaStore[persona.persona].push(
            ...foundScopeItem?.read_access_scopes
          );
        // eslint-disable-next-line no-fallthrough
        default:
          break;
      }
    }
  });

  return personaStore;
};

export const fetchScopesNamesAndPersonas = createAsyncThunk<
  {
    scopeStore: ScopeStore;
    scopeLookup: ScopeLookup;
    personas: TeammatePersonas;
  },
  any,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('FETCH_SCOPES_NAMES_AND_PERSONAS', async (args, thunkApi) => {
  try {
    const scopesNamesResp = await AuthedHttp.get<ScopesNamesDto>(
      TeammatesRoutes.getScopesNames()
    );
    const personasResp = await AuthedHttp.get<PersonasDto>(
      TeammatesRoutes.getPersonas()
    );

    const [scopesNamesResult, personasResult] = await Promise.all([
      scopesNamesResp,
      personasResp,
    ]);

    if (scopesNamesResult.ok && personasResult.ok) {
      const [scopesNamesAnswer, personasAnswer] = await Promise.all([
        scopesNamesResult.json(),
        personasResult.json(),
      ]);

      const addressAllowListOriginalDisplayName = 'Address Whitelist';
      const addressAllowListRevisedDisplayName = 'Address Allow List';
      const emailAddressValidationOriginalGroupName = 'Email Validation';
      const emailAddressValidationRevisedGroupName = 'Email Address Validation';
      scopesNamesAnswer.result = scopesNamesAnswer.result.map(
        (scopeNameData) => {
          // TODO: remove when backend officially says Address Allow List in display_name (INCLUSIVE)
          // Temporarily rename to Address Allow List until backend officially changes the name
          if (
            scopeNameData.display_name === addressAllowListOriginalDisplayName
          ) {
            return {
              ...scopeNameData,
              display_name: addressAllowListRevisedDisplayName,
            };
          }
          // Rename Email Validation to Email Address Validation
          if (
            scopeNameData.group_name === emailAddressValidationOriginalGroupName
          ) {
            return {
              ...scopeNameData,
              group_name: emailAddressValidationRevisedGroupName,
            };
          }

          return scopeNameData;
        }
      );
      // TODO: remove when backend officially says Address Allow List (INCLUSIVE)
      personasAnswer.result = personasAnswer.result.map((personaData) => {
        if (personaData.display_name === addressAllowListOriginalDisplayName) {
          return {
            ...personaData,
            display_name: addressAllowListRevisedDisplayName,
          };
        }
        if (
          personaData.group_name === emailAddressValidationOriginalGroupName
        ) {
          return {
            ...personaData,
            group_name: emailAddressValidationRevisedGroupName,
          };
        }
        return personaData;
      });

      const { scopeStore: builtScopeStore, scopeLookup } = buildStoreAndLookup(
        scopesNamesAnswer.result
      );

      const formattedPersonas = formatPersonas({
        personasList: personasAnswer.result,
        scopeStore: builtScopeStore,
      });

      return {
        scopeStore: builtScopeStore,
        scopeLookup,
        personas: formattedPersonas,
      };
    }
    throw new Error();
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to get scopes names and personas info: ${e}`,
    });
  }
});

export const fetchTeammate = createAsyncThunk<
  {
    scopeStore: ScopeStore;
    scopesNotInSelector: string[];
    selectedTeammate: SelectedTeammate;
  },
  { username: string },
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('FETCH_TEAMMATE', async (args, thunkApi) => {
  try {
    const prevState = thunkApi.getState();
    const {
      scopeStore: prevScopeStore,
      scopeLookup: prevScopeLookup,
    } = prevState.scopeSelector;
    const teammateResp = await AuthedHttp.get<SSOTeammateDto>(
      TeammatesRoutes.getTeammate({ username: args.username })
    );

    if (!teammateResp.ok) {
      return thunkApi.rejectWithValue({
        message: `Failed to get teammate info`,
      });
    }

    const teammateInfo = await teammateResp.json();

    if (teammateInfo.is_admin) {
      teammateInfo.scopes = [];
    }

    const { scopeStore, scopesNotInSelector } = updateStoreFromScopeList({
      scopeList: teammateInfo.scopes,
      prevScopeStore,
      prevScopeLookup,
      fetchedTeammate: true,
    });

    return {
      scopeStore,
      scopesNotInSelector,
      selectedTeammate: {
        email: teammateInfo.email,
        scopes: teammateInfo.scopes,
        isAdmin: teammateInfo.is_admin,
      },
    };
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to get teammate info: ${e}`,
    });
  }
});

// Get all scopes for editing sso/teammate (to send to PATCH/POST)
export const UserSelectedScopesRaw = (currScopeStore: ScopeStore) => {
  const theScopes: string[] = [];
  Object.entries(currScopeStore).forEach(([groupName, groupScopeInfo]) => {
    groupScopeInfo.scopeInfoList.forEach((scopeInfoItem) => {
      switch (scopeInfoItem.selected_scopes) {
        case 'none':
          break;
        case 'full':
          theScopes.push(...scopeInfoItem.full_access_scopes);
        // eslint-disable-next-line no-fallthrough
        case 'read':
          theScopes.push(...scopeInfoItem.read_access_scopes);
          break;
      }
    });
  });
  return theScopes;
};

export const flattenScopes = (rawscopes: any) =>
  flatten(rawscopes.filter((identity: any) => identity));

export const updateSSOTeammate = createAsyncThunk<
  SSOTeammateDto,
  {
    email: string;
    first_name: string;
    last_name: string;
    is_admin: boolean;
  },
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('UPDATE_SSO_TEAMMATE', async (args, thunkApi) => {
  try {
    const prevState = thunkApi.getState();
    const { email, first_name, last_name, is_admin } = args;

    const {
      scopeStore: prevScopeStore,
      scopesNotInSelector,
    } = prevState.scopeSelector;
    const rawScopes = UserSelectedScopesRaw(prevScopeStore);

    const newScopes = flattenScopes(rawScopes);

    let calculatedScopes = [...newScopes, ...scopesNotInSelector];

    if (is_admin) {
      calculatedScopes = [];
    }

    const teammateResp = await AuthedHttp.patch<SSOTeammateDto>(
      TeammatesRoutes.updateSSOTeammate({
        username: email,
      }),
      { first_name, last_name, is_admin, scopes: calculatedScopes }
    );

    if (!teammateResp.ok) {
      return thunkApi.rejectWithValue({
        message: `Failed to update teammate info`,
      });
    }

    const teammateInfo = await teammateResp.json();

    return teammateInfo;
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to update teammate info: ${e}`,
    });
  }
});

export const createSSOTeammate = createAsyncThunk<
  SSOTeammateDto,
  {
    email: string;
    first_name: string;
    last_name: string;
    is_admin: boolean;
  },
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('CREATE_SSO_TEAMMATE', async (args, thunkApi) => {
  try {
    const prevState = thunkApi.getState();
    const { email, first_name, last_name, is_admin } = args;

    const { scopeStore: prevScopeStore } = prevState.scopeSelector;
    const rawScopes = UserSelectedScopesRaw(prevScopeStore);

    let newScopes = flattenScopes(rawScopes);

    if (is_admin) {
      newScopes = [];
    }

    const teammateResp = await AuthedHttp.post<SSOTeammateDto>(
      TeammatesRoutes.createSSOTeammate(),
      {
        email,
        first_name,
        last_name,
        is_admin,
        scopes: newScopes,
      }
    );

    if (!teammateResp.ok) {
      const teammateError = await teammateResp.json();

      return thunkApi.rejectWithValue({
        message: `Failed to create teammate info`,
        error_id: teammateError.errors[0].error_id,
      });
    }

    const teammateInfo = await teammateResp.json();

    return teammateInfo;
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to create teammate info: ${e}`,
    });
  }
});

export const updateSSOMultisubuserTeammate = createAsyncThunk<
  SSOMultisubuserTeammateDto,
  {
    email: string;
    first_name: string;
    last_name: string;
    subuser_access: SSOTeammateSubuserAccessRequestBody[];
  },
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('UPDATE_SSO_MULTISUBUSER_TEAMMATE', async (args, thunkApi) => {
  try {
    const { email, first_name, last_name, subuser_access } = args;

    const teammateResp = await AuthedHttp.patch<SSOMultisubuserTeammateDto>(
      TeammatesRoutes.updateSSOTeammate({
        username: email,
      }),
      {
        first_name,
        last_name,
        has_restricted_subuser_access: true,
        subuser_access,
      }
    );

    if (!teammateResp.ok) {
      return thunkApi.rejectWithValue({
        message: `Failed to update teammate info`,
      });
    }

    const teammateInfo = await teammateResp.json();

    return teammateInfo;
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to update teammate info: ${e}`,
    });
  }
});

export const createSSOMultisubuserTeammate = createAsyncThunk<
  SSOMultisubuserTeammateDto,
  {
    email: string;
    first_name: string;
    last_name: string;
    subuser_access: SSOTeammateSubuserAccessRequestBody[];
  },
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('CREATE_SSO_MULTISUBUSER_TEAMMATE', async (args, thunkApi) => {
  try {
    const { email, first_name, last_name, subuser_access } = args;

    const teammateResp = await AuthedHttp.post<SSOMultisubuserTeammateDto>(
      TeammatesRoutes.createSSOTeammate(),
      {
        email,
        first_name,
        last_name,
        subuser_access,
        has_restricted_subuser_access: true,
      }
    );

    if (!teammateResp.ok) {
      const teammateError = await teammateResp.json();

      return thunkApi.rejectWithValue({
        message: `Failed to create teammate info`,
        error_id: teammateError.errors[0].error_id,
      });
    }

    const teammateInfo = await teammateResp.json();

    return teammateInfo;
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to create teammate info: ${e}`,
    });
  }
});

export type PersonaType = 'Developer' | 'Marketer' | 'Accountant' | 'Observer';

export const updateStoreFromButtonClick = createAction<
  {
    selected: any;
    groupName: string;
    displayName: string;
  },
  'updateStoreFromButtonClick'
>('updateStoreFromButtonClick');

export const updateStoreFromPersonaButtonClick = createAction<
  { persona: PersonaType },
  'updateStoreFromPersonaButtonClick'
>('updateStoreFromPersonaButtonClick');

export const updateStoreToTeammateResponse = createAction<
  { fetchedTeammate: boolean },
  'updateStoreToTeammateResponse'
>('updateStoreToTeammateResponse');

export const clearSSOTeammateErrorIDs = createAction(
  'clearSSOTeammateErrorIDs'
);

const scopeSelectorSlice = createSlice({
  name: 'scopeSelector',
  initialState,
  reducers: {
    resetScopeStore(state) {
      Object.entries(state.scopeStore).map(([groupName, groupInfo]) => {
        return groupInfo.scopeInfoList.map((scope) => {
          scope.selected_scopes = 'none';
        });
      });
    },

    resetSelectedTeammate(state) {
      state.selectedTeammate = {
        email: '',
        scopes: [],
        isAdmin: false,
      };
    },
  },
  extraReducers: (builder) => {
    // clear error IDs
    builder.addCase(clearSSOTeammateErrorIDs, (state) => {
      state.errorID = '';
    });
    builder.addCase(updateStoreFromButtonClick, (state, action) => {
      const { selected, groupName, displayName } = action.payload;
      const { scopeInfoList } = state.scopeStore[groupName];
      const lookedUpScopeIndex = scopeInfoList.findIndex(
        (scopeNames) => scopeNames.display_name === displayName
      );

      const lookedUpScope = scopeInfoList[lookedUpScopeIndex];

      const isReadOnly = lookedUpScope.full_access_scopes.length === 0;
      const isFullOnly = lookedUpScope.read_access_scopes.length === 0;

      let computedSelected = selected;

      // Allowing these to be selected as full/read when those buttons don't exist
      // make really weird things happen in the top level selector
      if (isReadOnly && selected === 'full') {
        computedSelected = 'read';
      }
      if (isFullOnly && selected === 'read') {
        computedSelected = 'none';
      }

      const updatedScope = {
        ...lookedUpScope,
        selected_scopes: computedSelected,
      };

      scopeInfoList[lookedUpScopeIndex] = updatedScope;
    });

    builder.addCase(updateStoreFromPersonaButtonClick, (state, action) => {
      const { scopeStore, personas, scopeLookup, scopesNotInSelector } = state;
      const { persona } = action.payload;

      const scopeList = [...personas[persona]];
      const prevScopeStore = cloneDeep(scopeStore);
      const prevScopeLookup = cloneDeep(scopeLookup);
      const prevScopesNotInSelector = [...scopesNotInSelector];

      const { scopeStore: updatedScopeStore } = updateStoreFromScopeList({
        scopeList,
        prevScopeStore,
        prevScopeLookup,
        scopesNotInSelector: prevScopesNotInSelector,
      });

      state.scopeStore = updatedScopeStore;
    });

    builder.addCase(updateStoreToTeammateResponse, (state, action) => {
      const { fetchedTeammate } = action.payload;
      const {
        scopeStore: updatedScopeStore,
        scopesNotInSelector: newScopesNotInSelector,
      } = updateStoreFromScopeList({
        scopeList: state.selectedTeammate.scopes,
        prevScopeStore: state.scopeStore,
        prevScopeLookup: state.scopeLookup,
        fetchedTeammate,
      });

      state.scopeStore = updatedScopeStore;
      state.scopesNotInSelector = newScopesNotInSelector;
    });

    // Network requests
    builder.addCase(fetchScopesNamesAndPersonas.pending, (state, action) => {
      state.loading = true;
      state.isFirstLoad = false;
      state.isScopesAndPersonasFetchError = false;
    });
    builder.addCase(fetchScopesNamesAndPersonas.fulfilled, (state, action) => {
      state.loading = false;
      state.scopeStore = action.payload.scopeStore;
      state.scopeLookup = action.payload.scopeLookup;
      state.personas = action.payload.personas;
      state.isScopesAndPersonasFetchError = false;
    });
    builder.addCase(fetchScopesNamesAndPersonas.rejected, (state, action) => {
      state.loading = false;
      state.isScopesAndPersonasFetchError = true;
    });

    builder.addCase(fetchTeammate.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(fetchTeammate.fulfilled, (state, action) => {
      state.loading = false;
      state.selectedTeammate = action.payload.selectedTeammate;
      state.scopeStore = action.payload.scopeStore;
      state.scopesNotInSelector = action.payload.scopesNotInSelector;
    });
    builder.addCase(fetchTeammate.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(updateSSOTeammate.pending, (state, action) => {
      state.saving = true;
    });
    builder.addCase(updateSSOTeammate.fulfilled, (state, action) => {
      state.saving = false;
    });
    builder.addCase(updateSSOTeammate.rejected, (state, action) => {
      state.saving = false;
    });

    builder.addCase(createSSOTeammate.pending, (state, action) => {
      state.saving = true;
    });
    builder.addCase(createSSOTeammate.fulfilled, (state, action) => {
      state.saving = false;
    });
    builder.addCase(createSSOTeammate.rejected, (state, action) => {
      state.saving = false;
      state.errorID = action.payload?.error_id ?? '';
    });
    builder.addCase(updateSSOMultisubuserTeammate.pending, (state, action) => {
      state.saving = true;
    });
    builder.addCase(
      updateSSOMultisubuserTeammate.fulfilled,
      (state, action) => {
        state.saving = false;
      }
    );
    builder.addCase(updateSSOMultisubuserTeammate.rejected, (state, action) => {
      state.saving = false;
    });

    builder.addCase(createSSOMultisubuserTeammate.pending, (state, action) => {
      state.saving = true;
    });
    builder.addCase(
      createSSOMultisubuserTeammate.fulfilled,
      (state, action) => {
        state.saving = false;
      }
    );
    builder.addCase(createSSOMultisubuserTeammate.rejected, (state, action) => {
      state.saving = false;
      state.errorID = action.payload?.error_id ?? '';
    });
  },
});

export const {
  resetScopeStore,
  resetSelectedTeammate,
} = scopeSelectorSlice.actions;
export { scopeSelectorSlice };
