import objectToQueryParams from '../../helpers/objectToQueryParams';

export const SettingsIPAddressesApiRoutes = {
  getRemainingIps: 'ips/remaining',

  sendIps: 'send_ips/ips',

  getSendIpsPools: ({
    limit,
    ip,
    after_key,
    include_region,
    region,
  }: {
    limit: number;
    ip?: string;
    after_key?: number;
    region?: string;
    include_region: boolean;
  }) =>
    `send_ips/pools${objectToQueryParams({
      limit,
      ...(after_key ? { after_key } : {}),
      ...(ip ? { ip } : {}),
      ...(include_region ? { include_region } : {}),
      ...(region ? { region } : {}),
    })}`,

  getSendIpDetails: (ip: string) => `send_ips/ips/${ip}`,

  getSubusersAssignedToIp: ({
    ip,
    limit,
    after_key,
  }: {
    ip: string;
    limit?: number;
    after_key?: string;
  }) =>
    `send_ips/ips/${ip}/subusers${objectToQueryParams({
      ...(limit ? { limit } : {}),
      ...(after_key ? { after_key } : {}),
    })}`,

  getSubuserUsernames: '/v3/subusers:batchGet',

  editSendIp: (ip: string) => `send_ips/ips/${ip}`,

  assignSubusersToSendIp: (ip: string) =>
    `send_ips/ips/${ip}/subusers:batchAdd`,

  unassignSubusersFromSendIp: (ip: string) =>
    `send_ips/ips/${ip}/subusers:batchDelete`,

  getSendIpPoolDetails: (poolId: string): string => `send_ips/pools/${poolId}`,

  deleteSendIpPool: (poolId: string) => `send_ips/pools/${poolId}`,

  createSendIpPool: `send_ips/pools`,

  editSendIpPool: (poolId: string) => `send_ips/pools/${poolId}`,

  assignIpsToSendIpPool: (poolId: string) =>
    `send_ips/pools/${poolId}/ips:batchAdd`,

  unassignIpsFromSendIpPool: (poolId: string) =>
    `send_ips/pools/${poolId}/ips:batchDelete`,

  getIpsAssignedToPool: ({
    poolId,
    limit,
    after_key,
    include_region,
  }: {
    poolId: string;
    limit?: number;
    after_key?: string;
    include_region: boolean;
  }) =>
    `send_ips/pools/${poolId}/ips${objectToQueryParams({
      ...(limit ? { limit } : {}),
      ...(after_key ? { after_key } : {}),
      ...(include_region ? { include_region } : {}),
    })}`,

  // old API routes, remove when we deprecate old IP Pools page and IP Pools Beta is in
  // a stable state
  getAllIPs: (): string => `ips`,

  createIPPool: (): string => `ips/pools`,

  getIPPools: (): string => `ips/pools`,

  getIPsInPool: (poolName: string): string =>
    `ips/pools/${encodeURIComponent(poolName)}`,

  updateIPPoolName: (poolName: string): string =>
    `ips/pools/${encodeURIComponent(poolName)}`,

  deleteIPPool: (poolName: string): string =>
    `ips/pools/${encodeURIComponent(poolName)}`,

  addIPToPool: (poolName: string): string =>
    `ips/pools/${encodeURIComponent(poolName)}/ips`,

  removeIPFromPool: (poolName: string, ip: string): string =>
    `ips/pools/${encodeURIComponent(poolName)}/ips/${ip}`,
};
