import { ResponseError } from './../types/responseError';
import { ActionsObservable, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { getTeammatesData } from './service';
import { ActionTypes } from './actionTypes';
import * as TeammatesActions from './actions';
import { TeammatesDto } from '../dtos';

const getTeammatesEpic = (
  action$: ActionsObservable<
    ReturnType<typeof TeammatesActions.Actions.getTeammates>
  >
): Observable<
  | ReturnType<typeof TeammatesActions.Actions.getTeammatesSuccess>
  | ReturnType<typeof TeammatesActions.Actions.getTeammatesFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.GetTeammatesRequest),
    switchMap(() => {
      return from(getTeammatesData()).pipe(
        map((data: TeammatesDto) => {
          if ((data as { errors: ResponseError[] }).errors) {
            return TeammatesActions.Actions.getTeammatesFailure(data);
          }
          return TeammatesActions.Actions.getTeammatesSuccess(
            data as TeammatesDto
          );
        })
      );
    })
  );
};

export default [getTeammatesEpic];
