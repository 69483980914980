import { PaymentMethodDto } from '../dtos';
import { sanitizer } from '../dtoSanitizer';
import { PaymentMethod } from '../types/paymentMethod';

export const paymentMethodAdapter = (dto: PaymentMethodDto): PaymentMethod => {
  const sanitizedPaymentMethod = sanitizer(dto);
  const {
    card_holder_name,
    card_number,
    card_type,
    expiration_month,
    expiration_year,
    zip_code,
    ...sanitized
  } = sanitizedPaymentMethod;

  return {
    cardHolderName: card_holder_name,
    cardNumber: card_number,
    cardType: card_type,
    expirationMonth: expiration_month,
    expirationYear: expiration_year,
    zip: zip_code,
    ...sanitized,
  } as PaymentMethod;
};
