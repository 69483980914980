import { setCookie } from './cookieHelpers';
import Cookies from 'js-cookie';

const SETUP_2FA_REQUIRED_CHECKPOINT = 'setup_2fa_required_checkpoint';

export enum Setup2FARequiredCheckpoint {
  EMAIL = 'email',
  SETUP_2FA = 'setup 2fa',
  NONE = 'none',
}

export const getSetup2FARequiredCheckpoint = (): string => {
  try {
    const item = Cookies.get(SETUP_2FA_REQUIRED_CHECKPOINT);
    return item ?? Setup2FARequiredCheckpoint.NONE;
  } catch (e) {
    console.warn(`failed to get ${SETUP_2FA_REQUIRED_CHECKPOINT} cookie`, e);
    return Setup2FARequiredCheckpoint.NONE;
  }
};

export const setSetup2FARequiredCheckpoint = (
  step: Setup2FARequiredCheckpoint
) => {
  try {
    setCookie(SETUP_2FA_REQUIRED_CHECKPOINT, step);
  } catch (e) {
    console.warn(`failed to set ${SETUP_2FA_REQUIRED_CHECKPOINT} cookie`, e);
  }
};

export const isOnSetup2FARequiredCheckpoint = (
  step: Setup2FARequiredCheckpoint
): boolean => {
  const Setup2FARequiredCheckpoint = getSetup2FARequiredCheckpoint();
  return Setup2FARequiredCheckpoint === step;
};
