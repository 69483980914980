import { PlanTierFeature } from '../../../types/planTierFeatures';
import { AccountAndPlanUtils } from '../../../../pages/AccountAndBilling/utils';

export const apisRelayAndWebhooks = {
  active: true,
  text: 'APIs, SMTP Relay, and Webhooks',
  tooltipText:
    'Optimize your email deliverability at scale on our custom MTA (with a 99.999% uptime, you can count on us!)',
};

export const deliveryOptimizationTools = {
  active: true,
  text: 'Delivery Optimization Tools',
  tooltipText:
    'Maximize your inbox placement with SPF records and custom DKIM, reputation monitoring, and suppression management.',
};

export const dynamicTemplateEditor = {
  active: true,
  text: 'Dynamic Template Editor',
  tooltipText:
    'Send personalized content with real-time design editing, point-to-click HTML rendering, and conditional testing.',
};

export const emailApiAnalytics = {
  active: true,
  text: 'Insightful Analytics',
  tooltipText:
    'Access a data-rich activity feed, aggregate statistics, and monitor your sender reputation.',
};

export const marketingCampaignsAnalytics = {
  active: true,
  text: 'A/B Testing & Actionable Analytics',
  tooltipText:
    'Test multiple subject lines and content variations within a single send and access analytics reports to learn from engagement and recipient data.',
};

export const ticketSupport = {
  active: true,
  text: 'Ticket Support',
  tooltipText: 'Get help from our expert support team through a web ticket.',
};

export const ticketAndChatSupport = {
  active: true,
  text: 'Ticket & Chat Support',
  tooltipText:
    'Get help from our expert support team through a web ticket or live chat.',
};

export const guaranteedTicketAndChatSupport = {
  active: true,
  text: 'Guaranteed Response Times on Ticket & Chat Support',
  tooltipText:
    'Get help from our expert support team through a web ticket or live chat.',
};

export const ticketChatAndPhoneSupport = {
  active: true,
  text: 'Guaranteed Response Times on Ticket, Chat, & Phone Support',
  tooltipText:
    'Get help faster from our expert support team through a web ticket, live chat, or phone call.',
};

export const noEmailTesting = {
  active: false,
  text: 'No Access to Email Testing',
};

export const noEmailValidation = {
  active: false,
  text: 'No Access to Email Validation',
};

export const noDedicatedIps = {
  active: false,
  text: 'No Access to Dedicated IPs',
};

export const noSubusers = {
  active: false,
  text: 'No Subuser Management',
};

export const dedicatedIp = {
  active: true,
  text: 'Dedicated IP Included 🎉',
  tooltipText:
    'Own your reputation with a dedicated IP address, and then automate your IP warmup and manage additional IP addresses.',
};

export const subusers = {
  active: true,
  text: 'Subuser Management',
  tooltipText:
    'Segment your sending, assign permissions and credit limits, and view separate statistics programmatically or in the UI.',
};

export const designAndCodeEditors = {
  active: true,
  text: 'Design & Code Editors',
  tooltipText:
    'Create emails using HTML code, drag & drop editing, or a mix of both.',
};

export const signupForms = (formCount: number): PlanTierFeature => {
  const formattedCount = AccountAndPlanUtils.numberWithCommas(formCount);
  const text = `${formattedCount} Signup Form${formCount === 1 ? '' : 's'}`;
  return {
    active: true,
    text,
    tooltipText:
      'Grow your contact list with a simple signup form you can embed on your website or share as a SendGrid hosted link.',
  };
};

export const noAutomation = {
  active: false,
  text: 'No Automation',
};

export const automation = {
  active: true,
  text: 'Automation',
  tooltipText:
    'Send automated emails or time-based drip series using triggers you already have in place.',
};

export const segmentation = {
  active: true,
  text: 'Segmentation',
  tooltipText:
    'Target audiences based on your custom field data or our email engagement data.',
};

export const teammates = (teammateCount: number): PlanTierFeature => {
  const formattedCount = AccountAndPlanUtils.numberWithCommas(teammateCount);
  const text = `${formattedCount} Teammate${teammateCount === 1 ? '' : 's'}`;
  return {
    active: true,
    text,
    tooltipText:
      'Allow multiple users to send email from the same account by defining distinct feature access per user.',
  };
};

export const webhooks = (webhooks?: number): PlanTierFeature => {
  const formattedCount = AccountAndPlanUtils.numberWithCommas(webhooks);
  const text = `${formattedCount} Event Webhook${webhooks === 1 ? '' : 's'}`;
  return {
    active: true,
    text,
    tooltipText:
      'Event Webhooks are a powerful way to collect, process, and deliver events in near real-time to your endpoints. Make data driven decisions at your fingertips!',
  };
};

export const emailActivity = (storageDays?: number) => {
  const formattedCount = AccountAndPlanUtils.numberWithCommas(storageDays);
  const text = `${formattedCount} Day${
    storageDays === 1 ? '' : 's'
  } of Email Activity History`;
  return {
    active: true,
    text,
    tooltipText:
      'Expedite the email delivery troubleshooting process with sequential event organization by message, advanced search and filtering, and CSV exporting.',
  };
};

export const emailTesting = (creditCount?: number) => {
  const formattedCount = AccountAndPlanUtils.numberWithCommas(creditCount);
  const text = `${formattedCount} Email Testing Credit${
    creditCount === 1 ? '' : 's'
  }`;
  return {
    active: true,
    text,
    tooltipText:
      'Test your Dynamic Templates for inbox rendering, link validity, and performance against spam filters.',
  };
};

export const emailValidation = (validationCount?: number) => {
  const formattedCount = AccountAndPlanUtils.numberWithCommas(validationCount);
  const text = `${formattedCount} Email Validation${
    validationCount === 1 ? '' : 's'
  }`;
  return {
    active: true,
    text,
    tooltipText:
      'Protect your sending reputation by validating email addresses before sending to them programmatically.',
  };
};
