import { UserPackageDto } from '../dtos';
import { UserPackage } from '../types/user';
import { sanitizer } from '../dtoSanitizer';

export function userPackageAdapter(dto: UserPackageDto): UserPackage {
  const sanitizedPackage = sanitizer(dto);
  const {
    base_price,
    campaign_price,
    downgrade_package_id,
    has_ip,
    is_cutoff_trial,
    is_hv,
    is_lite,
    is_signup_to_send,
    newsletter_price,
    overage_price,
    package_id,
    package_status,
    plan_type,
    trial_expiration_date,
    ...sanitized
  } = sanitizedPackage;

  const mappedPackage = {
    basePrice: base_price,
    campaignPrice: campaign_price,
    downgradePackageId: downgrade_package_id,
    hasIp: has_ip,
    isCutoffTrial: is_cutoff_trial,
    isHv: is_hv,
    isLite: is_lite,
    isSignupToSend: is_signup_to_send,
    newsletterPrice: newsletter_price,
    overagePrice: overage_price,
    packageId: package_id,
    packageStatus: package_status,
    planType: plan_type,
    trialExpirationDate: trial_expiration_date,
    ...sanitized,
  } as UserPackage;

  return mappedPackage;
}
