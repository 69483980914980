import { Action } from '../types/action';
import { MarketingCampaignsPlanTier } from '../types/marketingCampaignsPlan';
import { PlanTierFeatures } from '../types/planTierFeatures';
import { ActionTypes } from './actionTypes';

const initialState = {
  [MarketingCampaignsPlanTier.Free as string]: [],
  [MarketingCampaignsPlanTier.Basic as string]: [],
  [MarketingCampaignsPlanTier.Advanced as string]: [],
} as PlanTierFeatures;

interface MarketingCampaignsFeaturesAction extends Action {
  payload: PlanTierFeatures;
}

export const marketingCampaignsFeatures = (
  state = initialState,
  action: MarketingCampaignsFeaturesAction
) => {
  switch (action.type) {
    case ActionTypes.GetMarketingCampaignsFeaturesSuccess:
      return action.payload as PlanTierFeatures;

    default:
      return state;
  }
};
