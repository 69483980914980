import { AlertType } from '@sendgrid/ui-components/alert';

export enum AlertContentKey {
  GenericFailureContactSales = 1,
  GenericLoginFailureContactSupport = 2,
}

export interface Alert {
  // timeStamp acts as an id
  timeStamp?: number;
  type: AlertType;
  text?: string;
  contentKey?: AlertContentKey;
}

export enum ModalType {
  EmailImplementationSummaryOfWork = 'EMAIL_IMPLEMENTATION_SUMMARY_OF_WORK',
  EmailImplementationPurchaseSuccess = 'EMAIL_IMPLEMENTATION_PURCHASE_SUCCESS',
  ExpertInsightsConfirmRemoval = 'EXPERT_INSIGHTS_REMOVAL_CONFIRMATION',
  Trial40kUpgradeConfirmation = 'TRIAL_40K_UPGRADE_CONFIRMATION',
}

export interface Modal {
  type: ModalType;
  props?: any;
}

export interface UI {
  alerts: Alert[];
  modals: Modal[];
}
