import { AuthedHttp } from '../../helpers/AuthedHttp';
import { UpgradeEligibilityDto } from '../dtos';
import { UpgradeEligibility } from '../types/upgradeEligibility';
import { mapUpgradeEligibility } from './adapters';

export const fetchUpgradeEligibility = async (): Promise<
  UpgradeEligibility
> => {
  const response = await AuthedHttp.get<UpgradeEligibilityDto>(
    'upgrade/eligible'
  );
  const responseJson = await response.json();
  if (response.status === 403 || response.ok) {
    return mapUpgradeEligibility(responseJson as UpgradeEligibilityDto);
  }
  throw new Error('unexpected error fetching upgrade eligibilty');
};
