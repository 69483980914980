import qs from 'qs';

const hasUtm = () => {
  const query = qs.parse(window.location.search.substring(1));

  /* eslint-disable camelcase */
  const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = query;

  const hasUtmInQS = [
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
  ]
    .map((utm) => utm !== '' && utm !== undefined)
    .some((utm) => utm);

  return hasUtmInQS;
};

const getUtmParams = () => {
  const query = qs.parse(window.location.search.substring(1));

  const {
    utm_source,
    utm_medium,
    utm_campaign,
    utm_term,
    utm_content,
  }: {
    utm_source?: string;
    utm_medium?: string;
    utm_campaign?: string;
    utm_term?: string;
    utm_content?: string;
  } = query;

  return { utm_source, utm_medium, utm_campaign, utm_term, utm_content };
};

export { hasUtm, getUtmParams };
