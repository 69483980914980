interface QueryParamObject {
  [param: string]: string | number | string[] | boolean | null | undefined;
}

export const objectToQueryParam = (queryParamObject: QueryParamObject) => {
  const searchParams = new URLSearchParams();

  Object.entries(queryParamObject).map(([key, value]) => {
    if (value === null || value === undefined) {
      return;
    }

    let objValue = value.toString();

    if (Array.isArray(value)) {
      objValue = value.join(',');
    }

    searchParams.append(key, value.toString());
  });
  let searchString = searchParams.toString();
  if (searchString !== '') {
    searchString = `?${searchString}`;
  }

  return searchString;
};

export default objectToQueryParam;
