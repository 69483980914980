import { AuthedHttp } from '../../helpers/AuthedHttp';
import { Routes } from './routes';
import { AddExpertServiceImplementationDto } from '../dtos';
import { AddExpertServiceImplementationRequest } from '../types/addExpertServiceImplementation';

export function addExpertServiceImplementation({
  serviceName: service_name,
  contactEmail: contact_email,
}: AddExpertServiceImplementationRequest): Promise<
  AddExpertServiceImplementationDto
> {
  const addExpertServiceImplementationRequestBody = {
    service_name,
    contact_email,
  };

  return AuthedHttp.post<AddExpertServiceImplementationDto>(
    `${Routes.ExpertServiceImplementation}`,
    addExpertServiceImplementationRequestBody
  ).then((res) => {
    if (!res.ok) {
      return Promise.reject(res.json());
    }
    return Promise.resolve({} as AddExpertServiceImplementationDto);
  });
}
