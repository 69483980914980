import { FeatureToggleDto } from './../../../dtos';
import { FeatureToggle } from './../../../types/featureToggle';
import { OfferingsDto, OesAccountDto } from '../../services/oes/dtos';
import { Plans } from '../../../types/plans';
import { EmailApiPlan } from '../../../types/emailApiPlan';
import { MarketingCampaignsPlan } from '../../../types/marketingCampaignsPlan';
import { TestingCreditsPlan } from '../../../testing_credits_plans/types';
import { EasePlan } from '../../../ease_plans/types';
import { mapEmailApiFeatures } from './emailApiFeatures';
import { mapMarketingCampaignsFeatures } from './marketingCampaignsFeatures';
import { mapEmailApiPlan } from './emailApiPlans';
import { mapMarketingCampaignsPlan } from './marketingCampaignsPlans';
import { mapTestingCreditsPlan } from './testingCredits';
import { mapEasePlan } from './ease';
import {
  isEmailApiPlan,
  isMarketingCampaignsPlan,
  isTestingCreditsPlan,
  isEasePlan,
} from '../../common/adapters';
import { mapper } from '../../../dtoMapper';

export const offeringsAdapter: (
  offeringsDto: OfferingsDto,
  featureTogglesDto: FeatureToggleDto[],
  oesAccountDto: OesAccountDto
) => Plans = (offeringsDto, featureTogglesDto, oesAccountDto) => {
  const { offerings } = offeringsDto;
  const emailApiPlans = [] as EmailApiPlan[];
  const marketingCampaignsPlans = [] as MarketingCampaignsPlan[];
  const testingCreditsPlans = [] as TestingCreditsPlan[];
  const easePlans = [] as EasePlan[];
  const emailApiFeatures = mapEmailApiFeatures(offerings);
  const featureToggles = mapper(featureTogglesDto) as FeatureToggle[];
  const marketingCampaignsFeatures = mapMarketingCampaignsFeatures(
    offerings,
    featureToggles
  );

  offerings.forEach((offering) => {
    try {
      if (isEmailApiPlan(offering)) {
        emailApiPlans.push(mapEmailApiPlan(offering, oesAccountDto));
        return;
      }
      if (isMarketingCampaignsPlan(offering)) {
        marketingCampaignsPlans.push(
          mapMarketingCampaignsPlan(offering, oesAccountDto)
        );
        return;
      }
      if (isTestingCreditsPlan(offering)) {
        testingCreditsPlans.push(mapTestingCreditsPlan(offering));
        return;
      }
      if (isEasePlan(offering)) {
        easePlans.push(mapEasePlan(offering));
        return;
      }
    } catch (error) {
      console.log(`Failed to map ${offering.name}: ${error}`);
    }
  });

  return {
    emailApiPlans,
    marketingCampaignsPlans,
    testingCreditsPlans,
    easePlans,
    emailApiFeatures,
    marketingCampaignsFeatures,
  } as Plans;
};
