import { Contacts } from '../types/contacts';
import { BillableContactsInfoDto } from '../dtos';
import { sanitizer } from '../dtoSanitizer';

export const contactsAdapter = (data: BillableContactsInfoDto): Contacts => {
  const sanitizedDto = sanitizer(data);
  const {  recipient_count } = sanitizedDto;

  return {
    total: recipient_count,
  } as Contacts;
};
