import APIConfig from '../apiconfig';

// This is the alternative to setting a cookie rather than using the jquery.cookie dependency (which is no longer being maintained)
// We aim to eventually move away from jQuery in general
interface CookieOptions {
  secure?: boolean;
}

// This function is replicated in login/src/cookieHelpers.ts
// Please update the other setCookie func if you change this one to keep them in sync
export const setCookie = (
  cookieName: string,
  cookieValue: string,
  cookieOptions?: CookieOptions
) => {
  // Borrowing similar defaults to the existing main.js cookie options
  let cookieDomain = '';
  if (APIConfig.cookie_default_domain) {
    cookieDomain = APIConfig.cookie_default_domain;
  } else if (window.location.hostname !== 'localhost') {
    // This allows the cookie to transfer over to other SG domains i.e. mc/labs.sendgrid.com
    cookieDomain = '.sendgrid.com';
  }

  const cookie = `${cookieName}=${cookieValue}; domain=${cookieDomain}; path=/; ${
    cookieOptions?.secure ? 'Secure' : ''
  }`;
  document.cookie = cookie;
};
