import { createSlice, createAction } from '@reduxjs/toolkit';

export type MockType = 'page' | 'extra' | 'global';

export interface MockShape {
  mocks: string[];
  type: MockType;
}

export type Mock = Record<string, MockShape>;
type MockSliceState = { mock: Mock };

const initialMockSliceState: MockSliceState = { mock: {} };

export const addMocks = createAction<Mock>('addMocks');
export const resetMocks = createAction<null>('resetMocks');

export const mockSlice = createSlice({
  name: 'mockSlice',
  initialState: initialMockSliceState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(addMocks, (state, action) => {
      state.mock = { ...state.mock, ...action.payload };
    });

    builder.addCase(resetMocks, (state, action) => {
      const filteredMocks: Mock = {};

      Object.entries(state.mock).forEach(([key, value]) => {
        if (value.type === 'global') {
          filteredMocks[key] = value;
        }
      });

      state.mock = filteredMocks;
    });
  },
});
