import { ScopeMap } from './adapters';
import { SetScopesAction } from './actions';
import { ActionTypes } from './actionTypes';
import { MakoStateType } from '../types/makoStateType';
import { ScopesReduxState } from './../types/scopes';

const initialState: MakoStateType['scopes'] = {
  data: {} as ScopeMap,
  hasTneAccess: false,
  hasMCLegacyAccess: false,
  hasBillingReadAccess: false,
  hasBillingUpdateAccess: false,
} as ScopesReduxState;

export const scopes = (state = initialState, action: SetScopesAction) => {
  switch (action.type) {
    case ActionTypes.SetScopes:
      return action.payload;
    default:
      return state;
  }
};
