import { ActionsObservable, ofType } from 'redux-observable';
import * as ContactsActions from './actions';
import { from, Observable } from 'rxjs';
import { mergeMap, map } from 'rxjs/operators';
import { getBillableContactsCount } from './service';
import {
  BillableContactsInfoDto,
  BillableContactsDto,
} from '../dtos';
import { ActionTypes } from './actionTypes';

const billableContactsInfo = {} as BillableContactsInfoDto;

const getBillableContactsInfo = (
  action$: ActionsObservable<
    ReturnType<typeof ContactsActions.Actions.getBillableContactsInfo>
  >
): Observable<
  | ReturnType<typeof ContactsActions.Actions.getBillableContactsInfoSuccess>
  | ReturnType<typeof ContactsActions.Actions.getBillableContactsInfoFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.GetBillableContactsInfoRequest),
    mergeMap(() => {
      return getBillableContactsCount();
    }),
    map((data: BillableContactsDto) => {
      billableContactsInfo.recipient_count = data.recipient_count;
      return ContactsActions.Actions.getBillableContactsInfoSuccess(
        billableContactsInfo
      );
    })
  );
};

export default [getBillableContactsInfo];
