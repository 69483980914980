import { TeammatesDto } from './../dtos';
import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';

export const Actions = {
  getTeammates: () => createAction(ActionTypes.GetTeammatesRequest),
  getTeammatesSuccess: (teammates: TeammatesDto) =>
    createAction(ActionTypes.GetTeammatesSuccess, teammates),
  getTeammatesFailure: (errors: TeammatesDto) =>
    createAction(ActionTypes.GetTeammatesFailure, errors),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
