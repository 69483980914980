const Marionette = require("backbone.marionette");
const { analytics } = require("@sendgrid/bloodhound");

module.exports = Marionette.ItemView.extend({
  template: require("templates/errors/404.hbs"),

  onRender() {
    analytics.track({
      event: "404_error_page_rendered",
      properties: {
        userId: window.Reqres.request("currentUser").user_id,
      },
    });
  },
});
