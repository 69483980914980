import { OES } from '../services/oes/service';
import { MakoApi } from '../services/mako_api/service';

// types
import { UserProducts } from '../../types/userProducts';
import {
  UserAdapterData,
  userProductsAdapter,
} from '../user_products/adapters';
import {
  OesAccountDto,
  OfferingTypeDto,
  OfferingDto,
} from '../services/oes/dtos';
import { ProductId } from '../../types/productId';
import { NewPlanDto } from '../new_plan/service';
import { TypedMap } from '../../../@types/TypedMap';
import moment from 'moment';
import { getEmailApiPlan, getMarketingCampaignsPlan } from '../common/adapters';

export async function getUserProducts(): Promise<UserProducts> {
  try {
    const getUsageStatsPromise = MakoApi.getUsageStats();
    const getMarketingCampaignsAutomationsPromise = MakoApi.getMarketingCampaignsAutomations();
    const getLegacyMarketingCampaignsContactsCountPromise = MakoApi.getLegacyMarketingCampaignsContactsCount();
    const getMarketingCampaignsContactsCountPromise = MakoApi.getMarketingCampaignsContactsCount();
    const getMarketingCampaignsSignupFormsPromise = MakoApi.getMarketingCampaignsSignupForms();
    const getSubusers = MakoApi.getSubusers();
    const getOesAccountPromise = OES.getAccount();
    const getOfferingsPromise = OES.getOfferings();
    const getApiKeysPromise = (async () => {
      const apiKeys = await MakoApi.getApiKeys();
      const apiKeyScopes = apiKeys.result.map((apiKey) => {
        return MakoApi.getApiKey(apiKey.api_key_id);
      });
      return Promise.all(apiKeyScopes);
    })();

    const [
      oesAccountDto,
      offeringsDto,
      usageStatsDto,
      marketingCampaignsAutomationsDto,
      legacyMarketingCampaignsContactsCountDto,
      marketingCampaignsContactsCountDto,
      getMarketingCampaignsSignupFormsDto,
      subusersDto,
      apiKeysDto,
    ] = await Promise.all([
      getOesAccountPromise,
      getOfferingsPromise,
      getUsageStatsPromise,
      getMarketingCampaignsAutomationsPromise,
      getLegacyMarketingCampaignsContactsCountPromise,
      getMarketingCampaignsContactsCountPromise,
      getMarketingCampaignsSignupFormsPromise,
      getSubusers,
      getApiKeysPromise,
    ]);

    // Remove successors from the account offerings.
    // We don't display any information about them currently
    // and leaving them in requires non trivial updates to our
    // mapping logic in several places.
    removeSuccessorOfferings(oesAccountDto, offeringsDto.offerings);

    // If a user has any downgrades, we need to call the diff
    // endpoint before we being mapping.
    const diffIds = determineIdsForDiffRequest(oesAccountDto);
    let diffDto = Promise.resolve({} as OesAccountDto);
    if (diffIds.length) {
      const diffRequestOfferings = diffIds.map((d) => {
        return { name: d };
      });
      const diffRequest = {
        parent_key: oesAccountDto.key,
        offerings: diffRequestOfferings,
      } as NewPlanDto;
      diffDto = OES.getNewPlanAccountDiff(diffRequest);
    }

    const diff = await diffDto;
    let diffEntitlements;
    if (diff.entitlements) {
      diffEntitlements = diff.entitlements;
    }

    const camAccount = window.Reqres.request('camAccount');

    const dataToAdapt = {
      oesAccount: oesAccountDto,
      offerings: offeringsDto.offerings,
      usageStats: usageStatsDto,
      legacyMarketingCampaignsContactsCount: legacyMarketingCampaignsContactsCountDto,
      marketingCampaignsAutomations: marketingCampaignsAutomationsDto,
      marketingCampaignsContactsCount: marketingCampaignsContactsCountDto,
      marketingCampaignsSignupForms: getMarketingCampaignsSignupFormsDto,
      subusers: subusersDto,
      diffEntitlements,
      apiKeys: apiKeysDto,
      defaultMCPlan: await OES.getFreeMCOffering(
        camAccount.catalog_org || 'sg'
      ),
    } as UserAdapterData;

    const userProducts = userProductsAdapter(dataToAdapt);

    return {
      ...userProducts,
    };
  } catch (error) {
    window.EventBus.trigger('render500Error');
    console.error(`getUserProducts - ${error}`);
    return Promise.reject('unable to load user products');
  }
}

const removeSuccessorOfferings = (
  account: OesAccountDto,
  offerings: OfferingDto[]
): void => {
  const offeringsMap = offerings.reduce((map, offering) => {
    map[offering.name] = offering;
    return map;
  }, {} as TypedMap<OfferingDto>);

  const successorMap = account.offerings.reduce((map, accountOffering) => {
    const offering = offeringsMap[accountOffering.name];
    const successor = offering && offering.successor;
    if (successor) {
      map[successor] = true;
    }
    return map;
  }, {} as TypedMap<boolean>);

  account.offerings = account.offerings.filter((o) => !successorMap[o.name]);
};

const determineIdsForDiffRequest = (account: OesAccountDto): string[] => {
  const { offerings } = account;
  const planOfferings = offerings.filter(
    (o) => o.type === OfferingTypeDto.PACKAGE
  );

  // If we have a plan that's being downgraded there
  // will be another plan with a start date in the future
  // that represents destination plan.
  const now = moment().utc();
  const futurePlans = planOfferings.filter((o) => {
    const startDate = moment(o.start_date).utc();
    return startDate > now;
  });

  if (!futurePlans.length) {
    return [];
  }

  // OES expects to see both plans in the diff call. If a user only has one
  // downgrade id, we need to pass the plan id that's not be downgraded as well
  const currentPlans = planOfferings.filter((o) => {
    const startDate = moment(o.start_date).utc();
    return startDate <= now;
  });
  const currentEIPlan = getEmailApiPlan(currentPlans);
  const isCurrentPlanTrial40k =
    !!currentEIPlan && currentEIPlan.name === ProductId.SG_EI_TRIAL_40K_V1;
  const futureEIPlan = isCurrentPlanTrial40k
    ? undefined
    : getEmailApiPlan(futurePlans);

  const currentMCPlan = getMarketingCampaignsPlan(currentPlans);
  const futureMCPlan = getMarketingCampaignsPlan(futurePlans);

  const eiPlan = futureEIPlan || currentEIPlan;
  const mcPlan = futureMCPlan || currentMCPlan;

  return [eiPlan, mcPlan]
    .map((plan) => plan && (plan.name as string))
    .filter((id) => !!id) as string[];
};
