import moment from 'moment';

import { ExpertServiceImplementationDto } from '../dtos';
import { ExpertServiceImplementation } from '../types/expertServiceImplementation';
import { mapper } from '../dtoMapper';

export function expertServicesImplementationAdapter(
  expertServicesImplementationDto: ExpertServiceImplementationDto[]
): ExpertServiceImplementation[] {
  // run through our standard adapter
  const adapted = mapper(
    expertServicesImplementationDto
  ) as ExpertServiceImplementation[];
  // format purchase date
  const implementationServices = adapted.map(
    ({ purchaseDate, ...rest }: ExpertServiceImplementation) => {
      const formattedDate = moment(purchaseDate).format('M/D/YY');
      return { purchaseDate: formattedDate, ...rest };
    }
  );
  return implementationServices as ExpertServiceImplementation[];
}
