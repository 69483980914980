// Implicit global scope by the airbrake lib
require("../vendor/airbrake");

module.exports = function (config, providedAirBrake) {
  const Airbrake = providedAirBrake || window.Airbrake;

  if (config) {
    Airbrake.setProject(config.project_id, config.api_key);
    Airbrake.setEnvironmentName(config.environment);
  }

  return {
    notify(userId, message, error) {
      const airbrakePayload = {
        error: `${message}: ${JSON.stringify(error)}`,
        context: { userId: String(userId) },
      };

      if (config.should_notify) {
        Airbrake.push(airbrakePayload);
      }
    },
  };
};
