import { UserTypeAndRegionDto } from 'src/routes/sender_authentication/create_domain_helper';
import { AuthedHttp } from '../../helpers/AuthedHttp';
import { Routes } from './routes';
import { mapUserType } from './adapters';
import { UserAccountType } from '../types/user';

export const fetchUserType = async (): Promise<UserAccountType> => {
  const response = await AuthedHttp.get<UserTypeAndRegionDto>(Routes.UserType);
  const responseJson = await response.json();
  return mapUserType(responseJson);
};
