import { Actions } from './actions';
import { ActionTypes } from './actionTypes';
import { EmailApiUsage } from '../types/emailApiUsage';
import { ResponseError } from '../types/responseError';

export interface EmailApiUsageReduxState extends EmailApiUsage {
  error?: ResponseError;
}

export const emailApiUsage = (state = {}, action: Actions) => {
  switch (action.type) {
    case ActionTypes.GetEmailApiUsageSuccess:
      return action.payload;
    case ActionTypes.GetEmailApiUsageFailure:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};
