export enum Routes {
  UserProfile = 'user/profile',
  UserEmail = 'user/email',
  Username = 'user/username',
  UserPassword = 'user/password',
  PublicTokens = 'public/tokens',
}

export namespace Routes {
  export function CheckUsernameExists(username: string = '') {
    return `public/signup/username/${username}`;
  }
}
