import {
  AccountOfferingDto,
  EntitlementsDto,
  OfferingDto,
  PriceNamesDto,
} from '../../services/oes/dtos';
import { Ips } from '../../../ips/types';
import { getPriceByName } from '../../common/adapters';
import { ProductId } from '../../../types/productId';

const IP_ADDON_PREFIX = 'sg.x.ip';

export function getIpAddons(
  offerings: AccountOfferingDto[]
): AccountOfferingDto[] {
  return offerings.filter((x) =>
    x.name.toLowerCase().includes(IP_ADDON_PREFIX)
  );
}

export function mapIps(
  currentOfferings: AccountOfferingDto[],
  futureOfferings: AccountOfferingDto[],
  offerings: OfferingDto[],
  currentEntitlements: EntitlementsDto,
  diffEntitlements?: EntitlementsDto
): Ips {
  const futureIpsAddons = getIpAddons(futureOfferings);

  const currentIpAddons = getIpAddons(currentOfferings);

  const totalPurchasedIps = currentIpAddons
    .map((offering) => offering.quantity)
    .reduce((total, quantity) => total + quantity, 0);

  const totalIpCount =
    (currentEntitlements.compat_pkg_ip_count || 0) + totalPurchasedIps;

  // If the customer has legacy IPs, we'll treat them as the latest version
  // because the UI only shows a single unit price.
  // this looks against the full catalog to find the v2 ips so we can use that
  // as our base price
  const ipOffering = offerings.find(
    (offering) => offering.name === ProductId.SG_X_IP_V2
  );
  const ipPrice =
    ipOffering && getPriceByName(ipOffering.pricing, PriceNamesDto.IP_BASE);
  const totalPrice = totalPurchasedIps * (ipPrice || 0);

  let lostIpCount = 0;
  if (diffEntitlements) {
    const { compat_pkg_ip_count } = diffEntitlements;
    if (compat_pkg_ip_count !== undefined && compat_pkg_ip_count < 0) {
      // checkout out how many future ips we have and subtract that value from the ip_count
      // futureIpsAddonsCount are ips that were turned to addons during the downgrade process
      // these values needs offset against the lost count, since they are not "lost" from the user
      // due to an entitlement change
      const futureIpsAddonsCount = futureIpsAddons.length;
      lostIpCount = -1 * compat_pkg_ip_count - futureIpsAddonsCount;
    }
  }

  return {
    totalCount: totalIpCount,
    purchasedCount: totalPurchasedIps,
    freeCount: totalIpCount - totalPurchasedIps,
    totalPrice,
    unitPrice: ipPrice || 0,
    lostCount: lostIpCount,
  } as Ips;
}
