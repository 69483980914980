export const chartColors = {
  chartTextColor: '#546B81',
  chartPlotColor: '#D4DADF',
  chartBackgroundColor: '#ffffff',
  dataColors: {
    red: '#B71C1C',
    yellow: '#FDD835',
    orange: '#F5A623',
    orangeRed: '#FF5722',
    green: '#18C96E',
    lightBlue: '#00BCD4',
    blue: '#1A82E2',
    navyBlue: '#294661',
    grayBlue: '#7F91A1',
    lightPurple: '#7C4DFF',
    purple: '#7B1FA2',
    darkPurple: '#880E4F',
    hotPink: '#FF4081',
    pink: '#FF197F',
  },
};

export const chartFontStyles = {
  family: "Colfax, 'sans serif'",
  color: chartColors.chartTextColor,
  weight: '400',
  lightWeight: '200',
  size: '14px',
};

export const MSPColors = [
  chartColors.dataColors.navyBlue,
  chartColors.dataColors.purple,
  chartColors.dataColors.hotPink,
  chartColors.dataColors.lightBlue,
  chartColors.dataColors.green,
];
