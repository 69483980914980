import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { EmailApiPlan } from '../types/emailApiPlan';

export const Actions = {
  getEmailApiPlansSuccess: (emailApiPlans: EmailApiPlan[]) =>
    createAction(ActionTypes.GetEmailApiPlansSuccess, emailApiPlans),
  getEmailApiPlansFailure: () =>
    createAction(ActionTypes.GetEmailApiPlansFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
