import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SendGridAppDispatch, SendGridRootState } from './sendgridAppStore';
import { ResponseError } from '../types/responseError';
import { ResponseBody } from '../../helpers/AuthedHttp';
import { GenericNetworkSlice, NetworkState } from './sliceNetworkTypes';

export enum UserType {
  Unlimited = 'unlimited',
  Free = 'free',
  Active = 'active',
  Paid = 'paid',
  Subuser = 'subuser',
  Reseller = 'reseller',
}

export interface AccountDto {
  reputation: number;
  type: UserType;
  is_reseller_customer: boolean;
}

export type AccountStore = GenericNetworkSlice<AccountData>;

export interface AccountData {
  reputation: number;
  type: UserType;
  isResellerCustomer: boolean;
}

export const initialAccountState = {
  networkState: NetworkState.Unrequested,
} as AccountStore;

export const getAccountFromTiara = createAsyncThunk<
  AccountDto,
  ResponseBody<AccountDto>,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('accountSlice/getAccountFromTiara', async (args, thunkApi) => {
  try {
    const accountResp = await args;

    if (accountResp.ok) {
      const response = await accountResp.json();
      return response;
    }

    throw new Error('Network Response be Janky');
  } catch {
    return thunkApi.rejectWithValue({
      message: 'Network Level Error',
    } as ResponseError);
  }
});

export const accountSlice = createSlice({
  name: 'account',
  initialState: initialAccountState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAccountFromTiara.pending, (state, action) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(getAccountFromTiara.fulfilled, (state, action) => {
      state.networkState = NetworkState.Success;

      if (state.networkState === NetworkState.Success) {
        state.data = {
          reputation: action.payload.reputation,
          type: action.payload.type,
          isResellerCustomer: action.payload.is_reseller_customer,
        };
      }
    });
    builder.addCase(getAccountFromTiara.rejected, (state, action) => {
      state.networkState = NetworkState.Error;

      if (state.networkState === NetworkState.Error) {
        state.errorMessage = action?.payload?.message ?? 'Unknown Error';
      }
    });
  },
});

export const isSubuserSelector = (accountStore: AccountStore) => {
  return (
    accountStore.networkState === NetworkState.Success &&
    accountStore.data.type === UserType.Subuser
  );
};

export const isResellerSelector = (accountStore: AccountStore) => {
  return (
    accountStore.networkState === NetworkState.Success &&
    accountStore.data.isResellerCustomer
  );
};
