const Marionette = require("backbone.marionette");
const { analytics } = require("@sendgrid/bloodhound");

module.exports = Marionette.ItemView.extend({
  template: require("templates/errors/500.hbs"),

  ui: {
    refresh: "[data-role=refresh]",
  },

  events: {
    "click @ui.refresh": "refreshPage",
  },

  refreshPage() {
    window.location.reload();
  },

  onRender() {
    analytics.track({
      event: "500_error_page_rendered",
      properties: {
        userId: window.Reqres.request("currentUser").user_id,
      },
    });
  },
});
