var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"1":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"checkbox-cell\">\n"
    + ((stack1 = container.invokePartial(require("/opt/sendgrid/mako/src/templates/partials/checkbox.hbs"),depth0,{"name":"checkbox","hash":{"style":"display: inline;","label":"","checked":(depth0 != null ? lookupProperty(depth0,"checked") : depth0),"role":"selectBox","name":(depth0 != null ? lookupProperty(depth0,"emailId") : depth0),"id":(depth0 != null ? lookupProperty(depth0,"emailId") : depth0)},"data":data,"indent":"  ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "</td>\n";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"group\">\n  <span>\n    "
    + container.escapeExpression(container.lambda((depth0 != null ? lookupProperty(depth0,"group_name") : depth0), depth0))
    + "\n  </span>\n</td>\n";
},"5":function(container,depth0,helpers,partials,data) {
    var alias1=container.lambda, alias2=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<td class=\"reason\">\n  <span title=\""
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"reason") : depth0), depth0))
    + "\">\n    "
    + alias2(alias1((depth0 != null ? lookupProperty(depth0,"reason") : depth0), depth0))
    + "\n  </span>\n</td>\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var stack1, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.escapeExpression, alias3=container.lambda, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showCheckbox") : depth0),{"name":"if","hash":{},"fn":container.program(1, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":1,"column":0},"end":{"line":5,"column":7}}})) != null ? stack1 : "")
    + "<td class=\"contact primary\">\n  <div class=\"recipient\">\n    "
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/avatar.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"email") : depth0),31,{"name":"avatar","hash":{},"data":data,"loc":{"start":{"line":8,"column":4},"end":{"line":8,"column":23}}}))
    + "\n    <span title=\""
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "\">"
    + alias2(alias3((depth0 != null ? lookupProperty(depth0,"email") : depth0), depth0))
    + "</span>\n  </div>\n</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showGroup") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":12,"column":0},"end":{"line":18,"column":7}}})) != null ? stack1 : "")
    + "<td class=\"time\">"
    + alias2(__default(require("/opt/sendgrid/mako/src/templates/helpers/datetime.js")).call(alias1,(depth0 != null ? lookupProperty(depth0,"created") : depth0),{"name":"datetime","hash":{},"data":data,"loc":{"start":{"line":19,"column":17},"end":{"line":19,"column":37}}}))
    + "</td>\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"showReason") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":20,"column":0},"end":{"line":26,"column":7}}})) != null ? stack1 : "");
},"usePartial":true,"useData":true});