import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';
import { ActionTypes } from './actionTypes';
import { timezoneOptionsAdapter } from './adapters';
import { Timezone } from '../types/user';
import { TimezoneDto } from '../dtos';

const initialState: MakoStateType['timezoneOptions'] = {} as Timezone[];

interface TimezoneOptionsAction extends Action {
  payload: MakoStateType['timezoneOptions'];
}

export const timezoneOptions = (
  state = initialState,
  action: TimezoneOptionsAction
) => {
  switch (action.type) {
    case ActionTypes.GetTimezoneOptionsSuccess:
      return timezoneOptionsAdapter(action.payload as TimezoneDto[]);
    default:
      return state;
  }
};
