import { enableDmarc } from '../../helpers/enableDmarcToggle';
import objectToQueryParam from '../../helpers/objectToQueryParams';

const dmarcQueryParam = enableDmarc();

const routes = {
  landingPage: `/settings/sender_auth`,

  domainList: `/settings/sender_auth/domains`,
  senderList: `/settings/sender_auth/senders`,
  linkList: `/settings/sender_auth/links`,
  ipList: `/settings/sender_auth/reverse_dns`,

  domainCreate: `/settings/sender_auth/domain/create`,
  domainCreate2: `/settings/sender_auth/domain/configure`,
  senderCreate: `/settings/sender_auth/senders/new`,
  linkCreate: `/settings/sender_auth/link/create`,
  linkCreate2: `/settings/sender_auth/link/configure`,
  ipCreate: `/settings/sender_auth/reverse_dns/create`,

  senderAuthPublicVerification: 'public/sender_auth/verification',

  verify: ({
    link,
    domain,
    provider,
    rdns,
  }: {
    link?: string;
    domain?: string;
    provider?: string;
    rdns?: string;
  }) =>
    `/settings/sender_auth/verify${objectToQueryParam({
      link,
      domain,
      provider,
      rdns,
    })}`,
  wereSorry: ({
    link,
    domain,
    provider,
    message = '',
    failedAddIPs,
    totalAddIPsAttempt,
    failedDeleteIPs,
    totalDeleteIPsAttempt,
    domainName,
    subusers,
    subusersAttempt,
    linkAttempt,
    source,
  }: {
    link?: string;
    domain?: string;
    provider?: string;
    message?: string;
    failedAddIPs?: string[];
    totalAddIPsAttempt?: number;
    failedDeleteIPs?: string[];
    totalDeleteIPsAttempt?: number;
    domainName?: string;
    subusers?: string[];
    subusersAttempt?: boolean;
    linkAttempt?: boolean;
    source?: string;
  }) =>
    `/settings/sender_auth/confirmation/sorry${objectToQueryParam({
      link,
      domain,
      provider,
      message,
      failedAddIPs,
      totalAddIPsAttempt,
      failedDeleteIPs,
      totalDeleteIPsAttempt,
      domainName,
      subusers,
      subusersAttempt,
      linkAttempt,
      source,
    })}`,
  // All of these are undefined to make them optional for typescript, we can't figure out the apiconfig thing atm
  // When we do we'll give these real types.
  success: ({
    domain,
    rdns,
    ip,
  }: {
    domain?: string;
    rdns?: string;
    ip?: string;
  }) =>
    `/settings/sender_auth/confirmation/success${objectToQueryParam({
      domain,
      rdns,
      ip,
    })}`,
  instructionsSent: (email: string, authType: string) =>
    `/settings/sender_auth/confirmation/instructions_sent${objectToQueryParam({
      email,
      authType,
    })}`,
  linkDetail: (id: string) => `/settings/sender_auth/link/get/${id}`,
  domainDetail: (id: string) => `/settings/sender_auth/domain/get/${id}`,
  senderDetail: (id: string) => `/settings/sender_auth/senders/${id}/edit`,
  ipDetail: (id: string) => `/settings/sender_auth/reverse_dns/get/${id}`,

  domainListGet: ({
    limit = 20,
    offset,
    excludeSubusers = false,
    domain,
    dmarc = dmarcQueryParam,
  }: {
    limit?: number;
    offset?: number;
    excludeSubusers?: boolean;
    domain?: string;
    dmarc?: boolean;
  }) =>
    `whitelabel/domains${objectToQueryParam({
      limit,
      offset,
      exclude_subusers: excludeSubusers,
      domain,
      dmarc,
    })}`,

  linkListGet: ({
    limit = 20,
    offset,
    excludeSubusers = false,
    dmarc = dmarcQueryParam,
  }: {
    limit?: number;
    offset?: number;
    excludeSubusers?: boolean;
    dmarc?: boolean;
  }) =>
    `whitelabel/links${objectToQueryParam({
      limit,
      offset,
      exclude_subusers: excludeSubusers,
      dmarc,
    })}`,

  ipListGet: ({
    limit = 20,
    offset,
    dmarc = dmarcQueryParam,
  }: {
    limit?: number;
    offset?: number;
    dmarc?: boolean;
  }) =>
    `whitelabel/ips${objectToQueryParam({
      limit,
      offset,
      dmarc,
    })}`,

  userIpListGet: ({ limit = 20, offset }: { limit: number; offset: number }) =>
    `ips${objectToQueryParam({
      limit,
      offset,
    })}`,

  // DataFetching Routes
  usernameGet: `user/username`,
  subuserGet: `subusers`,
  userTypeAndRegionGet: 'user/type',
  subuserGetByUsername: ({
    username,
    limit = 500,
  }: {
    username: string;
    limit?: number;
  }) => `subusers?limit=${limit}&username=${username}`,
  sendToCoworker: `whitelabel/dns/email?dmarc=` + dmarcQueryParam,

  ipAndSubuserListGet: `whitelabel/domains/ips?dmarc=` + dmarcQueryParam,
  addIpToDomainPost: (id: string) =>
    `whitelabel/domains/${id}/ips?dmarc=` + dmarcQueryParam,
  removeIpFromDomainDelete: (id: string, ip: string) =>
    `whitelabel/domains/${id}/ips/${ip}?dmarc=` + dmarcQueryParam,

  domainGet: (id: string) =>
    `whitelabel/domains/${id}?dmarc=` + dmarcQueryParam,
  linkGet: (id: string) => `whitelabel/links/${id}?dmarc=` + dmarcQueryParam,
  ipGet: (id: string) => `whitelabel/ips/${id}?dmarc=` + dmarcQueryParam,

  domainDelete: (id: string) =>
    `whitelabel/domains/${id}?dmarc=` + dmarcQueryParam,
  linkDelete: (id: string) => `whitelabel/links/${id}?dmarc=` + dmarcQueryParam,
  ipDelete: (id: string | number) =>
    `whitelabel/ips/${id}?dmarc=` + dmarcQueryParam,

  domainValidate: (id: string) =>
    `whitelabel/domains/${id}/validate?dmarc=` + dmarcQueryParam,
  linkValidate: (id: string) =>
    `whitelabel/links/${id}/validate?dmarc=` + dmarcQueryParam,
  ipValidate: (id: string) =>
    `whitelabel/ips/${id}/validate?dmarc=` + dmarcQueryParam,

  publicDNSGet: (jwt: string) =>
    `public/whitelabel/dns?d=${jwt}&dmarc=` + dmarcQueryParam,
  publicDNSPost: `public/whitelabel/dns?dmarc=` + dmarcQueryParam,

  domainPost: `whitelabel/domains?dmarc=` + dmarcQueryParam,
  linkPost: `whitelabel/links?dmarc=` + dmarcQueryParam,
  ipPost: `whitelabel/ips?dmarc=` + dmarcQueryParam,

  domainSubuserPost: (id: string) =>
    `whitelabel/domains/${id}/subuser?dmarc=` + dmarcQueryParam,
  linkSubuserPost: (id: string) =>
    `whitelabel/links/${id}/subuser?dmarc=` + dmarcQueryParam,

  verifyDomainConnect: (domainId: number) =>
    `senderauth/domains/${domainId}/connect?dmarc=` + dmarcQueryParam,
};

export { routes as SenderAuthRoutes };
export default routes;
