import { IDPCertificateDto, IDPCertificateData } from './types';
import camelCase from 'lodash/camelCase';

export const IDPCertificateDtoToData = (certificate: IDPCertificateDto) => {
  const IDPCertData: any = {};

  Object.entries(certificate).forEach(([key, value]) => {
    IDPCertData[camelCase(key!)] = value;
  });

  return IDPCertData as IDPCertificateData;
};
