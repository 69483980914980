import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';
import { ActionTypes } from './actionTypes';
import { TeammateRequestAccessInfo } from '../types/teammateRequestAccess';
import { TeammateRequestAccessScopeDto } from '../dtos';
import { getTeammateRequestAccessRefinedErrorMessage } from './errors';

const initialState: MakoStateType['teammateRequestAccessInfo'] = {
  isRequestingAccess: false,
  isRequestedSuccess: false,
  requestAccessErrorMessage: '',
} as TeammateRequestAccessInfo;

interface TeammateRequestAccessAction extends Action {
  payload: MakoStateType['teammateRequestAccessInfo'];
}

export const teammateRequestAccessInfo = (
  state = initialState,
  action: TeammateRequestAccessAction
) => {
  switch (action.type) {
    case ActionTypes.TeammateRequestAccessRequest:
      return {
        ...state,
        isRequestingAccess: true,
        isRequestedSuccess: false,
      };
    case ActionTypes.TeammateRequestAccessSuccess:
      return {
        isRequestingAccess: false,
        isRequestedSuccess: true,
        requestAccessErrorMessage: '',
      };
    case ActionTypes.TeammateRequestAccessFailure:
      const { errors } = action.payload as TeammateRequestAccessScopeDto;
      const serverErrorMessage = (errors && errors[0].message) || '';
      const refinedTeammateRequestAccessErrorMessage = getTeammateRequestAccessRefinedErrorMessage(
        serverErrorMessage
      );

      return {
        isRequestingAccess: false,
        isRequestedSuccess: false,
        requestAccessErrorMessage: refinedTeammateRequestAccessErrorMessage,
      };
    case ActionTypes.ClearTeammateRequestAccess:
      return {
        isRequestingAccess: false,
        isRequestedSuccess: false,
        requestAccessErrorMessage: '',
      };
    default:
      return state;
  }
};
