var Handlebars = require("/opt/sendgrid/mako/node_modules/handlebars/runtime.js");
function __default(obj) { return obj && (obj.__esModule ? obj["default"] : obj); }
module.exports = (Handlebars["default"] || Handlebars).template({"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data) {
    var helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "<div class=\"emptyData\">\n  <h3>"
    + alias4(((helper = (helper = lookupProperty(helpers,"messageText") || (depth0 != null ? lookupProperty(depth0,"messageText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"messageText","hash":{},"data":data,"loc":{"start":{"line":2,"column":6},"end":{"line":2,"column":21}}}) : helper)))
    + "</h3>\n  <h4>"
    + alias4(((helper = (helper = lookupProperty(helpers,"subMessageText") || (depth0 != null ? lookupProperty(depth0,"subMessageText") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"subMessageText","hash":{},"data":data,"loc":{"start":{"line":3,"column":6},"end":{"line":3,"column":24}}}) : helper)))
    + "</h4>\n</div>";
},"useData":true});