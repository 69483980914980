import { ActionsObservable, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';

import * as ExpertServicesImplementationActions from './actions';
import { ActionTypes } from './actionTypes';
import {
  ExpertServiceImplementationDto,
  ExpertServicesImplementationDto,
} from '../dtos';
import { ResponseError } from '../types/responseError';
import { getExpertServicesImplementationData } from './service';

const getExpertServicesImplementationEpic = (
  action$: ActionsObservable<
    ReturnType<
      typeof ExpertServicesImplementationActions.Actions.getExpertServicesImplementation
    >
  >
): Observable<
  | ReturnType<
      typeof ExpertServicesImplementationActions.Actions.getExpertServicesImplementationSuccess
    >
  | ReturnType<
      typeof ExpertServicesImplementationActions.Actions.getExpertServicesImplementationFailure
    >
> => {
  return action$.pipe(
    ofType(ActionTypes.GetExpertServicesImplementationRequest),
    switchMap(() => {
      return from(getExpertServicesImplementationData());
    }),
    map((data: ExpertServicesImplementationDto) => {
      if ((data as { errors: ResponseError[] }).errors) {
        return ExpertServicesImplementationActions.Actions.getExpertServicesImplementationFailure(
          data
        );
      }
      return ExpertServicesImplementationActions.Actions.getExpertServicesImplementationSuccess(
        data as ExpertServiceImplementationDto[]
      );
    })
  );
};

export default [getExpertServicesImplementationEpic];
