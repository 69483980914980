const Marionette = require("backbone.marionette");
const APIConfig = require("apiconfig");

module.exports = Marionette.ItemView.extend({
  template: require("templates/provision_status.hbs"),

  ui: {
    provisionNeeded: "[role=provisionNeeded]",
    provisionFail: "[role=provisionFail]",
    provisionDeactivated: "[role=provisionDeactivated]",
  },

  modelEvents: {
    sync: "render",
  },

  initialize() {
    this.model.set("status", this.model.get("status") || "n/a");
    this.model.set("signupHost", APIConfig.signup_host);
  },

  onRender() {
    this.hideAll();

    if (this.provisionNeeded()) {
      this.ui.provisionNeeded.removeClass("hidden");
    } else if (this.provisionFail()) {
      this.ui.provisionFail.removeClass("hidden");
    } else if (this.provisionDeactivated()) {
      this.ui.provisionDeactivated.removeClass("hidden");
    }
  },

  active() {
    return this.model.get("status") === "active";
  },

  provisionNeeded() {
    const status = this.model.get("status") || "n/a";
    return status === "provision needed" || status === "provision pending";
  },

  provisionFail() {
    const status = this.model.get("status") || "n/a";
    return status === "provision fail";
  },

  provisionDeactivated() {
    const status = this.model.get("status") || "n/a";
    return status === "deactivated";
  },

  hideAll() {
    this.ui.provisionNeeded.addClass("hidden");
    this.ui.provisionFail.addClass("hidden");
    this.ui.provisionDeactivated.addClass("hidden");
  },
});
