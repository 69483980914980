// This value gets set on login and is read by the security checkup
// and teammate creation flows to determine when a user is required
// to setup 2FA instead of it being optional. Users that require 2FA
// setup have a very restritcted auth token, which is why we aren't
// calling endpoints to determine this state.

import { setCookie } from './cookieHelpers';
import Cookies from 'js-cookie';

const SETUP_2FA_REQUIRED = 'setup_2fa_required';

export const getIsSetup2FARequired = (): boolean => {
  try {
    const item = Cookies.get(SETUP_2FA_REQUIRED);
    return item ? Boolean(JSON.parse(item)) : false;
  } catch (e) {
    console.warn(`failed to parse ${SETUP_2FA_REQUIRED} cookie`, e);
    return false;
  }
};

export const setIs2FASetupRequired = (isRequired: boolean) => {
  try {
    setCookie(SETUP_2FA_REQUIRED, JSON.stringify(Boolean(isRequired)));
  } catch (e) {
    console.warn(`failed to set ${SETUP_2FA_REQUIRED} cookie`, e);
  }
};
