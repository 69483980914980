const Marionette = require("backbone.marionette");
const _ = require("underscore");
const StatsTableRowView = require("./table_row_view");
const StatsTableRowHeaderView = require("./table_row_header_view");
const snakeCase = require("templates/helpers/snakeCase");

module.exports = Marionette.CompositeView.extend({
  template: require("templates/stats/table.hbs"),

  collectionEvents: {
    sync: "onSync",
  },

  modelEvents: {
    "change:hiddenMetrics": "render",
    "change:metric": "render",
    "change:displayMode": "toggleComputations",
  },

  resetCollection() {
    this.render();
  },

  childView(opts) {
    if (opts.rowHeader) {
      return new StatsTableRowHeaderView(opts);
    }

    return new StatsTableRowView(opts);
  },

  childViewOptions(model, index) {
    return {
      columns: this.displayColumns,
      rowHeader: this.getOption("rowHeader"),
      index,
      className: this.getOption("childClassName"),
      formatter: this.getOption("formatter"),
      model,
      pageModel: this.model,
      modelKey: this.getOption("modelKey"),
    };
  },

  childViewContainer: "tbody",

  initialize() {
    this.hiddenColumns = {};

    const dropdownFilterView = this.getOption("dropdownFilterView");
    if (dropdownFilterView) {
      this.listenTo(dropdownFilterView, "selected", this.showKey);
      this.listenTo(dropdownFilterView, "unselected", this.hideKey);
    }

    if (this.model && this.getOption("modelKey")) {
      this.listenTo(
        this.model,
        `change:${this.getOption("modelKey")}`,
        this.resetCollection
      );
    }
  },

  updateColumns() {
    let metric;
    let limit;
    let name;

    if (this.model) {
      metric = this.model.get("metric");
      limit = this.model.get("names_limit");
      name = this.model.get(this.getOption("modelKey"));
    }

    const columns =
      this.getOption("columns") ||
      this.collection.withName(name).getNames(metric, limit);

    // Add ranks to the columns
    _.map(columns, (v, k) => {
      v.rank = k;
    });

    const hc = this.hiddenColumns;
    const hm = {};

    if (this.model) {
      const arr = this.model.get("hiddenMetrics");
      for (let i = 0; arr && i < arr.length; i++) {
        hm[arr[i]] = true;
      }
    }

    this.displayColumns = _.reject(columns, (c) => c.key in hc || c.key in hm);
  },

  onRender() {
    // Chrome bug with repainting table cells
    $(".data-table.stats").hide();
    window.setTimeout(() => {
      $(".data-table.stats").show();
    }, 1);
  },

  serializeData() {
    this.updateColumns();

    const computations = {};
    _.each(this.collection.computations || [], (c) => {
      computations[c.numerator] =
        c.description || `${c.numerator}/${c.denominator}`;
    });

    _.each(this.displayColumns, (c) => {
      c.computation = computations[c.key];
    });

    const data = {
      columns: this.displayColumns,
      rowHeader: this.getOption("rowHeader"),
      className: this.getOption("className"),
    };

    if (data.rowHeader) {
      data.className += " stats-row-header";
    }

    const displayComputations = this.model.get("displayMode") === "percentages";
    // Add displayComputations to the columns
    _.map(data.columns, (v) => {
      v.displayComputations = displayComputations;
    });

    return data;
  },

  showKey(key) {
    let keys;
    if (key instanceof Array) {
      keys = key;
    } else {
      keys = [key];
    }

    for (let i = 0; i < keys.length; i++) {
      let k = keys[i];
      k = snakeCase(k);
      delete this.hiddenColumns[k];
    }

    this.render();
  },

  hideKey(key) {
    let keys;
    if (key instanceof Array) {
      keys = key;
    } else {
      keys = [key];
    }

    for (let i = 0; i < keys.length; i++) {
      let k = keys[i];
      k = snakeCase(k);
      this.hiddenColumns[k] = true;
    }

    this.render();
  },

  onSync() {
    this.updateColumns();
    this.render();

    if (this.collection.length > 0) {
      this.trigger("loaded");
    }
  },

  toggleComputations(model, displayMode) {
    if (displayMode === "percentages") {
      this.$el.find(".computation").removeClass("hidden");
    } else {
      this.$el.find(".computation").addClass("hidden");
    }
  },
});
