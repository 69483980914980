const statsRoutes = {
  statistics: "statsOverview",
  "statistics/global": "statsGlobal",
  "statistics/category": "statsCategory",
  "statistics/category/compare": "statsCategoryCompare",
  "statistics/mailbox_provider/compare": "statsMailboxProviderCompare",
  "statistics/mailbox_provider": "statsMailboxProvider",
  "statistics/browser/compare": "statsBrowserCompare",
  "statistics/browser": "statsBrowser",
  "statistics/geo": "statsGeo",
  "statistics/subuser": "statsSubuser",
  "statistics/subuser/compare": "statsSubuserCompare",
  "statistics/device": "statsDevice",
  "statistics/parse_webhook": "statsParseWebhook",
};

module.exports = statsRoutes;
