import { ActionsObservable, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';

import * as ExpertServicesOngoingActions from './actions';
import { ActionTypes } from './actionTypes';
import { ExpertServiceOngoingDto, ExpertServicesOngoingDto } from '../dtos';
import { ResponseError } from '../types/responseError';
import { getExpertServicesOngoingData } from './service';

const getExpertServicesOngoingEpic = (
  action$: ActionsObservable<
    ReturnType<
      typeof ExpertServicesOngoingActions.Actions.getExpertServicesOngoing
    >
  >
): Observable<
  | ReturnType<
      typeof ExpertServicesOngoingActions.Actions.getExpertServicesOngoingSuccess
    >
  | ReturnType<
      typeof ExpertServicesOngoingActions.Actions.getExpertServicesOngoingFailure
    >
> => {
  return action$.pipe(
    ofType(ActionTypes.GetExpertServicesOngoingRequest),
    switchMap(() => {
      return from(getExpertServicesOngoingData());
    }),
    map((data: ExpertServicesOngoingDto) => {
      if ((data as { errors: ResponseError[] }).errors) {
        return ExpertServicesOngoingActions.Actions.getExpertServicesOngoingFailure(
          data
        );
      }
      return ExpertServicesOngoingActions.Actions.getExpertServicesOngoingSuccess(
        data as ExpertServiceOngoingDto[]
      );
    })
  );
};

export default [getExpertServicesOngoingEpic];
