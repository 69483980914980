import qs from "qs";
import APIConfig from "../apiconfig.js";
import { createAuth0Client } from "@auth0/auth0-spa-js";

let auth0Client = null;
const audience = APIConfig.auth0SendGridAPIAudience;

/**
 * Starts the authentication flow
 */
const login = async (options) => {
  options = options || {};
  let loginHint = options["loginHint"];
  let targetUrl = options["targetUrl"];

  try {
    // build the redirect uri
    let redirectUri = `${window.location.origin}${APIConfig.root}auth0/callback`;

    const options = {
      authorizationParams: {
        redirect_uri: redirectUri,
        audience: audience,
      },
      appState: undefined,
    };

    if (targetUrl) {
      options.appState = { targetUrl };
    }

    if (loginHint) {
      options.authorizationParams["login_hint"] = loginHint;
    }

    await auth0Client.loginWithRedirect(options);
  } catch (err) {
    console.log("Log in failed", err);
  }
};

/**
 * Executes the logout flow
 */
const logout = async () => {
  try {
    let returnTo = window.location.origin;
    returnTo += APIConfig.root;
    returnTo += `login?auth0=true`;
    await auth0Client.logout({
      logoutParams: {
        returnTo: returnTo,
      },
    });
  } catch (err) {
    console.log("Log out failed", err);
  }
};

/**
 * Initializes the Auth0 client
 */
const configureClient = async () => {
  auth0Client = await createAuth0Client({
    domain: APIConfig.auth0Domain,
    clientId: APIConfig.auth0ClientID,
  });
};

// Will run when page finishes loading
const redirectCallback = async (queryParams) => {
  await configureClient();

  const isAuthenticated = await auth0Client.isAuthenticated();

  if (isAuthenticated) {
    return await auth0Client.access;
  }

  const shouldParseResult =
    qs.parse(queryParams).code && qs.parse(queryParams).state;
  if (!shouldParseResult) {
    throw new Error("Missing code and state");
  }

  try {
    const result = await auth0Client.handleRedirectCallback();
    console.log("Logged in!");
    if (result.appState && result.appState.targetUrl) {
      return result.appState.targetUrl;
    }
    return "";
  } catch (err) {
    console.log("Error parsing redirect:", err);
  }
};

// callApi simulates calling the api with auth0 access token.
// If withIdToken is true, the body is replaced with only ID token.
// If withIdToken is false, the body is not changed.
const callApi = async (url, init) => {
  try {
    init = init || {};
    let withIdToken = init["withIdToken"] || false;
    delete init["withIdToken"];
    const resp = await auth0Client.getTokenSilently({
      authorizationParams: {
        audience: audience,
      },
      // If appendIdToken is true, then resp is a detailed response with ID token.
      detailedResponse: withIdToken,
    });
    let access_token = resp;
    if (withIdToken) {
      access_token = resp.access_token;

      init["body"] = JSON.stringify({
        id_token: resp.id_token,
      });
    }

    if (!init.hasOwnProperty("headers")) {
      init["headers"] = {};
    }
    init["headers"] = {
      Authorization: `bearer ${access_token}`,
    };
    return await fetch(url, init);
  } catch (e) {
    console.error(e);
  }
};

const postUnifiedSearch = async (access_token, id_token) => {
  return Promise.resolve({
    json: () => []
  })
  // const unifiedSearchApi = `${APIConfig.host}unified_search`;
  // return fetch(unifiedSearchApi, {
  //   method: "POST",
  //   headers: {
  //     "Content-Type": "application/json",
  //     Authorization: `Bearer ${access_token}`,
  //   },
  //   body: JSON.stringify({
  //     id_token,
  //   }),
  // });
};

const checkIfUserHasAccountWithAuth0Email = async () => {
  const { access_token, id_token } = await auth0Client.getTokenSilently({
    authorizationParams: {
      audience: audience,
    },
    detailedResponse: true,
  });
  const unifiedSearchResponse = await postUnifiedSearch(access_token, id_token);
  const results = await unifiedSearchResponse.json();
  return Array.isArray(results) && results.length > 0;
};

export {
  configureClient,
  login,
  logout,
  redirectCallback,
  callApi,
  checkIfUserHasAccountWithAuth0Email,
};
