import { AccountProfile } from '../types/user';
import { AccountProfileDto } from '../dtos';
import { sanitizer } from '../dtoSanitizer';

export function accountProfileAdapter(
  dto: AccountProfileDto,
  email?: string
): AccountProfile {
  const sanitizedProfile = sanitizer(dto);
  const {
    first_name,
    last_name,
    authy_id,
    userid,
    multifactor_phone,
    ...sanitized
  } = sanitizedProfile;

  const profile = {
    ...sanitized,
    firstName: first_name,
    lastName: last_name,
    authyId: authy_id,
    userId: userid,
    multifactorPhone: multifactor_phone,
    email,
  } as AccountProfile;

  return profile;
}
