export interface GlobalStat {
  metrics: GlobalStatMetrics;
}

export interface GlobalStatMetrics {
  blocks: number;
  bounce_drops: number;
  bounces: number;
  clicks: number;
  deferred: number;
  delivered: number;
  invalid_emails: number;
  opens: number;
  processed: number;
  requests: number;
  spam_report_drops: number;
  spam_reports: number;
  unique_clicks: number;
  unique_opens: number;
  unsubscribe_drops: number;
  unsubscribes: number;
}

export interface GlobalStatDto {
  date: string; // YYYY-MM-DD
  stats: GlobalStat[];
}

export type GlobalStatsDto = GlobalStatDto[];

export enum MailboxProviderName {
  Gmail = 'Gmail', // gmail-smtp-in.l.google.com
  Yahoo = 'Yahoo', // frontiernet.net, yahoodns.net
  MicrosoftOutlook = 'Microsoft Outlook', // olc.protection.outlook.com
  AppleICloud = 'Apple iCloud', // icloud.com
  MicrosoftExchange = 'Microsoft Exchange', // pamx1.hotmail.com
  GoogleApps = 'Google Apps', // aspmx.google.com, aspmx.l.google.com, googlemail.com, gmr-smtp-in.l.google.com, mx1.smtp.goog, smtp.google.com
  IIJ = 'IIJ', // xspmail.jp
  A1TelekomAustria = 'A1 Telekom Austria', // aon.at
  AgoraSA = 'Agora SA', // gazeta.pl
  AltiboxAS = 'Altibox AS', // altibox.net
  Altice = 'Altice', // ptempresas.pt, sapo.pt
  Amazon = 'Amazon', // amazon.co, amazon.com, amazon.co.uk, amazon.co.jp
  Apple = 'Apple', // apple.com
  ApplePrivateRelay = 'Apple Private Relay', // privaterelay.appleid.com
  AscioTechnologies = 'Ascio Technologies', // as9143.net
  ATT = 'AT&T', // prodigy.net
  ATTBusiness = 'AT&T Business', // att.net
  AtlanticBroadband = 'Atlantic Broadband', // atlanticbb.net
  Atmail = 'Atmail', // atmailcloud.com
  AmazonAWS = 'Amazon AWS', // amazonaws.com
  Barracuda = 'Barracuda', // barracudanetworks.com
  Basecamp = 'Basecamp', // hey.com
  BellCanada = 'Bell Canada', // bell.net, bellaliant.net, bell.ca, bellnet.ca
  BellMTS = 'Bell MTS', // mymts.net
  Bigpond = 'Bigpond', // bigpond.com
  BIGLOBE = 'BIGLOBE', // biglobe.ne.jp
  Bluetie = 'Bluetie', // bluetie.com
  Bluewin = 'Bluewin', // bluewin.ch
  BOL = 'BOL', // bol.com.br, bol.com
  BouyguesTelecom = 'Bouygues Telecom', // bbox.fr
  BTInternet = 'BT Internet', // btinternet.com
  BTMail = 'BT Mail', // btopenworld.com
  BuckeyeBroadband = 'Buckeye Broadband', // buckeyecom.net
  Carrierzone = 'Carrierzone', // carrierzone.com
  CentralMediaGroup = 'Central Media Group', // citromail.hu
  CenturyDragonInformationNetwork = 'Century Dragon Information Network', // 21cn.com
  CenturyLink = 'CenturyLink', // centurylink.net
  Charter = 'Charter', // charter.net, chartercom.com
  CiscoIronport = 'Cisco Ironport', // iphmx.com
  Claro = 'Claro', // claro.com.br
  CogecoConnexion = 'Cogeco Connexion', // cogeco.net, cogeco.ca, cgocable.ca
  CombellNv = 'Combell nv.', // mailprotect.be
  Comcast = 'Comcast', // comcast.net
  Cyren = 'Cyren', // expurgate.net
  Eastlink = 'Eastlink', // eastlink.ca
  EchoLabs = 'Echo Labs', // echolabs.net
  EgonetWorkKft = 'Egonet Work Kft', // gmail.hu
  EircomLimited = 'Eircom Limited', // eircom.net
  ElisaOyj = 'Elisa Oyj', // saunalahti.fi
  EmailIt = 'Email.it', // email.it
  EmiratesInternetMultimedia = 'Emirates Internet & Multimedia', // emirates.net.ae
  EPB = 'EPB', // epbfi.com
  EWE = 'EWE', // ewetel.de
  Excello = 'Excello', // spamfree.cz, virusfree.cz
  FastMail = 'FastMail', // messagingengine.com, pobox.com
  Fastweb = 'Fastweb', // fastwebnet.it
  FireEye = 'FireEye', // fireeyecloud.com
  FirstCloud = 'FirstCloud', // firstwave.com.au
  FutureQuest = 'FutureQuest', // futurequest.net
  Forcepoint = 'Forcepoint', // mailcontrol.com
  Fortinet = 'Fortinet', // fortimail.com
  FreeFr = 'Free.fr', // free.fr
  Freenet = 'Freenet', // freenet.de
  FuseNet = 'FuseNet', // fuse.net
  GCICommunicationsCorp = 'GCI Communications Corp', // gci.net
  UnitedInternet = 'United Internet', // gmx.net, web.de, schlund.de, mail.com, kundenserver.de, 1and1.com, 1and1.co.uk, 1and1.es, 1and1.fr, 1and1.mx, 1and1.it
  GoDaddy = 'GoDaddy', // secureserver.net
  GoSecure = 'GoSecure', // gosecure.net, rcimx.net
  USGovernment = 'US Government', // gov
  HiNet = 'HiNet', // hinet.net
  HongKongTelecommunications = 'Hong Kong Telecommunications (HKT)', // netvigator.com
  Hornetsecurity = 'Hornetsecurity', // antispameurope.com, hornetsecurity.com
  Hostedecom = 'Hostedecom', // hostedemail.com
  HushCommunications = 'Hush Communications', // hushmail.com
  IUa = 'I.ua', // i.ua
  IiNet = 'iiNet', // iinet.net.au
  InboxLv = 'Inbox.lv', // inbox.lv, inbox.eu
  Interia = 'Interia', // interia.pl
  InternetInitiativeJapan = 'Internet Initiative Japan', // securemx.biz, securemx.jp
  InternodePty = 'Internode Pty', // internode.on.net
  ItaliaOnline = 'ItaliaOnline', // inwind.it, iol.it
  JCOMCo = 'JCOM Co', // zaq.ne.jp
  JIMDO = 'JIMDO', // jimdo.com
  KakaoCorp = 'Kakao Corp', // hanmail.net
  KDDI = 'KDDI', // au.com, kddi.com, auone-net.jp, secure.ne.jp
  KPN = 'KPN', // kpnmail.nl
  LaPoste = 'La Poste', // laposte.fr, laposte.net
  Lasamailen = 'Läsamailen', // a3mail.se
  Libero = 'Libero', // libero.it, blu.it, virgilio.it
  Linode = 'Linode', // linode.com
  Locaweb = 'Locaweb', // correio.biz, locaweb.com.br
  LogixInfoSecurity = 'Logix InfoSecurity', // logix.in
  LytzenIt = 'Lytzen it', // lytzenitmail.dk
  MailBg = 'Mail.bg', // mail.bg
  MailDe = 'Mail.de', // mail.de
  MailRu = 'Mail.ru', // mail.ru, inbox.ru
  Mail2World = 'Mail2World', // mail2world.com, pangia.biz
  MailboxOrg = 'Mailbox.org', // mailbox.org
  MailChannelsCorporation = 'MailChannels Corporation', // mailchannels.net
  MauritiusTelecom = 'Mauritius Telecom', // intnet.mu
  MediaBEAMGmbH = 'mediaBEAM GmbH', // xworks.net
  MediaSolution = 'MediaSolution', // mail.dk
  MegaMailServers = 'MegaMailServers', // medcity.net, megamailservers.com, megamailservers.eu
  Messagelabs = 'Messagelabs', // messagelabs.com
  Microsoft365 = 'Microsoft 365', // eo.outlook.com, office365.us, mail.protection.outlook.com, mail.outlook.com
  MicrosoftAzure = 'Microsoft Azure', // azure.com
  MicrosoftExchangeLabs = 'Microsoft Exchange Labs', // exchangelabs.com
  MidcontinentCommunications = 'Midcontinent Communications', // midco.net
  Mimecast = 'Mimecast', // mimecast.co.za, mimecast.com
  FjordmailTechnologies = 'Fjordmail Technologies', // luukku.com
  Mweb = 'Mweb', // mweb.co.za
  MyComBV = 'My.com B.V.', // my.com
  NAble = 'N-able', // spamexperts.eu, mtaroutes.com, spamexperts.net
  NameCheap = 'NameCheap', // namecheaphosting.com, registrar-servers.com, privateemail.com
  Naver = 'Naver', // naver.com
  NetCologne = 'NetCologne', // netcologne.de
  Netease = 'Netease', // netease.com
  NetVision013Barak = 'NetVision 013 Barak', // 013net.net, ebox.co.il
  NewWaveMedia = 'New Wave Media', // freemail.hu
  NIFTYCorporation = 'NIFTY Corporation', // nifty.com
  NoIPCom = 'No-IP.com', // noip.com
  NTTDocomo = 'NTT Docomo', // docomo.ne.jp
  NTTPlala = 'NTT Plala', // plala.or.jp
  Onet = 'Onet', // onet.pl
  OpenComputerNetwork = 'Open Computer Network (Japan)', // ocn.ad.jp
  HughesNetworkSystems = 'Hughes Network Systems', // hughes.net
  Orange = 'Orange', // orange.com
  OrangeFrance = 'Orange France', // orange.fr
  Orcon = 'Orcon', // orcon.net.nz
  OVHcloud = 'OVHcloud', // ovh.net
  PenTeleData = 'PenTeleData', // ptd.net
  PlusNet = 'PlusNet', // plus.net
  Posteo = 'Posteo', // posteo.de
  PrimusTelecommunicationsPty = 'Primus Telecommunications Pty', // iprimus.com.au
  Cloudmark = 'Cloudmark', // cloudfilter.net
  Proofpoint = 'Proofpoint', // pphosted.com, snwlhosted.com, ppe-hosted.com, everyone.net
  ProofpointArchive = 'Proofpoint Archive', // proofpointarchiving.net
  ProtonMail = 'ProtonMail', // protonmail.ch
  Proximus = 'Proximus', // belgacom.be, proximus.be
  QQ = 'QQ', // qq.com
  Rackspace = 'Rackspace', // emailsrvr.com
  RamblerCo = 'Rambler & Co', // rambler.ru, ro.ru
  RCNTelecom = 'RCN Telecom', // rcn.com
  Rediff = 'Rediff', // rediff.akadns.net
  RoundCube = 'RoundCube', // mailhostbox.com
  Securence = 'Securence', // securence.com
  SekwetaTechnologies = 'Sekweta Technologies', // sekweta.com
  SentiaDenmark = 'Sentia Denmark', // mailcore.net
  SeznamCz = 'Seznam.cz', // seznam.cz
  SFR = 'SFR', // sfr.fr
  Sina = 'Sina', // sina.com.cn
  SingtelOptus = 'Singtel Optus', // optusnet.com.au
  SiteGroundHosting = 'SiteGround Hosting', // mailspamprotection.com
  SKCommunications = 'SK Communications', // nate.com
  Skymail = 'Skymail', // skymail.net.br
  Slingshot = 'Slingshot', // slingshot.co.nz
  SmarshArchive = 'Smarsh Archive', // smarshmail.com, smarsh.com, smarsh.cloud, actiance.net
  SoftBank = 'SoftBank', // softbank.jp, softbank.ne.jp
  Sonic = 'Sonic', // sonic.net
  SonyNetworkCommunications = 'Sony Network Communications', // so-net.ne.jp
  Sophos = 'Sophos', // sophos.com, sophos.id, reflexion.net
  SpamExpertsBV = 'SpamExperts B.V.', // spamexperts.com
  SparkNZ = 'Spark NZ', // xtra.co.nz
  Spectrum = 'Spectrum', // bresnan.net
  Suddenlink = 'Suddenlink', // suddenlink.net, suddenlinkmail.com
  SunriseUPC = 'Sunrise UPC', // sunrise.ch
  Swisscom = 'Swisscom', // ellb.ch, swisscom.com
  Synacor = 'Synacor', // cableone.net, syn-alias.com, synacor.com
  SYNAQ = 'SYNAQ', // synaq.com
  Synchronoss = 'Synchronoss', // cloud.synchronoss.net
  StratoAG = 'Strato AG', // rzone.de
  STROTHTelecomGmbH = 'STROTH Telecom GmbH', // tele2.de
  TOnlineHungary = 'T-Online Hungary', // t-online.hu, telekom.de
  TMobileAustria = 'T-Mobile Austria', // mymagenta.at
  TOnlineGermany = 'T-Online Germany', // t-online.de
  TalkTalk = 'TalkTalk', // talktalk.net
  TDSTelecommunications = 'TDS Telecommunications', // tds.net
  TelecomItalia = 'TelecomItalia', // telecomitalia.it
  Telefonica = 'Telefonica', // telefonica.net
  TelenetBV = 'Telenet BV', // telenet-ops.be, telenetgroup.be
  TelenorASA = 'Telenor ASA', // online.no, frisurf.no
  Telia = 'Telia', // telia.com
  TeliaEestiAS = 'Telia Eesti AS', // hot.ee
  TelkomSA = 'Telkom SA', // telkom.co.za, telkomsa.net
  Telstra = 'Telstra', // telstra.com.au
  Terra = 'Terra', // terra.com, terraempresas.com.br
  Tin = 'Tin', // tim.it, tin.it
  TiscaliUK = 'Tiscali (UK)', // tiscali.co.uk
  TiscaliItaly = 'Tiscali (Italy)', // tiscali.it
  TitanHQ = 'TitanHQ', // spamtitan.com
  TokoOnline = 'Toko online', // blibli.com
  TownWiFi = 'TownWiFi', // wifishare.jp
  TPGInternet = 'TPG Internet', // tpg.com.au
  TrendMicro = 'TrendMicro', // trendmicro.com, trendmicro.eu
  Tutanota = 'Tutanota', // tutanota.de
  UKRNET = 'UKR.NET', // ukr.net
  UnitedGroup = 'United Group', // abv.bg
  UnitedOnline = 'United Online', // untd.com
  UniversityOrEducation = 'University or Education', // ac.ae, ac.uk, lausd.net, ucs.mun.ca, k12.oh.us, uwo.ca, ryerson.ca, uc.cl, helsinki.fi, uottawa.ca, philasd.org, ubc.ca, ualberta.ca, .edu, edu.qa, edu.au, ac.za, ac.th, ac.nz, ac.kr, ac.jp, ac.il, ac.cr, ac.be
  UOL = 'UOL', // uol.com.br, uhserver.com
  USMilitary = 'US Military', // mail.mil
  USANET = 'USA.NET', // netaddress.usa.net, usa.net
  VadeSecure = 'Vade Secure', // oxsus-vadesecure.net, vadesecure.com
  Videotron = 'Vidéotron', // videotron.ca
  Vipre = 'Vipre', // mailanyone.net, serverdata.net
  VirginMedia = 'Virgin Media', // upcmail.net, unity-mail.com
  Vodacom = 'Vodacom', // vodamail.co.za
  VodafoneDE = 'Vodafone DE', // vodafonemail.de
  VoxTelecom = 'Vox Telecom', // vox.co.za
  Vtel = 'Vtel', // vermontel.net
  Wanadoo = 'Wanadoo (Spain)', // wanadoo.es
  WaveDivisionHoldings = 'WaveDivision Holdings', // wavemail.com
  WebmailSouthAfrica = 'Webmail South Africa', // wmi2.net
  WOWInternet = 'WOW Internet', // wowway.com, broadstripe.net
  Windstream = 'Windstream', // windstream.net
  WP = 'WP', // tlen.pl, wp.pl
  Xs4all = 'xs4all', // xs4all.nl
  YahooJapan = 'Yahoo Japan', // yahoo.co.jp
  Yandex = 'Yandex', // yandex.net, yandex-team.ru, yandex.ru
  ZeelandNet = 'ZeelandNet', // zeelandnet.nl
  ZEROSPAM = 'ZEROSPAM', // zerospam.ca
  Zix = 'Zix', // spamh.com
  MXGuarddog = 'MXGuarddog', // ik2.com
  DigitalOcean = 'Digital Ocean', // mail-data.net
  RegLtd123 = '123 Reg Ltd.', // 123-reg.co.uk
  GMOPepaboCo = 'GMO Pepabo Co.', // lolipop.jp
  OneCom = 'One.com', // one.com
  DREAMTRAININTERNET = 'DREAM TRAIN INTERNET', // dream.jp
  ImprovMX = 'ImprovMX', // improvmx.com
  TeliaFinlandOyj = 'Telia Finland Oyj', // inet.fi
  DNAOyj = 'DNA Oyj', // dnainternet.fi
  BahnhofAB = 'Bahnhof AB', // bahnhof.se
  MandicSA = 'Mandic S/A', // mandic.com.br
  NextGenTel = 'NextGenTel', // broadpark.no
  SocketTelecom = 'Socket Telecom', // socket.net
  Solarus = 'Solarus', // solarus.net
  Niftylettuce = 'Niftylettuce', // forwardemail.net
  NorthwesTel = 'NorthwesTel', // northwestel.net
  Kabelplus = 'Kabelplus', // kabsi.at
  NRTC = 'NRTC', // av-mx.com
  OTESA = 'OTE S.A.', // otenet.gr
  FusionCommunications = 'Fusion Communications', // mail.gol.com
  DuckDuckGo = 'DuckDuckGo', // duck.com
  SpectrumBusiness = 'Spectrum Business', // rr.com
  // This includes everything else not listed in this MSP enum
  OtherProviders = 'Other Providers', // All else
}

export interface MSPMetrics {
  blocks: number;
  bounces: number;
  clicks: number;
  deferred: number;
  delivered: number;
  drops: number;
  opens: number;
  processed: number;
  requests: number;
  spam_reports: number;
  unique_clicks: number;
  unique_opens: number;
}

export interface MailboxProviderStat {
  metrics: MSPMetrics;
  name: MailboxProviderName;
  type: 'mailbox_provider';
}

export interface MailboxProviderStatDto {
  date: string; // YYYY-MM-DD
  stats: MailboxProviderStat[];
}

export type MailboxProviderStatsDto = MailboxProviderStatDto[];

export enum BounceClassificationTypes {
  Content = 'Content',
  Frequency = 'Frequency or Volume Too High',
  InvalidAddress = 'Invalid Address',
  Reputation = 'Reputation',
  MailboxUnavailable = 'Mailbox Unavailable',
  Technical = 'Technical',
  Unclassified = 'Unclassified',
}

export interface BounceClassificationTotalsDto {
  result: BounceClassificationTotalsData[];
}

export interface BounceClassificationTotalsData {
  date: string; // YYYY-MM-DD
  stats: BounceClassificationTotalsStat[];
}

export interface BounceClassificationTotalsStat {
  classification: string;
  count: number;
}

export interface BounceClassificationByMailboxProviderDto {
  result: BounceClassificationByMailboxProviderData[];
}

export interface BounceClassificationByMailboxProviderData {
  date: string; // YYYY-MM-DD
  stats: BounceClassificationByMailboxProviderStat[];
}

export interface BounceClassificationByMailboxProviderStat {
  domain: string;
  count: number;
}
