import ReactDOM from "react-dom";
import { showGlobalNavBarUpdates } from "src/helpers/featureFlags/showGlobalNavBarUpdates";
import { GlobalNavbar } from "src/components/Navbar/GlobalNavbar";

const Marionette = require("backbone.marionette");
const _ = require("underscore");
const { breadcrumbClickHandler } = require("./breadcrumb_click_handler");
const shouldShowGlobalNavBarUpdates = showGlobalNavBarUpdates();

export default Marionette.LayoutView.extend({
  template: require("templates/page_layout.hbs"),
  className: "layout",
  regions: {
    page: "[role=page]",
    redirectModal: "[role=redirect-modal]",
    emailConfirmationModal: "[role=emailConfirmationModal]",
    provisionStatus: "[role=provision]",
    bannerContent: "[role=bannerContent]",
  },

  ui: {
    banner: "[role=banner]",
    close: "[role=close]",
    modalMask: ".side-modal-mask",
    breadcrumb: "[role=breadcrumb]",
  },

  events: {
    "click @ui.close": "clearBanner",
    "click @ui.modalMask": "clearAllModals",
    "click @ui.breadcrumb": breadcrumbClickHandler,
  },

  initialize() {
    window.EventBus.on("flash", _.bind(this.showFlashNoHtml, this));
    window.EventBus.on("flash_html", _.bind(this.showFlashHtml, this));
    window.EventBus.on("banner", _.bind(this.showBanner, this));
    window.EventBus.on("clearBanner", _.bind(this.clearBanner, this));
    window.EventBus.on("closeFlash", () => {
      this.$("[role=flash]").fadeOut();
    });
  },

  onDestroy() {
    const reactNode = document.querySelector(".reactNode");
    if (reactNode) {
      ReactDOM.unmountComponentAtNode(reactNode);
    }
  },

  onShow() {
    this.$("[role=flash]").hide();

    this.provisionStatus.show(this.getOption("provisionStatusView"));
    this.$el.attr("role", "layout");

    if (!shouldShowGlobalNavBarUpdates) {
      GlobalNavbar(".reactNode");
    }
  },

  showFlashNoHtml(message, error, timeout) {
    this.renderFlash(message, error, timeout, false);
  },

  showFlashHtml(message, error, timeout) {
    this.renderFlash(message, error, timeout, true);
  },

  prepareDangerFlash() {
    const $flash = this.$("[role=flash]");
    const $flashIcon = this.$("[role=flash-icon]");
    $flash.removeClass("alert-success");
    $flash.addClass("alert-danger");
    $flashIcon.removeClass("sg-icon-check-circle");
    $flashIcon.addClass("sg-icon-warning");
    $flash.data("role", "alert-failure"); // Automated tests need this data-role to be set. https://issues.corp.twilio.com/browse/MAKO-5867
  },

  prepareSuccessFlash() {
    const $flash = this.$("[role=flash]");
    const $flashIcon = this.$("[role=flash-icon]");
    $flash.removeClass("alert-danger");
    $flash.addClass("alert-success");
    $flashIcon.removeClass("sg-icon-warning");
    $flashIcon.addClass("sg-icon-check-circle");
    $flash.data("role", "alert-success"); // Automated tests need this data-role to be set. https://issues.corp.twilio.com/browse/MAKO-5867
  },

  renderFlash(message, error, timeout, shouldRenderHtml) {
    const $flash = this.$("[role=flash]");
    const $flashMessage = this.$("[role=flash] span");

    if (message && !error) {
      this.prepareSuccessFlash();
    } else {
      this.prepareDangerFlash();
    }

    const messageDisplay =
      message || "Sorry, an error occurred. Engineers have been notified.";
    if (shouldRenderHtml) {
      $flashMessage.html(messageDisplay);
    } else {
      $flashMessage.text(messageDisplay);
    }

    $flash.fadeIn();

    if (timeout) {
      setTimeout(() => {
        $flash.fadeOut();
      }, timeout);
    }
  },

  showBanner(view, type) {
    this.bannerContent.show(view);

    // explicitly set class to clear hidden class and any
    // existing banner-* classes
    this.ui.banner.attr("class", `banner banner-${type}`);
  },

  clearBanner() {
    this.ui.banner.addClass("hidden");
  },
});
