import { AuthedHttp } from '../../helpers/AuthedHttp';
import { Routes } from './routes';
import { UpdatedPlan, UpdatePlanType } from '../types/updatedPlan';
export interface ChangePlanDto {
  package_id: string;
  reason?: string;
}

const successStatusCode = 204;
export const freePlanName = 'Free 100';

export const updatePlan = (
  updatePlanReqBody: UpdatePlanType
): Promise<UpdatedPlan> => {
  const { packageId, reason, packageName } = updatePlanReqBody;
  const requestBody = {
    package_id: packageId,
    reason: reason || '',
  } as ChangePlanDto;
  const response = {} as UpdatedPlan;
  return AuthedHttp.put<any>(Routes.UpdatePlan, requestBody)
    .then((res) => {
      if (res.status === successStatusCode) {
        response.isUpdated = true;
        response.errors = [];

        return Promise.resolve(response);
      }
      // this happens when we get into an error state
      return res.json();
    })
    .catch((err) => {
      console.warn('Error updating plan:', err);
      if (packageName === freePlanName) {
        return { errors: [{ field: freePlanName }] };
      }
      return { errors: [{}] };
    });
};
