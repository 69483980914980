import { Plan } from '../types/plan';
import { TypedMap } from '../../../src/@types/TypedMap';
import moment from 'moment';

type VersionMap = TypedMap<number>;
type PlanMap = TypedMap<Plan>;

export const getLatestPlans = (plans: Plan[]) => {
  // Example plan id format: sg.ei.free100.v2
  // Find the .v followed by one or more digits
  // at the end of the string, case insensitive
  const regex = /\.v(\d+)$/i;
  const latestVersions: VersionMap = {};
  const latestPlans: PlanMap = {};
  plans
    .filter((plan) => moment(plan.startDate) <= moment().utc())
    .forEach((plan) => {
      if (!plan.isActive || !plan.isSelfServe) {
        return;
      }
      const match = regex.exec(plan.id);
      if (!match) {
        return;
      }
      const [, versionString] = match;
      const version = Number.parseInt(versionString, 10);
      const planKey = plan.id.slice(0, match.index);
      const latestVersion = latestVersions[planKey];
      if (!latestVersion || version > latestVersion) {
        latestVersions[planKey] = version;
        latestPlans[planKey] = plan;
      }
    });
  return Object.values(latestPlans);
};
