import { GlobalStatsDto } from '../types';
import {
  GlobalMetricNames,
  DateToGlobalMetric,
  calculateMetricRate,
} from './helpers';

export interface GlobalMetricRateByDate {
  MetricCountByDate: DateToGlobalMetric;
  DivisorMetricCountByDate: DateToGlobalMetric;
  MetricRateByDate: DateToGlobalMetric;
  Overall: {
    MetricCount: number;
    DivisorMetricCount: number;
    MetricRate: number;
  };
  Dates: string[];
}

/*
  Calculate the overall metric rate for a list of metric names (summed together) by date

  Usage: calculateGlobalMetricRateByDate(["bounces"], "processed", globalStatsDto); for bounce rate by date
  Output:
  {
    MetricRateByDate: {
      "2021-02-01": 10,
      ...
    },
    MetricCountByDate: {
      "2021-02-01": 10,
      ...
    },
    DivisorMetricCountByDate: {
      "2021-02-01":, 100,
      ...
    },
    Overall: {
      MetricCount: 10,
      DivisorMetricCount: 100,
      MetricRate: 10,
    },
    Dates: ["2021-02-01", ...]
  }

  calculateGlobalMetricRateByDate["bounces", "blocks"], "processed", globalStatsDto); for sum of bounce and block rate by date
  Output:
   {
    MetricRateByDate: {
      "2021-02-01": 20,
      ...
    },
    MetricCountByDate: {
      "2021-02-01": 20,
      ...
    },
    DivisorMetricCountByDate: {
      "2021-02-01":, 100,
      ...
    },
    Overall: {
      MetricCount: 20,
      DivisorMetricCount: 100,
      MetricRate: 20,
    },
    Dates: ["2021-02-01", ...]
  }
*/
export function calculateGlobalMetricRateByDate(
  metricNames: GlobalMetricNames[],
  divisorName: GlobalMetricNames,
  stats: GlobalStatsDto
): GlobalMetricRateByDate {
  const MetricCountByDate: DateToGlobalMetric = {};
  const DivisorMetricCountByDate: DateToGlobalMetric = {};
  const MetricRateByDate: DateToGlobalMetric = {};
  let OverallMetricCount = 0;
  let OverallDivisorMetricCount = 0;
  let OverallMetricRate = 0;
  const Dates: string[] = [];

  // Calculate the metric rate for each date and sum up the overall counts
  stats.forEach((byDate) => {
    Dates.push(byDate.date);

    byDate.stats.forEach((metricsByDate) => {
      let metricCountByDate = 0;
      const divisorMetricCountByDate = metricsByDate.metrics[divisorName];
      // We loop through the list of metrics to aggregate together i.e. ["bounces"] or ["bounces", "blocks"]
      metricNames.forEach((metricName) => {
        const metricCount = metricsByDate.metrics[metricName];
        metricCountByDate = metricCountByDate + metricCount;
      });

      MetricCountByDate[byDate.date] = metricCountByDate;
      DivisorMetricCountByDate[byDate.date] = divisorMetricCountByDate;
      MetricRateByDate[byDate.date] = calculateMetricRate(
        metricCountByDate,
        divisorMetricCountByDate
      );
      OverallMetricCount = OverallMetricCount + metricCountByDate;
      OverallDivisorMetricCount =
        OverallDivisorMetricCount + divisorMetricCountByDate;
    });
  });

  OverallMetricRate = calculateMetricRate(
    OverallMetricCount,
    OverallDivisorMetricCount
  );

  return {
    MetricCountByDate,
    DivisorMetricCountByDate,
    MetricRateByDate,
    Overall: {
      MetricCount: OverallMetricCount,
      DivisorMetricCount: OverallDivisorMetricCount,
      MetricRate: OverallMetricRate,
    },
    Dates,
  };
}
