import { AuthedHttp } from '../../helpers/AuthedHttp';
import { Routes } from './routes';
import { RemoveExpertInsightsDto } from '../dtos';
import { RemoveExpertInsightsRequest } from '../types/removeExpertInsights';
import { ResponseError } from '../types/responseError';

export function removeExpertInsights({
  subscriberId: subscriber_id,
}: RemoveExpertInsightsRequest = {}): Promise<RemoveExpertInsightsDto> {
  const removeExpertInsightsRequestBody = subscriber_id
    ? { subscriber_id }
    : null;

  return AuthedHttp.del<RemoveExpertInsightsDto>(
    `${Routes.ExpertInsights}`,
    removeExpertInsightsRequestBody
  )
    .then((res) => {
      if (res.ok) {
        return {};
      }
      return res.json();
    })
    .catch(() => {
      return {
        errors: [
          {
            message: 'Failed to remove expert insights due to some reason!',
          },
        ] as ResponseError[],
      };
    });
}
