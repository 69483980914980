// Analytics based on https://docs.google.com/spreadsheets/d/1ZtyOAUNJh6h_JscqICy4aqvcjlJglmphnkQFNkk8HtQ/edit?ts=5f51373a#gid=1870961829
import { ElementTypes, EventTypes } from '../__shared__';
import { Pages, Sections, Subsections } from './constants';
import { getPageName } from './utils';

export const AnalyticsEvents = {
  filterDropdown: ({ page = '', section = '' }) => ({
    event: 'filter dropdown toggled',
    properties: {
      page,
      section,
      elementType: ElementTypes.DropdownToggle,
      eventType: EventTypes.Click,
    },
  }),

  filterDropdownItem: ({ page = '', section = '' }) => ({
    event: 'filter dropdown item clicked',
    properties: {
      page,
      section,
      elementType: ElementTypes.ListItem,
      eventType: EventTypes.Click,
    },
  }),

  dateRangeCalendarButton: ({ page = '', section = '' }) => ({
    event: 'date range calendar toggled',
    properties: {
      page,
      section,
      elementType: ElementTypes.Button,
      eventType: EventTypes.Click,
    },
  }),

  aggregateByButton: ({ page = '', section = '', type = '' }) => ({
    event: `aggregate statistics by ${type} button clicked`,
    properties: {
      page,
      section,
      elementType: ElementTypes.Button,
      eventType: EventTypes.Click,
    },
  }),

  numberPercentToggle: ({ section = '', type = '' }) => {
    const formattedSection = section.toLowerCase();
    const page = getPageName();
    return {
      event: `${type} button toggled`,
      properties: {
        page,
        section: formattedSection,
        elementType: ElementTypes.Button,
        eventType: EventTypes.Click,
      },
    };
  },

  csvExportButton: ({ page = '', section = '' }) => ({
    event: 'csv exported',
    properties: {
      page,
      section,
      elementType: ElementTypes.Button,
      eventType: EventTypes.Click,
    },
  }),

  statsSearchInputToggleButton: ({ page = '', section = '', name = '' }) => ({
    event: `${name} statistics search input toggled`,
    properties: {
      page,
      section,
      elementType: ElementTypes.DropdownToggle,
      eventType: EventTypes.Click,
    },
  }),

  statsSearchInput: ({ page = '', section = '', name = '' }) => ({
    event: `${name} searched`,
    properties: {
      page,
      section,
      subsection: Subsections.Search,
      elementType: ElementTypes.Input,
      eventType: EventTypes.Blur,
    },
  }),

  addToComparisonButton: ({ page = '', section = '', name = '' }) => ({
    event: `${name} comparison added`,
    properties: {
      page,
      section,
      subsection: `search ${name}`,
      elementType: ElementTypes.ListItem,
      eventType: EventTypes.Click,
    },
  }),

  statsComparisonResetButton: ({ page = '', section = '', name = '' }) => ({
    event: `selected ${name} reset`,
    properties: {
      page,
      section,
      subsection: Subsections.Selected,
      elementType: ElementTypes.Button,
      eventType: EventTypes.Click,
    },
  }),

  removeFromComparisonButton: ({ page = '', section = '', name = '' }) => ({
    event: `${name} comparison removed`,
    properties: {
      page,
      section,
      subsection: Subsections.Selected,
      elementType: ElementTypes.Button,
      eventType: EventTypes.Click,
    },
  }),

  compareStatsButton: ({ page = '', section = '', name = '' }) => ({
    event: `${name} compared`,
    properties: {
      page,
      section,
      elementType: ElementTypes.Button,
      eventType: EventTypes.Click,
    },
  }),

  nameFilterDropdown: ({ page = '', section = '', name = '' }) => ({
    event: `${name} filter toggled`,
    properties: {
      page,
      section,
      elementType: ElementTypes.DropdownToggle,
      eventType: EventTypes.Click,
    },
  }),

  nameFilterDropdownItem: ({ page = '', section = '', name = '' }) => ({
    event: `${name} filter item clicked`,
    properties: {
      page,
      section,
      elementType: ElementTypes.ListItem,
      eventType: EventTypes.Click,
    },
  }),

  tableHeaderDropdown: ({ section = '', name = '' }) => {
    const formattedSection = section.toLowerCase();
    const formattedName = name.toLowerCase();
    return {
      event: `${formattedName} dropdown toggled`,
      properties: {
        page: getPageName(),
        section: formattedSection,
        elementType: ElementTypes.DropdownToggle,
        eventType: EventTypes.Click,
      },
    };
  },

  metricDropdown: ({ page = '', section = '' }) => ({
    event: 'metrics filter toggled',
    properties: {
      page,
      section,
      elementType: ElementTypes.DropdownToggle,
      eventType: EventTypes.Click,
    },
  }),

  metricSelect: ({ page = '', section = '' }) => ({
    event: 'metrics filter item clicked',
    properties: {
      page,
      section,
      elementType: ElementTypes.ListItem,
      eventType: EventTypes.Click,
    },
  }),

  deviceClientDropdownToggle: {
    event: 'top email clients filter toggled',
    properties: {
      page: Pages.StatisticsDevice,
      section: Sections.EmailClientsDevices,
      elementType: ElementTypes.DropdownToggle,
      eventType: EventTypes.Click,
    },
  },

  selectBrowserDropdownToggle: ({ page = '', section = '' }) => ({
    event: 'browser select button toggled',
    properties: {
      page,
      section,
      elementType: ElementTypes.DropdownToggle,
      eventType: EventTypes.Click,
    },
  }),

  selectBrowserDropdownItem: ({ page = '', section = '' }) => ({
    event: 'single browser selected',
    properties: {
      page,
      section,
      elementType: ElementTypes.ListItem,
      eventType: EventTypes.Click,
    },
  }),

  viewParseDocsButton: {
    event: 'view parse docs button clicked',
    properties: {
      page: Pages.StatisticsParseWebhook,
      section: Sections.ParseWebhookRequests,
      subsection: Subsections.GettingStartedWith,
      elementType: ElementTypes.Button,
      eventType: EventTypes.Click,
    },
  },
};
