import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';
import { SubuserDto, SubusersDto } from '../dtos';
import { ResponseError } from '../types/responseError';
import { ActionTypes } from './actionTypes';

const initialState: MakoStateType['subusers'] = {
  subusers: [],
  errors: [],
};

interface SubusersAction extends Action {
  payload: SubusersDto;
}

export const subusers = (state = initialState, action: SubusersAction) => {
  switch (action.type) {
    case ActionTypes.GetSubusersSuccess:
      return {
        subusers: action.payload as SubuserDto[],
        errors: '',
      };
    case ActionTypes.GetSubusersFailure:
      const payload = action.payload as { errors: ResponseError[] };
      const errors = (payload.errors && payload.errors[0].message) || '';
      return {
        subusers: [],
        errors,
      };
    default:
      return state;
  }
};
