import { BillableContactsDto } from '../dtos';
import { AuthedHttp, ResponseBodySuccess } from '../../helpers/AuthedHttp';
import { Routes } from './routes';


export function getBillableContactsCount(): Promise<BillableContactsDto> {
  return AuthedHttp.get<BillableContactsDto>(
    Routes.BillableCount
  ).then((res: ResponseBodySuccess<BillableContactsDto>) => res.json());
}
