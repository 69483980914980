import moment from 'moment';
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseError } from '../../../../../helperTypes/responseError';
import { EmailInsightsDeliverabilityRoutes } from '../../apiRoutes';
import { AuthedHttp } from '../../../../../helpers/AuthedHttp';
import {
  SendGridRootState,
  SendGridAppDispatch,
} from '../../../../../state/store/sendgridAppStore';
import { GlobalStatsDto } from '../types';
import { calculateGlobalMetricCount } from '../statCalculators/calculateGlobalMetricCount';
import {
  calculateGlobalMetricCountByDate,
  GlobalMetricCountByDate,
} from '../statCalculators/calculateGlobalMetricCountByDate';
import {
  calculateGlobalMetricRate,
  GlobalMetricRate,
} from '../statCalculators/calculateGlobalMetricRate';
import {
  calculateGlobalMetricRateByDate,
  GlobalMetricRateByDate,
} from '../statCalculators/calculateGlobalMetricRateByDate';
import {
  isWithin6Months,
  isWithin13MonthCap,
  roundPercentDifference,
} from '../statCalculators/helpers';
import { StatsAggregationOptions } from '../../../components/StatsAggregationSelect';

interface AdaptedDeliverabilityMetrics {
  processedTotal: number;
  deliveredMetrics: GlobalMetricRate;
  bouncedAndBlockedMetrics: GlobalMetricRate;
  uniqueOpensMetrics: GlobalMetricRate;
}

interface DeliverabilityMetricsPercentDifferences {
  processedPercentDifference: number;
  deliveredPercentDifference: number;
  bouncedAndBlockedPercentDifference: number;
  uniqueOpensPercentDifference: number;
}

export type DeliverabilityMetrics = {
  metrics: AdaptedDeliverabilityMetrics;
  comparisonPercentDifferences: DeliverabilityMetricsPercentDifferences | null;
} | null;

export type TrendingMetrics = {
  deliveredTotal: GlobalMetricCountByDate;
  bouncedAndBlockedPercent: GlobalMetricRateByDate;
  uniqueOpenedPercent: GlobalMetricRateByDate;
} | null;

export interface FetchedOverviewStatsState {
  deliverabilityMetrics: DeliverabilityMetrics;
  trendingMetrics: TrendingMetrics;
}

interface OverviewStatsState {
  deliverabilityMetrics: DeliverabilityMetrics | null;
  trendingMetrics: TrendingMetrics | null;
  isLoading: boolean;
  isError: boolean;
}

interface OverviewStatsArgs {
  startDate: string;
  endDate?: string;
  subuser?: string;
  isCustomDateRange?: boolean;
  aggregatedBy?: StatsAggregationOptions;
}

const initialState: OverviewStatsState = {
  deliverabilityMetrics: null,
  trendingMetrics: null,
  isLoading: true,
  isError: false,
};

export const deliverabilityMetricsAdapter = (
  globalStatsDto: GlobalStatsDto
): AdaptedDeliverabilityMetrics => {
  const processedTotal = calculateGlobalMetricCount(
    ['processed'],
    globalStatsDto
  );
  const deliveredMetrics = calculateGlobalMetricRate(
    ['delivered'],
    'processed',
    globalStatsDto
  );
  const bouncedAndBlockedMetrics = calculateGlobalMetricRate(
    ['bounces', 'blocks'],
    'processed',
    globalStatsDto
  );
  const uniqueOpensMetrics = calculateGlobalMetricRate(
    ['unique_opens'],
    'delivered',
    globalStatsDto
  );

  const deliverabilityMetrics = {
    processedTotal,
    deliveredMetrics,
    bouncedAndBlockedMetrics,
    uniqueOpensMetrics,
  };

  return deliverabilityMetrics;
};

export const calculatePercentDifferences = (
  currentDeliverabilityMetrics: AdaptedDeliverabilityMetrics,
  comparisonDeliverabilityMetrics: AdaptedDeliverabilityMetrics
): DeliverabilityMetricsPercentDifferences => {
  const processedPercentDifference = comparisonDeliverabilityMetrics.processedTotal
    ? ((currentDeliverabilityMetrics.processedTotal -
        comparisonDeliverabilityMetrics.processedTotal) /
        comparisonDeliverabilityMetrics.processedTotal) *
      100
    : 0;
  const deliveredPercentDifference =
    currentDeliverabilityMetrics.deliveredMetrics.MetricRate -
    comparisonDeliverabilityMetrics.deliveredMetrics.MetricRate;
  const bouncedAndBlockedPercentDifference =
    currentDeliverabilityMetrics.bouncedAndBlockedMetrics.MetricRate -
    comparisonDeliverabilityMetrics.bouncedAndBlockedMetrics.MetricRate;
  const uniqueOpensPercentDifference =
    currentDeliverabilityMetrics.uniqueOpensMetrics.MetricRate -
    comparisonDeliverabilityMetrics.uniqueOpensMetrics.MetricRate;

  return {
    processedPercentDifference: roundPercentDifference(
      processedPercentDifference
    ),
    deliveredPercentDifference: roundPercentDifference(
      deliveredPercentDifference
    ),
    bouncedAndBlockedPercentDifference: roundPercentDifference(
      bouncedAndBlockedPercentDifference
    ),
    uniqueOpensPercentDifference: roundPercentDifference(
      uniqueOpensPercentDifference
    ),
  };
};

export const trendingMetricsAdapter = (
  globalStatsDto: GlobalStatsDto
): TrendingMetrics => {
  const deliveredTotal = calculateGlobalMetricCountByDate(
    ['delivered'],
    globalStatsDto
  );
  const bouncedAndBlockedPercent = calculateGlobalMetricRateByDate(
    ['bounces', 'blocks'],
    'processed',
    globalStatsDto
  );
  const uniqueOpenedPercent = calculateGlobalMetricRateByDate(
    ['unique_opens'],
    'delivered',
    globalStatsDto
  );

  return {
    deliveredTotal,
    bouncedAndBlockedPercent,
    uniqueOpenedPercent,
  };
};

export const fetchOverviewStats = createAsyncThunk<
  FetchedOverviewStatsState,
  OverviewStatsArgs,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('FETCH_OVERVIEW_STATS', async (args, thunkApi) => {
  try {
    const { startDate, endDate, subuser, aggregatedBy } = args;
    const globalStatsResponse = await AuthedHttp.get<GlobalStatsDto>(
      EmailInsightsDeliverabilityRoutes.getGlobalStats({
        startDate,
        endDate,
        aggregatedBy,
      }),
      subuser
        ? ({ headers: { 'On-behalf-of': decodeURIComponent(subuser) } } as any)
        : undefined
    );

    if (globalStatsResponse.ok) {
      const globalStatsDto = await globalStatsResponse.json();
      const adaptedDeliverabilityMetrics = deliverabilityMetricsAdapter(
        globalStatsDto
      );
      const adaptedTrendingMetrics = trendingMetricsAdapter(globalStatsDto);

      const dateRangeDiff = moment(endDate).diff(moment(startDate), 'days');
      const comparisonEndDate = moment(startDate)
        .subtract(1, 'day')
        .format('YYYY-MM-DD');
      const comparisonStartDate = moment(comparisonEndDate)
        .subtract(dateRangeDiff, 'days')
        .format('YYYY-MM-DD');

      const within6Months = isWithin6Months({
        startDate,
        endDate,
      });
      const within13MonthCap = isWithin13MonthCap(moment(comparisonStartDate));
      // hide comparison if custom date range > 6 months or if comparison start date is past the 13 month cap
      if (!within6Months || !within13MonthCap) {
        return {
          deliverabilityMetrics: {
            metrics: adaptedDeliverabilityMetrics,
            comparisonPercentDifferences: null,
          },
          trendingMetrics: adaptedTrendingMetrics,
        };
      }

      // If within 6 months, we will calculate the percent differences in metrics
      // We will look at the date ranges selected to compare week by week or month by month, etc...
      const comparisonGlobalStatsResponse = await AuthedHttp.get<
        GlobalStatsDto
      >(
        EmailInsightsDeliverabilityRoutes.getGlobalStats({
          startDate: comparisonStartDate,
          endDate: comparisonEndDate,
        }),
        subuser
          ? ({
              headers: { 'On-behalf-of': decodeURIComponent(subuser) },
            } as any)
          : undefined
      );

      if (comparisonGlobalStatsResponse.ok) {
        const comparisonGlobalStatsDto = await comparisonGlobalStatsResponse.json();
        const adaptedcomparisonDeliverabilityMetrics = deliverabilityMetricsAdapter(
          comparisonGlobalStatsDto
        );

        const deliverabilityMetricsPercentDifferences = calculatePercentDifferences(
          adaptedDeliverabilityMetrics,
          adaptedcomparisonDeliverabilityMetrics
        );

        return {
          deliverabilityMetrics: {
            metrics: adaptedDeliverabilityMetrics,
            comparisonPercentDifferences: deliverabilityMetricsPercentDifferences,
          },
          trendingMetrics: adaptedTrendingMetrics,
        };
      }
    }
    throw new Error('Unable to get global stats');
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: 'Unable to get global stats',
    });
  }
});

const overviewStatsSlice = createSlice({
  name: 'overviewStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOverviewStats.pending, (state, action) => {
      state.isLoading = true;
      state.deliverabilityMetrics = null;
      state.trendingMetrics = null;
    });
    builder.addCase(fetchOverviewStats.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.deliverabilityMetrics = action.payload.deliverabilityMetrics;
      state.trendingMetrics = action.payload.trendingMetrics;
    });
    builder.addCase(fetchOverviewStats.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.deliverabilityMetrics = null;
      state.trendingMetrics = null;
    });
  },
});

export { overviewStatsSlice };
