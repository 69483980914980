import { EntitlementsDto } from './../../services/oes/dtos';
import { AccountOfferingDto, OfferingDto } from '../../services/oes/dtos';
import {
  UsageStatsDto,
  MarketingCampaignsSignupFormDto,
  MarketingCampaignsAutomationDto,
  MarketingCampaignsContactsCountDto,
  AutomationStatusDto,
  SubuserDto,
} from '../../services/mako_api/dtos';
import { MarketingCampaignsSubscription } from '../../../types/marketingCampaignsSubscription';
import { getMarketingCampaignsPlan } from '../../common/adapters';

// MC backend can't provide perfect contact count accuracy, so we give the
// customer a hidden buffer of "extra" contacts before considering them
// over the limit for downgrade logic.
const CONTACTS_OVERAGE_BUFFER = 500;

interface MapMarketingCampaignsSubscriptionData {
  currentOfferings: AccountOfferingDto[];
  futureOfferings: AccountOfferingDto[];
  usageStats: UsageStatsDto;
  entitlements: EntitlementsDto;
  marketingCampaignsContactsCount: MarketingCampaignsContactsCountDto;
  marketingCampaignsSignupForms: MarketingCampaignsSignupFormDto[];
  marketingCampaignsAutomations: MarketingCampaignsAutomationDto[];
  subusers: SubuserDto[];
  defaultMCPlan: OfferingDto;
}

export function mapMarketingCampaignsSubscription({
  currentOfferings,
  futureOfferings,
  usageStats,
  marketingCampaignsContactsCount,
  marketingCampaignsSignupForms,
  marketingCampaignsAutomations,
  entitlements,
  subusers,
  defaultMCPlan,
}: MapMarketingCampaignsSubscriptionData): MarketingCampaignsSubscription {
  const currentPlan = getMarketingCampaignsPlan(currentOfferings);
  // the only time we have a future dated plan is during a downgrade
  const downgradePlan = getMarketingCampaignsPlan(futureOfferings);

  // OES Should always return a MC plan
  // but if one doesn't exist, show free plan
  if (!currentPlan) {
    return {
      planId: defaultMCPlan.name,
      emailSendingCreditsUsed: 0,
      contactsUsed: 0,
      testingCreditsUsed: 0,
      signupFormsUsed: 0,
      automationCount: 0,
    } as MarketingCampaignsSubscription;
  }

  const automationCount = marketingCampaignsAutomations.filter(
    (automation) => automation.status === AutomationStatusDto.Live
  ).length;

  let contactsUsedBySubusers: Record<string, number> = {};

  if (
    marketingCampaignsContactsCount.billable_breakdown?.breakdown !== undefined
  ) {
    contactsUsedBySubusers = subusers.reduce((acc, obj) => {
      const { id, username } = obj;
      const foundContactCount =
        marketingCampaignsContactsCount.billable_breakdown?.breakdown[id];

      if (foundContactCount === undefined) {
        return acc;
      }

      acc[username] = foundContactCount;
      return acc;
    }, {} as Record<string, number>);
  }

  const totalContactsUsed =
    marketingCampaignsContactsCount.billable_breakdown === undefined
      ? marketingCampaignsContactsCount.billable_count
      : marketingCampaignsContactsCount.billable_count +
        marketingCampaignsContactsCount.billable_breakdown?.total;

  return {
    contactsOverageBuffer: CONTACTS_OVERAGE_BUFFER,
    planId: currentPlan.name,
    downgradePlanId: downgradePlan && downgradePlan.name,
    emailSendingCreditsUsed: usageStats.marketing_campaign_sends,
    contactsUsed: totalContactsUsed,
    contactsUsedByParent: marketingCampaignsContactsCount.billable_count,
    contactsUsedBySubusers,
    testingCreditsUsed: 0,
    signupFormsUsed: marketingCampaignsSignupForms.length,
    automationCount,
    hasAdsCanPublish: entitlements.ads_can_publish || false,
  } as MarketingCampaignsSubscription;
}
