import {
  AccountOfferingDto,
  EntitlementsDto,
  OfferingDto,
  PriceNamesDto,
} from '../../services/oes/dtos';
import { TestingCreditsSubscription } from '../../../testing_credits_subscription/types';
import { ProductId } from '../../../types/productId';
import { getPriceByName } from '../../common/adapters';

const TESTING_CREDIT_ADDON_PREFIX = 'sg.x.email-testing';

export function getTestingCreditsAddons(
  offerings: AccountOfferingDto[]
): AccountOfferingDto | undefined {
  return offerings.find((x) =>
    x.name.toLowerCase().includes(TESTING_CREDIT_ADDON_PREFIX)
  );
}

export function mapTestingCreditsSubscription(
  currentOfferings: AccountOfferingDto[],
  futureOfferings: AccountOfferingDto[],
  offerings: OfferingDto[],
  currentEntitlements: EntitlementsDto,
  futureEndingOfferings: AccountOfferingDto[]
): TestingCreditsSubscription {
  const futureTestCreditsAddons = getTestingCreditsAddons(futureOfferings);
  const currentTestCreditsAddons = getTestingCreditsAddons(currentOfferings);

  const currentTestCreditsOffering =
    currentTestCreditsAddons &&
    offerings.find(
      (offering) => offering.name === currentTestCreditsAddons.name
    );

  const isDowngrading = !!futureEndingOfferings.find((feo) => {
    if (currentTestCreditsOffering) {
      return (
        currentTestCreditsOffering.name.toLowerCase() === feo.name.toLowerCase()
      );
    }
    return false;
  });

  const futureTestCreditsOffering =
    futureTestCreditsAddons &&
    offerings.find(
      (offering) =>
        offering.name.toLowerCase() ===
        futureTestCreditsAddons.name.toLowerCase()
    );

  const totalCount = currentEntitlements.email_testing_cap_monthly || 0;

  let downgradeCount = 0;
  if (
    isDowngrading &&
    futureTestCreditsOffering &&
    futureTestCreditsOffering.entitlements.email_testing_cap_monthly
  ) {
    downgradeCount =
      futureTestCreditsOffering.entitlements.email_testing_cap_monthly;
  }

  let downgradeId = '' as string;
  if (
    isDowngrading &&
    futureTestCreditsOffering &&
    futureTestCreditsOffering.name
  ) {
    downgradeId = futureTestCreditsOffering.name as string;
  }

  let purchasedCount = 0;
  if (
    currentTestCreditsOffering &&
    currentTestCreditsOffering.entitlements.email_testing_cap_monthly
  ) {
    purchasedCount =
      currentTestCreditsOffering.entitlements.email_testing_cap_monthly;
  }

  const testingCreditsOffering =
    currentTestCreditsAddons &&
    offerings.find(
      (offering) => offering.name === currentTestCreditsAddons.name
    );

  let name = ProductId.SG_X_EMAIL_TESTING_0_V1 as string;
  if (testingCreditsOffering && testingCreditsOffering.name) {
    name = testingCreditsOffering.name as string;
  }

  const price = testingCreditsOffering
    ? getPriceByName(testingCreditsOffering.pricing, PriceNamesDto.TC_BASE)
    : 0;

  const canBePurchased =
    currentEntitlements.can_have_email_testing_addon || false;

  return {
    canBePurchased,
    isDowngrading,
    freeCount: totalCount - purchasedCount,
    downgradeCount,
    downgradeId,
    purchasedCount,
    totalCount,
    price,
    name,
  };
}
