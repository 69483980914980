import {
  createAsyncThunk,
  createSlice,
  createSelector,
} from '@reduxjs/toolkit';
import { SendGridAppDispatch, SendGridRootState } from './sendgridAppStore';
import { ResponseError } from '../types/responseError';
import { ResponseBody } from '../../helpers/AuthedHttp';
import { GenericNetworkSlice, NetworkState } from './sliceNetworkTypes';

export interface FeatureToggleDto {
  feature_name: string;
  app_name: string;
}

export type FeatureToggleStore = GenericNetworkSlice<FeatureToggleData>;

export interface FeatureToggleData {
  featureName: FeatureToggleDto[];
  adaptedFeatureNames: string[];
}

export const selectHasFeatureToggle = (
  state: SendGridRootState,
  featureName: string
) => {
  return createSelector(
    (state: SendGridRootState) => state.featureToggles,
    (featureToggles) => {
      return (
        featureToggles.networkState === NetworkState.Success &&
        featureToggles.data.adaptedFeatureNames.includes(featureName)
      );
    }
  )(state);
};

const initialFeatureToggleState = {
  networkState: NetworkState.Unrequested,
} as FeatureToggleStore;

export const getFeatureTogglesFromTiara = createAsyncThunk<
  FeatureToggleDto[],
  ResponseBody<FeatureToggleDto[]>,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('GET_FEATURE_TOGGLES_FROM_TIARA', async (args, thunkApi) => {
  try {
    const featureToggleResp = await args;

    if (featureToggleResp.ok) {
      const response = await featureToggleResp.json();

      return response;
    }

    throw new Error('Network Response be Janky');
  } catch {
    return thunkApi.rejectWithValue({
      message: 'Network Level Error',
    } as ResponseError);
  }
});

export const featureToggleSlice = createSlice({
  name: 'featureToggle',
  initialState: initialFeatureToggleState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getFeatureTogglesFromTiara.pending, (state, action) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(getFeatureTogglesFromTiara.fulfilled, (state, action) => {
      state.networkState = NetworkState.Success;

      if (state.networkState === NetworkState.Success) {
        state.data = {
          featureName: action.payload,
          adaptedFeatureNames: action.payload.map(
            (featureToggle) => featureToggle.feature_name
          ),
        };
      }
    });
    builder.addCase(getFeatureTogglesFromTiara.rejected, (state, action) => {
      state.networkState = NetworkState.Error;

      if (state.networkState === NetworkState.Error) {
        state.errorMessage = action?.payload?.message ?? 'Unknown Error';
      }
    });
  },
});
