import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { EmailApiSubscription } from '../types/emailApiSubscription';

export const Actions = {
  getEmailApiSubscriptionSuccess: (
    emailApiSubscription: EmailApiSubscription
  ) =>
    createAction(
      ActionTypes.GetEmailApiSubscriptionSuccess,
      emailApiSubscription
    ),
  getEmailApiSubscriptionFailure: () =>
    createAction(ActionTypes.GetEmailApiSubscriptionFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
