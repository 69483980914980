import { createApi } from '@reduxjs/toolkit/query/react';
import { authedBaseQuery } from './authedBaseQuery';
import {
  defaultSerializeQueryArgs,
  cleanQueryArgs,
  dataInjectionKey,
} from './utils';
import { Tags } from './Tags';

const reducerPath = 'rtkQuerySlice';

export const api = createApi({
  reducerPath,
  baseQuery: authedBaseQuery,
  // If you'd like to use a cache tag, please add it to the enum in ./Tags.ts
  // It will be automatically registered here
  tagTypes: [...Object.values(Tags)],
  endpoints: () => ({
    // Define (related) endpoints in a separate file using api.injectEndpoints.
    // Place these files in ./endpoints if they consume tiara and/or are used
    // by more than one page. Otherwise place it near the usage (i.e. in the
    // page folder)
    // See: userEmail.ts for an example of an endpoint being hydrated w/ tiara data
  }),
  serializeQueryArgs: ({ endpointName, queryArgs }) => {
    // Remove the dataInjectionKey argument from queryArgs so it's omitted from the cache key
    const cleanedQueryArgs = cleanQueryArgs(queryArgs, dataInjectionKey);
    return defaultSerializeQueryArgs({
      endpointName,
      queryArgs: cleanedQueryArgs,
    });
  },
});
