import { Action } from '../types/action';
import { ActionTypes } from './actionTypes';
import { scopesAdapter } from './adapters';
import { ScopesReduxState } from './../types/scopes';

export interface SetScopesAction extends Action {
  type: ActionTypes.SetScopes;
  payload: ScopesReduxState;
}

export const setScopes = (scopes: string[]): SetScopesAction => {
  /**
   * - because we have scopes data on page load (from Tiara)
   * - we don't need an epic/service layer for scopes
   * - therefore, we can pass scopes directly to our adapter to shape the data
   *   for Redux
   */
  return {
    type: ActionTypes.SetScopes,
    payload: scopesAdapter(scopes),
  };
};
