import { AuthedHttp } from '../../helpers/AuthedHttp';
import { UserPackageDto } from '../dtos';

const getUserPackageRoute = 'user/package';

export function getUserPackage(): Promise<
  UserPackageDto | Pick<UserPackageDto, 'errors'>
> {
  return AuthedHttp.get<UserPackageDto>(getUserPackageRoute)
    .then((response) => {
      if (response.ok) {
        return response.json();
      }
      throw new Error('Network error');
    })
    .catch((err) => {
      return {
        errors: [
          {
            field: 'user_package',
            message:
              'An error occurred attempting to get your user package. Please refresh the page.',
          },
        ],
      } as Pick<UserPackageDto, 'errors'>;
    });
}
