import React, { useCallback, useEffect, useState } from 'react';
import { Box } from '@twilio-paste/core/box';
import { Badge } from '@twilio-paste/core/badge';
import words from 'src/components/Navbar/en';
import { AccountAndPlanUtils } from 'src/pages/AccountAndBilling/utils';
import { Anchor } from '@twilio-paste/anchor';
import {
  AccountSwitcher,
  AccountSwitcherBadge,
  AccountSwitcherItem,
  useAccountSwitcherState,
} from '@twilio-paste/core';
import { ArrowBackIcon } from '@twilio-paste/icons/esm/ArrowBackIcon';
import { Truncate } from '@twilio-paste/truncate';
import Cookies from 'js-cookie';
import { IMPERSONATING_USERNAME_COOKIE } from 'src/constants';
import getSubusers, {
  SubuserDto,
} from 'src/pages/EmailInsights/Deliverability/services/getSubusers';
import { useAppSelector } from 'src/state/store/sendgridAppStore';
import { NetworkState } from 'src/state/store/sliceNetworkTypes';

const APIConfig = require('apiconfig');

export interface AccountSwitcherProps {
  friendlyName: string;
  isSubuser: boolean;
  isTrial: boolean;
  accountsHomeLink: string;
  allSubusersLink: string;
}

const AccountSwitcherWrapper: React.FC<AccountSwitcherProps> = ({
  friendlyName,
  isSubuser,
  isTrial,
  accountsHomeLink,
  allSubusersLink,
}) => {
  const [subusers, setSubusers] = useState<SubuserDto[]>([]);
  const { subuserLabel, trialLabel, upgradeLabel } = words.accountTypeBadge;
  const {
    viewSubusersItemLabel,
    goBackToMainAccountItemLabel,
    viewAllAccountsItemLabel,
  } = words.accountNameDropdown;

  const subuserAccess = useAppSelector((store) => store.subuserAccess);
  let hasRestrictedSubuserAccess = true;
  if (subuserAccess.networkState === NetworkState.Success) {
    hasRestrictedSubuserAccess = subuserAccess.data?.hasRestrictedSubuserAccess;
  }

  const impersonatingUsername = Cookies.get(IMPERSONATING_USERNAME_COOKIE);

  const accountSwitcher = useAccountSwitcherState();

  useEffect(() => {
    if (!isSubuser) {
      getSubusers('')
        .then((fetchedSubusers) => {
          setSubusers(fetchedSubusers);
        })
        .catch(() => {
          console.log('Error fetching subusers');
        });
    }
  }, [isSubuser]);

  const shouldShowViewSubusersItem =
    (impersonatingUsername && isSubuser) || // Impersonating a Subuser
    (subusers.length > 0 && !impersonatingUsername && !isSubuser); // Parent Account

  const shouldShowGoBackItem =
    impersonatingUsername && isSubuser && !hasRestrictedSubuserAccess;

  const shouldShowSubusersBadge = impersonatingUsername && isSubuser;

  const onViewAllAccountsClick = useCallback((): void => {
    window.location.href = accountsHomeLink;
    accountSwitcher.hide();
  }, [accountSwitcher, accountsHomeLink]);

  function navigateToSubuserAccess(path: string) {
    const makoHost = APIConfig?.mako_host;
    window.location.href = `${makoHost}/${path}?redirect_to=${encodeURIComponent(
      window.location.href
    )}`;
  }

  const onViewSubusersClick = useCallback((): void => {
    navigateToSubuserAccess(allSubusersLink);
    accountSwitcher.hide();
  }, [accountSwitcher, allSubusersLink]);

  const onGoBackToMainAccountClick = useCallback((): void => {
    Cookies.remove(IMPERSONATING_USERNAME_COOKIE);
    accountSwitcher.hide();
    window.location.reload();
  }, [accountSwitcher]);

  useEffect(() => {
    const button = document.querySelector(
      '[data-paste-element="ACCOUNT_SWITCHER_BADGE_BUTTON"]'
    );
    if (button instanceof HTMLElement) {
      if (!shouldShowViewSubusersItem && !shouldShowGoBackItem) {
        button.style.display = 'none';
      } else {
        button.style.display = 'block';
      }
    }
  }, [shouldShowViewSubusersItem, shouldShowGoBackItem]);

  return (
    <Box
      display="flex"
      columnGap="space50"
      alignItems="center"
      marginLeft={'space100'}
    >
      <Box maxWidth="size30">
        <AccountSwitcherBadge
          {...accountSwitcher}
          loading={false}
          i18nButtonLabel="Friendly Name"
        >
          <Box maxWidth="250px">
            {<Truncate title={friendlyName}>{friendlyName}</Truncate>}
          </Box>
        </AccountSwitcherBadge>

        <AccountSwitcher {...accountSwitcher} aria-label="Friendly Name">
          {shouldShowViewSubusersItem && (
            <AccountSwitcherItem
              onClick={onViewSubusersClick}
              {...accountSwitcher}
            >
              <Box display="flex" alignItems="center">
                <ArrowBackIcon decorative={true} />
                {viewSubusersItemLabel}
              </Box>
            </AccountSwitcherItem>
          )}
          {shouldShowGoBackItem && (
            <AccountSwitcherItem
              onClick={onGoBackToMainAccountClick}
              {...accountSwitcher}
            >
              <Box display="flex" alignItems="center">
                <ArrowBackIcon decorative={true} />
                {goBackToMainAccountItemLabel}
              </Box>
            </AccountSwitcherItem>
          )}
          {/*Todo: Uncomment this when we the Twilio home functionality is ready*/}
          {/*<AccountSwitcherItem
            onClick={onViewAllAccountsClick}
            {...accountSwitcher}
          >
            <Box display="flex" alignItems="center">
              <ArrowBackIcon decorative={true} />
              {viewAllAccountsItemLabel}
            </Box>
          </AccountSwitcherItem>*/}
        </AccountSwitcher>
      </Box>

      {shouldShowSubusersBadge && (
        <Badge as="span" variant="subaccount">
          {subuserLabel}
        </Badge>
      )}
      {isTrial && (
        <Badge as="span" variant="success">
          {trialLabel}
          <Anchor href={AccountAndPlanUtils.PlanLinks.ChoosePlan}>
            {upgradeLabel}
          </Anchor>
        </Badge>
      )}
    </Box>
  );
};

export default AccountSwitcherWrapper;
