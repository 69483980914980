import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';

import {
  SSOIntegrationsData,
  SSOIntegrationsDto,
  SSOIntegrationDto,
  SSOIntegrationData,
  SSOIntegration,
  SSOIntegrationUpdate,
} from './types';
import { ResponseError } from '../../../../helperTypes/responseError';
import { SettingsSSORoutes } from '../../apiRoutes';
import { AuthedHttp } from '../../../../helpers/AuthedHttp';
import {
  SendGridRootState,
  SendGridAppDispatch,
} from '../../../../state/store/sendgridAppStore';
import { ssoIntegrationAdapter } from './adapter';
import { ServiceProviderCertificateDto } from '../ssoCertificates/types';

export interface SSOIntegrationsState {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  loadingPublicCert: boolean;
  publicCert?: ServiceProviderCertificateDto;
  data: SSOIntegrationsData;
  currentConfig: SSOIntegrationData;
  errorID: string;
}

const defaultConfig = {
  name: '',
  lastUpdated: 0,
  enabled: false,
  jitProvisioningEnabled: false,
  signinUrl: '',
  signoutUrl: '',
  singleSignonUrl: '',
  audienceUrl: '',
  entityId: '',
  id: '',
  completedIntegration: false,
};

const initialState: SSOIntegrationsState = {
  loading: true,
  saving: false,
  deleting: false,
  loadingPublicCert: true,
  publicCert: undefined,
  data: [],
  currentConfig: { ...defaultConfig },
  errorID: '',
};

export const fetchSSOIntegrations = createAsyncThunk<
  SSOIntegrationsData,
  any,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('FETCH_SSO_INTEGRATIONS', async (args, thunkApi) => {
  try {
    const ssoIntegrationsResp = await AuthedHttp.get<SSOIntegrationsDto>(
      SettingsSSORoutes.getSSOIntegrations()
    );

    if (!ssoIntegrationsResp.ok) {
      const ssoIntegrationError = await ssoIntegrationsResp.json();

      return thunkApi.rejectWithValue({
        message: `Failed to get SSO Integrations info`,
        error_id: ssoIntegrationError.errors[0].error_id,
      });
    }

    const ssoIntegrations = await ssoIntegrationsResp.json();

    const response = ssoIntegrations.map((ssoIntegration) =>
      ssoIntegrationAdapter(ssoIntegration)
    );

    return response;
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to get SSO Integrations info`,
      error_id: e,
    });
  }
});

export const fetchSSOIntegration = createAsyncThunk<
  SSOIntegrationData,
  { id: string },
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('FETCH_SSO_INTEGRATION', async (args, thunkApi) => {
  try {
    const ssoIntegrationsResp = await AuthedHttp.get<SSOIntegrationDto>(
      SettingsSSORoutes.getSSOIntegration(args.id)
    );

    if (!ssoIntegrationsResp.ok) {
      const ssoIntegrationError = await ssoIntegrationsResp.json();

      return thunkApi.rejectWithValue({
        message: `Failed to get SSO Integration info`,
        error_id: ssoIntegrationError.errors[0].error_id,
      });
    }
    const ssoIntegration = await ssoIntegrationsResp.json();
    const response = ssoIntegrationAdapter(ssoIntegration);

    return response;
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to get SSO Integration info`,
      error_id: e,
    });
  }
});

export const createSSOIntegration = createAsyncThunk<
  SSOIntegrationData,
  { newConfig: SSOIntegration },
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('CREATE_SSO_INTEGRATION', async (args, thunkApi) => {
  try {
    const ssoIntegrationResp = await AuthedHttp.post<SSOIntegrationDto>(
      SettingsSSORoutes.createSSOIntegration(),
      args.newConfig
    );

    if (!ssoIntegrationResp.ok) {
      const ssoIntegrationError = await ssoIntegrationResp.json();

      return thunkApi.rejectWithValue({
        message: `Failed to create SSO integration`,
        error_id: ssoIntegrationError.errors[0].error_id,
      });
    }
    const ssoIntegration = await ssoIntegrationResp.json();
    const result = ssoIntegrationAdapter(ssoIntegration);

    return result;
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to create SSO Integration`,
      error_id: e,
    });
  }
});

export const updateSSOIntegration = createAsyncThunk<
  SSOIntegrationData,
  {
    updated: SSOIntegrationUpdate;
    id: string;
  },
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('UPDATE_SSO_INTEGRATION', async (args, thunkApi) => {
  try {
    const ssoIntegrationResp = await AuthedHttp.patch<SSOIntegrationDto>(
      SettingsSSORoutes.updateSSOIntegration({
        id: args.id,
      }),
      args.updated
    );

    if (!ssoIntegrationResp.ok) {
      const ssoIntegrationError = await ssoIntegrationResp.json();

      return thunkApi.rejectWithValue({
        message: `Failed to update SSO integration`,
        error_id: ssoIntegrationError.errors[0].error_id,
      });
    }
    const ssoIntegration = await ssoIntegrationResp.json();
    const result = ssoIntegrationAdapter(ssoIntegration);

    return result;
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to update SSO integration`,
      error_id: e,
    });
  }
});

export const deleteSSOIntegration = createAsyncThunk<
  { id: string },
  {
    id: string;
  },
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('DELETE_SSO_INTEGRATION', async (args, thunkApi) => {
  try {
    const ssoIntegrationResp = await AuthedHttp.del<unknown>(
      SettingsSSORoutes.deleteSSOIntegration({
        id: args.id,
      })
    );

    if (!ssoIntegrationResp.ok) {
      const ssoIntegrationError = await ssoIntegrationResp.json();

      return thunkApi.rejectWithValue({
        message: `Failed to delete SSO integration`,
        error_id: ssoIntegrationError.errors[0].error_id,
      });
    }

    return { id: args.id };
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to delete SSO Integration`,
      error_id: e,
    });
  }
});

export const fetchServiceProviderCert = createAsyncThunk<
  ServiceProviderCertificateDto,
  any,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('FETCH_SERVICE_PROVIDER_CERT', async (args, thunkApi) => {
  try {
    const spCertResp = await AuthedHttp.get<ServiceProviderCertificateDto>(
      SettingsSSORoutes.getServiceProviderCert()
    );

    if (!spCertResp.ok) {
      const spCertError = await spCertResp.json();

      return thunkApi.rejectWithValue({
        message: `Failed to get Service Provider Cert`,
        error_id: spCertError.errors[0].error_id,
      });
    }
    const spCert = await spCertResp.json();

    return spCert;
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to get Service Provider Cert`,
      error_id: e,
    });
  }
});

export const clearSSOIntegrationErrorIDs = createAction(
  'clearSSOIntegrationErrorIDs'
);

const ssoIntegrationsSlice = createSlice({
  name: 'ssoIntegrations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // clear error IDs
    builder.addCase(clearSSOIntegrationErrorIDs, (state) => {
      state.errorID = '';
    });
    // Fetch Service Provider
    builder.addCase(fetchServiceProviderCert.pending, (state) => {
      state.loadingPublicCert = true;
    });
    builder.addCase(fetchServiceProviderCert.fulfilled, (state, action) => {
      state.loadingPublicCert = false;
      state.publicCert = action.payload;
    });
    builder.addCase(fetchServiceProviderCert.rejected, (state, action) => {
      state.loadingPublicCert = false;
      state.errorID = action.payload?.error_id ?? '';
    });

    // Fetch One integration
    builder.addCase(fetchSSOIntegration.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSSOIntegration.fulfilled, (state, action) => {
      state.loading = false;
      state.currentConfig = action.payload;
    });
    builder.addCase(fetchSSOIntegration.rejected, (state, action) => {
      state.loading = false;
      state.errorID = action.payload?.error_id ?? '';
    });

    // Fetch All integrations
    builder.addCase(fetchSSOIntegrations.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchSSOIntegrations.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(fetchSSOIntegrations.rejected, (state, action) => {
      state.loading = false;
      state.errorID = action.payload?.error_id ?? '';
    });

    // Create integration
    builder.addCase(createSSOIntegration.pending, (state) => {
      state.saving = true;
    });
    builder.addCase(createSSOIntegration.fulfilled, (state, action) => {
      state.saving = false;
      state.currentConfig = action.payload;
    });
    builder.addCase(createSSOIntegration.rejected, (state, action) => {
      state.saving = false;
      state.errorID = action.payload?.error_id ?? '';
    });

    // Update integration
    builder.addCase(updateSSOIntegration.pending, (state) => {
      state.saving = true;
    });
    builder.addCase(updateSSOIntegration.fulfilled, (state, action) => {
      state.saving = false;
      state.currentConfig = { ...state.currentConfig, ...action.payload };
    });
    builder.addCase(updateSSOIntegration.rejected, (state, action) => {
      state.saving = false;
      state.errorID = action.payload?.error_id ?? '';
    });

    // Delete integration
    builder.addCase(deleteSSOIntegration.pending, (state) => {
      state.deleting = true;
    });
    builder.addCase(deleteSSOIntegration.fulfilled, (state, action) => {
      state.deleting = false;
      state.currentConfig = { ...defaultConfig };
      const deleteIndex = state.data.findIndex(
        (dataz) => dataz.id === action.payload.id
      );
      state.data.splice(deleteIndex, 1);
    });
    builder.addCase(deleteSSOIntegration.rejected, (state, action) => {
      state.deleting = false;
      state.errorID = action.payload?.error_id ?? '';
    });
  },
});

export { ssoIntegrationsSlice };
