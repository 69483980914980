import defaultJSONHeaders from './default_json_headers';
import APIConfig from '../apiconfig';

// This should probably live here but I'm too lazy to update types
import { ResponseErrorsDto } from '../helperTypes/responseError';

export enum RequestTypes {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Patch = 'PATCH',
  Delete = 'DELETE',
}

export type ResponseBody<T> =
  | ResponseBodySuccess<T>
  | ResponseBodyError<ResponseErrorsDto>;

export interface ResponseBodySuccess<T> extends Response {
  ok: true;
  json(): Promise<T>;
  clone(): ResponseBodySuccess<T>;
}

export interface ResponseBodyError<T> extends Response {
  ok: false;
  json(): Promise<T>;
  clone(): ResponseBodyError<T>;
}

export type RequestOptions = Partial<
  RequestInit & {
    omitImpersonationHeader?: boolean;
    omitAuthorizationHeader?: boolean;
  }
>;

/**
 * namespace for all Authenticated Http calls
 */
// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace AuthedHttp {
  const buildRequest = (
    requestType: RequestTypes,
    body?: any,
    requestOptions?: RequestOptions
  ): Request => {
    let request = {} as any;
    // Spreading defaultJSONHeaders.headers to ensure we don't modify the default headers
    let headers = { ...defaultJSONHeaders.headers };
    if (requestOptions) {
      if (requestOptions.headers) {
        headers = { ...headers, ...requestOptions.headers };
      }
      const {
        omitImpersonationHeader,
        omitAuthorizationHeader,
        ...rest
      } = requestOptions;
      if (omitImpersonationHeader) {
        delete headers['On-behalf-of'];
      }
      if (omitAuthorizationHeader) {
        delete headers['Authorization'];
      }
      // We use {...rest } here since omitImpersonationHeaders, etc. is a custom option we
      // added and isn't recognized by native browser fetch
      request = { ...rest };
    }

    request.headers = headers;
    request.method = requestType;

    if (body) {
      request.body = JSON.stringify(body);
    }
    return request as Request;
  };

  // eslint-disable-next-line no-inner-declarations
  function makeRequest<T>(
    baseUri = APIConfig.host,
    uri: string,
    request: Request
  ): Promise<ResponseBody<T>> {
    const url = new URL(uri, baseUri);

    if (process.env.NODE_ENV === 'development') {
      const currentUrl = new URL(location.toString());

      currentUrl.searchParams.forEach((value, key) => {
        if (key.startsWith('mock_')) {
          url.searchParams.set(key, value);
        }
      });
    }
    const theRequest = fetch(url.toString(), request);

    // If we're in dev mode lets check to see if mocks are available
    if (process.env.NODE_ENV === 'development') {
      const theDopeRequest = theRequest.then((response) => response.clone());
      // This is a super jenky way to not have a bad import dependency in jest (we essentially only import in development mode in the browser)
      // Bringing in the sendgridappstore is pretty weird in this context but we did it anyways because it's how I made things work
      Promise.all([
        import('../state/mocks/mockslice'),
        import('../state/store/sendgridAppStore'),
      ]).then(([{ addMocks }, { sendgridAppStore }]) => {
        theDopeRequest
          .then((response) => {
            const availableMockRequests = response.headers.get(
              'x-mock-query-params'
            );

            if (availableMockRequests) {
              try {
                const parsedMock = JSON.parse(availableMockRequests);
                sendgridAppStore.dispatch(addMocks(parsedMock));
              } catch (e) {
                // if it errors ¯\_(ツ)_/¯ this is for funzies, but we should probably fix that
                console.warn(`We probably want to fix this ${response.url}`);
              }
            }
          })
          .catch((err) => {
            console.log('thedoperequest err:', err);
          });
      });
    }

    return theRequest as Promise<ResponseBody<T>>;
  }

  /**
   * Wrapper for the HTTP GET method
   * Requires the Return Type as the generic parameter
   * @param apiEndpoint - API Endpoint to hit
   * @param requestOptions - request options to override
   * @param baseUrl - optional parameter to change the root of the API being hit, defaults to MAKO API
   */
  export function get<T>(
    apiEndpoint: string,
    requestOptions?: RequestOptions,
    baseUrl?: string
  ): Promise<ResponseBody<T>> {
    const request = buildRequest(RequestTypes.Get, undefined, requestOptions);
    return makeRequest<T>(baseUrl, apiEndpoint, request);
  }

  /**
   * Wrapper for the HTTP POST method
   * Requires the Return Type as the generic parameter
   * @param apiEndpoint - API Endpoint to hit
   * @param body - json body to post
   * @param requestOptions - request options to override
   * @param baseUrl - optional parameter to change the root of the API being hit, defaults to MAKO API
   */
  export function post<T>(
    apiEndpoint: string,
    body?: any,
    requestOptions?: RequestOptions,
    baseUrl?: string
  ): Promise<ResponseBody<T>> {
    const request = buildRequest(RequestTypes.Post, body, requestOptions);
    return makeRequest<T>(baseUrl, apiEndpoint, request);
  }

  /**
   * Wrapper for the HTTP PUT method
   * Requires the Return Type as the generic parameter
   * @param apiEndpoint - API Endpoint to hit
   * @param body - json body to post
   * @param requestOptions - request options to override
   * @param baseUrl - optional parameter to change the root of the API being hit, defaults to MAKO API
   */
  export function put<T>(
    apiEndpoint: string,
    body: any,
    requestOptions?: RequestOptions,
    baseUrl?: string
  ): Promise<ResponseBody<T>> {
    const request = buildRequest(RequestTypes.Put, body, requestOptions);
    return makeRequest<T>(baseUrl, apiEndpoint, request);
  }

  /**
   * Wrapper for the HTTP PATCH method
   * Requires the Return Type as the generic parameter
   * @param apiEndpoint - API Endpoint to hit
   * @param body - json body to post
   * @param requestOptions - request options to override
   * @param baseUrl - optional parameter to change the root of the API being hit, defaults to MAKO API
   */
  export function patch<T>(
    apiEndpoint: string,
    body: any,
    requestOptions?: RequestOptions,
    baseUrl?: string
  ): Promise<ResponseBody<T>> {
    const request = buildRequest(RequestTypes.Patch, body, requestOptions);
    return makeRequest<T>(baseUrl, apiEndpoint, request);
  }

  /**
   * Wrapper for the HTTP DELETE method
   * Requires the Return Type as the generic parameter
   * @param apiEndpoint - API Endpoint to hit
   * @param body - json body to post
   * @param requestOptions - request options to override
   * @param baseUrl - optional parameter to change the root of the API being hit, defaults to MAKO API
   */
  export function del<T>(
    apiEndpoint: string,
    body?: any,
    requestOptions?: RequestOptions,
    baseUrl?: string
  ): Promise<ResponseBody<T>> {
    const request = buildRequest(RequestTypes.Delete, body, requestOptions);
    return makeRequest<T>(baseUrl, apiEndpoint, request);
  }

  /**
   * Wrapper for the MOCK HTTP method
   * Requires the Return Type as the generic parameter
   * @param apiEndpoint - API Endpoint to hit
   * @param body - json body to post
   * @param baseUrl - optional parameter to change the root of the API being hit, defaults to MAKO API
   */
  export function mock<T>(
    apiEndpoint: string,
    body?: any,
    baseUri?: string
  ): Promise<ResponseBody<T>> {
    const mockPromise = Promise.resolve({
      json: () => Promise.resolve({}),
    } as ResponseBody<T>);
    return mockPromise;
  }
}
