export enum AutomationStatusDto {
  Live = 'live',
  Paused = 'paused',
  Draft = 'draft',
}

export interface MarketingCampaignsAutomationDto {
  id: string;
  name: string;
  created_at: string;
  updated_at: string;
  live_at: string;
  message_count: number;
  status: AutomationStatusDto;
  type: string;
}

export interface LegacyMarketingCampaignsContactsCountDto {
  recipient_count: number;
}

export interface MarketingCampaignsContactsCountDto {
  billable_count: number;
  contact_count: number;
  billable_breakdown?: {
    breakdown: Record<number, number>,
    total: number;
  };
}

export interface SubuserDto {
  id: number
  username: string
}

export interface UsageStatsDto {
  email_api_sends: number;
  marketing_campaign_sends: number;
  email_validations: number;
}

export interface MarketingCampaignsSignupFormDto {
  id: string;
  list_id: string;
  created_at: string;
  updated_at: string;
  name: string;
  form_url: string;
}

export interface ApiKeysDto {
  result: ApiKeyDto[];
}

export interface ApiKeyDto {
  api_key_id: string;
  name: string;
  scopes?: string[];
}

export interface FeatureToggleDto {
  app_name: string;
  feature_name: string;
}
