import { AuthedHttp, ResponseBodySuccess } from '../../helpers/AuthedHttp';
import { Routes } from './routes';
import { SubuserDto } from '../dtos';
import { ResponseError } from '../types/responseError';

export interface SubusersRequestParams {
  limit?: number;
  username?: string;
}

const SUBUSERS_LIMIT = 500;

// Get subusers under a parent user
// You may set the following query params:
// - limit (optional): number of subusers matching username, defaults to 500 limit
// - username (optional): subuser's username (searches by exact prefix and is case-insensitive),
//                        defaults to empty string
export const getSubusersData = ({
  limit = SUBUSERS_LIMIT,
  username = '',
}: SubusersRequestParams = {}): Promise<
  SubuserDto[] | { errors: ResponseError[] }
> => {
  return AuthedHttp.get<SubuserDto[]>(
    `${Routes.Subusers}?limit=${limit}&username=${username}`
  )
    .then((res: ResponseBodySuccess<SubuserDto[]>) => res.json())
    .catch(() => {
      return {
        errors: [
          {
            message: 'Failed to retrieve subusers due to some reason!',
          },
        ] as ResponseError[],
      };
    });
};
