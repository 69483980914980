import { ActionsObservable, ofType } from 'redux-observable';
import * as UpgradeEligibilityActions from './actions';
import { from, Observable } from 'rxjs';
import { ActionTypes } from './actionTypes';
import { switchMap, map, catchError } from 'rxjs/operators';
import { fetchUpgradeEligibility } from './service';
import { UpgradeEligibility } from '../types/upgradeEligibility';

const upgradeEligibilityEpic = (
  action$: ActionsObservable<
    ReturnType<
      typeof UpgradeEligibilityActions.Actions.upgradeEligibilityRequest
    >
  >
): Observable<
  | ReturnType<
      typeof UpgradeEligibilityActions.Actions.upgradeEligibilitySuccess
    >
  | ReturnType<
      typeof UpgradeEligibilityActions.Actions.upgradeEligibilityFailure
    >
> => {
  return action$.pipe(
    ofType(ActionTypes.UpgradeEligibilityRequest),
    switchMap(() => {
      return from(fetchUpgradeEligibility()).pipe(
        map((upgradeEligibility: UpgradeEligibility) => {
          return UpgradeEligibilityActions.Actions.upgradeEligibilitySuccess(
            upgradeEligibility
          );
        }),
        catchError((error) => {
          return [
            UpgradeEligibilityActions.Actions.upgradeEligibilityFailure(
              error.message
            ),
          ];
        })
      );
    })
  );
};

export default [upgradeEligibilityEpic];
