import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthedHttp } from '../../helpers/AuthedHttp';
import { SendGridAppDispatch, SendGridRootState } from './sendgridAppStore';
import {
  DtoWrapper,
  GenericNetworkSlice,
  NetworkState,
} from './sliceNetworkTypes';
import { ResponseError } from '../../helperTypes/responseError';

export const billingAccountAdapter = (
  billingAccountDto: BillingAccountDto
): BillingAccount => {
  return {
    billingProviderAccountId: billingAccountDto.billing_provider_account_id,
    postPaymentMethodUrl: billingAccountDto.post_payment_method_url,
    signature: billingAccountDto.signature,
    token: billingAccountDto.token,
  };
};

enum Routes {
  BillingAccounts = 'billing/accounts',
}

export enum BillingAccountError {
  GenericFetchError = 'Network Error. Failed to fetch billing account.',
}

export interface BillingAccountDto {
  billing_provider_account_id: string;
  post_payment_method_url: string;
  signature: string;
  token: string;
}

export interface BillingAccount {
  billingProviderAccountId: string;
  postPaymentMethodUrl: string;
  signature: string;
  token: string;
}

export const fetchBillingAccount = createAsyncThunk<
  DtoWrapper<BillingAccountDto>,
  void,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('billingAccount/fetchBillingAccount', async (_, thunkApi) => {
  try {
    const response = await AuthedHttp.get<BillingAccountDto>(
      Routes.BillingAccounts
    );

    if (response.ok) {
      const data = await response.json();
      return {
        statusCode: response.status,
        data,
      };
    }

    const errorResponse = await response.json();

    return thunkApi.rejectWithValue({
      message: errorResponse.errors[0].message,
      statusCode: response.status,
    });
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: BillingAccountError.GenericFetchError,
    });
  }
});

export type BillingAccountStore = GenericNetworkSlice<BillingAccount>;

const initialState = {
  networkState: NetworkState.Unrequested,
  data: null,
} as BillingAccountStore;

export const billingAccountSlice = createSlice({
  name: 'billingAccount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchBillingAccount.pending, (state) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(fetchBillingAccount.fulfilled, (_, action) => {
      return {
        data: billingAccountAdapter(action.payload.data),
        statusCode: action.payload.statusCode,
        networkState: NetworkState.Success,
      };
    });
    builder.addCase(fetchBillingAccount.rejected, (_, action) => {
      return {
        statusCode: action.payload?.statusCode || 0,
        networkState: NetworkState.Error,
        errorMessage:
          action.payload?.message || BillingAccountError.GenericFetchError,
      };
    });
  },
});
