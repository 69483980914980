import { PackageDto, UserPackageDto } from '../dtos';
import packageConfig from './packageConfig';

interface OveragePackages {
  [key: string]: number;
}

// Some packages have incorrect overage rates from the API, so we're updating that here.

export type P = PackageDto | UserPackageDto;

// use this function when you need to update a single package
export const updatePackageOverages = (p: P): P => {
  const overagePackages: OveragePackages = packageConfig.overage_rates;

  if (p.name in overagePackages) {
    const updatedOveragePrice = overagePackages[p.name];
    return { ...p, overage_price: updatedOveragePrice };
  }
  return p;
};
