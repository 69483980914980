import React from 'react';
import { Topbar, TopbarActions } from '@twilio-paste/core/topbar';
import {
  UserDialog,
  UserDialogContainer,
  UserDialogList,
  UserDialogListItem,
  UserDialogSeparator,
  UserDialogUserEmail,
  UserDialogUserInfo,
  UserDialogUserName,
  useUserDialogListState,
} from '@twilio-paste/core/user-dialog';
import {
  ProductSwitcher,
  ProductSwitcherButton,
  ProductSwitcherItem,
  useProductSwitcherState,
} from '@twilio-paste/core/product-switcher';
import { ProductCommsIcon } from '@twilio-paste/icons/cjs/ProductCommsIcon';
import { ProductSegmentIcon } from '@twilio-paste/icons/cjs/ProductSegmentIcon';
import { ProductSendGridIcon } from '@twilio-paste/icons/cjs/ProductSendGridIcon';
import { UserIcon } from '@twilio-paste/icons/esm/UserIcon';
import { LogOutIcon } from '@twilio-paste/icons/esm/LogOutIcon';
import { DocumentationIcon } from '@twilio-paste/icons/esm/DocumentationIcon';
import { SupportIcon } from '@twilio-paste/icons/esm/SupportIcon';
import { Button } from '@twilio-paste/core/button';
import APIConfig from '../../apiconfig';
import { Text } from '@twilio-paste/core/text';
import { MenuSeparator } from '@twilio-paste/core/menu';
import { Box } from '@twilio-paste/box';
import { showGlobalNavBarUpdates } from 'src/helpers/featureFlags/showGlobalNavBarUpdates';
import AccountSwitcherWrapper from 'src/components/Navbar/AccountSwitcher/AccountSwitcherWrapper';
import { useAppSelector } from 'src/state/store/sendgridAppStore';
import { selectIsSubuser } from 'src/state/store/rtkQuery/endpoints/account';
import { selectAccountName } from 'src/state/store/rtkQuery/endpoints/accountProfile';

export const Navbar = ({
  showProductSwitcher,
  name,
  email,
}: {
  showProductSwitcher: boolean;
  name: string;
  email: string;
}) => {
  const userDialogList = useUserDialogListState();
  const productSwitcher = useProductSwitcherState();
  const encodedEmail = encodeURIComponent(email);
  const twilioConsoleUrl = `https://${APIConfig.twilioHost}/unified_login/start?email=${encodedEmail}&context=switch&source=sendgrid`;
  const userSettingsUrl = `${twilioConsoleUrl}&deeplink=user-settings`;
  const segmentUrl = `https://${APIConfig.segmentHost}/unified_login/start?email=${encodedEmail}&context=switch&source=sendgrid`;
  const accountsHomeLinkLegacy = `${twilioConsoleUrl}&deeplink=user-accounts`;
  const shouldShowGlobalNavBarUpdates = showGlobalNavBarUpdates();
  const isSubuser = useAppSelector(selectIsSubuser);
  const accountName = useAppSelector(selectAccountName);
  const allSubusersUrl = `subuser/access`;

  return (
    <Topbar id="topbar">
      {shouldShowGlobalNavBarUpdates && (
        <TopbarActions justify="start">
          <Box
            width="100%"
            display="flex"
            alignItems="center"
            columnGap={'space200'}
            marginLeft={'space200'}
            paddingLeft={'space200'}
          >
            {/*Todo: Uncomment this line when we the Twilio home functionality is ready*/}
            {/*<TwilioHomeButton homeLink={accountsHomeLinkLegacy} />*/}
            <AccountSwitcherWrapper
              friendlyName={accountName}
              isSubuser={isSubuser}
              isTrial={false}
              accountsHomeLink={accountsHomeLinkLegacy}
              allSubusersLink={allSubusersUrl}
            />
          </Box>
        </TopbarActions>
      )}
      <TopbarActions justify="end">
        {showProductSwitcher && (
          <>
            <ProductSwitcherButton
              {...productSwitcher}
              i18nButtonLabel="Switch products"
            />
            <ProductSwitcher
              {...productSwitcher}
              aria-label="Available products"
            >
              <ProductSwitcherItem
                {...productSwitcher}
                name="product"
                value="twilio"
                checked={false}
                onChange={() => {
                  window.location.href = twilioConsoleUrl;
                }}
                productName="Twilio Comms"
                productStrapline="SMS, Voice & Video"
                productIcon={
                  <ProductCommsIcon
                    decorative
                    color="colorTextBrandHighlight"
                    size="sizeIcon50"
                  />
                }
              />
              <MenuSeparator />
              <ProductSwitcherItem
                {...productSwitcher}
                name="product"
                value="segment"
                checked={false}
                onChange={() => {
                  window.location.href = segmentUrl;
                }}
                productName="Twilio Segment"
                productStrapline="Customer data platform"
                productIcon={
                  <Text as="span" style={{ color: '#6ADDB2' }}>
                    <ProductSegmentIcon decorative size="sizeIcon50" />
                  </Text>
                }
              />
              <MenuSeparator />
              <ProductSwitcherItem
                {...productSwitcher}
                name="product"
                value="sendgrid"
                checked={true}
                productName="Twilio SendGrid"
                productStrapline="Email API & Marketing Campaigns"
                productIcon={
                  <Text as="span" style={{ color: '#51A9E3' }}>
                    <ProductSendGridIcon decorative size="sizeIcon50" />
                  </Text>
                }
              />
            </ProductSwitcher>
          </>
        )}
        <UserDialogContainer
          name="User Name"
          icon={UserIcon}
          baseId="i-am-user-dialog"
        >
          <UserDialog aria-label="user menu" data-testid="basic-user-dialog">
            <UserDialogUserInfo tabIndex={-1}>
              <span style={{ wordBreak: 'break-all' }}>
                <UserDialogUserName>{name}</UserDialogUserName>
              </span>
              <span style={{ wordBreak: 'break-all' }}>
                <UserDialogUserEmail>{email}</UserDialogUserEmail>
              </span>
              <Button
                onClick={() => {
                  window.open(userSettingsUrl, '_blank');
                }}
                variant="link"
                tabIndex={-1}
              >
                <span style={{ fontWeight: 400 }}>User Settings</span>
              </Button>
            </UserDialogUserInfo>
            <UserDialogList {...userDialogList} aria-label="User menu actions">
              <UserDialogListItem
                {...userDialogList}
                tabIndex={-1}
                href="/account/details"
              >
                <UserIcon decorative />
                SendGrid Preferences
              </UserDialogListItem>
              <UserDialogSeparator />
              <UserDialogListItem
                {...userDialogList}
                tabIndex={-1}
                href="/guide"
              >
                <DocumentationIcon decorative />
                Setup Guide
              </UserDialogListItem>
              <span
                onClick={() => {
                  window.open(
                    'https://support.sendgrid.com/hc/en-us',
                    '_blank'
                  );
                }}
              >
                <UserDialogListItem {...userDialogList} tabIndex={-1}>
                  <SupportIcon decorative />
                  Help & Support
                </UserDialogListItem>
              </span>
              <UserDialogSeparator />
              <UserDialogListItem
                {...userDialogList}
                tabIndex={-1}
                href="/logout"
              >
                <LogOutIcon decorative />
                Log out
              </UserDialogListItem>
            </UserDialogList>
          </UserDialog>
        </UserDialogContainer>
      </TopbarActions>
    </Topbar>
  );
};
