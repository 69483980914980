import objectToQueryParams from '../../../helpers/objectToQueryParams';
import { BounceClassificationTypes } from './features/types';

export interface StatsQueryParams {
  aggregatedBy?: 'day' | 'week' | 'month';
  startDate: string; // in the format YYYY-MM-DD
  endDate?: string; // in the format YYYY-MM-DD
  limit?: number;
  offset?: number;
}

export type DownloadStatsQueryParams = Pick<
  StatsQueryParams,
  'aggregatedBy' | 'startDate' | 'endDate'
>;

interface MailboxProviderStatsQueryParams extends StatsQueryParams {
  mailboxProviders?: string[];
}

export interface BounceClassificationsQueryParams {
  startDate?: string; // in the format YYYY-MM-DD
  endDate?: string; // in the format YYYY-MM-DD
}

export interface BounceClassificationsByMailboxProviderQueryParams
  extends BounceClassificationsQueryParams {
  classification: BounceClassificationTypes;
}

// Stats calls require this format for start_date and end_date query strings
export const dateQueryFormat = 'YYYY-MM-DD';

export const EmailInsightsDeliverabilityRoutes = {
  getGlobalStats: ({
    aggregatedBy,
    startDate,
    endDate,
    limit,
    offset,
  }: StatsQueryParams) =>
    `stats${objectToQueryParams({
      start_date: startDate,
      ...(aggregatedBy ? { aggregated_by: aggregatedBy } : {}),
      ...(endDate ? { end_date: endDate } : {}),
      ...(limit ? { limit } : {}),
      ...(offset ? { offset } : {}),
    })}`,

  postGlobalStatsDownload: ({
    aggregatedBy,
    startDate,
    endDate,
  }: DownloadStatsQueryParams) =>
    `download/stats${objectToQueryParams({
      start_date: startDate,
      ...(aggregatedBy ? { aggregated_by: aggregatedBy } : {}),
      ...(endDate ? { end_date: endDate } : {}),
    })}`,

  getMailboxProviderStats: ({
    aggregatedBy,
    endDate,
    startDate,
    mailboxProviders,
    limit,
    offset,
  }: MailboxProviderStatsQueryParams) =>
    `mailbox_providers/stats${objectToQueryParams({
      start_date: startDate,
      ...(aggregatedBy ? { aggregated_by: aggregatedBy } : {}),
      ...(endDate ? { end_date: endDate } : {}),
      ...(mailboxProviders ? { mailbox_providers: mailboxProviders } : {}),
      ...(limit ? { limit } : {}),
      ...(offset ? { offset } : {}),
    })}`,

  postMailboxProviderStatsDownload: ({
    aggregatedBy,
    startDate,
    endDate,
  }: DownloadStatsQueryParams) =>
    `download/mailbox_providers/stats${objectToQueryParams({
      start_date: startDate,
      ...(aggregatedBy ? { aggregated_by: aggregatedBy } : {}),
      ...(endDate ? { end_date: endDate } : {}),
    })}`,

  getBounceClassificationsTotals: ({
    startDate,
    endDate,
  }: BounceClassificationsQueryParams) =>
    `suppressions/bounces/classifications${objectToQueryParams({
      ...(startDate ? { start_date: startDate } : {}),
      ...(endDate ? { end_date: endDate } : {}),
    })}`,

  getBounceClassificationByMailboxProvider: ({
    startDate,
    endDate,
    classification,
  }: BounceClassificationsByMailboxProviderQueryParams) =>
    `suppressions/bounces/classifications/${classification}${objectToQueryParams(
      {
        ...(startDate ? { start_date: startDate } : {}),
        ...(endDate ? { end_date: endDate } : {}),
      }
    )}`,
};

interface SubuserQueryParams {
  limit?: number;
  username?: string;
}

export const SubuserRoutes = {
  getSubusers: ({ limit = 20, username }: SubuserQueryParams) =>
    `subusers${objectToQueryParams({
      ...(limit ? { limit } : {}),
      ...(username ? { username } : {}),
    })}`,
};
