import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';

import { Ips } from './types';

export const Actions = {
  getIpsSuccess: (ips: Ips) => createAction(ActionTypes.GetIpsSuccess, ips),
  getIpsFailure: () => createAction(ActionTypes.GetIpsFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
