import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import {
  IDPCertificateData,
  IDPCertificateDto,
  IDPCertificateRequest,
} from './types';

import { ResponseError } from '../../../../helperTypes/responseError';
import { SettingsSSORoutes } from '../../apiRoutes';
import { AuthedHttp } from '../../../../helpers/AuthedHttp';
import {
  SendGridRootState,
  SendGridAppDispatch,
} from '../../../../state/store/sendgridAppStore';
import { IDPCertificateDtoToData } from './adapter';

export interface SSOCertificateStore {
  loading: boolean;
  saving: boolean;
  deleting: boolean;
  certs: { [key: number]: IDPCertificateData };
  certificatesByIdpId: { [key: string]: number[] | undefined };
  errorID: string;
}

const initialState: SSOCertificateStore = {
  loading: true,
  saving: false,
  deleting: false,
  certs: {},
  certificatesByIdpId: {},
  errorID: '',
};

export const createCertificateByIntegration = createAsyncThunk<
  IDPCertificateData,
  { certificate: IDPCertificateRequest },
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('CREATE_IDP_CERT', async (args, thunkApi) => {
  try {
    const certificateResponse = await AuthedHttp.post<IDPCertificateDto>(
      SettingsSSORoutes.createCertificate(),
      args.certificate
    );

    if (certificateResponse.ok) {
      const certificates = await certificateResponse.json();
      const adaptedCert = IDPCertificateDtoToData(certificates);

      return adaptedCert;
    }

    const ErrorResponse = await certificateResponse.json();

    return thunkApi.rejectWithValue({
      message: `Failed to create Certificate`,
      error_id: ErrorResponse.errors[0].error_id,
    });
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to create Certificate`,
      error_id: e,
    });
  }
});

export const deleteCertificateById = createAsyncThunk<
  { id: number; integrationId: string },
  { certId: number },
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('DELETE_IDP_CERT', async (args, thunkApi) => {
  try {
    const certificateResponse = await AuthedHttp.del(
      SettingsSSORoutes.deleteCertificate({ id: args.certId })
    );

    if (!certificateResponse.ok) {
      const ErrorResponse = await certificateResponse.json();

      return thunkApi.rejectWithValue({
        message: `Failed to delete Certificate`,
        error_id: ErrorResponse.errors[0].error_id,
      });
    }
    const store = thunkApi.getState();

    return {
      id: args.certId,
      integrationId: store.ssoCertificates.certs[args.certId].integrationId,
    };
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to delete Certificate`,
      error_id: e,
    });
  }
});

export const fetchCertificatesByIntegration = createAsyncThunk<
  { integrationId: string; certData: IDPCertificateData[] },
  { integrationId: string },
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('FETCH_IDP_CERTS_BY_INTEGRATION_ID', async (args, thunkApi) => {
  try {
    const certificateResponse = await AuthedHttp.get<IDPCertificateDto[]>(
      SettingsSSORoutes.getCertificates(args.integrationId)
    );

    if (!certificateResponse.ok) {
      const errors = await certificateResponse.json();

      return thunkApi.rejectWithValue({
        message: `Failed to get certificates by integration`,
        error_id: errors.errors[0].error_id,
      });
    }

    const certificates = await certificateResponse.json();

    const adaptedCerts = certificates.map((cert) =>
      IDPCertificateDtoToData(cert)
    );

    return { integrationId: args.integrationId, certData: adaptedCerts };
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to get certificates by integration`,
      error_id: e,
    });
  }
});

export const clearSSOCertificateErrorIDs = createAction(
  'clearSSOCertificateErrorIDs'
);

export const certificateSlice = createSlice({
  name: 'certificateSlice',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // clear error IDs
    builder.addCase(clearSSOCertificateErrorIDs, (state) => {
      state.errorID = '';
    });
    // Fetch All certificates for a given integration
    builder.addCase(fetchCertificatesByIntegration.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      fetchCertificatesByIntegration.fulfilled,
      (state, action) => {
        state.loading = false;
        state.certificatesByIdpId[action.payload.integrationId] = [];
        action.payload.certData.forEach((certificate) => {
          state.certs[certificate.id] = certificate;
          // We instantiated this above so we can be sure we're pushing to an array
          state.certificatesByIdpId[action.payload.integrationId]?.push(
            certificate.id
          );
        });
      }
    );
    builder.addCase(
      fetchCertificatesByIntegration.rejected,
      (state, action) => {
        state.loading = false;
        state.errorID = action.payload?.error_id ?? '';
      }
    );
    // Create Certificate
    builder.addCase(createCertificateByIntegration.pending, (state) => {
      state.saving = true;
    });
    builder.addCase(
      createCertificateByIntegration.fulfilled,
      (state, action) => {
        state.saving = false;
        state.certs[action.payload.id] = action.payload;

        if (
          state.certificatesByIdpId[action.payload.integrationId] === undefined
        ) {
          state.certificatesByIdpId[action.payload.integrationId] = [];
        }

        state.certificatesByIdpId[action.payload.integrationId]?.push(
          action.payload.id
        );
      }
    );
    builder.addCase(
      createCertificateByIntegration.rejected,
      (state, action) => {
        state.saving = false;
        state.errorID = action.payload?.error_id ?? '';
      }
    );
    // Delete Certificate
    builder.addCase(deleteCertificateById.pending, (state) => {
      state.deleting = true;
    });
    builder.addCase(deleteCertificateById.fulfilled, (state, action) => {
      const { id, integrationId } = action.payload;

      state.deleting = false;
      delete state.certs[id];

      if (state.certificatesByIdpId?.[integrationId] !== undefined) {
        state.certificatesByIdpId[integrationId] = state.certificatesByIdpId?.[
          integrationId
        ]?.filter((certId) => certId !== id);
      }
    });
    builder.addCase(deleteCertificateById.rejected, (state, action) => {
      state.deleting = false;
      state.errorID = action.payload?.error_id ?? '';
    });
  },
});
