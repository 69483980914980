import {
  teammates,
  automation,
  dedicatedIp,
  signupForms,
  noAutomation,
  segmentation,
  emailTesting,
  ticketSupport,
  noDedicatedIps,
  ticketAndChatSupport,
  designAndCodeEditors,
  ticketChatAndPhoneSupport,
  marketingCampaignsAnalytics,
} from './features';
import { PlanType } from '../../../types/planType';
import { OfferingDto } from '../../services/oes/dtos';
import { getTierSummaryMap } from '../../common/adapters';
import { FeatureToggle } from './../../../types/featureToggle';
import { MarketingCampaignsPlanTier } from '../../../types/marketingCampaignsPlan';
import {
  PlanTierFeatures,
  PlanTierFeature,
} from '../../../types/planTierFeatures';

export function mapMarketingCampaignsFeatures(
  offerings: OfferingDto[],
  featureToggles: FeatureToggle[]
): PlanTierFeatures {
  // TODO: Use this summary data when ready to display dynamic entitlemetns in feature lists
  // Convert the imported feature from an object to a function that takes the data you need to insert
  // https://issues.corp.twilio.com/browse/CL-875
  // tslint:disable-next-line
  const mcSummaries = getTierSummaryMap(offerings, PlanType.MarketingCampaigns);

  return {
    [MarketingCampaignsPlanTier.Free]: trimFeatures(featureToggles, [
      designAndCodeEditors,
      segmentation,
      marketingCampaignsAnalytics,
      emailTesting(3),
      signupForms(1),
      ticketSupport,
      teammates(1),
      automation,
      noDedicatedIps,
    ]),
    [MarketingCampaignsPlanTier.Basic]: trimFeatures(featureToggles, [
      designAndCodeEditors,
      segmentation,
      marketingCampaignsAnalytics,
      emailTesting(10),
      signupForms(5),
      ticketAndChatSupport,
      teammates(1),
      noAutomation,
      noDedicatedIps,
    ]),
    [MarketingCampaignsPlanTier.Advanced]: trimFeatures(featureToggles, [
      designAndCodeEditors,
      segmentation,
      marketingCampaignsAnalytics,
      emailTesting(60),
      signupForms(15),
      ticketChatAndPhoneSupport,
      teammates(1000),
      automation,
      dedicatedIp,
    ]),
  } as PlanTierFeatures;
}

/**
 * Determine if the feature list needs to have items removed based on feature toggles
 * @param userFt feature toggles that the user has
 * @param featureList full feature list
 * @returns Plan Tier Features that the user is allowed to see
 */
export const trimFeatures = (
  userFt: FeatureToggle[],
  featureList: PlanTierFeature[]
): PlanTierFeature[] => {
  return featureList.filter((item: PlanTierFeature) => {
    const itemFt = item.featureToggle;
    if (!itemFt) {
      // if no item feature toggle, we want it in the list
      return true;
    } else {
      // if item feature toggle, we only want the item if the user has the same feature toggle
      return userFt.some(
        (ft) =>
          ft.appName === itemFt.appName && ft.featureName === itemFt.featureName
      );
    }
  });
};
