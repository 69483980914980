import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';
import { ActionTypes } from './actionTypes';
import { UpdatedPlan } from '../types/updatedPlan';

const initialState = {
  isUpdated: false,
  isUpdatingPlan: false,
} as UpdatedPlan;

interface UpdatedPlanAction extends Action {
  payload: MakoStateType['updatedPlan'];
}

export const updatedPlan = (
  state = initialState,
  action: UpdatedPlanAction
) => {
  switch (action.type) {
    case ActionTypes.UpdatePlanRequest:
      return {
        ...state,
        isUpdatingPlan: true,
      };
    case ActionTypes.UpdatePlanSuccess:
      return {
        ...state,
        ...action.payload,
        isUpdatingPlan: false,
      };
    case ActionTypes.UpdatePlanFailure:
      return {
        ...state,
        ...action.payload,
        isUpdatingPlan: false,
      };
    case ActionTypes.ClearUpdatePlanServerErrors:
      return {
        ...state,
        errors: [],
        isUpdatingPlan: false,
      };
    default:
      return state;
  }
};
