import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthedHttp } from '../../helpers/AuthedHttp';
import { RequestStatus } from '../store/requestStatusStateTypes';
import {
  SendGridAppDispatch,
  SendGridRootState,
} from '../store/sendgridAppStore';

export const MFA_ROUTE = 'access_settings/multifactor';
export const SEND_OTP_SMS_ROUTE = `${MFA_ROUTE}/send`;

export enum MfaNetworkError {
  None = '',
  No2faAccount = 'User does not have a 2FA account',
  GenericFetchError = 'Network Error. Failed to fetch MFA settings',
  GenericDeleteError = 'Network Error. Failed to delete MFA settings',
  InvalidMfaCode = 'Authentication code invalid. Please enter your code exactly as provided.',
  GenericSendOtpTextError = 'Network Error. Failed to send OTP via SMS.',
}

export interface MfaSettingsSuccess {
  status: RequestStatus.Success;
  data: MfaSettings;
}

export interface MfaSettingsFailure {
  status: RequestStatus.Failure;
  data: null;
  error: string;
}

export interface MfaSettingsIdle {
  status: RequestStatus.Idle;
  data: null;
  error: null;
}

export interface MfaSettingsPending {
  status: RequestStatus.Pending;
  data: null;
  error: null;
}

export type MfaSettingsState =
  | MfaSettingsIdle
  | MfaSettingsPending
  | MfaSettingsSuccess
  | MfaSettingsFailure;

interface MfaSettingsDto {
  country_code: string;
  is_verified: boolean;
  method: string;
  phone: string;
}

interface MfaSettings {
  countryCode: string;
  isVerified: boolean;
  method: string;
  phone: string;
}

interface DeleteMfaSettingsRequestDto {
  multifactor_token: string;
}

const initialState: MfaSettingsIdle = {
  status: RequestStatus.Idle,
  data: null,
  error: null,
};

const deleteMfaSettingsRequestAdapter = (
  mfaToken: string
): DeleteMfaSettingsRequestDto => {
  return { multifactor_token: mfaToken };
};

const mfaSettingsAdapter = (mfaSettingsDto: MfaSettingsDto): MfaSettings => {
  return {
    countryCode: mfaSettingsDto.country_code,
    isVerified: mfaSettingsDto.is_verified,
    method: mfaSettingsDto.method,
    phone: mfaSettingsDto.phone,
  };
};

export const fetchMfaSettings = createAsyncThunk<
  MfaSettings,
  void,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
  }
>('mfaSettings/fetchMfaSettings', async () => {
  const response = await AuthedHttp.get<MfaSettingsDto>(MFA_ROUTE);
  if (response.ok) {
    const mfaSettingsDto = await response.json();
    return mfaSettingsAdapter(mfaSettingsDto);
  }
  if (response.status === 404) {
    throw new Error(MfaNetworkError.No2faAccount);
  }
  throw new Error(MfaNetworkError.GenericFetchError);
});

export const deleteMfaSettings = createAsyncThunk<
  void,
  string,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
  }
>('mfaSettings/deleteMfaSettings', async (mfaToken) => {
  const request = deleteMfaSettingsRequestAdapter(mfaToken);
  const response = await AuthedHttp.del<MfaSettingsDto>(MFA_ROUTE, request);
  if (response.ok) {
    return;
  }
  if (response.status === 400) {
    throw new Error(MfaNetworkError.InvalidMfaCode);
  }
  throw new Error(MfaNetworkError.GenericDeleteError);
});

export const sendOtpSms = createAsyncThunk<
  void,
  void,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
  }
>('mfaSettings/sendOtpSms', async () => {
  const response = await AuthedHttp.post<MfaSettingsDto>(SEND_OTP_SMS_ROUTE);
  if (response.ok) {
    return;
  }
  throw new Error(MfaNetworkError.GenericSendOtpTextError);
});

export const mfaSettingsSlice = createSlice<MfaSettingsState, any>({
  name: 'mfaSettings',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMfaSettings.pending, (state) => {
      state.status = RequestStatus.Pending;
    });
    builder.addCase(fetchMfaSettings.fulfilled, (_, action) => {
      return {
        data: action.payload,
        status: RequestStatus.Success,
      };
    });
    builder.addCase(fetchMfaSettings.rejected, (_, action) => {
      return {
        status: RequestStatus.Failure,
        data: null,
        error: action.error.message || MfaNetworkError.GenericFetchError,
      };
    });
    builder.addCase(deleteMfaSettings.fulfilled, (_) => {
      return {
        status: RequestStatus.Failure,
        data: null,
        error: MfaNetworkError.No2faAccount,
      };
    });
  },
});
