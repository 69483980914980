const APIConfig = require("apiconfig");
const ImpersonationModel = require("../models/impersonation_model");
require("jquery.cookie");

const COOKIE_NAME = "timezone_offset";

module.exports = ImpersonationModel.extend({
  initialize() {
    this.on("sync", function () {
      $.cookie(COOKIE_NAME, this.get("offset_seconds"));
    });
  },
  url: `${APIConfig.host}user/timezone`,
  idAttribute: "code",

  getOffsetSeconds() {
    // try to return the most accurate offset, in order of accuracy:
    // 1) model's offset
    // 2) offset stored in cookie
    // 3) offset guessed from browser
    return (
      this.get("offset_seconds") ||
      parseInt($.cookie(COOKIE_NAME)) ||
      new Date().getTimezoneOffset() * 60 ||
      0
    );
  },
  getOffsetMinutes() {
    return this.getOffsetSeconds() / 60;
  },
});
