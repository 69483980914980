import { AuthedHttp } from '../../helpers/AuthedHttp';
import { UpdateOfferingsDto } from '../dtos';
import { getEmailApiPlan } from '../middle_tier/common/adapters';
import { OES } from '../middle_tier/services/oes/service';
import { Routes } from './routes';
import { EaseSubscription } from './types';

const AcceptedStatusCode = 202;

export const deleteEaseService = async (): Promise<EaseSubscription> => {
  const camAccount = window.Reqres.request('camAccount');

  if (camAccount && camAccount.new_upgrade_enabled) {
    const account = await OES.getAccount();
    const emailPlan = getEmailApiPlan(account.offerings);

    if (!emailPlan?.name) {
      return Promise.reject('email plan name is undefined');
    }
    const ease = await OES.getAssociatedEaseOffering(emailPlan.name);

    const offeringsUpdate = {
      remove: [{ name: ease?.name }],
    } as UpdateOfferingsDto;

    const ssapiResponse = await AuthedHttp.patch<string>(
      Routes.OfferingsPatch,
      offeringsUpdate
    );

    if (!ssapiResponse.ok) {
      return Promise.reject('unable to patch offerings');
    }

    return Promise.resolve({} as EaseSubscription);
  }
  return AuthedHttp.del<any>(Routes.EaseStorage).then((res) => {
    if (res.status === AcceptedStatusCode) {
      return Promise.resolve();
    }
    return res.json();
  });
};

export const addEaseService = async (): Promise<void> => {
  const camAccount = window.Reqres.request('camAccount');

  if (camAccount && camAccount.new_upgrade_enabled) {
    const account = await OES.getAccount();
    const emailPlan = getEmailApiPlan(account.offerings);

    if (!emailPlan?.name) {
      return Promise.reject('email plan name is undefined');
    }
    const ease = await OES.getAssociatedEaseOffering(emailPlan.name);

    const offeringsUpdate = {
      add: [{ name: ease?.name }],
    } as UpdateOfferingsDto;

    const ssapiResponse = await AuthedHttp.patch<string>(
      Routes.OfferingsPatch,
      offeringsUpdate
    );

    if (!ssapiResponse.ok) {
      return Promise.reject('unable to patch offerings');
    }

    return Promise.resolve();
  }
  return AuthedHttp.post<any>(Routes.EaseStorage).then((res) => {
    if (res.status === AcceptedStatusCode) {
      return Promise.resolve();
    }
    return res.json();
  });
};
