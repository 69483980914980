const PASSWORD_RESET_REQUIRED = 'password_reset_required';

export const getIsPasswordResetRequired = (): boolean => {
  try {
    const item = localStorage.getItem(PASSWORD_RESET_REQUIRED);
    return item ? Boolean(JSON.parse(item)) : false;
  } catch (e) {
    console.warn(
      `failed to parse ${PASSWORD_RESET_REQUIRED} local storage item`,
      e
    );
    return false;
  }
};

export const setIsPasswordResetRequired = (isRequired: boolean) => {
  try {
    localStorage.setItem(
      PASSWORD_RESET_REQUIRED,
      JSON.stringify(Boolean(isRequired))
    );
  } catch (e) {
    console.warn(
      `failed to set ${PASSWORD_RESET_REQUIRED} local storage item`,
      e
    );
  }
};

export const deleteIsPasswordResetRequired = () => {
  window.localStorage.removeItem(PASSWORD_RESET_REQUIRED);
};
