/* eslint-disable @typescript-eslint/ban-ts-comment */
import { TiaraDefToPromise } from '../tiaraDeferredsToPromise';

// RTK slices
import {
  getFeatureTogglesFromTiara,
  FeatureToggleDto,
} from '../state/store/featureToggleSlice';
import { getScopesFromTiara, ScopesDto } from '../state/store/scopeSlice';
import {
  getUserTypeFromTiara,
  UserTypeDto,
} from '../state/store/userTypeSlice';
import { getAccountFromTiara, AccountDto } from '../state/store/accountSlice';
import { getProfileFromTiara, ProfileDto } from '../state/store/profileSlice';
import {
  getAccountProfile,
  AccountProfileDto,
} from '../state/store/accountProfileSlice';
import {
  getNonImpersonatedUsernameFromTiara,
  UsernameDto,
} from '../state/store/nonImpersonatedUsernameSlice';
import { getNonImpersonatedProfileFromTiara } from '../state/store/nonImpersonatedProfileSlice';
import {
  getSubuserAccessFromTiara,
  SubuserAccessDto,
} from '../state/store/subuserAccessSlice';
import {
  getUserEmailFromTokensEmailModel,
  EmailConfirmationDto,
} from '../state/store/userEmailSlice';

// RTK Query Endpoints
import { injectTiaraDefsIntoRtkQuery } from '../state/store/rtkQuery/utils';
import { Endpoints } from '../state/store/rtkQuery/Endpoints';
import { accountApi } from '../state/store/rtkQuery/endpoints/account';
import { userEmailApi } from '../state/store/rtkQuery/endpoints/userEmail';
import { scopesApi } from '../state/store/rtkQuery/endpoints/scopes';
import {
  camAccountApi,
  CamAccountDto,
} from '../state/store/rtkQuery/endpoints/camAccount';
import { usernameApi } from '../state/store/rtkQuery/endpoints/username';
import { accountProfileApi } from '../state/store/rtkQuery/endpoints/accountProfile';
import { sendgridAppStore } from '../state/store/sendgridAppStore';

/**
 * Type mimicking what is given back by Tiara.
 * It's either:
 * - an array with an object representing the dto,
 * - an array with an empty object or array representing empty data,
 * - an array with three entries
 *
 * See: tiaraDefToRtkQueryDataInjection() for more information on the structure.
 */
type TiaraDef<T> = [T] | [Record<string, never>] | [[]] | [any, any, any];

export type DispatchInitialDataArgs = {
  account?: TiaraDef<AccountDto>;
  profile?: TiaraDef<ProfileDto>;
  nonImpersonatedProfile?: TiaraDef<AccountProfileDto>;
  feature_toggles?: TiaraDef<FeatureToggleDto>;
  scopes?: TiaraDef<ScopesDto>;
  email?: TiaraDef<EmailConfirmationDto>;
  nonImpersonatedUsername?: TiaraDef<UsernameDto>;
  accountProfile?: TiaraDef<AccountProfileDto>;
  camAccount?: TiaraDef<CamAccountDto>;
  userType?: TiaraDef<UserTypeDto>;
  subuserAccess?: TiaraDef<SubuserAccessDto>;
};

/**
 * Given data either passed in from Tiara or manually, hydrates the passed in store with the data.
 */
export const dispatchInitialData = (
  {
    account = [{}], // [{}] and [[]] are set as a default in case nothing is passed in (e.g. when using in a test)
    profile = [{}],
    nonImpersonatedProfile = [{}],
    feature_toggles = [[]],
    scopes = [{}],
    email = [{}],
    nonImpersonatedUsername = [{}],
    accountProfile = [{}],
    camAccount = [{}],
    userType = [{}], // Could be undefined coming back if in login page,
    subuserAccess = [{}],
  }: DispatchInitialDataArgs,
  store: typeof sendgridAppStore
) => {
  const FeatureTogglePromise = TiaraDefToPromise(feature_toggles);
  const ScopesPromise = TiaraDefToPromise(scopes);
  const UserTypePromise = TiaraDefToPromise(userType);
  const AccountPromise = TiaraDefToPromise(account);
  const ProfilePromise = TiaraDefToPromise(profile);
  const NonImpersonatedProfilePromise = TiaraDefToPromise(
    nonImpersonatedProfile
  );
  const AccountProfilePromise = TiaraDefToPromise(accountProfile);
  const EmailPromise = TiaraDefToPromise(email);
  const NonImpersonatedUsernamePromise = TiaraDefToPromise(
    nonImpersonatedUsername
  );
  const SubuserAccessPromise = TiaraDefToPromise(subuserAccess);

  // Normal RTK dispatches
  store.dispatch(
    // @ts-ignore
    getFeatureTogglesFromTiara(FeatureTogglePromise)
  );
  // @ts-ignore
  store.dispatch(getScopesFromTiara(ScopesPromise));
  // @ts-ignore
  store.dispatch(getUserTypeFromTiara(UserTypePromise));
  // @ts-ignore
  store.dispatch(getAccountFromTiara(AccountPromise));
  // @ts-ignore
  store.dispatch(getProfileFromTiara(ProfilePromise));
  store.dispatch(
    // @ts-ignore
    getNonImpersonatedProfileFromTiara(NonImpersonatedProfilePromise)
  );
  // @ts-ignore
  store.dispatch(getAccountProfile(AccountProfilePromise));
  store.dispatch(
    // @ts-ignore
    getUserEmailFromTokensEmailModel(EmailPromise)
  );
  store.dispatch(
    // @ts-ignore
    getNonImpersonatedUsernameFromTiara(NonImpersonatedUsernamePromise)
  );
  store.dispatch(
    // @ts-ignore
    getSubuserAccessFromTiara(SubuserAccessPromise)
  );

  injectTiaraDefsIntoRtkQuery(
    [
      [userEmailApi.endpoints[Endpoints.fetchUserEmail].initiate, email],
      [accountApi.endpoints[Endpoints.fetchAccount].initiate, account],
      [camAccountApi.endpoints[Endpoints.fetchCamAccount].initiate, camAccount],
      [scopesApi.endpoints[Endpoints.fetchScopes].initiate, scopes],
      [
        accountProfileApi.endpoints[Endpoints.fetchAccountProfile].initiate,
        accountProfile,
      ],
      [
        usernameApi.endpoints[Endpoints.fetchUsername].initiate,
        nonImpersonatedUsername,
      ],
    ],
    store
  );
};
