import { GlobalStatsDto } from '../types';
import { GlobalMetricNames, DateToGlobalMetric } from './helpers';

export interface GlobalMetricCountByDate {
  MetricCountByDate: DateToGlobalMetric;
  OverallMetricCount: number;
  Dates: string[];
}

/*
  Calculate the overall count for a list of metric names (summed together) by date

  Usage: calculateGlobalMetricCountByDate(["bounces"], globalStatsDto); for bounce count by date
  Output:
  {
    MetricCountByDate: {
      "2021-02-01": 20,
      ...
    },
    OverallMetricCount: 120,
    Dates: ["2021-02-01", ...]
  }

  calculateGlobalMetricCountByDate["bounces", "blocks"], globalStatsDto); for sum of bounce and block counts by date
  Output:
  {
    MetricCountByDate: {
      "2021-02-01": 30,
      ...
    },
    OverallMetricCount: 200,
    Dates: ["2021-02-01", ...]
  }
*/
export function calculateGlobalMetricCountByDate(
  metricNames: GlobalMetricNames[],
  stats: GlobalStatsDto
): GlobalMetricCountByDate {
  const MetricCountByDate: DateToGlobalMetric = {};
  let OverallMetricCount = 0;
  const Dates: string[] = [];

  // Sum up the overall count for the metric names provided for each date
  stats.forEach((byDate) => {
    Dates.push(byDate.date);

    byDate.stats.forEach((metricsByDate) => {
      let metricCountByDate = 0;
      // We loop through the list of metrics to aggregate together i.e. ["bounces"] or ["bounces", "blocks"]
      metricNames.forEach((metricName) => {
        const metricCount = metricsByDate.metrics[metricName];
        metricCountByDate = metricCountByDate + metricCount;
      });

      MetricCountByDate[byDate.date] = metricCountByDate;
      OverallMetricCount = OverallMetricCount + metricCountByDate;
    });
  });

  return {
    MetricCountByDate,
    OverallMetricCount,
    Dates,
  };
}
