import { analytics } from '@sendgrid/bloodhound';
import { AuthedHttp } from './AuthedHttp';
import { shouldShowAntiSpoof } from './shouldShowAntiSpoof';

/*
  This will wrap all fetch calls and checks needed to determine if the user should see the Anti-Spoof friction
  
  Usage:
    determineShouldShowAntiSpoofFriction().then((shouldShowAntiSpoofFriction) => {
      if (shouldShowAntiSpoofFriction) {
        // Show the Anti-Spoof friction i.e. redirect to guide, show new guide step, show Tiara banner on certain routes, trigger events
      } else {
        // Don't show the Anti-Spoof friction i.e. user logs in as usual, no events triggered
      }
    })

  Users that should see the Anti-Spoof friction must meet the following criteria:
  * User should not have MC only signup intent
  * User should have "sender_verification_eligible" scope and not have "sender_verification_exempt" scope
    as a free Trial 40K or Essentials 40K package user
  * User did not verify a Sender or verify a Domain Auth
  
  Users who should see the Anti-Spoof friction will not be able to send emails through the API until the requirements are met

  Anti-Spoofing Logic Chart: https://drive.google.com/file/d/1XsZL6xytuuIAjec2j6Sj-QbkFNg_dQ_3/view?usp=sharing
*/
export const determineShouldShowAntiSpoofFriction = async (
  userId: number
): Promise<boolean> => {
  // TODO: remove when Anti-Spoof will stay permanently
  if (!shouldShowAntiSpoof()) {
    // In case we roll back changes, we need to clear localStorage to not show the Anti-Spoof banner anymore
    deleteIsInAntiSpoofLocalStorage();
    return false;
  }

  // Check for free Trial 40K/Essentials 40K users with proper Anti-Spoof eligible scope
  const isAntiSpoofEligibleBasedOnScopes = await determineIsAntiSpoofEligibleBasedOnScopes();
  if (!isAntiSpoofEligibleBasedOnScopes) {
    // In case someone is logging into different users i.e. one who should see Anti-Spoof
    // friction and one who should not see the friction, we'll attempt to clear localStorage
    // in case it is still set
    deleteIsInAntiSpoofLocalStorage();
    return false;
  }

  const hasVerifiedSenderOrDomainAuth = await determineHasVerifiedSenderOrDomainAuth();
  const shouldShowAntiSpoofFriction = !hasVerifiedSenderOrDomainAuth;

  if (shouldShowAntiSpoofFriction) {
    setIsInAntiSpoofLocalStorage();
    triggerInAntiSpoofEvent({ userId });
    return true;
  }

  deleteIsInAntiSpoofLocalStorage();
  return false;
};

export const antiSpoofEligibleScope = 'sender_verification_eligible';
export const antiSpoofExemptScope = 'sender_verification_exempt';

/*
  Check if a user is Anti-Spoof eligible by verifying /v3/scopes
  returns "sender_verification_eligible" and no "sender_verification_exempt"
  This will cover users who are free Trial 40K/Essentials 40K
*/
export const determineIsAntiSpoofEligibleBasedOnScopes = async (): Promise<
  boolean
> => {
  const { scopes } = await fetchScopesList();
  const hasAntiSpoofEligibleScope = scopes.some(
    (scope) => scope === antiSpoofEligibleScope
  );
  const hasAntiSpoofExemptScope = scopes.some(
    (scope) => scope === antiSpoofExemptScope
  );

  const isAntiSpoofEligibleBasedOnScopes =
    hasAntiSpoofEligibleScope && !hasAntiSpoofExemptScope;

  return isAntiSpoofEligibleBasedOnScopes;
};

interface ScopesListDto {
  scopes: string[];
}

export const fetchScopesList = (): Promise<ScopesListDto> => {
  return AuthedHttp.get<ScopesListDto>(`scopes`)
    .then((resp) => {
      if (resp.ok) {
        return resp.json();
      }

      throw new Error('Failed to retrieve scopes... Defaulting to no scopes!');
    })
    .catch(() => ({ scopes: [] }));
};

/* 
  If the user has verified a sender (sender_verified) or domain auth (domain_verified), the user fulfilled the Anti-Spoof requirements
  This convenience call saves us from having to make two separate GET calls for all senders and domains and validating which ones are verified
  and already tells us which steps are completed
*/
export const determineHasVerifiedSenderOrDomainAuth = async (): Promise<
  boolean
> => {
  const { results } = await fetchSenderStepsCompleted();
  const { sender_verified, domain_verified } = results;
  const hasVerifiedSenderOrDomainAuth = sender_verified || domain_verified;

  return hasVerifiedSenderOrDomainAuth;
};

interface SenderStepsCompletedDto {
  results: {
    sender_verified: boolean;
    domain_verified: boolean;
  };
}

export const fetchSenderStepsCompleted = (): Promise<
  SenderStepsCompletedDto
> => {
  return (
    AuthedHttp.get<SenderStepsCompletedDto>(`verified_senders/steps_completed`)
      .then((resp) => {
        if (resp.ok) {
          return resp.json();
        }

        throw new Error(
          'Failed to retrieve sender steps completed... Defaulting to completed!'
        );
      })
      // If we cannot determine verification, we default to both verified and we won't show the
      // Anti-Spoof friction to the user because that user may already be finished with the requirements
      .catch(() => ({
        results: { domain_verified: true, sender_verified: true },
      }))
  );
};

/*
  The "IN_ANTISPOOF" localStorage value is used to communicate with Tiara through the router_helpers.js file
  to hide/show the Anti-Spoofing banner on certain routes
*/
const IN_ANTISPOOF = 'IN_ANTISPOOF';
export const isInAntiSpoofLocalStorage = () =>
  Boolean(window.localStorage.getItem(IN_ANTISPOOF));

export const setIsInAntiSpoofLocalStorage = () => {
  window.localStorage.setItem(IN_ANTISPOOF, 'TRUE');
};

export const deleteIsInAntiSpoofLocalStorage = () => {
  window.localStorage.removeItem(IN_ANTISPOOF);
};

export const AntiSpoofLocalStorage = {
  isInAntiSpoof: isInAntiSpoofLocalStorage,
  setIsInAntiSpoof: setIsInAntiSpoofLocalStorage,
  deleteIsInAntiSpoof: deleteIsInAntiSpoofLocalStorage,
};

/*
  Depending on whether or not the user is seeing the Anti-Spoof friction and when the user verifies a
  Sender through the Mako Senders page or verifies a domain auth through Sender Auth, we will fire Segment
  events to track the user journey
  Backend will fire event, "sender_created", whenever a Sender is created
*/
export const triggerInAntiSpoofEvent = ({ userId }: { userId: number }) => {
  analytics.track({
    event: 'in_antispoof',
    properties: {
      userId,
    },
  });
};

export const triggerDomainVerifiedEvent = ({
  userId,
  domainId,
}: {
  userId: number;
  domainId: any;
}) => {
  analytics.track({
    event: 'domain_verified',
    properties: { userId, domainId },
  });
};

export const triggerSenderVerifiedEvent = ({ userId }: { userId: number }) => {
  analytics.track({
    event: 'sender_verified',
    properties: { userId },
  });
};

export const AntiSpoofSegmentEvents = {
  triggerInAntiSpoofEvent,
  triggerDomainVerifiedEvent,
  triggerSenderVerifiedEvent,
};

// If we redirect too soon, Segment events will not be fired and tracked
// We should wait at least 1s for events to fire
export const waitForAntiSpoofEventsToFire = (waitInMs: number) =>
  new Promise((resolveWait) => setTimeout(resolveWait, waitInMs));
