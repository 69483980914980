import { Action } from '../types/action';
import { ActionTypes } from './actionTypes';
import { MarketingCampaignsSubscription } from '../types/marketingCampaignsSubscription';

interface MarketingCampaignsSubscriptionAction extends Action {
  payload: MarketingCampaignsSubscription;
}

export const marketingCampaignsSubscription = (
  state = null,
  action: MarketingCampaignsSubscriptionAction
) => {
  switch (action.type) {
    case ActionTypes.GetMarketingCampaignsSubscriptionSuccess:
      return action.payload as MarketingCampaignsSubscription;
    default:
      return state;
  }
};
