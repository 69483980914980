import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { MarketingCampaignsSubscription } from '../types/marketingCampaignsSubscription';

export const Actions = {
  getMarketingCampaignsSubscriptionSuccess: (
    marketingCampaignsSubscription: MarketingCampaignsSubscription
  ) =>
    createAction(
      ActionTypes.GetMarketingCampaignsSubscriptionSuccess,
      marketingCampaignsSubscription
    ),
  getMarketingCampaignsSubscriptionFailure: () =>
    createAction(ActionTypes.GetMarketingCampaignsSubscriptionFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
