import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { TestingCreditsPlan } from './types';

export const Actions = {
  getTestingCreditsSuccess: (testingCreditsPlans: TestingCreditsPlan[]) =>
    createAction(
      ActionTypes.GetTestingCreditsPlansSuccess,
      testingCreditsPlans
    ),
  getTestingCreditsFailure: () =>
    createAction(ActionTypes.GetTestingCreditsPlansFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
