import { accountProfile } from './../account_profile/reducer';
import { ActionsObservable, ofType } from 'redux-observable';
import { from, Observable } from 'rxjs';
import { map, switchMap, catchError } from 'rxjs/operators';
// types
import { AlertContentKey } from '../types/ui';
import { MakoStateType } from '../types/makoStateType';
// actions
import { Actions as UIActions } from '../ui/actions';
import { Actions as EaseActions } from './actions';
import { ActionTypes } from './actionTypes';

// service
import { deleteEaseService, addEaseService } from './service';
import { buildOptimisticDeleteEaseState } from './buildOptimisticState';
import { Cache } from '../middle_tier/cache';
import { CacheKeys } from '../middle_tier/cache/keys';

interface EpicState {
  value: MakoStateType;
}

const deleteEase = (
  action$: ActionsObservable<ReturnType<typeof EaseActions.deleteEase>>,
  state$: EpicState
): Observable<
  | ReturnType<typeof EaseActions.deleteEaseSuccess>
  | ReturnType<typeof EaseActions.deleteEaseFailure>
  | ReturnType<typeof UIActions.createAlert>
> => {
  return action$.pipe(
    ofType(ActionTypes.DeleteEaseRequest),
    switchMap(() => {
      return from(deleteEaseService()).pipe(
        map(() => {
          const deleteState = buildOptimisticDeleteEaseState(
            state$.value.easeSubscription
          );
          const { userId } = state$.value.accountProfile;
          Cache.setStorageData(userId, CacheKeys.Ease, deleteState);
          return EaseActions.deleteEaseSuccess(deleteState);
        }),
        catchError(() => {
          return [
            EaseActions.deleteEaseFailure(),
            // TODO: confirm error banner for delete EASE failure
            UIActions.createAlert({
              type: 'danger',
              contentKey: AlertContentKey.GenericFailureContactSales,
            }),
          ];
        })
      );
    })
  );
};

const addEase = (
  action$: ActionsObservable<ReturnType<typeof EaseActions.addEase>>,
  state$: EpicState
): Observable<
  | ReturnType<typeof EaseActions.addEaseSuccess>
  | ReturnType<typeof EaseActions.addEaseFailure>
  | ReturnType<typeof UIActions.createAlert>
> => {
  return action$.pipe(
    ofType(ActionTypes.AddEaseRequest),
    switchMap(() => {
      return from(addEaseService()).pipe(
        map(() => {
          const { userId } = state$.value.accountProfile;
          const { easeSubscription } = state$.value;
          const purchasedEase = {
            ...easeSubscription,
            hasPurchasedEase: true,
            isPendingDowngrade: false,
          };
          Cache.setStorageData(userId, CacheKeys.Ease, purchasedEase);
          return EaseActions.addEaseSuccess(purchasedEase);
        }),
        catchError(() => {
          return [
            EaseActions.addEaseFailure(),
            UIActions.createAlert({
              type: 'danger',
              contentKey: AlertContentKey.GenericFailureContactSales,
            }),
          ];
        })
      );
    })
  );
};

export default [deleteEase, addEase];
