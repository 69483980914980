import { MailboxProviderStatsDto, MailboxProviderName } from '../types';
import {
  MSPMetricNames,
  MSPAggregation,
  calculateTopMSPsLookupFromMetricsCounts,
} from './helpers';

export interface MSPMetricCounts {
  MetricCounts: MSPAggregation;
  TopMSPsLookup: string[];
}

/*
  Calculate the overall metric counts by MSP
  Usage: calculateMSPMetricCounts("bounces", mspStatsDto); for overall bounce count per MSP
  Output: 
  {
    MetricCounts: {
      Gmail: 50,
      Yahoo: 25,
      AOL: 10,
      Microsoft Outlook Live: 10,
      Other: 5,
      ...
    },
    TopMSPsLookup: ["Gmail", "Yahoo", "AOL", "Microsoft Outlook Live", "Other",...]
  }
*/
export function calculateMSPMetricCounts(
  metricName: MSPMetricNames,
  stats: MailboxProviderStatsDto
): MSPMetricCounts {
  const MetricCounts: MSPAggregation = {};

  // Seed all the known MSPs to handle the empty data case where a user with no stats will get back an empty array
  Object.values({ ...MailboxProviderName }).forEach((mspName) => {
    MetricCounts[mspName] = 0;
  });

  // Sum up the counts for the metric for each MSP
  stats.forEach((byDate) => {
    byDate.stats.forEach((metricByMsp) => {
      const mspName: string = metricByMsp.name;

      const metricCount = metricByMsp.metrics[metricName] ?? 0;
      MetricCounts[mspName] = (MetricCounts[mspName] ?? 0) + metricCount;
    });
  });

  const TopMSPsLookup = calculateTopMSPsLookupFromMetricsCounts(MetricCounts);

  return { MetricCounts, TopMSPsLookup };
}

export function getTopMSPNamesFromMSPMetricCounts(
  numTopMSPs: number,
  MSPMetricCounts: MSPMetricCounts
): string[] {
  return [...MSPMetricCounts.TopMSPsLookup.slice(0, numTopMSPs)];
}
