import { ActionsUnion, createAction } from '../actionBuilder';
import { ActionTypes } from './actionTypes';
import { EasePlan } from './types';

export const Actions = {
  getEasePlansSuccess: (easePlans: EasePlan[]) =>
    createAction(ActionTypes.GetEasePlansSuccess, easePlans),
  getEasePlansFailure: () => createAction(ActionTypes.GetEasePlansFailure),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
