import { AuthedHttp, ResponseBodySuccess } from '../../helpers/AuthedHttp';
import { Routes } from './routes';
import { TimezoneDto } from '../dtos';

export function getTimezoneOptions(): Promise<TimezoneDto[] | void> {
  return AuthedHttp.get<TimezoneDto[]>(Routes.TimezoneOptions)
    .then((response: ResponseBodySuccess<TimezoneDto[]>) => {
      return response.json();
    })
    .catch((err) => {
      console.warn('Error fetching timezone options:', err);
    });
}
