import { Action } from '../types/action';
import { MakoStateType } from '../types/makoStateType';
import { ActionTypes } from './actionTypes';
import { OesAccountDto } from '../middle_tier/services/oes/dtos';

const initialState: MakoStateType['cancelAccountDiff'] = {
  entitlements: {},
} as OesAccountDto;

interface CancelAccountDiffAction extends Action {
  payload: MakoStateType['cancelAccountDiff'];
}

export const cancelAccountDiff = (
  state = initialState,
  action: CancelAccountDiffAction
) => {
  switch (action.type) {
    case ActionTypes.CancelAccountDiffSuccess:
      return action.payload;
    default:
      return state;
  }
};
