export enum ActionTypes {
  SubscriptionChanges = 'subscriptionChanges',
  SubscriptionChangesRequest = 'subscriptionChanges.request',
  SubscriptionChangesSuccess = 'subscriptionChanges.success',
  SubscriptionChangesFailure = 'subscriptionChanges.failure',
  SubscriptionChangesReset = 'subscriptionChanges.reset',

  StartTrial40K = 'startTrial40k',
  StartTrial40KRequest = 'startTrial40k.request',
  StartTrial40KSuccess = 'startTrial40k.success',
  StartTrial40KFailure = 'startTrial40k.failure',
}
