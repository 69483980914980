import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ResponseError } from '../../../../../helperTypes/responseError';
import { EmailInsightsDeliverabilityRoutes } from '../../apiRoutes';
import { AuthedHttp } from '../../../../../helpers/AuthedHttp';
import {
  SendGridRootState,
  SendGridAppDispatch,
} from '../../../../../state/store/sendgridAppStore';
import { MailboxProviderStatsDto } from '../types';
import {
  calculateMSPMetricRatesByDate,
  MSPMetricRatesByDate,
} from '../statCalculators/calculateMSPMetricRatesByDate';
import {
  calculateMSPMetricCountsByDate,
  MSPMetricCountsByDate,
} from '../statCalculators/calculateMSPMetricCountsByDate';
import { getTopMSPNames } from '../statCalculators/helpers';
import { StatsAggregationOptions } from '../../../components/StatsAggregationSelect';

export type EmailsSentToMSPMetrics = MSPMetricCountsByDate | null;

export type EmailsDeliveredToMSPsMetrics = MSPMetricRatesByDate | null;

export type OpenRatesByMSPMetrics = MSPMetricRatesByDate | null;

interface FetchedMailboxProviderStatsState {
  emailsSentToMSPMetrics: EmailsSentToMSPMetrics;
  emailsDeliveredToMSPsMetrics: EmailsDeliveredToMSPsMetrics;
  openRatesByMSPMetrics: OpenRatesByMSPMetrics;
}

export interface MailboxProviderStatsState
  extends FetchedMailboxProviderStatsState {
  isLoading: boolean;
  isError: boolean;
}

interface MailboxProviderStatsArgs {
  startDate: string;
  endDate?: string;
  subuser?: string;
  aggregatedBy?: StatsAggregationOptions;
}

const initialState: MailboxProviderStatsState = {
  emailsSentToMSPMetrics: null,
  emailsDeliveredToMSPsMetrics: null,
  openRatesByMSPMetrics: null,
  isLoading: true,
  isError: false,
};

export const emailsSentToMSPMetricsAdapter = (
  mailboxProviderStatsDto: MailboxProviderStatsDto
): EmailsSentToMSPMetrics => {
  const topMSPNames = getTopMSPNames(mailboxProviderStatsDto);
  const MSPProcessedCountsByDate = calculateMSPMetricCountsByDate(
    'processed',
    mailboxProviderStatsDto,
    topMSPNames
  );

  return MSPProcessedCountsByDate;
};

export const emailsDeliveredToMSPsMetricsAdapter = (
  mailboxProviderStatsDto: MailboxProviderStatsDto
): EmailsDeliveredToMSPsMetrics => {
  const topMSPNames = getTopMSPNames(mailboxProviderStatsDto);
  const MSPDeliveredRatesByDate = calculateMSPMetricRatesByDate(
    'delivered',
    'processed',
    mailboxProviderStatsDto,
    topMSPNames
  );

  return MSPDeliveredRatesByDate;
};

export const openRatesByMSPMetricsAdapter = (
  mailboxProviderStatsDto: MailboxProviderStatsDto
): MSPMetricRatesByDate => {
  const topMSPNames = getTopMSPNames(mailboxProviderStatsDto);
  const MSPUniqueOpensRatesByDate = calculateMSPMetricRatesByDate(
    'unique_opens',
    'delivered',
    mailboxProviderStatsDto,
    topMSPNames
  );

  return MSPUniqueOpensRatesByDate;
};

export const fetchMailboxProviderStats = createAsyncThunk<
  FetchedMailboxProviderStatsState,
  MailboxProviderStatsArgs,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('FETCH_MAILBOX_PROVIDER_STATS', async (args, thunkApi) => {
  try {
    const { startDate, endDate, subuser, aggregatedBy } = args;
    const mailboxProviderStatsResponse = await AuthedHttp.get<
      MailboxProviderStatsDto
    >(
      EmailInsightsDeliverabilityRoutes.getMailboxProviderStats({
        startDate,
        endDate,
        aggregatedBy,
      }),
      subuser
        ? ({ headers: { 'On-behalf-of': decodeURIComponent(subuser) } } as any)
        : undefined
    );

    if (mailboxProviderStatsResponse.ok) {
      const mailboxProviderStatsDto = await mailboxProviderStatsResponse.json();

      const adaptedEmailsSentToMSPMetrics = emailsSentToMSPMetricsAdapter(
        mailboxProviderStatsDto
      );
      const adaptedEmailsDeliveredToMSPsMetrics = emailsDeliveredToMSPsMetricsAdapter(
        mailboxProviderStatsDto
      );
      const adaptedOpenRatesByMSPMetrics = openRatesByMSPMetricsAdapter(
        mailboxProviderStatsDto
      );

      return {
        emailsSentToMSPMetrics: adaptedEmailsSentToMSPMetrics,
        emailsDeliveredToMSPsMetrics: adaptedEmailsDeliveredToMSPsMetrics,
        openRatesByMSPMetrics: adaptedOpenRatesByMSPMetrics,
      };
    }

    throw new Error('Unable to get mailbox provider stats');
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: 'Unable to get mailbox provider stats',
    });
  }
});

const mailboxProviderStatsSlice = createSlice({
  name: 'mailboxProviderStats',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchMailboxProviderStats.pending, (state, action) => {
      state.isLoading = true;
      state.emailsSentToMSPMetrics = null;
      state.emailsDeliveredToMSPsMetrics = null;
      state.openRatesByMSPMetrics = null;
    });
    builder.addCase(fetchMailboxProviderStats.fulfilled, (state, action) => {
      state.isLoading = false;
      state.isError = false;
      state.emailsSentToMSPMetrics = action.payload.emailsSentToMSPMetrics;
      state.emailsDeliveredToMSPsMetrics =
        action.payload.emailsDeliveredToMSPsMetrics;
      state.openRatesByMSPMetrics = action.payload.openRatesByMSPMetrics;
    });
    builder.addCase(fetchMailboxProviderStats.rejected, (state, action) => {
      state.isLoading = false;
      state.isError = true;
      state.emailsSentToMSPMetrics = null;
      state.emailsDeliveredToMSPsMetrics = null;
      state.openRatesByMSPMetrics = null;
    });
  },
});

export { mailboxProviderStatsSlice };
