import { teammates } from './teammates/reducer';
import { combineReducers } from 'redux';

// These lists are really long and easily duplicated.
// Please try to keep them alphabetized to prevent that.
import { account } from './account/reducer';
import { accountProfile } from './account_profile/reducer';
import { addExpertInsights } from './add_expert_insights/reducer';
import { addExpertServiceImplementation } from './add_expert_service_implementation/reducer';
import { cancelAccountDiff } from './cancel_account_diff/reducer';
import { cancelAccountInfo } from './cancel_account/reducer';
import { contacts } from './contacts/reducer';
import { easeSubscription } from './ease_subscription/reducer';
import { easePlans } from './ease_plans/reducer';
import { emailApiFeatures } from './email_api_features/reducer';
import { emailApiPlans } from './email_api_plans/reducer';
import { emailApiSubscription } from './email_api_subscription/reducer';
import { expertServicesImplementation } from './expert_services_implementation/reducer';
import { expertServicesOngoing } from './expert_services_ongoing/reducer';
import { ips } from './ips/reducer';
import { loading } from './loading/reducer';
import { marketingCampaignsFeatures } from './marketing_campaigns_features/reducer';
import { marketingCampaignsPlans } from './marketing_campaigns_plans/reducer';
import { marketingCampaignsSubscription } from './marketing_campaigns_subscription/reducer';
import { nextInvoice } from './next_invoice/reducer';
import { paymentMethod } from './payment_methods/reducer';
import { removeExpertInsights } from './remove_expert_insights/reducer';
import { scopes } from './scopes/reducer';
import { subscriptionChanges } from './subscription_changes/reducer';
import { subusers } from './subusers/reducer';
import { teammateRequestAccessInfo } from './teammate_request_access/reducer';
import { testingCreditsPlans } from './testing_credits_plans/reducer';
import { testingCreditsSubscription } from './testing_credits_subscription/reducer';
import { timezone } from './time_zone/reducer';
import { timezoneOptions } from './time_zone_options/reducer';
import { ui } from './ui/reducer';
import { updatedPlan } from './update_plan/reducer';
import { upgradeEligibility } from './upgrade_eligibility/reducer';
import { usageStats } from './usage_stats/reducer';
import { userType } from './user/reducer';
import { userPackage } from './user_package/reducer';
import { emailApiUsage } from './email_api_usage/reducer';
import { emailValidation } from './email_validation/reducer';

export const rootReducer = combineReducers({
  account,
  accountProfile,
  addExpertInsights,
  addExpertServiceImplementation,
  cancelAccountDiff,
  cancelAccountInfo,
  contacts,
  easeSubscription,
  easePlans,
  emailApiFeatures,
  emailApiPlans,
  emailApiSubscription,
  emailValidation,
  expertServicesImplementation,
  expertServicesOngoing,
  ips,
  loading,
  marketingCampaignsFeatures,
  marketingCampaignsPlans,
  marketingCampaignsSubscription,
  nextInvoice,
  paymentMethod,
  removeExpertInsights,
  scopes,
  subscriptionChanges,
  subusers,
  teammateRequestAccessInfo,
  teammates,
  testingCreditsPlans,
  testingCreditsSubscription,
  timezone,
  timezoneOptions,
  ui,
  updatedPlan,
  upgradeEligibility,
  usageStats,
  userType,
  userPackage,
  emailApiUsage,
});
