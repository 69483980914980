import { ActionsUnion } from './../actionBuilder';
import { EmailValidation } from './../types/emailValidation';
import { ActionTypes } from './actionTypes';
import { createAction } from '../actionBuilder';
import { ResponseError } from '../types/responseError';

export const Actions = {
  getEmailValidationSuccess: (emailValidation: EmailValidation) =>
    createAction(ActionTypes.GetEmailValidationSuccess, emailValidation),
  getEmailValidationFailure: (error: ResponseError) =>
    createAction(ActionTypes.GetEmailValidationFailure, error),
};

export type Actions = ActionsUnion<typeof Actions>;
export { Actions as EmailValidationActions };
export default Actions;
