import { ActionsObservable, ofType } from 'redux-observable';
import { concatMap, switchMap, catchError } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { Actions as AddExpertServiceImplementationActions } from './actions';
import { ActionTypes } from './actionTypes';
import { AddExpertServiceImplementationRequest } from '../types/addExpertServiceImplementation';
import { AddExpertServiceImplementationDto } from '../dtos';
import { addExpertServiceImplementation } from './service';
import { Actions as UIActions } from '../ui/actions';
import { AlertContentKey, ModalType } from '../types/ui';

const addExpertServiceImplementationEpic = (
  action$: ActionsObservable<
    ReturnType<
      typeof AddExpertServiceImplementationActions.addExpertServiceImplementation
    >
  >
): Observable<
  | ReturnType<
      typeof AddExpertServiceImplementationActions.addExpertServiceImplementationSuccess
    >
  | ReturnType<
      typeof AddExpertServiceImplementationActions.addExpertServiceImplementationFailure
    >
  | ReturnType<typeof UIActions.showModal>
  | ReturnType<typeof UIActions.hideModal>
  | ReturnType<typeof UIActions.createAlert>
> => {
  return action$.pipe(
    ofType(ActionTypes.AddExpertServiceImplementationRequest),
    switchMap((action) => {
      const request = action.payload as AddExpertServiceImplementationRequest;
      return from(addExpertServiceImplementation(request)).pipe(
        concatMap(() => {
          const modalType = ModalType.EmailImplementationPurchaseSuccess;
          return [
            AddExpertServiceImplementationActions.addExpertServiceImplementationSuccess(),
            UIActions.showModal({ type: modalType }),
          ];
        }),
        catchError((errors: AddExpertServiceImplementationDto) => {
          return [
            AddExpertServiceImplementationActions.addExpertServiceImplementationFailure(
              errors
            ),
            UIActions.hideModal(),
            UIActions.createAlert({
              type: 'danger',
              contentKey: AlertContentKey.GenericFailureContactSales,
            }),
          ];
        })
      );
    })
  );
};

export default [addExpertServiceImplementationEpic];
