import objectToQueryParam from '../../helpers/objectToQueryParams';
import { shouldShowIPRegions } from 'src/helpers/shouldShowIPRegions';

export const TeammatesRoutes = {
  createSSOTeammate: (): string => `sso/teammates`,

  updateSSOTeammate: ({ username }: { username: string }): string => {
    const encodedUsername = encodeURIComponent(username);
    return `sso/teammates/${encodedUsername}`;
  },

  // used for both password and sso teammates
  getTeammate: ({ username }: { username: string }): string => {
    const encodedUsername = encodeURIComponent(username);
    return `teammates/${encodedUsername}`;
  },

  getPersonas: (): string => `teammates/personas`,

  getScopesNames: (): string => `scopes/names`,

  getSubuserAccess: ({ username }: { username: string }): string =>
    `teammates/${encodeURIComponent(username)}/subuser_access`,
};

interface SubuserQueryParams {
  limit?: number;
  username?: string;
  after_subuser_id?: number; // User ID cursor of where to start the next page
  include_region?: string;
}

export const SubuserRoutes = {
  getSubusersCursor: ({
    limit = 100,
    username,
    after_subuser_id,
  }: SubuserQueryParams) =>
    `subusers_cursor${objectToQueryParam({
      ...(limit ? { limit } : {}),
      ...(username ? { username } : {}),
      ...(after_subuser_id ? { after_subuser_id } : {}),
      include_region: shouldShowIPRegions() ? true : undefined,
    })}`,
};
