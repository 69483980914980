import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { AuthedHttp } from '../../../../../helpers/AuthedHttp';
import routes from '../../../routes';
import {
  SendGridRootState,
  SendGridAppDispatch,
} from '../../../../../state/store/sendgridAppStore';
import { ResponseError } from '../../../../../helperTypes/responseError';
import {
  GenericNetworkSlice,
  NetworkState,
  DtoWrapper,
} from '../../../../../state/store/sliceNetworkTypes';

export type DomainConnectState = GenericNetworkSlice<DomainConnectDto>;

const initialState = {
  networkState: NetworkState.Unrequested,
  data: null,
} as DomainConnectState;

interface DomainConnectArgs {
  domainId: number;
}

export interface DomainConnectDto {
  url: string; // i.e. https://dcc.godaddy.com/manage/v2/domainTemplates/providers/exampleservice.domainconnect.org/services/template1/apply?domain=loclats.com&RANDOMTEXT=shm%3A1621361758%3Aasdf&IP=132.148.166.208
  provider: string; // i.e. "GoDaddy"
}

export const verifyDomainConnect = createAsyncThunk<
  DtoWrapper<DomainConnectDto>,
  DomainConnectArgs,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('VERIFY_DOMAIN_CONNECT', async (args, thunkApi) => {
  const { domainId } = args;

  try {
    const domainConnectResponse = await AuthedHttp.get<DomainConnectDto>(
      routes.verifyDomainConnect(domainId)
    );

    if (domainConnectResponse.ok) {
      const domainConnect = await domainConnectResponse.json();
      return { statusCode: domainConnectResponse.status, data: domainConnect };
    }

    const ErrorResponse = await domainConnectResponse.json();

    return thunkApi.rejectWithValue({
      message: ErrorResponse.errors[0].message,
      statusCode: domainConnectResponse.status,
    });
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: `Failed to verify domain connect`,
    });
  }
});

export const domainConnectSlice = createSlice({
  name: 'domainConnect',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(verifyDomainConnect.pending, (state) => {
      state.networkState = NetworkState.Loading;

      if (state.networkState === NetworkState.Loading) {
        state.data = null;
      }
    });
    builder.addCase(verifyDomainConnect.fulfilled, (state, action) => {
      state.networkState = NetworkState.Success;

      if (state.networkState == NetworkState.Success) {
        state.data = {
          url: action.payload.data.url,
          provider: action.payload.data.provider,
        };
        state.statusCode = action.payload.statusCode;
      }
    });
    builder.addCase(verifyDomainConnect.rejected, (state, action) => {
      state.networkState = NetworkState.Error;

      if (state.networkState == NetworkState.Error) {
        state.errorMessage =
          action.payload?.message ?? 'Failed to verify domain connect';
        state.statusCode = action.payload?.statusCode ?? 0;
      }
    });
  },
});
