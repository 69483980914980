import { AuthedHttp, ResponseBodySuccess } from '../../helpers/AuthedHttp';
import { Routes } from './routes';
import { TimezoneDto } from '../dtos';
import { Timezone } from '../types/user';

export function updateTimezone(timezone: Timezone): Promise<TimezoneDto> {
  const requestBody = {
    code: timezone.code,
  };
  return AuthedHttp.put<TimezoneDto>(Routes.UserTimezone, requestBody)
    .then((response: ResponseBodySuccess<TimezoneDto>) => {
      return response.json();
    })
    .catch(() => {
      return {
        errors: [
          {
            field: 'timezone',
            message:
              'An error occurred attempting to update your timezone. Please try again.',
          },
        ],
      };
    });
}
