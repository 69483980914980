import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { SendGridAppDispatch, SendGridRootState } from './sendgridAppStore';
import { ResponseError } from '../types/responseError';
import { ResponseBody } from '../../helpers/AuthedHttp';
import { GenericNetworkSlice, NetworkState } from './sliceNetworkTypes';

export enum UserType {
  Unlimited = 'unlimited',
  Free = 'free',
  Active = 'active',
  Paid = 'paid',
  Recurring = 'recurring',
  Subuser = 'subuser',
  Reseller = 'reseller',
  ResellerCustomer = 'reseller_customer',
  ResellerCustomerFree = 'reseller_customer_free',
  ResellerCustomerSubuser = 'reseller_customer_subuser',
}

export interface UserTypeDto {
  type: UserType;
}

export type UserTypeStore = GenericNetworkSlice<UserTypeDto>;

const initialUserTypeState = {
  networkState: NetworkState.Unrequested,
} as UserTypeStore;

export const getUserTypeFromTiara = createAsyncThunk<
  UserTypeDto,
  ResponseBody<UserTypeDto>,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('GET_USER_TYPE_FROM_TIARA', async (args, thunkApi) => {
  try {
    const userTypeResp = await args;

    if (userTypeResp.ok) {
      const response = await userTypeResp.json();
      return response;
    }

    throw new Error('Network Response be Janky');
  } catch {
    return thunkApi.rejectWithValue({
      message: 'Network Level Error',
    } as ResponseError);
  }
});

export const userTypeSlice = createSlice({
  name: 'userType',
  initialState: initialUserTypeState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserTypeFromTiara.pending, (state, action) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(getUserTypeFromTiara.fulfilled, (state, action) => {
      state.networkState = NetworkState.Success;

      if (state.networkState === NetworkState.Success) {
        state.data = {
          type: action.payload.type,
        };
      }
    });
    builder.addCase(getUserTypeFromTiara.rejected, (state, action) => {
      state.networkState = NetworkState.Error;

      if (state.networkState === NetworkState.Error) {
        state.errorMessage = action?.payload?.message ?? 'Unknown Error';
      }
    });
  },
});
