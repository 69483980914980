import { ActionsObservable, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import * as TimezoneActions from './actions';
import { ActionTypes } from './actionTypes';
import { getTimezoneOptions } from './service';
import { from, Observable } from 'rxjs';
import { TimezoneDto } from '../dtos';

const getTimezoneOptionsEpic = (
  action$: ActionsObservable<
    ReturnType<typeof TimezoneActions.Actions.getTimezoneOptionsRequest>
  >
): Observable<
  | ReturnType<typeof TimezoneActions.Actions.getTimezoneOptionsSuccess>
  | ReturnType<typeof TimezoneActions.Actions.getTimezoneOptionsFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.GetTimezoneOptionsRequest),
    switchMap(() => {
      return from(getTimezoneOptions());
    }),
    map((data: TimezoneDto[]) => {
      // TODO: handle once we know the shape of the errors
      return TimezoneActions.Actions.getTimezoneOptionsSuccess(data);
    })
  );
};

export default [getTimezoneOptionsEpic];
