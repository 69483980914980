import { TypedMap } from '../@types/TypedMap';

export function mapper<T>(obj: T) {
  type WithIndex = TypedMap<any>;
  const sanitizedObj = {} as WithIndex;

  if (!obj) {
    return getCleanedValue(obj);
  }

  // if it's an array we want to sanitize all values within
  if (isArray(obj)) {
    return (obj as any).map(mapper);
  }

  // if it's not an object we want to sanitize that value
  if (!isObject(obj)) {
    return getCleanedValue(obj);
  }

  // it's an object, so we iterate over the keys and sanitize each key
  for (const key in obj) {
    if (key) {
      const mappedKey = snakeToCamelCase(key);
      sanitizedObj[mappedKey] = mapper(obj[key]) as WithIndex;
    }
  }

  return sanitizedObj;
}

export function snakeToCamelCase(str = '') {
  return str.replace(/_\w/g, (m) => m[1].toUpperCase());
}

function getCleanedValue(value: any): any {
  if (isZero(value)) {
    return 0;
  }

  if (isFalse(value)) {
    return false;
  }

  return value || '';
}

function isArray(value: any): boolean {
  return Array.isArray(value);
}

function isObject(value: any): boolean {
  return typeof value === 'object' && value !== null && value !== undefined;
}

function isZero(value: any): boolean {
  return value === 0;
}

function isFalse(value: any): boolean {
  return value === false;
}
