import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { AuthedHttp } from '../../helpers/AuthedHttp';
import {
  SendGridAppDispatch,
  SendGridRootState,
} from '../store/sendgridAppStore';
// types
import { OesAccountDto } from '../middle_tier/services/oes/dtos';
import {
  DtoWrapper,
  GenericNetworkSlice,
  NetworkState,
  Unrequested,
} from '../store/sliceNetworkTypes';
import { ResponseError } from '../../helperTypes/responseError';

const GenericFetchErrorMessage = 'Network Error. Failed to fetch OES account';

export const isPaymentDeclinedSelector = createSelector(
  (state: SendGridRootState) => state.oesAccount,
  (oesAccount) => {
    return (
      oesAccount.networkState === NetworkState.Success &&
      oesAccount.data.entitlements.compat_payment_declined === true
    );
  }
);

export const isIndiaCustomerSelector = createSelector(
  (state: SendGridRootState) => state.oesAccount,
  (oesAccount) => {
    return (
      oesAccount.networkState === NetworkState.Success &&
      oesAccount.data.entitlements.zuora_tax_country === 'India'
    );
  }
);

export const fetchOesAccount = createAsyncThunk<
  DtoWrapper<OesAccountDto>,
  void,
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('oes/fetchAccount', async (_, thunkApi) => {
  try {
    const response = await AuthedHttp.get<OesAccountDto>(
      'accounts/offerings?_show&_pricing&_offerings'
    );

    if (response.ok) {
      return {
        statusCode: response.status,
        data: await response.json(),
      };
    }

    const errorResponse = await response.json();

    return thunkApi.rejectWithValue({
      message: errorResponse.errors[0].message,
      statusCode: response.status,
    });
  } catch (e) {
    return thunkApi.rejectWithValue({
      message: GenericFetchErrorMessage,
    });
  }
});

export type OesAccountState = GenericNetworkSlice<OesAccountDto>;

const initialState: Unrequested = {
  networkState: NetworkState.Unrequested,
  data: null,
};

export const oesAccountSlice = createSlice<OesAccountState, any>({
  name: 'oesAccount',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchOesAccount.pending, (state) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(fetchOesAccount.fulfilled, (_, action) => {
      return {
        data: action.payload.data,
        statusCode: action.payload.statusCode,
        networkState: NetworkState.Success,
      };
    });
    builder.addCase(fetchOesAccount.rejected, (_, action) => {
      return {
        statusCode: action.payload?.statusCode || 0,
        networkState: NetworkState.Error,
        errorMessage: action.payload?.message || GenericFetchErrorMessage,
      };
    });
  },
});
