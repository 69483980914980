import { MakoStateType } from '../types/makoStateType';
import { UsageStats } from '../types/user';
import { Action } from '../types/action';
import { UsageStatsDto } from '../dtos';
import { ActionTypes } from './actionTypes';
import { usageStatsAdapter } from './adapters';

const initialState: MakoStateType['usageStats'] = {} as UsageStats;

interface UsageStatsAction extends Action {
  payload: MakoStateType['usageStats'] | UsageStatsDto;
}

export const usageStats = (state = initialState, action: UsageStatsAction) => {
  switch (action.type) {
    case ActionTypes.CreateUsageStats:
      return usageStatsAdapter(action.payload as UsageStatsDto);
    default:
      return state;
  }
};
