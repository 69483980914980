import { ActionsObservable, ofType } from 'redux-observable';
import { map, switchMap } from 'rxjs/operators';
import * as TimezoneActions from './actions';
import { ActionTypes } from './actionTypes';
import { updateTimezone } from './service';
import { from, Observable } from 'rxjs';
import { TimezoneDto } from '../dtos';

const updateTimezoneEpic = (
  action$: ActionsObservable<
    ReturnType<typeof TimezoneActions.Actions.updateTimezoneRequest>
  >
): Observable<
  | ReturnType<typeof TimezoneActions.Actions.updateTimezoneSuccess>
  | ReturnType<typeof TimezoneActions.Actions.updateTimezoneFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.UpdateTimezoneRequest),
    switchMap((action) => {
      return from(updateTimezone(action.payload));
    }),
    map((data: TimezoneDto) => {
      if (data.errors) {
        return TimezoneActions.Actions.updateTimezoneFailure(data);
      }
      return TimezoneActions.Actions.updateTimezoneSuccess(data);
    })
  );
};

export default [updateTimezoneEpic];
