import { SignupStatusDto } from '../dtos';

export const isVerified = (signupStatus: SignupStatusDto): boolean => {
  const {
    email_complete,
    email_required,
    mfa_complete,
    mfa_required,
    whitelabel_complete,
    whitelabel_required,
  } = signupStatus;
  if (email_required && !email_complete) {
    return false;
  }

  if (mfa_required && !mfa_complete) {
    return false;
  }

  if (whitelabel_required && !whitelabel_complete) {
    return false;
  }
  return true;
};
