const Marionette = require("backbone.marionette");
const $ = require("jquery");
const _ = require("underscore");
const APIConfig = require("apiconfig");
const Flash = require("../helpers/flashes");

require("bootstrap-sass/assets/javascripts/bootstrap/dropdown");
const moment = require("moment");

const { analytics } = require("@sendgrid/bloodhound");
const { GroupEvents } = require("../analytics/suppressions/groupEvents");

module.exports = Marionette.ItemView.extend({
  template: require("templates/suppressions_header.hbs"),
  selectAll: false,
  ui: {
    dateRangeButton: "[role=dateRangeButton]",
    calendarContainer: "[role=calendarContainer]",
    refresh: "[role=refresh]",
    unsubsToAdd: "[role=unsubs]",
    confirmClearAllModal: "[data-role=confirmClearAllModal]",
    modal: "[role=modal]",
    modalMask: "[role=modalMask]",
    dropdownMenu: "[role=selectDropdownMenu]",
  },
  events: {
    "click @ui.refresh": "refresh",
    "click [role=removeSelected]": "removeSelected",
    "click [role=downloadCSV]": "csvDownload",
    "click [role=manualAdd]": "manualAdd",
    "click [role=addUnsub]": "submitManualUnsubs",
    "click [role=cancel]": "hideUnsubs",
    "click [role=modalMask]": "hideUnsubs",
    "click [role=removeAll]": "clearAll",
    "click [role=manageGroups]": "manageGroups",
    "click [role=clearAll]": "clearAll",
    "click [role=cancelClear]": "cancelClear",
    "click [role=confirmClear]": "confirmClear",
    "click @ui.dateRangeButton": () =>
      analytics.track(GroupEvents.datePickerButton),
    "click @ui.dropdownMenu": "analyticsTrackSelectDropdownOpen",
  },
  collectionEvents: {
    updateCounter: "render",
    selectAll: "selectAllState",
  },

  initialize() {
    this.csvLink = $('<a id="download-link"></a>');
    window.EventBus.on("clearAllModals", _.bind(this.hideUnsubs, this));
  },

  serializeData() {
    const results = {};
    results.selectedCount = this.collection.where({ checked: true }).length;
    results.isSelected = this.collection.where({ checked: true }).length > 0;
    results.downloadable = this.collection.type !== "group_unsubscribes";
    results.canAdd = this.collection.type === "unsubscribes";
    results.selectAll =
      this.selectAll && this.collection.type !== "group_unsubscribes";
    results.selectAllGroups =
      this.selectAll && this.collection.type === "group_unsubscribes";

    return results;
  },

  onRender() {
    this.ui.dateRangeButton
      .daterangepicker({
        startDate: moment().utcOffset(
          -window.Reqres.request("timezone").getOffsetMinutes()
        ),
        endDate: moment().utcOffset(
          -window.Reqres.request("timezone").getOffsetMinutes()
        ),
        opens: "left",
        format: "MMM D, YYYY",
        autoApply: true,
      })
      .on("apply.daterangepicker", this.applyDaterangepicker.bind(this));
  },
  applyDaterangepicker(e, picker) {
    this.collection.startTime = picker.startDate.unix();
    this.collection.endTime = picker.endDate.unix();
    this.collection.offset = 0;
    this.collection.fetch();
  },

  selectAllState(state) {
    this.selectAll = state;
  },

  refresh() {
    analytics.track(GroupEvents.refreshButton);
    const { collection } = this;
    collection.fetch({
      success() {
        collection.trigger("updateCounter");
      },
    });
  },

  csvDownload() {
    const postData = {};
    if (this.collection.startTime) {
      postData.start_time = this.collection.startTime;
    }
    if (this.collection.endTime) {
      postData.end_time = this.collection.endTime;
    }
    if (this.collection.searchEmail) {
      postData.email = this.collection.searchEmail;
    }
    const { csvLink } = this;
    $.ajax({
      type: "POST",
      context: this,
      url: `${APIConfig.host}download/suppression/${
        this.collection.type
      }?${$.param(postData)}`,
      headers: {
        Accept: "text/csv",
      },
      success(response) {
        csvLink.attr("href", response.download_url);
        this.$el.append(csvLink);
        csvLink[0].click();
        this.$el.remove("#download-link");
      },
    });
  },

  manualAdd() {
    this.ui.modal.addClass("is-visible");
    this.ui.modalMask.addClass("is-visible");
  },

  clearAll() {
    this.ui.confirmClearAllModal.addClass("is-visible");
  },

  cancelClear() {
    this.ui.confirmClearAllModal.removeClass("is-visible");
  },

  manageGroups() {
    analytics.track(GroupEvents.manageGroupsButton);
    window.EventBus.trigger(
      "navigate",
      "suppressions/advanced_suppression_manager"
    );
  },

  confirmClear() {
    const { type } = this.collection;
    $.ajax({
      url: `${APIConfig.host}suppression/${type}`,
      type: "DELETE",
      contentType: "application/json",
      context: this,
      data: JSON.stringify({ delete_all: true }),
    })
      .success(() => {
        window.EventBus.trigger(
          "flash",
          "All suppressions were successfully deleted."
        );
      })
      .fail(() => {
        window.EventBus.trigger("flash");
      })
      .always(function () {
        this.ui.confirmClearAllModal.removeClass("is-visible");
        this.collection.fetch({ reset: true });
      });
  },

  removeSelected() {
    analytics.track(GroupEvents.removeSelectedGroupsButton);
    const XHRS = [];
    const { type } = this.collection;
    const suppressionEmails = [];
    this.collection.each((model) => {
      if (model.get("checked")) {
        let XHR;
        if (model.has("group_id")) {
          XHR = $.ajax({
            url: `${APIConfig.host}asm/groups/${model.get(
              "group_id"
            )}/suppressions/${model.get("email")}`,
            type: "DELETE",
          });
          XHRS.push(XHR);
        } else {
          suppressionEmails.push(model.get("email"));
        }
      }
    });

    if (!_.isEmpty(suppressionEmails)) {
      const XHR = $.ajax({
        url: `${APIConfig.host}suppression/${type}`,
        type: "DELETE",
        contentType: "application/json",
        dataType: "json",
        data: JSON.stringify({ emails: suppressionEmails }),
      });
      XHRS.push(XHR);
    }

    const { collection } = this;
    $.when(...XHRS).then(
      () => {
        if (XHRS.length > 1) {
          window.EventBus.trigger(
            "flash",
            `${XHRS.length} events were successfully deleted.`
          );
        } else if (suppressionEmails.length > 1) {
          window.EventBus.trigger(
            "flash",
            `${suppressionEmails.length} events were successfully deleted.`
          );
        } else {
          window.EventBus.trigger("flash", "1 event was successfully deleted.");
        }
        collection.fetch({
          success() {
            collection.trigger("updateCounter");
          },
        });
      },
      () => {
        window.EventBus.trigger("flash");
        collection.fetch({
          success() {
            collection.trigger("updateCounter");
          },
        });
      }
    );
  },

  hideUnsubs() {
    this.ui.modal.removeClass("is-visible");
    this.ui.modalMask.removeClass("is-visible");
    this.ui.unsubsToAdd.val("");
  },

  submitManualUnsubs() {
    const rawEmailText = this.ui.unsubsToAdd.val();
    const emails = this.splitEmails(rawEmailText);
    const count = emails.length;

    if (count === 0) {
      Flash.danger(this.ui.modal, "No emails specified.");
      return;
    }

    const formattedEmails = emails.map((email) => ({
      email,
    }));

    $.ajax({
      type: "POST",
      url: `${APIConfig.host}suppression/unsubscribes`,
      contentType: "application/json",
      dataType: "json",
      context: this,
      data: JSON.stringify({ emails: formattedEmails }),
    })
      .done(function () {
        const plural = count === 1 ? "" : "s";
        const msg = `${count} recipient${plural} added`;
        window.EventBus.trigger("flash", msg);
        this.hideUnsubs();
        this.collection.fetch();
      })
      .fail(function (response) {
        Flash.danger(this.ui.modal, response.responseJSON.errors[0].message);
      });
  },

  splitEmails(text) {
    return text.split(/[\s,]+/).filter((email) => email.length > 0);
  },

  analyticsTrackSelectDropdownOpen(e) {
    const isOpen = $(e.currentTarget).hasClass("is-active");
    if (!isOpen) {
      analytics.track(GroupEvents.selectDropdownMenu);
    }
  },
});
