import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AuthedHttp } from '../../helpers/AuthedHttp';
import {
  SendGridAppDispatch,
  SendGridRootState,
} from '../store/sendgridAppStore';
import APIConfig from '../../apiconfig';
import { ResponseError } from '../../helperTypes/responseError';
import {
  DtoWrapper,
  GenericNetworkSlice,
  NetworkState,
} from './../store/sliceNetworkTypes';

export enum Routes {
  Invoices = 'billing/invoices',
}

export enum InvoicesError {
  NoInvoices = 'User does not have any invoices',
  GenericError = 'Network Error. Failed to fetch invoices',
}

export interface InvoiceDto {
  id: string;
  invoice_number: string;
  due_date: string;
  total_amount: string;
  unpaid_balance: string;
  status: string;
  pdf_status?: string;
}

export interface AdaptedInvoice
  extends Omit<
    InvoiceDto,
    | 'invoice_number'
    | 'due_date'
    | 'total_amount'
    | 'unpaid_balance'
    | 'pdf_status'
  > {
  invoiceNumber: string;
  dueDate: string;
  totalAmount: string;
  unpaidBalance: string;
  pdfStatus?: string;
}
export interface Zuora {
  url: string;
  id: string;
  doPayment: string;
  storePaymentMethod: string;
  field_accountId: string;
  documents: string;
  tenantId: string;
  token: string;
  key: string;
  signature: string;
}

export interface InvoicesDto {
  account_balance: string;
  result: InvoiceDto[];
  zuora_hpm_params?: Zuora;
}

export interface Invoices {
  accountBalance: string;
  result: AdaptedInvoice[];
  zuoraParams?: Zuora;
}

export const fetchInvoices = createAsyncThunk<
  DtoWrapper<Invoices>,
  {
    zuoraHpmParams?: boolean;
  },
  {
    dispatch: SendGridAppDispatch;
    state: SendGridRootState;
    rejectValue: ResponseError;
  }
>('invoices/fetchInvoices', async (args, thunkApi) => {
  try {
    let route = `${Routes.Invoices}`;

    if (args.zuoraHpmParams) {
      route += '?zuora_hpm_params';

      if (APIConfig.zuora_payment_page_id) {
        route += `&page_id=${APIConfig.zuora_payment_page_id}`;
      }
    }

    const response = await AuthedHttp.get<InvoicesDto>(route);

    if (response.ok) {
      const data = await response.json();
      return {
        statusCode: response.status,
        data: invoicesAdapter(data),
      };
    }

    if (response.status === 404) {
      return thunkApi.rejectWithValue({
        message: InvoicesError.NoInvoices,
        statusCode: response.status,
      });
    }

    const errorResponse = await response.json();

    return thunkApi.rejectWithValue({
      message: errorResponse.errors[0].message,
      statusCode: response.status,
    });
  } catch (err) {
    return thunkApi.rejectWithValue({
      message: InvoicesError.GenericError,
    } as ResponseError);
  }
});

export const invoicesAdapter = (dto: InvoicesDto): Invoices => {
  const accountBalance = dto.account_balance;
  const result = dto.result.map((invoice) => ({
    ...invoice,
    invoiceNumber: invoice.invoice_number,
    dueDate: invoice.due_date,
    totalAmount: invoice.total_amount,
    unpaidBalance: invoice.unpaid_balance,
    pdfStatus: invoice.pdf_status,
  }));

  if (dto.zuora_hpm_params) {
    return {
      accountBalance,
      result,
      zuoraParams: dto.zuora_hpm_params,
    };
  }

  return {
    accountBalance,
    result,
  };
};

export type InvoicesStore = GenericNetworkSlice<Invoices>;

const initialState = {
  networkState: NetworkState.Unrequested,
} as InvoicesStore;

export const invoicesSlice = createSlice<InvoicesStore, any>({
  name: 'invoices',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchInvoices.pending, (state) => {
      state.networkState = NetworkState.Loading;
    });
    builder.addCase(fetchInvoices.fulfilled, (_, action) => ({
      networkState: NetworkState.Success,
      statusCode: action.payload.statusCode,
      data: action.payload.data,
    }));
    builder.addCase(fetchInvoices.rejected, (_, action) => ({
      networkState: NetworkState.Error,
      statusCode: action.payload?.statusCode ?? 0,
      errorMessage: action.payload?.message ?? InvoicesError.GenericError,
      data: null,
    }));
  },
});
