import { updateBrowserFingerprintHeader } from '../default_json_headers';

let _base64Fingerprint: string;

export const generateBrowserFingerprint = async (): Promise<string> => {
  if (!window.SendGridTiara.browserFingerprintReady) {
    return Promise.reject('failed to get browser fingerprint from tiara');
  }
  return await window.SendGridTiara.browserFingerprintReady;
};

export const getBrowserFingerprintHeader = (): string | undefined => {
  return _base64Fingerprint;
};

export const setBrowserFingerprintHeader = async (): Promise<void> => {
  const fingerprint = await generateBrowserFingerprint();
  _base64Fingerprint = btoa(fingerprint);
  updateBrowserFingerprintHeader(_base64Fingerprint);
};
