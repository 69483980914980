import { ActionsObservable, ofType } from 'redux-observable';
import * as UserActions from './actions';
import { from, Observable } from 'rxjs';
import { ActionTypes } from './actionTypes';
import { switchMap, map, catchError } from 'rxjs/operators';
import { fetchUserType } from './service';
import { UserAccountType } from '../types/user';

const userTypeEpic = (
  action$: ActionsObservable<
    ReturnType<typeof UserActions.Actions.userTypeRequest>
  >
): Observable<
  | ReturnType<typeof UserActions.Actions.userTypeSuccess>
  | ReturnType<typeof UserActions.Actions.userTypeFailure>
> => {
  return action$.pipe(
    ofType(ActionTypes.UserTypeRequest),
    switchMap(() => {
      return from(fetchUserType()).pipe(
        map((userType: UserAccountType) => {
          const errors = userType.errors;
          if (errors) {
            return UserActions.Actions.userTypeFailure({ errors });
          }
          return UserActions.Actions.userTypeSuccess(userType);
        }),
        catchError((error) => {
          return [
            UserActions.Actions.userTypeFailure({
              errors: [{ message: error.message }],
            }),
          ];
        })
      );
    })
  );
};

export default [userTypeEpic];
