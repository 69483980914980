import { ActionTypes } from './actionTypes';
import { CancelAccountRequestBody } from '../types/cancelAccount';
import { CancelAccountInfoDto } from '../dtos';
import { ActionsUnion, createAction } from '../actionBuilder';

export const Actions = {
  cancelAccountRequest: (cancelAccountRequestBody: CancelAccountRequestBody) =>
    createAction(ActionTypes.CancelAccountRequest, cancelAccountRequestBody),
  cancelAccountSuccess: () => createAction(ActionTypes.CancelAccountSuccess),
  cancelAccountFailure: (errors: CancelAccountInfoDto) =>
    createAction(ActionTypes.CancelAccountFailure, errors),
  resetCancelAccountInfo: () =>
    createAction(ActionTypes.ResetCancelAccountInfo),
};

export type Actions = ActionsUnion<typeof Actions>;

export default Actions;
