import { Action } from '../types/action';
import { ActionTypes } from './actionTypes';
import { TestingCreditsSubscription } from './types';

interface TestingCreditsAction extends Action {
  payload: TestingCreditsSubscription;
}

export const testingCreditsSubscription = (
  state = {},
  action: TestingCreditsAction
) => {
  switch (action.type) {
    case ActionTypes.GetTestingCreditsSubscriptionSuccess:
      return action.payload;
    case ActionTypes.GetTestingCreditsSubscriptionFailure:

    case ActionTypes.UpdateTestingCreditSubscriptionSuccess:
      return action.payload;

    default:
      return state;
  }
};
